import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService"; // api service class that contains general services ( functions and variables )

class CartService {
  // guest Id function
  get guestId() {
    return localStorage.getItem("guestId");
  }

  // get User Cart function
  async getUserCart() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.cart.getUserCart}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // clear Cart function
  async clearCart() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.cart.clearMyCart}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get User Favorites function
  async getUserFavorites() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.cart.getUserFavorites}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Checkout Amounts function
  async getCheckoutAmounts(cartData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.cart.getCheckoutAmounts, {
          ...cartData,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // check Coupon If Valid function
  async checkCouponIfValid(cartData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.cart.checkCouponIfValid, {
          ...cartData,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const cartService = new CartService();
