import React, { Component } from "react";
import PageTitle from "../../blocks/PageTitle";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { connect } from "react-redux";
import { setUser } from "../../../redux/actions-reducers/user";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { generalServices } from "../../../services/GeneralService";
import PaymentMethods from "../../Pages/Subscriptions/PaymentMethods";
import CheckoutSummaryBlock from "../BeamCheckout/CheckoutSummaryBlock";
import { cartService } from "../../../services/CartService";
import Swal from "sweetalert2";
import { productService } from "../../../services/ProductService";

export class CheckoutPayment extends Component {
  state = {
    adsData: {},
    adsLoader: false,
    currentStep: 1,
    cartAmount: {},
    cart: [],
    selectPayment: {},
    withPrice: true,
    delivery_company_id: 0,
    cartLoading: false,
    deliveryTypeName: "",
  };

  componentDidMount() {
    this.setState({ cartLoading: true });

    Promise.all([
      this.getSingleAds(),
      this.getUserCart(),
      this.getCheckoutAmounts(),
    ]).finally(() => this.setState({ cartLoading: false }));
  }

  handlePaymentSubmit = (cb = () => {}) => {
    if (this.state.cart.length > 0) {
      // if there is any bundle in cart,
      // check that there are at least BUNDLE_QTY items in cart that are part of this bundle
      // if not, return

      //  first find all products that are bundles
      const bundles = this.state.cart.filter((item) => item.product?.bundle);
      if (bundles.length > 0) {
        // if there are bundles in cart, check that there are at least BUNDLE_QTY items in cart that are part of this bundle
        bundles.forEach((bundle) => {
          console.log("bundle", bundle);
          const bundleItems = this.state.cart.filter(
            (item) => item?.bundle_id === bundle.product?.id
          );
          if (bundleItems.length < bundle.product?.bundle_count) {
            // Here i must remove the bundle from cart
            // and show error message

            // remove bundle from cart
            productService
              .addToCart(bundle.product_option?.id, 0)
              .then((res) => {
                // reload cart
                if (res.success) {
                  cartService.getUserCart().then(async (res) => {
                    await this.getCartIDs();

                    // set cart to current state
                    this.setState({
                      ...this.state,
                      cart: res.data,
                    });
                    this.props.setUser({
                      ...this.props.currentUser,
                      cart: res.data.length,
                    });

                    // show error message
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: this.props.i18n.bundleProductSoldOutError,
                    }).then(() => {
                      this.props.history.push(
                        `/ProductDetailsBundle/${bundle.product?.machine_name}`
                      );
                    });
                  });
                }
              });

            return;
          } else {
            cb();
          }
        });
      } else {
        cb();
      }
    }
  };

  // get Cart IDs start
  getCartIDs = async () => {
    // function to get user cart from the server and set it to user redux store data
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };
  // get Cart IDs end

  // get Single Ads start
  getSingleAds = async () => {
    // function to get Single Ads
    const { data, success } = await generalServices.getSingleAds(
      "home_under_categories_block_left_space",
      0,
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      adsData: data,
      adsLoader: false,
    });
  };
  // get Single Ads end

  deliveryCompanySet = (x) => {
    // function to handle delivery Company Set by set it to the state
    if (x !== this.state.delivery_company_id) {
      this.setState({ delivery_company_id: x }, () =>
        this.getCheckoutAmounts()
      );
    }
  };

  render() {
    const {
      i18n: { Payment, Home, CartMobile, Checkout },
    } = this.props;

    const {
      cartLoading,
      selectPayment,
      cartAmount,
      cart,
      withPrice,
      delivery_company_id,
    } = this.state;

    return (
      <div className="subscriptions">
        {/* page title start  */}
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={Payment}
          links={[
            { name: Home, url: "/" },
            { name: CartMobile, url: "/cart" },
            { name: Checkout, url: "/Checkout" },
            { name: Payment, url: "/checkoutpayment" },
          ]}
        />
        {/* page title end  */}
        <Container>
          <div className="subscriptions__questions">
            <Row>
              <Col md={12} lg={8} style={{ marginBottom: "100px" }}>
                {cartLoading ? (
                  <div>
                    <Skeleton height={100} className="mb-5" />
                    <Skeleton height={100} />
                  </div>
                ) : (
                  (this.props.currentUser?.selectedLocation?.place ||
                    this.props.currentUser?.selectedLocation?.place_id) && (
                    <PaymentMethods
                      setDeliveryTypeName={(name) =>
                        this.setState({ deliveryTypeName: name })
                      }
                      selectPayment={(payment) =>
                        this.setState({ selectPayment: payment }, () =>
                          this.getCheckoutAmounts()
                        )
                      }
                      withPriceChange={(data) =>
                        this.setState({ withPrice: data })
                      }
                      place_id={
                        this.props.currentUser?.selectedLocation?.place
                          ? this.props.currentUser?.selectedLocation?.place?.id
                          : this.props.currentUser?.selectedLocation?.place_id
                      }
                      deliveryCompanySet={this.deliveryCompanySet}
                    />
                  )
                )}
              </Col>

              {cartLoading ? (
                <Col lg={4} md={12}>
                  <Skeleton height={600} />
                </Col>
              ) : (
                <Col md={12} lg={4} className="d-flex justify-content-end">
                  <CheckoutSummaryBlock
                    deliveryTypeName={this.state.deliveryTypeName}
                    handlePaymentSubmit={this.handlePaymentSubmit}
                    cartAmount={cartAmount}
                    cart={cart}
                    FirstBlock="products"
                    currentStep={4}
                    selectPayment={selectPayment}
                    delivery_company_id={delivery_company_id}
                    inSubscriptions={false}
                    checkOutAmount={(coupons) => {
                      this.checkOutAmount(coupons);
                    }}
                    withPrice={withPrice}
                    fromPayment
                  />
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    );
  }

  // check Out Amount without coupons start
  getCheckoutAmounts = async () => {
    // function to get Checkout Amounts and set it to current state
    if (this.state?.selectPayment?.id) {
      const { data, success } = await cartService.getCheckoutAmounts({
        payment_id: this.state?.selectPayment?.id ?? "",
        delivery_company_id: this.state.delivery_company_id,
        place_id: this.props.currentUser?.selectedLocation?.place
          ? this.props.currentUser?.selectedLocation?.place?.id
          : this.props.currentUser?.selectedLocation?.place_id,
        coupons: [localStorage.getItem("beam_coupon")],
      });

      if (!success) return;
      this.setState({
        cartAmount: data,
      });
    }
  };
  // check Out Amount without coupons end

  // check Out Amount with coupons start
  checkOutAmount = async (coupons) => {
    // get checkout Amount payment by send it as a api request
    const { data, success } = await cartService.getCheckoutAmounts({
      payment_id: this.state?.selectPayment?.id,
      delivery_company_id: this.state.delivery_company_id,
      place_id: this.props.currentUser?.selectedLocation?.place
        ? this.props.currentUser?.selectedLocation?.place?.id
        : this.props.currentUser?.selectedLocation?.place_id,
      coupons,
    });
    if (!success) this.setState({ couponError: true });

    this.setState({
      cartAmount: data,
      Coupon: data.coupons,
    });
  };
  // check Out Amount with coupons end

  // get User Cart start
  getUserCart = async () => {
    // function to get user cart data
    const { data, success } = await cartService.getUserCart();
    const itemsCount = data?.map(({ qty }) => qty);
    if (!success) return;
    this.setState({
      cart: data,
      itemsCount,
      payment_methods: data.payment_methods,
      loaderPage: false,
    });
  };
  // get User Cart en
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setUser })(CheckoutPayment);
