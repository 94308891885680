import React, { Component } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { authService } from "../../../services/AuthService";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import { Form } from "react-bootstrap";
import PageTitle from "../../blocks/PageTitle";
import LoginModal from "../../blocks/LoginModal";
import { generalServices } from "../../../services/GeneralService";
import PhoneInput from "../../blocks/PhoneInput";

class SocialLogin extends Component {
  state = {
    fields: {
      name: this.props.currentUser?.socialMedia?.name ?? "",
      phone: this.props.currentUser?.socialMedia?.phone ?? "",
      email: this.props.currentUser?.socialMedia?.email ?? "",
      social_provider:
        this.props.currentUser?.socialMedia?.social_provider ?? "",
      social_provider_id:
        this.props.currentUser?.socialMedia?.social_provider_id ?? "",
      social_image: this.props.currentUser?.socialMedia?.social_image ?? "",
      password_confirmation: "",
      password: "",
      terms_conditions: false,
    },
    errors: {
      name: "",
      phone: "",
      password_confirmation: "",
      password: "",
      email: "",
      terms_conditions: "",
    },
    loginShow: false,
    termsChecked: false,
    termsCheckedError: false,
    agreeCond: false,
    agreeError: false,
    countries: [],
    selectedCountry: {},
  };

  responseFacebook = async (response) => {
    // response Facebook for login with Facebook
    const {
      error: errorTag,
      pleaseVerificationEmail,
      success: successText,
    } = this.props.i18n;

    this.setState({
      fields: {
        email: response.email ?? "",
        name: response.name ?? "",
        phone: response.phone ?? "",
        password_confirmation: "",
        password: "",
        terms_conditions: false,
      },
    });

    const { success, data, message, errors } = await authService.socialLogin({
      phone: "",
      place_id: this.props.country.id ?? 1, // What
      email: response.email ?? "",
      password: "",
      password_confirmation: "",
      name: response.name ?? "",
      need_to_email_verification: false,
    });
    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }
    return displayAlert(successText, pleaseVerificationEmail, "success");
  };

  responseGoogle = async (response) => {
    // response Google for login with Google
    const {
      error: errorTag,
      pleaseVerificationEmail,
      success: successText,
    } = this.props.i18n;

    this.setState({
      fields: {
        email: response.profileObj.email ?? "",
        name: response.profileObj.name ?? "",
        phone: response.profileObj.phone ?? "",
        password_confirmation: "",
        password: "",
        terms_conditions: false,
      },
    });

    const { success, data, message, errors } = await authService.SignUp({
      phone: "",
      place_id: this.props.country.id ?? 1, // What
      email: response.profileObj.email ?? "",
      password: "",
      password_confirmation: "",
      name: response.profileObj.name ?? "",
      need_to_email_verification: false,
    });
    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }

    return displayAlert(successText, pleaseVerificationEmail, "success");
  };

  getCountries = async () => {
    // function to get countries from the server and set it and the first one to the current state
    const { data, success } = await generalServices.getAllCountries(0);
    if (!success) return;

    this.setState({
      countries: data,
      selectedCountry: data[0],
    });
  };

  componentDidMount() {
    this.getCountries();
  }

  render() {
    const closeModal = () => {
      this.setState({
        loginShow: false,
      });
    };
    const {
      language,
      i18n: {
        submit,
        Mobile,
        SocialLogin,
        FullName,
        EnterFullName,
        EnterEmail,
        EmailID,
        EnterMobile,
        AgreeTerms,
      },
    } = this.props;

    const { errors, fields, countries } = this.state;
    return (
      <>
        <LoginModal loginShow={this.state.loginShow} closeModal={closeModal} />
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={SocialLogin}
        />
        <Container>
          <div
            className={`signup-login p-3 ${
              language === "rtl" && "signup-login-arabic"
            }`}
          >
            <div className="signup-login__form-container shadow mt-5 mb-5">
              <Row>
                <Col sm={12} md={6} className="mt-3 ps-4 pe-4">
                  <Form.Label>
                    {FullName} <span className="red-astrick">*</span>
                  </Form.Label>
                  <Form.Group className="mt-3 signup-login__form-container__field">
                    <TextInput
                      placeholder={EnterFullName}
                      name="name"
                      value={fields.name}
                      onFieldChange={this.onFieldChange}
                      validate={errors.name}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="mt-3 ps-4 pe-4">
                  <Form.Label>
                    {EmailID} <span className="red-astrick">*</span>
                  </Form.Label>
                  <Form.Group className="mt-3 signup-login__form-container__field">
                    <TextInput
                      placeholder={EnterEmail}
                      name="email"
                      value={fields.email}
                      onFieldChange={this.onFieldChange}
                      validate={errors.email}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="mt-3 ps-4 pe-4">
                  <Form.Label>
                    {Mobile} <span className="red-astrick">*</span>
                  </Form.Label>
                  <Form.Group className="phone-number d-flex">
                    <InputGroup.Text className="phone-zipcode register-phone-zipCode text-center">
                      <select
                        onChange={({ target }) => {
                          this.setState({
                            selectedCountry: JSON.parse(target.value),
                          });
                        }}
                      >
                        {countries.map((item) => {
                          return (
                            <option key={item.id} value={JSON.stringify(item)}>
                              {item.zipcode} {item.code}
                            </option>
                          );
                        })}
                      </select>
                    </InputGroup.Text>

                    <PhoneInput
                      type="number"
                      placeholder={EnterMobile}
                      name="phone"
                      onFieldChange={this.onFieldChange}
                      value={fields.phone}
                      className="signup-login__form-container__field phone_field"
                      digits={this.state.selectedCountry.digits}
                    />
                  </Form.Group>
                  <small
                    style={{
                      fontSize: "0.875em",
                      color: "red",
                      letterSpacing: "0px",
                    }}
                  >
                    {errors.phone}
                  </small>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="mt-3 ps-4 pe-4 d-flex align-items-center"
                >
                  <div className="agree-signup">
                    <label className="checkbox-container">
                      <p className="d-flex justify-content-center align-items-center">
                        {AgreeTerms}
                      </p>

                      <input
                        type="checkbox"
                        className={`${
                          this.state.termsCheckedError && "false-checkbox"
                        } `}
                        onChange={() => {
                          this.setState({
                            termsChecked: !this.state.termsChecked,
                            agreeError: false,
                          });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {this.state.agreeError && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginTop: "-22px",
                          display: "block",
                        }}
                      >
                        Please, agree our terms and condition before
                      </span>
                    )}
                  </div>
                </Col>
                <Col sm={12} md={6} className="ps-4 pe-4 mt-3">
                  <button
                    className="submit-btn mt-3 p-3"
                    onClick={() => {
                      if (this.state.termsChecked) {
                        this.submit();
                      } else {
                        this.setState({
                          agreeError: true,
                        });
                      }
                    }}
                  >
                    {submit}
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }

  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        name: "",
        mobile: "",
        password_confirmation: "",
        password: "",
        email: "",
      },
    });
  };

  submit = async () => {
    const { fields } = this.state;
    const { success, data, message, errors } = await authService.socialLogin({
      phone: fields.phone,
      place_id: this.props.country.id,
      email: fields.email,
      name: fields.name,
      social_image: fields.image ?? "",
      social_provider_id: fields.social_provider_id ?? "",
      social_provider: fields.social_provider,
    });
    const { error: errorTag } = this.props.i18n;

    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }
    apiService.storeToken(data.access_token);
    this.props.setUser({ ...data.user });
    window.location.href = "/";
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(SocialLogin);
