import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { productService } from "../../services/ProductService";
import Global from "../../language/Global.json";
import "moment/min/locales";
import { displayAlert } from "../../utils/misc";
import LoginModal from "./LoginModal";
import Swal from "sweetalert2";

// The Style is in ProductDetails.scss

const Reviews = (props) => {
  const [selectedRate, SetSelectedRate] = useState(1);
  const [comment, setComment] = useState("");
  const { id } = props;
  const language = useSelector(({ language }) => language);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const [openLogin, setOpenLogin] = useState(false);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const history = useHistory();
  const {
    leaveComment,
    error,
    PleaseEnterRate,
    submit,
    RateThisItem,
    SubmitYourStarRating,
    thisFeaturesJustForUsers,
    submittedOn,
    Done,
    ThankYouForReview,
    Sorry,
    Login,
    Register,
  } = i18n;

  // add Product Review
  const addProductReview = async () => {
    const { success } = await productService.addProductReview({
      product_id: id,
      rate: selectedRate,
      comment,
    });

    if (!apiService.accessToken) {
      displayAlert(Done, ThankYouForReview, "success", 2000);
    }
    if (!success) return;
    setComment("");
    SetSelectedRate(1);
    props.refetchData();
  };

  return (
    <div className="reviews-section">      
      <LoginModal
        loginShow={openLogin}
        closeModal={() => setOpenLogin(false)}
      />

      {/*Your Comment Section Start */}
      <div className="send-review">
        {/* title start  */}
        <div className="send-review__title">
          <h5>{RateThisItem}</h5>
          <span>{SubmitYourStarRating}</span>
        </div>
        {/* title end  */}

        {/* rate-bar start */}
        <div className="bar-row">
          <ul className="bar flex">
            {[1, 2, 3, 4, 5].map((item) => {
              return (
                <li key={item}>
                  <i
                    onClick={() => {
                      SetSelectedRate(item);
                    }}
                    className={`fa fa-star ${
                      selectedRate >= item && "selectedRate"
                    }`}
                  ></i>
                </li>
              );
            })}
          </ul>
        </div>
        {/* rate-bar end */}

        <div className="flex send-review__input">
          {/* review message input  start  */}
          <textarea
            placeholder={leaveComment}
            aria-label="Amount (to the nearest dollar)"
            onChange={({ target }) => setComment(target.value)}
            value={comment}
          />
          {/* review message input  end  */}

          {/* review button start  */}
          <button
            className="submit-btn"
            onClick={() => {
              if (currentUser?.email) {
                if (selectedRate && comment) {
                  addProductReview();
                } else {
                  displayAlert(
                    error,
                    PleaseEnterRate,
                    "error",
                    "noButtons",
                    2000
                  );
                }
              } else {
                Swal.fire({
                  title: Sorry,
                  showCloseButton: true,
                  text: thisFeaturesJustForUsers,

                  showConfirmButton: true,
                  showDenyButton: true,

                  confirmButtonText: Login,
                  denyButtonText: Register,

                  confirmButtonColor: "#000",
                  denyButtonColor: "#000",
                }).then((value) => {
                  if (value.isDenied) history.push("/signup");
                  else if (value.isConfirmed) setOpenLogin(true);
                });
              }
            }}
          >
            {submit}
          </button>
          {/* review button end  */}
        </div>
      </div>
      {/*Your Comment Section End */}

      {/*Customers Comments Section Start */}
      {props.data?.length > 0 && (
        <div className="reviews-section__comment-container">
          {props.data?.map(({ user, comment, rate, created_at }, index) => {
            return (
              <div className="comment-content" key={index}>
                <div className="comment-head">
                  <div className="user">
                    <div className="user-name-rate">
                      <span className="user-name">{user?.name}</span>
                      <div className="date ">
                        {" "}
                        {submittedOn}
                        {moment(created_at).format("yy-MM-DD")}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Comment Date Start */}
                <div>
                  <span className="user-rate">{props.rateBar(rate)}</span>
                  <p className="comment-text">{comment}</p>
                </div>
                {/* Comment Date End */}
              </div>
            );
          })}
          {/* {meta.total !== currentPage + 1 ? (
                        <div className="reviews-section__comment-container__more-comment-loader">
                            {props.isLoading ? (
                                <div>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">{Loading}...</span>
                                    </Spinner>
                                </div>
                            ) : (
                                <button
                                    className="p-3"
                                    onClick={() => {
                                        props.loadMoreComments();
                                    }}
                                    style={meta.current_page === meta.last_page ? { display: "none" } : null}
                                >
                                    <i className="fa fa-arrow-down me-2"></i>
                                    {LoadMoreComments}
                                </button>
                            )}
                        </div>
                    ) : (
                        ""
                    )} */}
        </div>
      )}
      {/*Customers Comments Section End */}

    </div>
  );
};

export default withRouter(Reviews);
