import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import AccountPageList from "../../../blocks/AccountPageList";
import AccountPageMobileList from "../../../blocks/AccountPageMobileList";
import PageTitle from "../../../blocks/PageTitle";
import EditLocationUser from "../../Checkout/EditLocationUser";
import Addresses from "./Addresses";

class AddressesPage extends Component {
  state = {
    remindMe: false,
    editLocation: false,
    locationInfo: {},
    isAdd: true,
  };

  render() {
    const {
      language,
      i18n: { MyAccount, AddressBook, Home },
    } = this.props;
    const { editLocation, locationInfo, isAdd } = this.state;

    return (
      <>
        {/* page title with breadcrumb start  */}
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={MyAccount}
          links={[
            { name: Home, url: "/" },
            { name: MyAccount, url: "/profile" },
            { name: AddressBook, url: "/Address" },
          ]}
        />
        {/* page title with breadcrumb end  */}

        <Container>
          <div
            className={`account-page ${
              language === "rtl" && "account-page-arabic"
            }`}
          >
            {/* Mobile Side Menu list Start */}
            <AccountPageMobileList />
            {/*Mobile Side Menu list End */}

            <Row>
              {/*web Menu list Start */}
              <Col className="board" md={3}>
                <AccountPageList />
              </Col>
              {/*web Menu list End */}

              {/* address list and add&edit form start  */}
              <Col md={9}>
                <Row>
                  <Col xs={12}>
                    {editLocation ? (
                      <EditLocationUser
                        show={editLocation}
                        closeModal={() =>
                          this.setState({ editLocation: false })
                        }
                        reFetchData={() => {}}
                        currentState={"add"}
                        isAdd={isAdd}
                        data={locationInfo}
                        goBack={() => {
                          this.setState({
                            locationInfo: {},
                            editLocation: false,
                            isAdd: false,
                          });
                        }}
                      />
                    ) : (
                      <Addresses
                        showEditLocation={(locationInfo) => {
                          this.setState({
                            locationInfo,
                            editLocation: true,
                            isAdd: false,
                          });
                        }}
                        addLocation={() =>
                          this.setState({
                            isAdd: true,
                            editLocation: true,
                            locationInfo: {},
                          })
                        }
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              {/* address list and add&edit form start  */}
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(AddressesPage);
