import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";
import { generalServices } from "../../../services/GeneralService";
import PageTitle from "../../blocks/PageTitle";
import GiftCardBlock from "./GiftCardBlock";

class GiftCardPage extends Component {
  state = {
    giftCards: [],
    loaderPage: true,
    adsData: {},
    adsLoader: true,
  };

  componentDidMount() {
    Promise.all([this.getSingleAds(), this.getGiftCards()]);
  }

  // get Single Ads start
  getSingleAds = async () => {
    // function to get Single Ads
    const { data, success } = await generalServices.getSingleAds(
      "home_under_categories_block_left_space",
      0,
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      adsData: data,
      adsLoader: false,
    });
  };
  // get Single Ads end

  // get Gift Cards start
  getGiftCards = async () => {
    // function to get Gift Cards by call it from the server and set it to the current state
    const { data, success } = await generalServices.getGiftCards(1);
    if (!success) return;
    this.setState({
      giftCards: data,
      loaderPage: false,
    });
  };
  // get Gift Cards end

  render() {
    const { giftCards, loaderPage } = this.state;
    const {
      language,
      i18n: { GIFTCARDS, giftCardEmpty, Home },
    } = this.props;

    return (
      <div
        className={`giftCardPage ${
          language === "rtl" && "giftCardPage-Arabic"
        }`}
      >
        {/* Title */}
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={GIFTCARDS}
          links={[
            { name: Home, url: "/" },
            { name: GIFTCARDS, url: "/GiftCardPage" },
          ]}
        />
        {/* Title end */}

        {/* Cart Products And Amount */}
        <Container className="mt-5">
          <Row>
            {/* gift cards start  */}
            {loaderPage ? (
              <>
                <Col sm={6} className="mb-4">
                  <Skeleton height={200} className="mt-4" />
                </Col>
                <Col sm={6} className="mb-4">
                  <Skeleton height={200} className="mt-4" />
                </Col>
                <Col sm={6} className="mb-4">
                  <Skeleton height={200} className="mt-4" />
                </Col>
                <Col sm={6} className="mb-4">
                  <Skeleton height={200} className="mt-4" />
                </Col>
              </>
            ) : giftCards.length > 0 ? (
              giftCards.map((item, index) => (
                <Col
                  sm={6}
                  className={` ${index % 2 === 0 ? "pr-3" : "pl-3"}`}
                  key={index}
                >
                  <GiftCardBlock data={item} />
                </Col>
              ))
            ) : (
              // if  gift cards empty
              <Col md={12}>
                <div className="empty-products">
                  <img src="/img/giftEmpty.svg" alt="k" />
                  <p> {giftCardEmpty} </p>
                </div>
              </Col>
            )}
            {/* gift cards end  */}
          </Row>
        </Container>
        {/* Cart Products And Amount End */}
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setUser })(GiftCardPage);
