import React, { useState } from "react";
import { ErrorMessage } from "./ErrorMessage";
import Label from "./Label";
import ReactLoading from "react-loading";

function UploadFile({
  uploadImage,
  name,
  value,
  placeholder,
  setValue,
  className,
  validate,
  label,
  loading,
}) {

  const [imageData, setImageData] = useState({
    oldPath: "",
    image: "",
    fileImage: "",
    imageUploaded: false,
    blobImage: null,
    IDpicture: [],
  });
  React.useEffect(() => {
    if (value === "rerender") setValue("");
  }, [value, setValue]);

  return (
    <div>
      <div> {label && <Label label={label} />}</div>
      <div className="custom-file-upload-container">
        {/* upload image input start  */}
        <label className="custom-file-upload">
          <input
            className={`custom-file-input ${className ? className : ""}`}
            type="file"
            id="pic"
            name={name}
            accept="image/png, image/jpeg , image.jpg"
            placeholder={placeholder}
            onChange={(image) => {
              if (!image.target.files.length) return;
              const path = (window.URL || window.webkitURL).createObjectURL(
                image.target.files[0]
              );
              setImageData({
                oldPath: imageData.image,
                image: image.target.value,
                fileImage: image.target.files[0],
                imageUploaded: true,
                blobImage: path,
                IDpicture: [],
              });

              uploadImage(image.target.files[0]);
            }}
            key={value === "rerender" ? 5 : 1}
          />
          {loading ? (
            <>
              <ReactLoading type="spin" color="#fff" width="20" height="20" />
            </>
          ) : (
            "+"
          )}
        </label>
        {/* upload image input end  */}

        {/* image uploaded name start  */}
        <div className="custom-file-upload__uploaded-files w-100 text-left">
          <span
            style={{
              color: "#000",
            }}
          >
            {placeholder}
          </span>
        </div>
        {/* image uploaded name end  */}
      </div>
      {validate && <ErrorMessage message={validate} />}
    </div>
  );
}

export default UploadFile;
