import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

class SubCategories extends Component {
  render() {
    const {
      language,
      subCats,
      i18n: { subCategories },
    } = this.props;

    return (
      <>
        <div
          className={`subCategories ${
            language === "rtl" && "subCategories-arabic"
          }`}
        >
          <div className="subCategories__accordion">
            <Accordion
              accordion={true}
              defaultActiveKey={"1"}
              expandMode={"multiple"}
              flush
            >
              <Accordion.Item eventKey="1">
                <Accordion.Header>{subCategories}</Accordion.Header>
                <Accordion.Body>
                  {/* subcategories start  */}
                  <ul className="">
                    {subCats?.map((sub) => {
                      return (
                        <a
                          href={"/productsCategory/" + sub.machine_name + "/1"}
                        >
                          <li>
                            {language === "rtl" ? (
                              <i class="fa fa-long-arrow-left ms-2"></i>
                            ) : (
                              <i class="fa fa-long-arrow-right me-2"></i>
                            )}
                            {sub.name}
                          </li>
                        </a>
                      );
                    })}
                  </ul>
                  {/* subcategories end  */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});

export default connect(mapStateToProps)(SubCategories);
