import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { setLanguage } from "../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../utils/misc";
import { generalServices } from "../../services/GeneralService";
import Global from "./../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import PageTitle from "./PageTitle";
class PublicPagesData extends Component {
  state = {
    data: {},
  };
  componentDidMount() {
    this.getPublicPagesDataDetails();
  }

  componentDidUpdate(prevProps) {
    // when any change in the machine name with the address, it re-requests the data
    prevProps.location.pathname !== window.location.pathname &&
      this.getPublicPagesDataDetails();
  }

  async getPublicPagesDataDetails() {
    // requesting the page data from the serve
    const { success, data } = await generalServices.getPublicPagesDataDetails(
      this.props.match.params.machineName
    );
    if (!success) return;
    this.setState({
      data: data,
    });
  }

  render() {
    const {
      language,
      i18n: { Home },
    } = this.props;
    const { data } = this.state;
    return (
      <>
        {/* page title start  */}
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={data.name}
          links={[
            { name: Home, url: "/" },
            { name: data.name, url: "/GiftCardPage" },
          ]}
        />
        {/* page title end  */}

        <Container>
          <div
            className={`Public-pages-data  ${
              language === "rtl" && "Public-pages-data-arabic"
            }`}
          >
            {data?.length < 1 ? (
              <Row>
                <div className="title">
                  <Skeleton height={tabletScreen ? 100 : 56}></Skeleton>
                </div>
                <Skeleton height={tabletScreen ? 100 : 112}></Skeleton>
              </Row>
            ) : (
              <Row>
                {/* Text Start */}
                <p dangerouslySetInnerHTML={{ __html: data.text }}></p>
                {/* Text End */}
              </Row>
            )}
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(PublicPagesData);
