import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Pdf from "react-to-pdf";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import PageTitle from "../../blocks/PageTitle";
import { subscriptionsService } from "../../../services/SubscriptionsService";
import AnsweredFormTable from "../../blocks/AnsweredFormTable";
import SummaryPackage from "./SummaryPackage";

function SubscriptionInvoice() {
  const currency = useSelector(({ currency }) => currency);
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const {
    Summary,
    TotalPrice,
    PaymentMethodStatus,
    SubTotal,
    Subscription,
    SUBSCRIPTIONS,
    Print,
    SubscriptionDetails,
    deliveryAmount,
    feesAmount,
    taxesAmount,
    T,
    DeliveryAddress,
    AnsweredForm,
    ThankYou,
    Download,
    Home,
  } = i18n;
  const [OrderDetails, setOrderDetails] = useState({});
  const params = useParams();

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    Promise.all([getSubscriptionOrderDetails()]);
  }, []);

  const getSubscriptionOrderDetails = async () => {
    const { data, success } =
      await subscriptionsService.getSubscriptionOrderDetails(params.id);
    if (!success) return;
    setOrderDetails(data);
    setLoader(false);
  };

  let componentRef = useRef();
  const options = {
    format: "a1",
  };

  return (
    <div ref={componentRef}>
      <PageTitle // component that shows links to the page ( Bread Crumb )
        title={ThankYou}
        links={[
          { name: Home, url: "/" },
          { name: SUBSCRIPTIONS, url: "/Subscriptions" },
          { name: SubscriptionDetails, url: params },
        ]}
      />
      {/* subscription id (header ) start  */}
      <Container className="thank-u-page">
        <header className="thank-u-page-subscription-header text-center">
          <b>
            {Subscription} #{OrderDetails?.order_information?.id}
          </b>
        </header>
      </Container>
      {/* subscription id (header ) start  */}

      <Container className="invoice-block-container mb-5">
        <div className="invoice-block-content final-invoice w-100">
          <header className="invoice-block-header text-start mb-2">
            {Summary}
          </header>
          {/* package details start  */}
          {loader ? (
            <Skeleton height={200} />
          ) : (
            <div className="cart-summary__section__body__summary-field flex-col">
              <div className="product-container w-100">
                <SummaryPackage
                  packageInfo={{
                    ...OrderDetails?.order_information?.subscription_info,
                    total_price: OrderDetails?.order_information?.total_amount,
                  }}
                  invoice={true}
                  is_subsecribtion={true}
                  selectedMonths={OrderDetails?.order_information?.months?.map(
                    (item) => ({ label: item })
                  )}

                  // Here if you in order details or in track order details (according to the order ID entered)
                  // yo must change products data from cart data to order data ... don't forget that
                />
              </div>
            </div>
          )}

          {loader ? (
            <Skeleton height={200} />
          ) : (
            <div className="answered-form__section__body__field mt-3">
              <header className="invoice-block-header text-start text-capitalize mb-2">
                {AnsweredForm}
              </header>
              <AnsweredFormTable data={OrderDetails?.fields} />
            </div>
          )}
          {/* extra fields answers start  */}

          <div className="invoice-info__container d-flex justify-content-between mt-3">
            {/* delivery address and user phone start  */}
            <div className="invoice-info__block">
              <header className="invoice-block-header text-start text-capitalize mb-2">
                {DeliveryAddress}
              </header>
              <div className="invoice-block__body mt-3">
                <p className="m-0 text-start">
                  {OrderDetails?.order_information?.receiver_details?.address}
                </p>

                <div className="addresses__block__phone flex">
                  <span className="d-block m-3 ms-0">
                    <b>{T} : </b>
                  </span>
                  {/* <span>{` ${
                                    data.phone ? data.phone : ""
                                } `}</span> */}
                  <span className="d-block m-3 ms-1">{` ${
                    OrderDetails?.order_information?.receiver_details?.phone ??
                    ""
                  } `}</span>
                </div>
              </div>
            </div>
            {/* delivery address and user phone end  */}

            {/* 
                        <div className="invoice-info__block">
                            <header className="invoice-block-header text-start text-capitalize">{PaymentMethod}</header>
                            <div className="invoice-block__body mt-3">
                                <p className="m-0 text-start">{OrderDetails?.order_information?.payment_method.name}</p>
                            </div>

                            <header className="invoice-block-header text-start text-capitalize mt-2">{PaymentStatus}</header>
                            <div className="invoice-block__body mt-3">
                                <p className="m-0 text-start">{OrderDetails?.order_information?.statusText}</p>
                            </div>
                        </div> */}

            <div className="invoice-info__block">
              <header className="invoice-block-header text-start text-capitalize">
                {PaymentMethodStatus}
              </header>
              <div className="invoice-block__body invoice-method mt-3">
                <div>
                  {/* payment method and status start  */}
                  <div className="invoice-method-field d-flex justify-content-between">
                    <p>
                      {OrderDetails?.order_information?.payment_method.name}
                    </p>
                    <p style={{ color: "#00A110" }}>
                      {OrderDetails?.order_information?.statusText}
                    </p>
                  </div>
                  {/* payment method and status end  */}

                  {/* SubTotal start  */}
                  <div className="cart-summary__section__body__summary-field d-flex justify-content-between mt-3 pb-3 border-bot">
                    <div className="summary-field__title">{SubTotal}</div>
                    <div className="summary-field__value">
                      {OrderDetails?.order_information?.items_amount &&
                        (
                          OrderDetails?.order_information?.items_amount *
                          currency.currencyFactor
                        ).toFixed(2)}{" "}
                      {currency.currencyName}
                    </div>
                  </div>
                  {/* SubTotal end  */}

                  {/* feesAmount start  */}
                  {OrderDetails?.order_information?.fees_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field d-flex justify-content-between mt-3 border-bot pb-3">
                      <div className="summary-field__title">{feesAmount}</div>
                      <div className="summary-field__value">
                        {(
                          OrderDetails?.order_information?.fees_amount *
                          currency.currencyFactor
                        ).toFixed(2)}{" "}
                        {currency.currencyName}
                      </div>
                    </div>
                  )}
                  {/* feesAmount end  */}

                  {/* taxesAmount start  */}
                  {OrderDetails?.order_information?.taxes_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field d-flex justify-content-between mt-3 border-bot pb-3">
                      <div className="summary-field__title">{taxesAmount}</div>
                      <div className="summary-field__value">
                        {(
                          OrderDetails?.order_information?.taxes_amount *
                          currency.currencyFactor
                        ).toFixed(2)}{" "}
                        {currency.currencyName}
                      </div>
                    </div>
                  )}
                  {/* taxesAmount end  */}

                  {/* deliveryAmount start  */}
                  {OrderDetails?.order_information?.delivery_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field d-flex justify-content-between mt-3 border-bot pb-3">
                      <div className="summary-field__title">
                        {deliveryAmount}
                      </div>
                      <div className="summary-field__value">
                        {/* <span>this.props.country.currency</span>
                                        {"  "} cartAmount?.delivery_amount */}
                        {(
                          OrderDetails?.order_information?.delivery_amount *
                          currency.currencyFactor
                        ).toFixed(2)}{" "}
                        {currency.currencyName}
                      </div>
                    </div>
                  )}
                  {/* deliveryAmount end  */}

                  {/* TotalPrice start  */}
                  <div className="cart-summary__section__body__summary-field total-field d-flex justify-content-between mt-3 pb-3">
                    <div className="summary-field__title  bold-18">
                      {TotalPrice}
                    </div>
                    <div className="summary-field__value  bold-18">
                      {/* <span>this.props.country.currency</span>{" "}
                                        {"  "} cartAmount?.total_amount */}

                      <span>
                        {OrderDetails?.order_information?.total_amount &&
                          (
                            OrderDetails?.order_information?.total_amount *
                            currency.currencyFactor
                          ).toFixed(2)}{" "}
                        {currency.currencyName}
                      </span>
                    </div>
                  </div>
                  {/* TotalPrice end  */}
                </div>
              </div>
            </div>
          </div>

          <div className="invoice-btns-container text-center subscription-invoice-btns">
            {/* print button start  */}
            <ReactToPrint
              trigger={() => (
                <button className="invoice-btn black-one ms-0">{Print}</button>
              )}
              content={() => componentRef.current}
            />
            {/* print button end  */}

            {/* download  button start  */}
            <Pdf
              targetRef={componentRef}
              filename="div-blue.pdf"
              x={0.5}
              scale={1}
              options={options}
            >
              {({ toPdf }) => (
                <button className="invoice-btn gray-one ms-0" onClick={toPdf}>
                  {" "}
                  {Download}
                </button>
              )}
            </Pdf>
            {/* download  button end  */}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SubscriptionInvoice;
