import React, { Component } from "react";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";
import { productService } from "../../../services/ProductService";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { generalServices } from "../../../services/GeneralService";
import { Link, withRouter } from "react-router-dom";

class FavoriteProductBlock extends Component {
  render() {
    const {
      language,
      data,
      i18n: { ShopNow },
      reFetchData,
      fromSale,
    } = this.props;

    return (
      <div
        className={`fav-product  ${language === "rtl" && "fav-product-arabic"}`}
      >
        {/* remove product Icon start  */}
        {!fromSale && (
          <i
            class="fa fa-window-close"
            onClick={() => this.addRemoveFavorites(data?.id, reFetchData)}
          />
        )}
        {/* remove product Icon end  */}

        {/* product img start  */}
        <Link
          to={
            data?.bundle
              ? `/ProductDetailsBundle/${data?.machine_name}`
              : `/productDetails/${data?.machine_name}`
          }
        >
          <img
            src={
              data?.main_image
                ? apiService.imageLink + data?.main_image
                : "/images/placeholder.png"
            }
            alt="k"
            className="product__img"
          />
        </Link>
        {/* product img end  */}

        {/* product title start  */}

        <Link
          to={
            data?.bundle
              ? `/ProductDetailsBundle/${data?.machine_name}`
              : `/productDetails/${data?.machine_name}`
          }
        >
          <h5 className="mt-0">{data?.title}</h5>
        </Link>
        {/* product title end  */}

        {/* product price start  */}

        <div className="product__price">
          <span>
            {this.props.currency.currencyName}{" "}
            {(
              data?.option.price_after_discount *
              this.props.currency.currencyFactor
            ).toFixed(2)}
          </span>
        </div>

        {/* product price end  */}

        {/* product button start  */}

        <button
          onClick={() => {
            this.props.history.push(
              data?.bundle
                ? `/ProductDetailsBundle/${data?.machine_name}`
                : `/productDetails/${data?.machine_name}`
            );
          }}
          className="submit-button2"
        >
          {ShopNow}
        </button>

        {/* product button end  */}

        {/* <div
                                onClick={() => {
                                    this.addToCart(data?.option?.id, currentUser.cartIds?.includes(data?.option?.id) ? 0 : 1, reFetchData);
                                }}
                            >
                                <img src={`/images/${currentUser.cartIds?.includes(data?.option?.id) ? "Group 1519" : "cartWithColor"}.svg`} alt="ss" />
                                &nbsp; &nbsp;
                                {currentUser.cartIds?.includes(data?.option?.id) ? RemoveFromCart : MoveToCart}
                            </div>

                            <div
                                onClick={() => {
                                    this.addRemoveFavorites(
                                        data?.id,

                                        reFetchData
                                    );
                                }}
                            >
                                <img alt="Delete" src="/images/fi-rr-trash.svg" />
                                &nbsp; {Remove}
                            </div> */}
      </div>
    );
  }

  getCartIDs = async () => {
    // function to get user cart from the server and set it to user redux store data
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };
  getFavoritesIDs = async () => {
    // function to get user Favorites IDs from the server and set it to user redux store data
    const { data, success } = await generalServices.getFavoritesIDs();
    if (!success) return;

    this.props.setUser({
      favoritesId: data,
    });
  };
  getIconData = async () => {
    // function to get Fav Cart compare Counters from the server and set it to user redux store data
    const { data, success } = await generalServices.getIconData();
    if (!success) return;
    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
      compare: data.compare_counter,
    });
  };

  addToCart = async (id, qty, reFetchData) => {
    // add to cart 
    const { success } = await productService.addToCart(id, qty);
    if (!success) return;
    this.addRemoveFavorites(this.props.data?.id, reFetchData);
  };

  addRemoveFavorites = async (id, reFetchData) => {
    // add Remove Favorites
    const { success } = await productService.addOrRemoveFavorite(id);
    if (!success) return;
    await reFetchData();
    this.getFavoritesIDs();
    this.getCartIDs();

    this.getIconData();
  };
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
  currency,
});
export default connect(mapStateToProps, { setUser })(
  withRouter(FavoriteProductBlock)
);
