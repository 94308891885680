import React, { Component } from "react";
import { connect } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )

class AccountPageList extends Component {
  state = {
    menuOpen: false,
  };

  render() {
    const pathname = window.location.pathname.toLowerCase();

    const {
      language,
      i18n: {
        MyOrders,
        AddressBook,
        ReturnRequests,
        LoyaltyProgram,
        Wallet,
        Subscriptions,
        GiftCards,
        Settings,
        AccountInformation,
        Logout,
      },
    } = this.props;

    return (
      <div className="mobile-push-menu d-none">
        <Menu
          right={language === "rtl" && "account-page-arabic"}
          isOpen={this.state.menuOpen}
          customBurgerIcon={<i className="fa  fa-cog" />}
          customCrossIcon={<i className="fa fa-times croosIconMenu" />}
          onOpen={() =>
            this.setState({
              menuOpen: !this.state.menuOpen,
            })
          }
        >
          <ul>
            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname === "/profile" ? "#fff" : "inherit"
                  }`,
                  color: `${pathname === "/profile" ? "#000" : "inherit"}`,
                }}
              >
                <Link to="/profile">
                  <h6 href="/profile">{AccountInformation}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            )}

            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname.toLowerCase() === "/address" ? "#fff" : "inherit"
                  }`,
                  color: `${
                    pathname.toLowerCase() === "/address" ? "#000" : "inherit"
                  }`,
                }}
              >
                <Link to="/Address">
                  <h6 href="/Address">{AddressBook}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            )}

            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname.toLowerCase().startsWith("/orders")
                      ? "#fff"
                      : "inherit"
                  }`,
                  color: `${
                    pathname.toLowerCase().startsWith("/orders")
                      ? "#000"
                      : "inherit"
                  }`,
                }}
              >
                <Link to="/orders/1">
                  <h6 href="/orders/1">{MyOrders}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            )}

            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname.toLowerCase() === "/returnrequestsprofilepage"
                      ? "#fff"
                      : "inherit"
                  }`,
                  color: `${
                    pathname.toLowerCase() === "/returnrequestsprofilepage"
                      ? "#000"
                      : "inherit"
                  }`,
                }}
              >
                <Link to="/ReturnRequestsProfilePage">
                  <h6 href="/ReturnRequestsProfilePage">{ReturnRequests}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            )}
            {/* ReturnRequests end  */}

            {/* LoyaltyProgram start  */}
            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname.toLowerCase().startsWith("/layaltyprogram")
                      ? "#fff"
                      : "inherit"
                  }`,
                  color: `${
                    pathname.toLowerCase().startsWith("/layaltyprogram")
                      ? "#000"
                      : "inherit"
                  }`,
                }}
              >
                <Link to="/LayaltyProgram/1">
                  <h6 href="/LayaltyProgram/1">{LoyaltyProgram}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            )}
            {/* LoyaltyProgram end  */}

            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname.toLowerCase().startsWith("/walletpage")
                      ? "#fff"
                      : "inherit"
                  }`,
                  color: `${
                    pathname.toLowerCase().startsWith("/walletpage")
                      ? "#000"
                      : "inherit"
                  }`,
                }}
              >
                <Link to="/WalletPage/1">
                  <h6 href="/WalletPage/1">{Wallet}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            )}

            {/* Subscriptions start  */}
            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname
                      .toLowerCase()
                      .startsWith("/subscriptionsprofilepage")
                      ? "#fff"
                      : "inherit"
                  }`,
                  color: `${
                    pathname
                      .toLowerCase()
                      .startsWith("/subscriptionsprofilepage")
                      ? "#000"
                      : "inherit"
                  }`,
                }}
              >
                <Link to="/SubscriptionsProfilePage/1">
                  <h6 href="/SubscriptionsProfilePage/1">{Subscriptions}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            )}
            {/* Subscriptions end  */}

            {/* GiftCards start  */}
            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname.toLowerCase() === "/giftcardprofilepage"
                      ? "#fff"
                      : "inherit"
                  }`,
                  color: `${
                    pathname.toLowerCase() === "/giftcardprofilepage"
                      ? "#000"
                      : "inherit"
                  }`,
                }}
              >
                <Link to="/GiftCardProfilePage">
                  <h6 href="/GiftCardProfilePage">{GiftCards}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            )}
            {/* GiftCards end  */}

            {/* Settings start  */}
            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname === "/settings" ? "#fff" : "inherit"
                  }`,
                  color: `${pathname === "/settings" ? "#000" : "inherit"}`,
                }}
              >
                <Link to="/Settings">
                  <h6 href="/Settings">{Settings}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            )}
            {/* Settings end  */}

            {/* Logout start  */}
            {apiService.accessToken !== 0 && (
              <li
                onClick={() => {
                  this.setState({
                    menuOpen: false,
                  });
                }}
                style={{
                  backgroundColor: `${
                    pathname === "/Logout" ? "#fff" : "inherit"
                  }`,
                  color: `${pathname === "/Logout" ? "#000" : "inherit"}`,
                }}
              >
                <a>
                  <h6
                    href="/Logout"
                    onClick={async () => {
                      // logout and remove user data from localStorage and refresh
                      localStorage.removeItem("beam_admin_access_token");
                      localStorage.removeItem("beamAccessToken");
                      localStorage.removeItem("persist:Beam");
                      window.location.href = "/";
                    }}
                  >
                    {Logout}
                  </h6>
                  {pathname === "/Logout" && (
                    <img src="/img/Forma 1 copy 8.svg" alt="l" />
                  )}
                </a>
              </li>
            )}
            {/* Logout end  */}
          </ul>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps)(AccountPageList);
