export const apiEndPoints = {
  // auth api endPoints
  auth: {
    login: "basicLogin",
    signUp: "registerUser",
    guestLogin: "generateGuestID",
    forgetPassword: "forgetPassword",
    resetPassword: "resetForgetPassword",
    socialLogin: "loginBySocialMedia",
    verifyForgetPassword: "verifyForgetPassword",
  },

  // general api endPoints
  general: {
    getPlaces: "getPlaces",
    getPlaceDetails: "getPlaceDetails",
    getIconData: "getFavCartCompCounters",
    getSlider: "getSliderData",
    getCategories: "getCategories",
    getCities: "/getCities",
    getAreas: "/getAreas",
    getBrands: "getBrandsData",
    getFooter: "getSettings",
    getPublicPagesData: "getPublicPages ",
    getPublicPagesDataDetails: "getPublicPageDetails",
    search: "getSearchProducts",
    getHomeAds: "getHomeAds",
    getSocialMediaLinks: "getSocialMediaLinks",
    getPaymentMethodsByPlaceID: "getPaymentMethodsByPlaceID",
    getHelpCenter: "getHelpCenter",
    getSingleAds: "getSingleAds",
    getFavoritesIDs: "getFavoritesIDs",
    getComparesIDs: "getComparesIDs",
    getCartIDs: "getCartIDs",
    getCurrencies: "getCurrencies",
    updateUserGuestCurrency: "updateUserGuestCurrency",
    submitContactForm: "submitContactForm",
    getCompanyLocationByPlace: "getCompanyLocationByPlace",
    getMysteryBoxes: "getMysteryBoxes",
    getPlaceFields: "getPlaceUserLocationFields",
    uploadFile: "uploadFile",
    getGiftCards: "getGiftCards",
    createGiftCardOrder: "createGiftCardOrder",
    getGiftCardsOrderDetails: "getGiftCardsOrderDetails",
    editUserSettings: "editUserSettings",
    getLanguages: "getLanguages",
  },

  // category api endPoints
  category: {
    getSingleCategoryProducts: "getSingleCategoryProducts",
    getSingleCategoryInfoAndFilters: "getSingleCategoryInfoAndFilters",
  },

  // home api endPoints
  home: {
    getHomePageData:
      "/getHomePageData?sub_categories=1&categories=1&recommended_products=1&trending_products=1&brands=1&daily_deals=1",
    getHomeTDRDeals: "getHomeTDRDeals",
    getHomeCategoriesWithProducts: "getHomeCategoriesWithProducts",
    getSingleBrandProducts: "getSingleBrandProducts",
    getCollections: "getCollections",
  },

  // cart api endPoints
  cart: {
    getUserCart: "getCart",
    getUserFavorites: "getFavorites",
    getCheckoutAmounts: "getCheckoutAmounts",
    checkCouponIfValid: "checkCouponIfValid",
    clearMyCart: "clearMyCart",
  },

  // user api endPoints
  user: {
    checkoutSubmit: "createOrder",
    getUserOrders: "getMyOrders",
    getOrderInvoice: "getMyOrderDetails",
    getUserInformation: "/getUserInformation",
    updateGeneralInfo: "/editUserInformation",
    updateSecurityInfo: "editUserPassword",
    getMyWallet: "getMyWallet",
    getGiftCardsOrders: "getGiftCardsOrders",
    getReturnRequests: "getReturnRequests",
    getExchangeRequests: "getExchangeRequests",
    getRefundReasons: "getRefundReasons",
    submitReturnRequest: "submitReturnRequest",
    submitExchange: "submitExchangeRequest",
    getMyPoints: "getMyPoints",
    getMyLoyalityRewards: "getMyLoyalityRewards",
  },

  // location api endPoints
  location: {
    getUserLocation: "getUserLocations",
    addLocationSubmit: "/user/addNewLocation",
    deleteUserLocation: "deleteUserLocation",
    addUserLocation: "addUserLocation",
    editUserLocation: "editUserLocation",
    getUserLocationDetails: "getUserLocationDetails",
  },

  // product api endPoints
  product: {
    addOrRemoveFavorite: "addRemoveFavorites",
    addRemoveCompares: "addRemoveCompares",
    getProductDetails: "getProductDetails",
    addToCart: "addUpdateRemoveCart",
    getUserProductCompared: "getCompares",
    getProductRelated: "getProductRelated",
    getProductSoldWith: "getProductSoldWith",
    getSingleProductReviews: "getProductReviews",
    addProductReview: "addProductReview",
    submitNotifyMe: "addProductNotifyMe",
  },

  // subscriptions api endPoints
  subscriptions: {
    getPackages: "getSubscriptions",
    getSubscriptionDetails: "getSubscriptionDetails",
    getSubscriptionOrderDetails: "getSubscriptionOrderDetails",
    getSubscriptionsOrders: "getSubscriptionsOrders",
    createSubscriptionOrder: "createSubscriptionOrder",
    subscriptionCheckoutSubmit: "createSubscriptionOrder",
    validateSubscriptionFields: "validateSubscriptionFields",
  },
};
