import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";

export class SummaryPackage extends Component {
  render() {
    const {
      packageInfo,
      selectedMonths,
      invoice,
      i18n: { Total, Duration },
    } = this.props;
    return (
      <div className="summary-product mt-3">
        <Row className={`${invoice ? "align-items-center" : ""}`}>
          <Col xs={12} md={invoice ? 2 : 4}>
            {/* subscription image start  */}
            <div className="summary-product__img">
              <img
                src={
                  packageInfo?.main_image
                    ? apiService.imageLink + packageInfo?.main_image
                    : "/images/placeholder.png"
                }
                alt="main_image"
              />
            </div>
            {/* subscription image end  */}
          </Col>
          <Col xs={12} md={invoice ? 10 : 8}>
            <div className="summary-product__info">
              {/* subscription name end  */}
              <div className="summary-product__info__title">
                <p>{packageInfo?.name}</p>
              </div>
              {/* subscription name end  */}

              {/* <div className="summary-product__info__options">
                                <p>{product_option?.join(",")}</p>
                            </div> */}

              <div className="summary-product__info__qty-price">
                {/* subscription months start  */}

                <p className="m-0 duration">
                  {selectedMonths?.length > 0 && (
                    <>
                      <span>
                        <b>{Duration}</b>: {selectedMonths.length} Months
                      </span>
                      <span>
                        {selectedMonths?.map(({ label }) => label).join(", ")}
                      </span>
                    </>
                  )}
                </p>
                {/* subscription months end  */}
                {/* subscription price end  */}

                <p className="price m-0">
                  {invoice && <span className="total-word">{Total}</span>}
                  {this.props.currency.currencyName}{" "}
                  {(
                    (invoice
                      ? packageInfo.total_price
                      : packageInfo?.max_price) *
                    this.props.currency.currencyFactor
                  ).toFixed(2)}
                </p>
                {/* subscription price end  */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
  currency,
});

export default connect(mapStateToProps, { setUser })(SummaryPackage);
