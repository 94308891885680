import React, { Component } from "react";
import { connect } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { locationService } from "../../services/LocationService";
import { displayAlert, getResponseErrors } from "../../utils/misc";
import { generalServices } from "../../services/GeneralService";
class LocationBlock extends Component {
  state = {
    openPopup: false,
    addAddress: false,
    placesArray: [],
    /* Default position */
    defaultPosition: {
      lat: 31.963158,
      lng: 35.930359,
    },
    position: {
      lat: 31.963158,
      lng: 35.930359,
    },
    fields: {
      nickname: this.props.data.nickname ?? "",
      address: this.props.data.address ?? "",
      building: this.props.data.building ?? "",
      phone: this.props.data.phone ?? "",
      floor: this.props.data.floor ?? "",
      apartment: this.props.data.appartment ?? "",
      place_id: this.props.data.place.id ?? "",
    },
    errors: {
      nickname: "",
      address: "",
      building: "",
      phone: "",
      floor: "",
      apartment: "",
    },
    show: false,
    place: this.props.data.place,
    showSelect: false,
    placesIds: [],
    addressChecked: -1,
  };

  componentDidMount() {
    this.getPlacesFunction(this.props.country.id, this.state.placesArray);
  }

  closeModal = () => {
    this.setState({
      show: false,
      showSelect: false,
      placesArray: [],
    });
  };

  render() {
    const {
      currentUser,
      data,
      withoutEditDelete,
      showEditLocation,
      selectedLocation,
      i18n: { Edit, Delete },
    } = this.props;
    const { addressChecked } = this.state;
    return (
      <>
        <label
          className="addresses__block mb-4 "
          style={{ height: withoutEditDelete ? 153 : 210 }}
        >
          {/* check boc input start  */}{" "}
          <input
            type="radio"
            name="addressChecked"
            value={addressChecked}
            onChange={() => {
              this.setState({ addressChecked: data.id });
              selectedLocation(data);
            }}
          />
          {/* check boc input end  */}
          {/* if withoutEditDelete ( address in checkout and subscription ) to show checkmark ( to select one) */}
          {withoutEditDelete && <span className="checkmark"></span>}
          {/* location nick name start */}
          <h5>{data.nickname}</h5>
          {/* location nick name end */}
          {/* user name  start  */}
          <p>{currentUser.name}</p>
          {/* user name  end  */}
          {/* location address start  */}
          <p>{data?.place?.name}</p>
          {/* location address end  */}
          {/* location phone start  */}
          <div className="addresses__block__phone flex">
            <span>T:</span>
            <span>{` ${data.phone ? data.phone : ""} `}</span>
          </div>
          {/* location phone end  */}
          {/* location block with edit and delete button start  */}
          {!withoutEditDelete && (
            <div className="address__block__buttons flex mt-3">
              <button
                className="submit-button2"
                onClick={() => {
                  showEditLocation(data);
                }}
              >
                {Edit}
              </button>

              <button
                className="submit-button2"
                onClick={() => this.deleteLocation(data.id)}
              >
                {Delete}
              </button>
            </div>
          )}
          {/* location block with edit and delete button end  */}
        </label>
      </>
    );
  }

  // deleteLocation function start
  deleteLocation = async (id) => {
    // delete location handler by api request
    const {
      error: errorTag,
      success: successText,
      LocationDeletedSuccessfully,
      AreYouSureToDeleteThisLocation,
      no,
      yes,
    } = this.props.i18n;
    displayAlert(errorTag, AreYouSureToDeleteThisLocation, "warning", [
      no,
      yes,
    ]).then(async (confirm) => {
      if (confirm) {
        const { success } = await locationService.deleteUserLocation({
          user_location_id: id,
        });
        if (!success) return;

        displayAlert(successText, LocationDeletedSuccessfully, "success");
        this.props.reFetchData();
      }
    });
  };
  // deleteLocation function end

  // get Places Function start
  getPlacesFunction = async (id, placesArrayParameter) => {
    // function to get places from the server and set it to the current state and add placesArrayParameter if show Select
    const { data, success } = await generalServices.getAllCountries(id);
    if (!success) return;
    if (data?.length > 0 && !this.state.showSelect) {
      this.setState({
        placesArray: [
          data?.map((item, index) => ({
            value: item.id,
            label: item.name,
          })),
        ],
      });
    }
    if (data?.length > 0 && this.state.showSelect) {
      this.setState({
        placesArray: [
          ...placesArrayParameter,
          data?.map((item, index) => ({
            value: item.id,
            label: item.name,
          })),
        ],
      });
    }
  };
  // get Places Function end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  countryId: country.id,
  country,
});
export default connect(mapStateToProps)(LocationBlock);
