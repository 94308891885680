import React from "react";
import { useSelector } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import moment from "moment";

function LayaltyProgramTable({ data }) {
  const language = useSelector(({ language }) => language);

  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const { Points: PointsText, PlacedOrder } = i18n;

  return (
    <>
      <div className="layalty-table mb-5">
        <table>
          {data?.map(({ id, created_at, points }) => {
            return (
              <tr className="layalty-table__item">
                <td>{moment(created_at).format("yy-MM-DD")}</td>
                <td>
                  {PlacedOrder} <span> #{id} </span>{" "}
                </td>
                <td>
                  <img src="/img/plus.svg" alt="/" />
                  {points} {PointsText}
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
}

export default LayaltyProgramTable;
