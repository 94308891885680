import React, { Component } from "react";
import { Col, Container, Row, DropdownButton, Modal } from "react-bootstrap";
import Product from "../../blocks/Product";
import Filter from "../productsCategoryPage/Filter";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import FilterModal from "./FilterModal";
import { tabletScreen } from "../../../utils/misc";
import { categoryService } from "../../../services/CategoryService";
import { generalServices } from "../../../services/GeneralService";
import Skeleton from "react-loading-skeleton";
import Dropdown from "@restart/ui/esm/Dropdown";
import SubCategories from "./SubCategories";
import PaginatedItems from "./PaginationBlack";
import PageTitle from "../../blocks/PageTitle";
import CategoryAccordion from "./CategoryAccordion";

class ProductsCategory extends Component {
  state = {
    products: [],
    fullView: false,
    ShowFilter: false,
    adsData: {},
    adsLoader: true,
    ReviewData: [],
    filterModal: false,
    sideFilterShow: true,
    filterData: {},
    brands: [],
    price_from: "",
    price_to: "",
    rate: "",
    options_values: [],
    free_shipping: false,
    new_arrivals: false,
    sale: false,
    sliderLoader: true,
    filterLoader: true,
    categoriesLoader: true,
    meta: {},
    currentPage: 1,
    dropdownOpen: false,
    SortByCase: "",
    DisplayCase: 50,
    imageSwitch: 1,
    isFilterModalShow: false,
    lastFilteredData: {},
    display: "",
    isSortModalShow: false,
    categoryProductLoader: true,
  };
  componentDidMount() {
    this.setState({ page: this.props.match.params.page });
    Promise.all([
      this.getSingleCategoryProducts(
        this.props.match.params.page,
        [],
        [],
        0,
        0,
        999999999,
        false,
        false,
        10
      ),
      ,
      this.getCategories(0, 0, 0, 0, 0),
      this.getSingleCategoryInfoAndFilters(),
      this.getSingleAds(),
    ]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params?.machineName !==
      this.props.match.params?.machineName
    ) {
      this.setState({ page: this.props.match.params.page });
      Promise.all([
        this.getSingleCategoryProducts(
          this.props.match.params.page,
          [],
          [],
          0,
          0,
          999999999,
          false,
          false,
          10
        ),
        ,
        this.getCategories(0, 0, 0, 0, 0),
        this.getSingleCategoryInfoAndFilters(),
        this.getSingleAds(),
      ]);
    }
  }

  // get Single Category Info And Filters start
  getSingleCategoryInfoAndFilters = async () => {
    // function to get Single Category Info And Filters and set it to current state
    const { data, success } =
      await categoryService.getSingleCategoryInfoAndFilters(
        this.props.match.params.machineName
      );

    if (!success) return;
    this.setState({
      filterData: data,
      sort: data.sortTypes[0]?.value,
      SortByCase: data.sortTypes[0]?.name,
      filterLoader: false,
    });
  };
  // get Single Category Info And Filters end

  // get Categories start
  getCategories = async (parentId, isFeatured, topBar, showBrands, showSub) => {
    // function to get categories from the server and set it to current state base isTopBar select data name
    const { data, success } = await generalServices.getCategories(
      parentId,
      isFeatured,
      topBar,
      showBrands,
      showSub,
      this.props.country.id
    );

    if (!success) return;
    this.setState({
      categories: data,
      categoriesLoader: false,
    });
  };
  // get Categories end

  // get Single Category Products start
  getSingleCategoryProducts = async (
    page,
    options_values,
    brands,
    rate,
    price_from,
    price_to,
    free_shipping,
    new_arrivals,
    DisplayCase
  ) => {
    // send the api request to get the categories products and show it as a product cards
    this.setState({ categoryProductLoader: true });
    const { data, success, meta } =
      await categoryService.getSingleCategoryProducts({
        machine_name: this.props.match.params.machineName,
        page,
        options_values,
        brands,
        rate,
        price_from,
        price_to,
        free_shipping,
        new_arrivals,
        has_discount: this.state.sale,
        sort: this.state.sort ?? 0,
        display: DisplayCase,
      });

    if (!success) {
      this.setState({
        page: page,
      });
      return;
    }
    this.setState({
      products: data,
      meta: meta,
      categoryProductLoader: false,
      page: page,
    });
  };
  // get Single Category Products end

  changePage(page) {
    // handel on Pagination page change
    this.setState({ page: page + 1 });
  }

  // toggle function start
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  render() {
    const {
      products,
      ShowFilter,
      filterLoader,
      categoriesLoader,
      filterData,
      adsLoader,
      meta,
      SortByCase,
      isSortModalShow,
      categoryProductLoader,
    } = this.state;
    const {
      language,
      i18n: { Home, SortBy, Sort, brandProductsEmpty },
    } = this.props;
    const closeModal = () => {
      this.setState({ isFilterModalShow: false });
    };

    return (
      <div
        className={`products-category  ${ShowFilter === true && "podMob"}  ${
          language === "rtl" && "products-category-arabic"
        }`}
      >
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={filterData?.categoryInfo?.name}
          links={[
            { name: Home, url: "/" },
            { name: filterData?.categoryInfo?.name, url: "#" },
          ]}
        />

        <Container>
          {this.state.isFilterModalShow && (
            <FilterModal //Filter Modal component
              pathName={this.props.location.pathname}
              filterModal={true}
              closeModal={closeModal}
              getFilterData={this.getFilterData}
              data={filterData}
            />
          )}

          {this.state.isSortModalShow && (
            <Modal
              show={isSortModalShow}
              className={`ProductView ${language === "rtl" && "modal-arabic"}`}
              onHide={() => this.setState({ isSortModalShow: false })}
            >
              <Modal.Header
                closeButton
                onClick={() => this.setState({ isSortModalShow: false })}
              >
                <Modal.Title>{Sort}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* sort items start  */}
                <div className="sortBy-and-display-select m-auto">
                  <p>{SortBy}</p>
                  <DropdownButton id="dropdown-basic-button" title={SortByCase}>
                    {filterData.sortTypes?.map(({ name, value }) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            this.setState(
                              {
                                SortByCase: name,
                                sort: value,
                              },
                              () => {
                                this.getSingleCategoryProducts(
                                  1,
                                  [],
                                  [],
                                  0,
                                  0,
                                  999999999,
                                  false,
                                  false,
                                  10
                                );
                              }
                            );
                          }}
                        >
                          {name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </div>
              </Modal.Body>
            </Modal>
          )}

          <Row>
            <Col md={3} xl={3} className="products-filter-component">
              {categoriesLoader ? (
                <Skeleton height={tabletScreen ? 300 : 400} />
              ) : (
                <CategoryAccordion //Category Accordion  component
                  categories={this.state.categories}
                  isSub={
                    filterData?.categoryInfo?.parent_id > 0 &&
                    filterData?.categoryInfo?.parent_id
                  }
                />
              )}

              {this.state.filterData.subCategories?.length > 0 && (
                <SubCategories subCats={this.state.filterData.subCategories} />
              )}

              {filterLoader ? (
                <Skeleton height={tabletScreen ? 300 : 400} />
              ) : (
                <Filter //Filter  component
                  pathName={this.props.location.pathname}
                  data={filterData}
                  getFilterData={this.getFilterData}
                  subCats={this.state.filterData.subCategories}
                />
              )}
            </Col>

            <Col md={9} className="ProductCategoriesWrap">
              <div
                className={`ProductView ${
                  language === "rtl" && "ProductView-arabic"
                } `}
              >
                <div className="flex gap-1">
                  <div
                    onClick={() =>
                      this.setState({
                        isFilterModalShow: true,
                      })
                    }
                    className="filter-icon"
                  >
                    <a>
                      <img
                        src="/images/filtergray.svg"
                        width="25"
                        height="23"
                      />
                    </a>
                  </div>

                  <div
                    onClick={() =>
                      this.setState({
                        isSortModalShow: true,
                      })
                    }
                    className="filter-icon"
                  >
                    <a>
                      <img src="/img/sortIcon.svg" width="25" height="23" />
                    </a>
                  </div>
                </div>

                <div className="sortBy-and-display-select d-none-tablet pt-5px pb-1">
                  <p>{SortBy}</p>
                  <DropdownButton id="dropdown-basic-button" title={SortByCase}>
                    {filterData.sortTypes?.map(({ name, value }) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            this.setState(
                              {
                                SortByCase: name,
                                sort: value,
                              },
                              () => {
                                this.getSingleCategoryProducts(
                                  1,
                                  [],
                                  [],
                                  0,
                                  0,
                                  999999999,
                                  false,
                                  false,
                                  10
                                );
                              }
                            );
                          }}
                        >
                          {name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </div>
              </div>

              <Row>
                {categoryProductLoader ? (
                  Array(9)
                    .fill(4)
                    .map((item, index) => (
                      <Col
                        lg={4}
                        md={6}
                        xs={6}
                        className="mb-4 pb-4"
                        key={index}
                      >
                        <Skeleton className="product-image-Skeleton" />
                        <div className="px-2 py-3">
                          <div className="mb-2 px-3 px-sm-4">
                            <Skeleton height={25} />
                          </div>
                          <div className="px-4 px-sm-5">
                            <Skeleton height={25} />
                          </div>
                        </div>
                      </Col>
                    ))
                ) : products.length < 1 ? (
                  <div className="empty-products">
                    <img src="/img/productsEmpty.svg" alt="k" />
                    <p> {brandProductsEmpty} </p>{" "}
                  </div>
                ) : (
                  <>
                    {/* products  start  */}
                    {products
                      .filter((_, index) => index < 6)
                      .map((item) => (
                        <Col lg={4} md={6} xs={6}>
                          <Product // Product card that takes product as data and show product as a card with image
                            data={item}
                            reFetchData={() =>
                              this.getSingleCategoryProducts(
                                1,
                                [],
                                [],
                                0,
                                0,
                                999999999,
                                false,
                                false,
                                10
                              )
                            }
                          />
                        </Col>
                      ))}
                    {adsLoader ? (
                      <Col xs={12} className="mb-5">
                        <Skeleton height={tabletScreen ? 110 : 200} />
                      </Col>
                    ) : null}

                    {products
                      .filter((_, index) => index > 5)
                      .map((item) => (
                        <Col lg={4} md={6} xs={6}>
                          <Product // Product card that takes product as data and show product as a card with image
                            data={item}
                            reFetchData={() =>
                              this.getSingleCategoryProducts(
                                1,
                                [],
                                [],
                                0,
                                0,
                                999999999,
                                false,
                                false,
                                10
                              )
                            }
                          />
                        </Col>
                      ))}
                    {/* products  end  */}
                  </>
                )}
              </Row>

              {/* Pagination Start */}
              {meta?.last_page > 1 && (
                <Row>
                  <Col>
                    <div className="Pagination">
                      <PaginatedItems // pagination component that handle the pages
                        items={[...Array(meta?.total).keys()]}
                        itemsPerPage={meta?.per_page}
                        changePage={(page) => this.changePage(page)}
                        page={this.state.page}
                        last_page={meta?.last_page}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/* Pagination End */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  // get Single Ads start
  getSingleAds = async () => {
    // function to get Single Ads
    const { data, success } = await generalServices.getSingleAds(
      "categories_block_full_space",
      0,
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      adsData: data,
      adsLoader: false,
    });
  };
  // get Single Ads end

  // getFilterData start
  getFilterData = async (filterData) => {
    // function to get filter data and set it to current state
    this.setState(
      {
        options_values: filterData.options_values,
        brands: filterData.brands,
        rate: filterData.rate,
        price_from: filterData.price_from,
        price_to: filterData.price_to,
        free_shipping: filterData.free_shipping,
        new_arrivals: filterData.new_arrivals,
        sale: filterData.sale,
      },
      () => {
        this.getSingleCategoryProducts(
          1,
          this.state.options_values,
          this.state.brands,
          this.state.rate,
          this.state.price_from,
          this.state.price_to,
          this.state.free_shipping,
          this.state.new_arrivals,
          this.state.sale,
          this.state.DisplayCase
        );
      }
    );
  };
  // getFilterData end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setLanguage })(ProductsCategory);
