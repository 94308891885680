import React, { Component } from "react";
import Select from "react-select";
import { ErrorMessage } from "./ErrorMessage";
import Label from "./Label";
import Global from "../../../src/language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { connect } from "react-redux";
import { setLanguage } from "../../redux/actions-reducers/language";
class SelectInput extends Component {
  render() {
    const {
      label,
      tooltip,
      data = [],
      validate,
      name,
      value,
      isRequired,
      isMulti,
      onAddNew,
      placeholder,
      className,
    } = this.props;

    return (
      <div className="selectBlock mb-3">
        {/* select label start  */}
        {label && (
          <Label
            label={label}
            tooltip={tooltip}
            isRequired={isRequired}
            onAddNew={onAddNew}
          />
        )}
        {/* select label end  */}

        {/* select input start  */}
        <Select
          className={`basic-single mainSelect ${className ? className : ""}`}
          classNamePrefix="select"
          options={data}
          name={name}
          value={value}
          onChange={this.handleChange}
          isMulti={isMulti}
          placeholder={placeholder}
        />
        {/* select input end  */}

        {/* select error start  */}
        {validate && <ErrorMessage message={validate} isSelect />}
        {/* select error end  */}
      </div>
    );
  }

  handleChange = (selectedOption) => {
    this.props.onFieldChange(this.props.name, selectedOption);
  };
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(SelectInput);
