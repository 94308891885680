import React, { Component } from "react";
import { Accordion, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

class Filter extends Component {
  state = {
    products: [1, 2, 3, 4],
    products2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    brands: [],
    price_from: "",
    price_to: "",
    rate: [],
    options_values: [],
    free_shipping: false,
    sale: false,
    new_arrivals: false,
    isChecked: false,
    arr: [],
    priceValue: { min: 0, max: 100 },
    min: 0,
    max: 100,
  };

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        rate: [],
        price_to: [],
        price_from: [],
        options_values: [],
        priceValue: {
          min: this.props.data.prices?.min,
          max: this.props.data.prices?.max,
        },
        min: this.props.data.prices?.min,
        max: this.props.data.prices?.max,
      });
    }
  }
  componentDidUpdate(pree) {
    if (pree.pathName !== this.props.pathName) {
      if (this.props.data) {
        this.setState({
          rate: [],
          price_to: [],
          price_from: [],
          options_values: [],
          priceValue: {
            min: this.props.data.prices?.min,
            max: this.props.data.prices?.max,
          },
          min: this.props.data.prices?.min,
          max: this.props.data.prices?.max,
        });
      }
    }
  }
  handleToggleInSale = () => {
    // sale to not sale and then re call data 
    Promise.all([this.setState({ sale: !this.state.sale })]).then(() => {
      this.sendFilterData();
    });
  };

  render() {
    const { options_values, priceValue, max, min } = this.state;

    const {
      language,
      data,
      i18n: { filterBy, resetFilter, Price, Rate, inSale },
    } = this.props;

    // Push new filter to state
    return (
      <div
        className={`products-filter ${
          language === "rtl" && "products-filter-arabic"
        }`}
      >
        <div className="FilterTitle">
          <h2>{filterBy}</h2>
          <a onClick={() => this.resetFilter()}>{resetFilter}</a>
        </div>
        <div className="AccordionFilters">
          <Accordion
            defaultActiveKey="9"
            accordion={true}
            expandMode={"multiple"}
            key={this.props.pathName + "1"}
            flush
          >
            {/* Rate Accordion */}
            <Accordion.Item eventKey="1">
              <Accordion.Header>{Rate}</Accordion.Header>
              <Accordion.Body>
                <form>
                  <ul>
                    <li>
                      <label
                        className={
                          this.state.rate.includes(5)
                            ? "CehckBoxContainer ActiveFa"
                            : "CehckBoxContainer"
                        }
                      >
                        <div className="primary--color">
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <span className="CehckBoxContainer__upText"> </span>
                        </div>

                        <input
                          type="checkbox"
                          name="rate_check"
                          data-rate="5"
                          onClick={() => {
                            this.pushNewFilter("rate", 5);
                          }}
                          checked={this.state.rate.includes(5)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>

                    <li>
                      <label
                        className={
                          this.state.rate.includes(4)
                            ? "CehckBoxContainer ActiveFa"
                            : "CehckBoxContainer"
                        }
                      >
                        <div className="primary--color">
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star" aria-hidden="true" />
                          <i className="fa fa-star-o" aria-hidden="true" />
                          <span className="CehckBoxContainer__upText"> </span>
                        </div>
                        <input
                          type="checkbox"
                          name="rate_check"
                          data-rate="4"
                          onClick={() => {
                            this.pushNewFilter("rate", 4);
                          }}
                          checked={this.state.rate.includes(4)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>

                    <li>
                      <label
                        className={
                          this.state.rate.includes(3)
                            ? "CehckBoxContainer ActiveFa"
                            : "CehckBoxContainer"
                        }
                      >
                        <div>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <span className="CehckBoxContainer__upText"> </span>
                        </div>

                        <input
                          type="checkbox"
                          name="rate_check"
                          data-rate="3"
                          onClick={() => {
                            this.pushNewFilter("rate", 3);
                          }}
                          checked={this.state.rate.includes(3)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>

                    <li>
                      <label
                        className={
                          this.state.rate.includes(2)
                            ? "CehckBoxContainer ActiveFa"
                            : "CehckBoxContainer"
                        }
                      >
                        <div>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <span className="CehckBoxContainer__upText"> </span>
                        </div>

                        <input
                          type="checkbox"
                          name="rate_check"
                          data-rate="2"
                          onClick={() => {
                            this.pushNewFilter("rate", 2);
                          }}
                          checked={this.state.rate.includes(2)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>

                    <li>
                      <label
                        className={
                          this.state.rate.includes(1)
                            ? "CehckBoxContainer ActiveFa"
                            : "CehckBoxContainer"
                        }
                      >
                        <div>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <span className="CehckBoxContainer__upText"> </span>
                        </div>

                        <input
                          type="checkbox"
                          name="rate_check"
                          data-rate="1"
                          onClick={() => {
                            this.pushNewFilter("rate", 1);
                          }}
                          checked={this.state.rate.includes(1)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </form>
              </Accordion.Body>
            </Accordion.Item>
            {/* Rate Accordion End */}
          </Accordion>

          {/* dynamic filter Accordion */}
          {data.options?.map(({ name, id, values }) => {
            return (
              <Accordion
                defaultActiveKey="9"
                accordion={true}
                expandMode={"multiple"}
                flush
                key={this.props.pathName + id}
              >
                <Accordion.Item eventKey={id}>
                  <Accordion.Header>{name}</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {values.map(({ name, id }) => {
                        return (
                          <li key={id}>
                            <label
                              className={`CehckBoxContainer ${
                                options_values.includes(id) &&
                                "CehckBoxContainerActive"
                              }`}
                            >
                              {name}

                              <input
                                type="checkbox"
                                checked={
                                  this.state.options_values.includes(id) &&
                                  "checked"
                                }
                                onClick={() =>
                                  this.pushNewFilter("options_values", id)
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          })}
          <Accordion
            defaultActiveKey="9"
            accordion={true}
            expandMode={"multiple"}
            key={this.props.pathName + "2"}
            flush
          >
            <Accordion.Item eventKey="3">
              <Accordion.Header>{Price}</Accordion.Header>
              <Accordion.Body className="with-border-bottom">
                <div>
                  <InputRange
                    maxValue={max}
                    minValue={min}
                    value={priceValue}
                    formatLabel={(value) =>
                      `${(value * this.props.currency.currencyFactor).toFixed(
                        0
                      )}` +
                      " " +
                      this.props.currency.currencyName
                    }
                    onChange={(value) => {
                      this.setState({
                        priceValue: value,
                      });
                    }}
                    onChangeComplete={() => {
                      this.pushNewFilter("price_from", priceValue.min);
                      this.pushNewFilter("price_to", priceValue.max);
                    }}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <span className="h5 text-gray">{inSale}</span>
          <Form.Check
            className="checkbox-toggle"
            type="switch"
            id="sale_check"
            checked={this.state.sale}
            onChange={this.handleToggleInSale}
          />
        </div>
      </div>
    );
  }

  // send Filter Data start
  sendFilterData = () => {
    // call getFilterData with tha filter data
    this.props.getFilterData({
      options_values: this.state.options_values,
      brands: this.state.brands,
      rate: this.state.rate,
      price_from: this.state.price_from,
      price_to: this.state.price_to,
      free_shipping: this.state.free_shipping,
      new_arrivals: this.state.new_arrivals,
      sale: this.state.sale,
    });
  };
  // send Filter Data end

  // get new filter start
  pushNewFilter = (filterType, value) => {
    // set filters data
    if (
      filterType === "brands" ||
      filterType === "options_values" ||
      filterType === "rate"
    ) {
      if (this.state[`${filterType}`].includes(value)) {
        this.setState(
          {
            [filterType]: this.state[`${filterType}`].filter(
              (element) => element !== value
            ),
          },
          () => {
            this.sendFilterData();
          }
        );
      } else {
        this.setState(
          {
            [filterType]: [...this.state[`${filterType}`], value],
          },
          () => {
            this.sendFilterData();
          }
        );
      }
    } else {
      this.setState(
        {
          [filterType]: value,
        },
        () => {
          this.sendFilterData();
        }
      );
    }
  };
  // get new filter end

  // reset Filter start
  resetFilter = () => {
    this.setState(
      {
        options_values: [],
        brands: [],
        rate: "",
        price_from: this.props.data.prices.min,
        price_to: this.props.data.prices.max,
        free_shipping: false,
        sale: false,
        new_arrivals: false,
        priceValue: {
          min: this.props.data.prices.min,
          max: this.props.data.prices.max,
        },
      },
      () => {
        this.props.getFilterData({
          options_values: [],
          brands: [],
          rate: "",
          price_from: this.props.data.prices.min,
          price_to: this.props.data.prices.max,
          free_shipping: false,
          sale: this.state.sale,
          new_arrivals: false,
        });
      }
    );
  };
  // reset Filter end
}

const mapStateToProps = ({ language, currentUser, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  currency,
});

export default connect(mapStateToProps)(Filter);
