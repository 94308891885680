import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import PaginatedItems from "../../Search/PaginationBlack";
import { withRouter } from "react-router-dom";
import SubscriptionsProfileTable from "./SubscriptionsProfileTable";
import { subscriptionsService } from "../../../../services/SubscriptionsService";

class SubscriptionsProfile extends Component {
  state = {
    StatusCase: this.props.i18n.All,
    color: "",
    orders: [],
    fields: {
      to: "",
      from: "",
      key: "",
      status: "",
    },
    currentPage: 1,
    loader: true,
    meta: {},
  };

  componentDidMount() {
    Promise.all([
      this.getSubscriptionsOrders(1),
      this.getSubscriptionsOrders(5),
    ]).then(() => {
      this.setState({
        pageLoader: false,
        loader: false,
      });
    });
  }
  refetchData() {
    Promise.all([
      this.getSubscriptionsOrders(1),
      this.getSubscriptionsOrders(5),
    ]).then(() => {
      this.setState({
        pageLoader: false,
        loader: false,
      });
    });
  }
  
  getSubscriptionsOrders = async (status) => {
    // function to get Subscriptions Orders from the server and set it to the current state by status if its 1 activeOrders else historyOrders
    const { data, success, meta } =
      await subscriptionsService.getSubscriptionsOrders(
        this.props.match.params.page,
        {
          from_date: "",
          to_date: "",
          search_key: "",
          status,
        }
      );
    if (!success) return;

    if (+status === 1) {
      this.setState({
        activeOrders: data,
        ["activeMeta"]: meta,
      });
    } else {
      this.setState({
        historyOrders: data,
        ["historyMeta"]: meta,
      });
    }
  };

  render() {
    const {
      language,
      i18n: { subscriptionEmpty },
    } = this.props;
    const { meta, historyOrders, activeMeta, historyMeta, activeOrders } =
      this.state;

    return (
      <Container>
        <div className={`orders ${language === "rtl" && "orders-arabic"}`}>
          {/* Order start */}
          {this.state.loader ? (
            <>
              <Skeleton height={400} className="mt-3" />
            </>
          ) : activeOrders?.length > 0 || historyOrders?.length > 0 ? (
            <SubscriptionsProfileTable
              refetchData={() => this.refetchData()}
              activeOrders={activeOrders}
              historyOrders={historyOrders}
              activeMeta={activeMeta}
              historyMeta={historyMeta}
              changePage={(page) => this.changePage(page)}
              isActive={true}
              getUserOrders={() => this.getUserOrders()}
              controlExchangeModal={this.controlExchangeModal}
            />
          ) : (
            <Col md={12}>
              <div className="empty-products">
                <img src="/img/orderEmpty.svg" alt="k" />
                <p> {subscriptionEmpty} </p>
              </div>
            </Col>
          )}
          {/* Order End */}
        </div>

        {/* Pagination Start */}
        {this.state.loader ? null : meta.last_page > 1 ? (
          <Row>
            <Col md={12}>
              <div className="Pagination">
                <PaginatedItems // pagination component that handle the pages
                  items={[...Array(meta?.total).keys()]}
                  itemsPerPage={meta?.per_page}
                  changePage={(page) => this.changePage(page)}
                />
              </div>
            </Col>
          </Row>
        ) : null}

        {/* Pagination End */}
      </Container>
    );
  }

  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(`/SubscriptionsProfilePage/${page + 1}`);

    this.setState(
      {
        pageLoader: true,
      },
      () => {
        this.getUserOrders();
      }
    );
  }

  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState(
      {
        fields: { ...fields, [name]: value },
      },
      () => {
        this.getUserOrders(this.state.currentPage);
      }
    );
  };
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(
  withRouter(SubscriptionsProfile)
);
