import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService"; // api service class that contains general services ( functions and variables )

class SubscriptionsService {
  // guest Id getter function
  async getPackages(page) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.subscriptions.getPackages}?page=${page}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Subscription Details function
  async getSubscriptionDetails(subscription_id) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.subscriptions.getSubscriptionDetails}?subscription_id=${subscription_id}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Subscription Order Details function
  async getSubscriptionOrderDetails(order_reference) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.subscriptions.getSubscriptionOrderDetails}?order_reference=${order_reference}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Subscriptions Orders function
  async getSubscriptionsOrders(page, ordersData) {
    try {
      const data = await apiService
        .authenticated()
        .post(
          `${apiEndPoints.subscriptions.getSubscriptionsOrders}?page=${page}`,
          {
            guest_id: apiService.accessToken === 0 ? this.guestId : 0,
            ...ordersData,
          }
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // create Subscription Order function
  async createSubscriptionOrder() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.subscriptions.createSubscriptionOrder}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // subscription Checkout Submit function
  async subscriptionCheckoutSubmit(checkOutData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.subscriptions.subscriptionCheckoutSubmit, {
          ...checkOutData,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // validate Subscription Fields function
  async validateSubscriptionFields({ fields, subscription_id }) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.subscriptions.validateSubscriptionFields, {
          subscription_id: subscription_id,
          fields: { ...fields },
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({
        success: false,
        ...response?.data,
        message: response?.data?.message,
      });
    }
  }
}

export const subscriptionsService = new SubscriptionsService();
