import React from "react";
import { useSelector } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import moment from "moment";
import { Link } from "react-router-dom";

function ReturnRequestsProfileTable({ data, meta, getUserOrders }) {
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const {
    Status,
    Pending,
    Accepted,
    rejected,
    RequestID,
    ReturnAndRequests,
    prodName,
    OrderId,
    Price,
    Date,
  } = i18n;
  const currency = useSelector(({ currency }) => currency);
  
  return (
    <>
      <div className="account-page__title__gift-card">
        <h3>{ReturnAndRequests}</h3>
      </div>

      <div className="order-table">
        <table>
          <tr className="table-header">
            <th>{RequestID}</th>
            <th>{OrderId}</th>
            <th>{Status}</th>
            <th>{prodName}</th>
            <th>{Price}</th>
            <th> {Date} </th>
          </tr>

          {data?.map(
            ({
              id,
              status,
              return_unit_price,
              statusColor,
              order,
              order_item,
              created_at,
            }) => {
              return (
                <tr className="order-table__item">
                  <td>#{id}</td>
                  <td>
                    <Link to={`/orderinvoice/${order.order_reference}`}>
                      #{order.id}
                    </Link>
                  </td>

                  <td style={{ color: statusColor }}>
                    {status === 1
                      ? Pending
                      : status === 2
                      ? Accepted
                      : rejected}
                  </td>
                  <td>{order_item?.product?.title}</td>
                  <td>
                    {return_unit_price &&
                      (return_unit_price * currency.currencyFactor).toFixed(2)}
                    {currency.currencyName}
                  </td>
                  <td>{moment(created_at).format("yy-MM-DD")}</td>
                </tr>
              );
            }
          )}
        </table>
      </div>
    </>
  );
}

export default ReturnRequestsProfileTable;
