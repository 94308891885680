import React, { Component } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { productService } from "../../../services/ProductService";
import CategoriesBlock from "../../blocks/CategoriesBlock";
import Skeleton from "react-loading-skeleton";
import { displayAlert, tabletScreen } from "../../../utils/misc";
import Reviews from "../../blocks/Reviews";
import { generalServices } from "../../../services/GeneralService";
import PageTitle from "../../blocks/PageTitle";
import PaginatedItems from "../../Pages/Search/PaginationBlack";
import ReactPlayer from "react-player";
import FirstTimeModal from "../../blocks/FirstTimeModal";
import {
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import ProductCarousel from "../../blocks/ProductCarousel";
import Back from "./Back";
import ImageGallery from "./ImageGallery";

class ProductDetails extends Component {
  state = {
    isLoading: false,
    isFav: false,
    activeTab: 1,
    images: [],
    product_option_group: [],
    product_option_group_obj: [],
    selectedRate: 0,
    showShears: false,
    firstTimeShow: false,
    options: [],
    optionSelected: "",
    qty: 1,
    isCheckOutSideShow: false,
    CompareProducts: [],
    showInvoice: window.innerWidth > 992 ? true : false,
    productDetails: {},
    pageLoader: true,
    relatedProducts: [],
    min_order: 1,
    start_Bar: [1, 2, 3, 4, 5],
    ReviewData: [],
    reviewRates: [],
    currentPage: 1,
    reviewMeta: {},
    compareLoader: true,
    compare: false,
    ordersCount: "",
    lastOptionId: -1,
    upSelling: [],
    selectedOptions: [],
    selectedValues: [],
    currentOptionGroup: null,
  };

  getFavoritesIDs = async () => {
    // function to get user Favorites IDs from the server and set it to user redux store data
    const { data, success } = await generalServices.getFavoritesIDs();
    if (!success) return;

    this.props.setUser({
      favoritesId: data,
    });
  };

  addRemoveFavorites = async (id) => {
    // Favorites toggle api request
    const { success } = await productService.addOrRemoveFavorite(id);
    if (!success) return;
    this.getFavoritesIDs();
    this.setState({ isFav: !this.state.isFav });
    this.getIconData();
  };

  _renderCustomControls(item) {
    if (item.isVideo) {
      return (
        <ReactPlayer
          url={item.videoUrl}
          width="100%"
          controls
          className="product-details__video"
        />
      );
    } else {
      return (
        <InnerImageZoom
          zoomScale={1}
          src={item.original}
          zoomSrc={item.original}
        />
      );
    }
  }

  _renderCustomThumbs(item) {
    return <img src={item.thumbnail} alt="l" style={{ width: "100%" }} />;
  }

  componentDidMount() {
    Promise.all([
      this.getProductDetails(),
      this.getProductRelated(),
      this.getProductReviews(),
      this.getProductSoldWith(),
    ]);
  }

  getProductReviews = async () => {
    // function to get Product Reviews and set it to current state
    const { data = [], meta } = await productService.getProductReviews(
      this.props.match.params.id,
      this.state.currentPage
    );
    this.setState({
      ReviewData: Array.isArray(data) ? [...data] : [],
      reviewMeta: meta,
    });
  };

  getProductRelated = async () => {
    // function to get Product Related and set it to current state
    const { data, success } = await productService.getProductRelated(
      this.props.match.params.id
    );
    if (!success) return;
    this.setState({
      relatedProducts: data,
    });
  };

  getProductSoldWith = async () => {
    // function to get Product Sold With and set it to current state
    const { data, success } = await productService.getProductSoldWith(
      this.props.match.params.id
    );
    if (!success) return;
    this.setState({
      upSelling: data,
    });
  };

  getProductDetails = async () => {
    // function to get Product Details by call it from the server and set it to the state
    const { data, success } = await productService.getProductDetails(
      this.props.match.params.id
    );

    if (!success) return this.props.history.push("/notfound");

    const arr = [
      {
        original: apiService.imageLink + data.main_image,
        thumbnail: apiService.imageLink + data.main_image,
        videoUrl: data.is_external_video
          ? data.video
          : apiService.imageLink + data.video,
        isVideo: Boolean(data.video),
      },
    ];
    data.images.forEach(({ image, image_thumb, video, is_external_video }) => {
      arr.push({
        original: apiService.imageLink + image,
        thumbnail: apiService.imageLink + image_thumb,
        videoUrl: is_external_video ? video : apiService.imageLink + video,
        isVideo: Boolean(video),
      });
    });

    let ActiveGroupObj = [];
    if (data.options_groups.length > 0) {
      ActiveGroupObj = data.options_groups[0];
    }
    if (data?.bundle && data?.bundle?.length > 0) {
      this.props.history.push(
        "/ProductDetailsBundle/" + this.props.match.params.id
      );
    }

    this.setState({
      isFav: this.props.currentUser.favoritesId.includes(data?.id)
        ? true
        : false,
      productDetails: data,
      product_option_group: data.options_groups,
      product_option_group_obj: ActiveGroupObj,
      currentOptionGroup: ActiveGroupObj,
      images: arr,
      pageLoader: false,
      min_order: data.options_groups[0].min_order,
      qty:
        data.options_groups[0].qty === 0 &&
        data.options_groups[0].preOrderQTY > 0
          ? 1
          : data.options_groups[0].min_order > data.options_groups[0].qty
          ? data.options_groups[0].qty
          : data.options_groups[0].min_order,

      reviewRates: data.rates,
      compare: data.in_compare,
      ordersCount: data.store.ordersCount,
    });
  };

  closeModal() {
    this.setState({
      firstTimeShow: false,
    });
  }

  componentDidUpdate(ppp) {
    if (ppp?.match?.params?.id !== this.props.match.params.id) {
      Promise.all([
        this.getProductDetails(),
        this.getProductRelated(),
        this.getProductReviews(),
        this.getProductSoldWith(),
      ]);
    }
  }

  render() {
    const pathname = window.location.pathname.toLowerCase();
    const {
      images,
      min_order,
      qty,
      upSelling,
      relatedProducts,
      isCheckOutSideShow,
      showShears,
      product_option_group_obj,
      ReviewData,
      reviewRates,
    } = this.state;
    const {
      i18n: {
        Home,
        Quantity,
        NotifyMe,
        materials,
        preOrder,
        shareWithFriends,
        InStock,
        PreOrderInstructions,
        CrossSelling,
        UpSellingProducts,
        soldOut,
        Views,
        RelatedProducts,
        showInCart,
        AddToCart,
        description,
        reviews,
      },
      language,
      currentUser,
    } = this.props;

    const { productDetails, pageLoader, reviewMeta, firstTimeShow } =
      this.state;

    return (
      <>
        <FirstTimeModal // modal that show login form or notify form
          loginShow={firstTimeShow}
          closeModal={() => this.closeModal()}
          type="notify"
          productId={product_option_group_obj?.id}
        />

        {isCheckOutSideShow && (
          <div
            className={`product-Details ${
              language === "rtl" && "product-Details-arabic"
            }`}
            style={{
              position: "fixed",
              top: 0,
              width: "100vw",
              height: "100vh",
              left: 0,
              right: 0,
              zIndex: "1200",
            }}
          >
            <div className="checkout-side-bar flex">
              <div
                className="overlay"
                onClick={() =>
                  this.setState(
                    { isCheckOutSideShow: false },
                    this.getProductDetails
                  )
                }
              ></div>

              <div className="checkout-side-bar__contents">
                <div className="size-gide-img">
                  <img
                    src={apiService.imageLink + productDetails?.guide?.image}
                    alt="/"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`product-Details ${
            language === "rtl" && "product-Details-arabic"
          }`}
        >
          <PageTitle // component that shows links to the page ( Bread Crumb )
            title={productDetails?.title}
            pagination="Home / Register"
            links={[
              { name: Home, url: "/" },
              { name: productDetails?.title, url: pathname },
            ]}
          />

          <div className="product-details__contents">
            <Container className="mb-5">
              <Row>
                <Col md={6} lg={6}>
                  {pageLoader ? (
                    <Skeleton height={tabletScreen ? 200 : 500} />
                  ) : (
                    <>
                      <ImageGallery
                        showBullets
                        items={
                          product_option_group_obj.images.length > 0
                            ? product_option_group_obj.images.map((item) => {
                                return {
                                  original: apiService.imageLink + item.image,
                                  thumbnail:
                                    apiService.imageLink + item.image_thumb,
                                };
                              })
                            : images
                        }
                        images={images}
                        product={product_option_group_obj}
                        imageLink={apiService.imageLink}
                        thumbnailPosition="left"
                        showNav={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showThumbnails={true}
                        _renderCustomControls={this._renderCustomControls}
                        _renderCustomThumbs={this._renderCustomThumbs}
                      />
                    </>
                  )}
                </Col>

                <Col md={6} lg={6}>
                  {pageLoader ? (
                    <Skeleton height={tabletScreen ? 200 : 500} />
                  ) : (
                    <div className="product-details__contents__center-details">
                      <div className="flex inStock-share">
                        <div className="inStock-label flex">
                          {product_option_group_obj?.qty > 0
                            ? InStock
                            : soldOut}
                        </div>

                        <div className="d-flex gap-2">
                          <span
                            role="button"
                            className="like-button"
                            onClick={() =>
                              this.addRemoveFavorites(productDetails?.id)
                            }
                          >
                            <i
                              className={
                                this.state.isFav
                                  ? "fa fa-heart"
                                  : "fa fa-heart-o"
                              }
                              style={{
                                fontSize: "22px",
                                color: this.state.isFav ? "#fb3838" : "#000",
                              }}
                            />
                          </span>
                          <span
                            className="share-button"
                            onClick={() => {
                              this.setState({
                                showShears: true,
                              });
                            }}
                          >
                            <img src="/img/shareImg.svg" alt="l" />
                          </span>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end">
                        <Back />
                      </div>
                      <p className="text-capitalize product-details__title">
                        {productDetails?.title}
                      </p>

                      <div className="product-rates-orders-count flex">
                        <ul className="flex">
                          <li>
                            <i
                              className={
                                productDetails?.rate > 0
                                  ? "fa fa-star"
                                  : "fa fa-star-o"
                              }
                            ></i>
                          </li>

                          <li>
                            <i
                              className={
                                productDetails?.rate > 1
                                  ? "fa fa-star"
                                  : "fa fa-star-o"
                              }
                            ></i>
                          </li>

                          <li>
                            <i
                              className={
                                productDetails?.rate > 2
                                  ? "fa fa-star"
                                  : "fa fa-star-o"
                              }
                            ></i>
                          </li>

                          <li>
                            <i
                              className={
                                productDetails?.rate > 3
                                  ? "fa fa-star"
                                  : "fa fa-star-o"
                              }
                            ></i>
                          </li>

                          <li>
                            <i
                              className={
                                productDetails?.rate > 4
                                  ? "fa fa-star"
                                  : "fa fa-star-o"
                              }
                            ></i>
                          </li>
                        </ul>

                        <span>
                          {reviewMeta?.total} {reviews}
                        </span>
                        <span>
                          {productDetails?.views} {Views}
                        </span>
                        <a
                          onClick={() => {
                            this.setState({ isCheckOutSideShow: true });
                          }}
                        >
                          {productDetails?.guide?.name}
                        </a>
                      </div>

                      <p className="product-details__subTitle">
                        {productDetails?.subtitle}
                      </p>

                      <div className="product-details__price">
                        {this.props.currency.currencyName}{" "}
                        {parseFloat(
                          this.Price() * +this.props.currency.currencyFactor
                        ).toFixed(2)}{" "}
                        <del className="product-details__price_old px-2">
                          {product_option_group_obj.price !=
                            product_option_group_obj.price_after_discount &&
                            (
                              product_option_group_obj.price *
                              this.props.currency.currencyFactor
                            ).toFixed(2)}
                        </del>
                      </div>

                      <Row className="product-details__options">
                        {productDetails?.options?.map(
                          ({ name, values }, index) => {
                            return (
                              <Col xs={6}>
                                <Dropdown key={index}>
                                  {values.length < 1 ? null : (
                                    <Dropdown.Toggle
                                      variant="none"
                                      id="dropdown-basic"
                                      className="w-100 border border-black text-start"
                                    >
                                      {name}
                                      <br />
                                      {values.map((item) => {
                                        return (
                                          this.state.currentOptionGroup?.values.indexOf(
                                            item.id
                                          ) > -1 && item.name
                                        );
                                      })}
                                    </Dropdown.Toggle>
                                  )}

                                  <Dropdown.Menu className="w-100 py-0 overflow-hidden">
                                    {values.map((item) => {
                                      return (
                                        <Dropdown.Item
                                          key={item.id}
                                          className={`${
                                            item.image && "option-img"
                                          } ${
                                            this.state.currentOptionGroup &&
                                            this.state.currentOptionGroup.values.indexOf(
                                              item.id
                                            ) > -1 &&
                                            "bg-secondary text-white"
                                          }`}
                                          onClick={() =>
                                            this.handleClickOption(item)
                                          }
                                        >
                                          {item.image ? (
                                            <div>
                                              <img
                                                src={
                                                  apiService.imageLink +
                                                  item.image
                                                }
                                                alt="l"
                                                style={{
                                                  height: "30px",
                                                  width: "30px",
                                                }}
                                              />
                                              <span className="mx-2">
                                                {" "}
                                                {item.name}
                                              </span>
                                            </div>
                                          ) : (
                                            <span>{item.name}</span>
                                          )}
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            );
                          }
                        )}
                      </Row>

                      {/* {product_option_group_obj?.materials.length > 0 && (
                        <div className="product-details-quantity ">
                          <p>{materials}</p>

                          <div className="product-details__materials">
                            {product_option_group_obj?.materials?.join(", ")}
                          </div>
                        </div>
                      )} */}

                      {(product_option_group_obj.qty > 0 ||
                        product_option_group_obj.preOrderQTY > 0) && (
                        <div className="product-details-quantity ">
                          <p>{Quantity}</p>

                          <div className="qty flex">
                            <span
                              onClick={() => {
                                if (qty > min_order) {
                                  this.setState({
                                    qty: qty - 1,
                                  });
                                }
                              }}
                            >
                              <i className="fa fa-minus"></i>
                            </span>

                            <input
                              type="tel"
                              value={qty}
                              onBlur={() =>
                                !qty && this.setState({ qty: min_order })
                              }
                              onChange={({ target: { value } }) => {
                                if (!value) {
                                  this.setState({
                                    qty: null,
                                  });
                                  return;
                                }
                                if (value < product_option_group_obj?.qty) {
                                  if (value < min_order) {
                                    this.setState({
                                      qty: min_order,
                                    });
                                  } else {
                                    this.setState({
                                      qty: parseInt(value),
                                    });
                                  }
                                } else {
                                  this.setState({
                                    qty: product_option_group_obj.qty,
                                  });
                                }
                              }}
                            />

                            <span
                              onClick={() => {
                                if (
                                  qty === 0 &&
                                  product_option_group_obj?.preOrderQTY
                                ) {
                                  this.setState({
                                    qty: qty + 1,
                                  });
                                } else {
                                  if (
                                    qty <
                                    product_option_group_obj?.qty +
                                      product_option_group_obj?.preOrderQTY
                                  ) {
                                    this.setState({
                                      qty: qty + 1,
                                    });
                                  }
                                }
                              }}
                            >
                              <i className="fa fa-plus"></i>
                            </span>
                          </div>
                        </div>
                      )}

                      {(+product_option_group_obj?.qty === 0 &&
                        +product_option_group_obj?.preOrderQTY > 0) ||
                      (qty > +product_option_group_obj?.qty &&
                        +product_option_group_obj?.preOrderQTY > 0) ? (
                        <button
                          className="submit-btn mt-4"
                          onClick={() => {
                            this.addToCart(product_option_group_obj.id, 1);
                          }}
                        >
                          {preOrder}
                        </button>
                      ) : this.state.currentOptionGroup?.qty === 0 ? (
                        <button
                          className="submit-btn mt-4"
                          onClick={() => this.setState({ firstTimeShow: true })}
                        >
                          {NotifyMe}
                        </button>
                      ) : (
                        <button
                          className="submit-btn mt-4"
                          onClick={() => {
                            if (product_option_group_obj?.in_cart) {
                              window.location.href = "/cart";
                            } else {
                              this.addToCart(product_option_group_obj.id);
                            }
                          }}
                        >
                          {currentUser.cartIds?.includes(
                            product_option_group_obj.id
                          )
                            ? showInCart
                            : AddToCart}
                        </button>
                      )}

                      {product_option_group_obj?.qty < 1 &&
                        product_option_group_obj?.preOrderQTY > 0 && (
                          <div className="product-details__pre-order-note mt-3">
                            <div className="">
                              <i class="fa fa-exclamation-circle"></i>
                              <p>{PreOrderInstructions}</p>
                            </div>
                          </div>
                        )}

                      <div className="product-details__description mt-4">
                        <h6>{description}</h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: productDetails?.description,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
            {relatedProducts.length > 0 && (
              <CategoriesBlock
                labelTitle={""}
                title={RelatedProducts}
                data={relatedProducts.filter(({ option }) => option.qty > 0)}
                reFetchData={() => this.getProductRelated(2)}
                isRelated={true}
              />
            )}

            <Container>
              {pageLoader ? (
                <Skeleton height={tabletScreen ? 200 : 500} className="mt-3" />
              ) : (
                <div className="product-details__reviews" id="reviews">
                  <div className="product-details__reviews__contents  mb-5">
                    <Reviews // product Reviews component
                      rateBar={this.rateBar}
                      data={ReviewData}
                      refetchData={() => this.getProductReviews()}
                      reviewRates={reviewRates}
                      loadMoreComments={this.loadMoreComments}
                      isLoading={this.state.isLoading}
                      meta={this.state.reviewMeta}
                      currentPage={this.state.currentPage}
                      ordersCount={productDetails?.ordersCount}
                      id={productDetails.id}
                    />
                  </div>

                  {reviewMeta?.last_page > 1 && (
                    <Row>
                      <Col md={12} className="d-flex justify-content-center">
                        <div className="Pagination">
                          <PaginatedItems // pagination component that handle the pages
                            items={[...Array(reviewMeta?.total).keys()]}
                            itemsPerPage={reviewMeta?.per_page}
                            changePage={(page) => this.changeReviewesPage(page)}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              )}

              {relatedProducts.length > 0 && (
                <div className="mb-4">
                  <ProductCarousel //Product Carousel ( slider ) component
                    labelTitle={""}
                    title={CrossSelling}
                    data={relatedProducts.filter(
                      ({ option }) => option.qty > 0
                    )}
                    reFetchData={() => this.getHomeTDRDeals(4)}
                    rowCount={1}
                  />
                </div>
              )}

              {upSelling.length > 0 && (
                <div className="mb-4">
                  <ProductCarousel //Product Carousel ( slider ) component
                    labelTitle={""}
                    title={UpSellingProducts}
                    data={upSelling.filter(({ option }) => option.qty > 0)}
                    reFetchData={() => this.getHomeTDRDeals(4)}
                    rowCount={1}
                  />
                </div>
              )}
            </Container>
          </div>

          <Modal
            className="shareModal"
            show={showShears}
            onHide={() => this.setState({ showShears: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title> {shareWithFriends}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="ShareRow">
                <ul>
                  <a>
                    <li>
                      <FacebookShareButton
                        url={Global.shareLink + this.props.match.params.id}
                        separator=":: "
                        quote={"Look at this amazing offer from Beam"}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </li>
                  </a>
                  <a>
                    <li>
                      <WhatsappShareButton
                        url={Global.shareLink + this.props.match.params.id}
                        separator=":: "
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    </li>
                  </a>
                  <a>
                    <li>
                      <EmailShareButton
                        url={Global.shareLink + this.props.match.params.id}
                        body="I want you to see this offer from Beam "
                        separator=":: "
                      >
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                    </li>
                  </a>
                  <a title="Copy Link">
                    <li
                      onClick={() =>
                        this.copyPageUrl(
                          Global.shareLink + this.props.match.params.id
                        )
                      }
                    >
                      <img src="/img/copy2.svg"></img>
                    </li>
                  </a>
                </ul>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }

  handleClickOption = (item) => {
    let options_groups = this.state.product_option_group;
    let itemId = item.id;
    let itemOptionId = item.option_id;
    let selectedValuesLocal = this.state.selectedValues;
    let selectedOptionsLocal = this.state.selectedOptions;

    // if item id in selectedValues
    if (selectedValuesLocal.indexOf(itemId) < 0) {
      // if item itemOptionId is not in selectedOptionsLocal then add item id to selectedValues and itemOptionId to selectedOptions
      if (selectedOptionsLocal.indexOf(itemOptionId) < 0) {
        selectedValuesLocal.push(itemId);
        this.setState({ selectedValues: selectedValuesLocal });
        selectedOptionsLocal.push(itemOptionId);
        this.setState({ selectedOptions: selectedOptionsLocal });
      } else {
        // else splice selectedValuesLocal and selectedOptionsLocal to itemOptionId index and then add item id to selectedValues and itemOptionId to selectedOptions
        let indexToReplace = selectedOptionsLocal.indexOf(itemOptionId);
        selectedValuesLocal.splice(indexToReplace, 1);
        this.setState({ selectedValues: selectedValuesLocal });

        selectedOptionsLocal.splice(indexToReplace, 1);
        this.setState({ selectedOptions: selectedOptionsLocal });

        selectedValuesLocal.push(itemId);
        this.setState({ selectedValues: selectedValuesLocal });

        selectedOptionsLocal.push(itemOptionId);
        this.setState({ selectedOptions: selectedOptionsLocal });
      }
    }

    let targetGroup = null;

    // search to the object group with selected value
    for (let i = 0; i < options_groups.length; i++) {
      let optGroup = options_groups[i];
      let doesAllValuesExist = selectedValuesLocal.every((val) =>
        optGroup.values.includes(val)
      );

      if (doesAllValuesExist && !targetGroup) {
        targetGroup = optGroup;
      }
    }

    // select object group with selected value
    while (!targetGroup) {
      selectedValuesLocal.shift();
      this.setState({ selectedValues: selectedValuesLocal });

      selectedOptionsLocal.shift();
      this.setState({ selectedOptions: selectedOptionsLocal });

      for (let i = 0; i < options_groups.length; i++) {
        let optGroup = options_groups[i];
        let doesAllValuesExist = selectedValuesLocal.every((val) =>
          optGroup.values.includes(val)
        );

        if (doesAllValuesExist && !targetGroup) {
          targetGroup = optGroup;
        }
      }
    }

    this.setState({
      currentOptionGroup: targetGroup,
      product_option_group_obj: targetGroup,
    });
  };

  async copyPageUrl(url) {
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {}
  }

  loadMoreComments = () => {
    this.setState(
      {
        isLoading: true,
        currentPage: this.state.currentPage,
      },
      () => {
        this.getProductReviews(
          this.props.match.params.id,
          this.state.currentPage
        );
        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 500);
      }
    );
  };

  changeReviewesPage = async (page) => {
    await this.setState({
      currentPage: page + 1,
    });

    this.getProductReviews();
  };

  changePage(page) {
    // handel on Pagination page change
    this.nextPage(
      page,
      this.state.options_values,
      this.state.brands,
      this.state.rate,
      this.state.price_from,
      this.state.price_to,
      this.state.free_shipping,
      this.state.new_arrivals,
      this.state.DisplayCase
    );
  }

  Price = () => {
    // return product Price
    const { product_option_group_obj, qty } = this.state;

    let isNotifyMe =
      +product_option_group_obj?.qty === 0 &&
      +product_option_group_obj?.preOrderQTY === 0;

    if (qty < 1) {
      this.setState({
        qty: 1,
      });
    }

    return parseFloat(
      product_option_group_obj?.price_after_discount * (isNotifyMe ? 1 : qty)
    ).toFixed(2);
  };

  getCartIDs = async () => {
    // function to get user cart from the server and set it to user redux store data
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };

  addToCart = async (id, is_pre_order = 0) => {
    const { qty } = this.state;
    const {
      success: successText,
      AddedToCart,
      DeletedFromCart,
      ContinueShopping,
      goToCart,
      Pre_orderSendSuccessfully,
    } = this.props.i18n;
    // add the product To Cart
    const { success } = await productService.addToCart(id, qty, is_pre_order);
    if (!success) return;
    this.getCartIDs();
    this.getIconData();

    // alert if user click on Continue Shopping go to /productsCategory/All_Products/1 if the user clicked on go to cart go to /cart
    displayAlert(
      successText,
      is_pre_order === 1
        ? Pre_orderSendSuccessfully
        : qty === 0
        ? DeletedFromCart
        : AddedToCart,
      "success",
      "",
      10000,
      {
        mycancel: {
          text: ContinueShopping,
          value: 1,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: goToCart,
          value: 2,
          visible: true,
          className: "",
          closeModal: true,
        },
      }
    ).then((confirm) => {
      if (confirm === 2) {
        this.getIconData();
        this.props.history.push("/cart");
      } else if (confirm === 1) {
        this.props.history.push("/productsCategory/All_Products/1");
      }
      return;
    });
  };

  getIconData = async () => {
    // function to get Fav Cart compare Counters from the server and set it to user redux store data
    const { data, success } = await generalServices.getIconData();
    if (!success) return;

    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
      compare: data.compare_counter,
    });
  };

  rateBar = (rate, rate_count) => {
    return (
      <div className="bar-row">
        <ul className="bar">
          <li>
            <i className={rate > 0 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
          <li>
            <i className={rate > 1 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
          <li>
            <i className={rate > 2 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
          <li>
            <i className={rate > 3 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
          <li>
            <i className={rate > 4 ? "fa fa-star" : "fa fa-star-o"}></i>
          </li>
        </ul>
        <div className="evaluator-number">
          <span> {rate.toFixed(1)}</span>
          {rate_count > -1 ? <span> {rate_count}</span> : null}
        </div>
      </div>
    );
  };
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
  currency,
});

export default connect(mapStateToProps, { setUser })(ProductDetails);
