import React from "react";

export const ErrorMessage = ({ message, isSelect }) => (
  <div
    className="validation-message--underline"
    style={isSelect ? { marginTop: 0 } : null}
  >
    <small className="font-weight-bold text-capitalize ls-1">{message}</small>
  </div>
);
