import React from "react";
import { useSelector } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import moment from "moment";
import { useHistory } from "react-router-dom";

function GiftCardProfileTable({ data, meta, getUserOrders }) {
  const language = useSelector(({ language }) => language);

  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const {
    Status,
    GiftCardss,
    Details,
    SlNo,
    GiftCode,
    ActivationDate,
    ExpiryDate,
    AvailableBalance,
  } = i18n;
  const currency = useSelector(({ currency }) => currency);
  const history = useHistory();

  return (
    <>
      {/* title start  */}
      <div className="account-page__title__gift-card">
        <h3>{GiftCardss}</h3>
      </div>
      {/* title start */}

      <div className="order-table">
        <table>
          {/* table header start  */}
          <tr className="table-header">
            <th>{SlNo}</th>
            <th>{GiftCode}</th>
            <th>{ActivationDate}</th>
            <th>{ExpiryDate}</th>
            <th>{AvailableBalance}</th>
            <th>{Status}</th>
            <th></th>
          </tr>
          {/* table header end  */}

          {/* table body start  */}
          {data?.map(
            ({
              id,
              code,
              activation_date,
              order_status,
              amount,
              statusColor,
              validation_date,
              txn_ref,
            }) => {
              return (
                <tr className="order-table__item">
                  <td>#{id}</td>
                  <td>{code}</td>

                  <td>
                    {activation_date
                      ? moment(activation_date).format("yy-MM-DD")
                      : "---"}
                  </td>
                  <td>
                    {validation_date
                      ? moment(validation_date).format("yy-MM-DD")
                      : "---"}
                  </td>

                  <td>
                    {(amount * currency.currencyFactor).toFixed(2)}{" "}
                    {currency.currencyName}
                  </td>
                  <td style={{ color: statusColor }}>{order_status}</td>
                  <td className="order-table__buttons">
                    <button
                      className="submit-btn"
                      onClick={() =>
                        history.push(`/GiftCardsDetails/${txn_ref}`)
                      }
                    >
                      {Details}
                    </button>
                  </td>
                </tr>
              );
            }
          )}
          {/* table body end  */}
        </table>
      </div>
    </>
  );
}

export default GiftCardProfileTable;
