import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

class CategoryAccordion extends Component {
  render() {
    const {
      language,
      categories,
      isSub,
      i18n: { Categories },
    } = this.props;

    return (
      <div
        className={`subCategories ${
          language === "rtl" && "subCategories-arabic"
        }`}
      >
        <div className="subCategories__accordion">
          <Accordion
            accordion={true}
            defaultActiveKey={"1"}
            expandMode={"multiple"}
            flush
          >
            <Accordion.Item eventKey="1">
              <Accordion.Header>{Categories}</Accordion.Header>
              <Accordion.Body>
                {/* Categories start  */}
                <ul className="">
                  {categories?.map((item, index) => {
                    return (
                      <a
                        href={"/productsCategory/" + item.machine_name + "/1"}
                        key={index}
                      >
                        <li
                          className={
                            (window.location.pathname ===
                              "/productsCategory/" +
                                item.machine_name +
                                "/" +
                                this.props.match.params.page ||
                              item.id === isSub) &&
                            "activeCategoryAccordion"
                          }
                        >
                          {language === "rtl" ? (
                            <i class="fa fa-long-arrow-left ms-2"></i>
                          ) : (
                            <i class="fa fa-long-arrow-right me-2"></i>
                          )}
                          {item.name}
                        </li>
                      </a>
                    );
                  })}
                </ul>
                {/* Categories end  */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});

export default connect(mapStateToProps)(withRouter(CategoryAccordion));
