import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import React, { useEffect, useLayoutEffect, useState } from "react";
import "./assets/style/main.scss";
import Topbar from "./components/blocks/Topbar";
import Header from "./components/blocks/Header";
import HeaderMobile from "./components/blocks/HeaderMobile";
import Home from "./components/Pages/home/Home";
import Footer from "./components/blocks/Footer";
import SignUp from "./components/Pages/SignUp/SignUp";
import Cart from "./components/Pages/Cart/Cart";
import ProductsCategory from "./components/Pages/productsCategoryPage/ProductsCategory";
import ProductDetails from "./components/Pages/productDetails/ProductDetails";
import ProfilePage from "./components/Pages/AccountPage/profilePage/ProfilePage";
import AddressesPage from "./components/Pages/AccountPage/addressesPage/AddressesPage";
import OrdersPage from "./components/Pages/AccountPage/ordersPage/OrdersPage";
import Favourite from "./components/Pages/Favourite/Favourite";
import Search from "./components/Pages/Search/Search";
import PublicPagesData from "./components/blocks/PublicPagesData";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "./services/AuthService";
import localIpUrl from "local-ip-url";
import { setUser } from "./redux/actions-reducers/user";
import ForgetPassword from "./components/Pages/ForgetPassword/ForgetPassword";
import Subscriptions from "./components/Pages/Subscriptions/Subscriptions";
import ProfileSettings from "./components/Pages/AccountPage/SettingsPage/SettingsPage";
import SubscriptionsProfilePage from "./components/Pages/AccountPage/SubscriptionsProfilePage/SubscriptionsProfilePage";
import GiftCardProfilePage from "./components/Pages/AccountPage/GiftCardProfile/GiftCardProfilePage";
import WalletPage from "./components/Pages/AccountPage/WalletPage/WalletPage";
import ReturnRequestsProfilePage from "./components/Pages/AccountPage/ReturnRequests/ReturnRequestsProfilePage";
import ExchangeRequestsProfilePage from "./components/Pages/AccountPage/ExchangeRequests/ExchangeRequestsProfilePage";
import LayaltyProgramPage from "./components/Pages/AccountPage/LayaltyProgram/LayaltyProgramPage";
import GiftCardPage from "./components/Pages/giftCard/GiftCardPage";
import GiftCardsDetails from "./components/Pages/giftCard/GiftCardsDetails";
import PrivateCollections from "./components/Pages/PrivateCollections/PrivateCollections";
import Sale from "./components/Pages/Sale/Sale";
import ContactUs from "./components/Pages/ContactUs/ContactUs";
import CheckoutPayment from "./components/Pages/Payment/CheckoutPayment";
import FinalInvoice from "./components/blocks/FinalInvoice";
import MySteryBoxPage from "./components/Pages/MySteryBoxPage/MySteryBoxPage";
import NotFound from "./components/common/NotFound";
import FeaturedCollections from "./components/Pages/FeaturedCollections/FeaturedCollections";
import SocialLogin from "./components/Pages/SignUp/SocialLogin";
import SubscriptionInvoice from "./components/Pages/Subscriptions/SubscriptionInvoice";
import MainLoader from "./components/blocks/MainLoader";
import { userService } from "./services/UserService";
import { setCurrency } from "./redux/actions-reducers/currency";
import { setPath } from "./redux/actions-reducers/app";
import Checkout from "./components/Pages/Checkout";
import ProductDetailsBundle from "./components/Pages/ProductDetailsBundle";
import { Container } from "react-bootstrap";
import PaymentSuccess from "./components/Pages/PaymentStatus/PaymentSuccess";
import PaymentFail from "./components/Pages/PaymentStatus/PaymentFail";
import { generalServices } from "./services/GeneralService";

function App() {
  const { langCode } = useSelector(({ language }) => language);
  document.documentElement.dir = langCode === "rtl" ? "rtl" : "ltr";
  document.documentElement.lang = langCode === "rtl" ? "ar" : "en";
  if (langCode) localStorage.setItem("langCode", langCode);
  const { pathname } = useLocation();
  document.body.style.textAlign = langCode === "rtl" ? "right" : "left";
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();
  const [backPath, setBackPath] = useState("");
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (currentPath !== pathname) {
      setBackPath(currentPath);
      setCurrentPath(pathname);
    }
  }, [pathname, setBackPath, setCurrentPath, currentPath]);

  useEffect(() => {
    dispatch(setPath(backPath));
  }, [backPath, dispatch]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const guestLogin = React.useCallback(async () => {
    setIsLoader(true);
    const { success, data } = await authService.guestLogin({
      device_id: Math.floor(Math.random() * 1000000),
      ip_address: localIpUrl(),
    });

    if (!success) {
      setIsLoader(false);
      return;
    }
    localStorage.setItem("guestId", data.guest.id);
    if (data.guest.currency) {
      await dispatch(
        setCurrency({
          currencyName: data.guest.currency.code,
          currencyFactor: data.guest.currency.convert_factor,
        })
      );
    }
    await dispatch(setUser({ ...data.guest }));

    setIsLoader(false);

    // We want to check user country
    // https://ip.nf/me.json
  }, [dispatch]);

  const isUserCheck = React.useCallback(
    async (beamAccessToken) => {
      if (currentUser.email || currentUser.email !== "" || beamAccessToken) {
        const { data, success } = await userService.getUserInformation();
        if (!data || data === false || data?.status === -2) {
          localStorage.removeItem("beam_admin_access_token");
          localStorage.removeItem("beamAccessToken");
          localStorage.removeItem("persist:Beam");
          window.location.href = "/";
          return;
        }
      }
    },
    [currentUser.email]
  );

  const isGestCheck = React.useCallback(async (guestId) => {
    const { data } = await userService.getGestInformation(guestId);
    if (!data) {
      localStorage.removeItem("guestId");
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    const beamAccessToken = localStorage.getItem("beamAccessToken");
    isUserCheck(beamAccessToken);
  }, [isUserCheck]);

  useEffect(() => {
    const guestId = localStorage.getItem("guestId");
    if (guestId) isGestCheck(guestId);
    else guestLogin();
  }, [isGestCheck, guestLogin]);

  return isLoader ? (
    <MainLoader />
  ) : (
    <div className="App">
      {/* <Test />  */}
      {!pathname.includes("payment-status") && (
        <>
          <Header
            setLoader={(x) => setIsLoader(x)}
            showLogin={showLoginModal}
            setShowLoginModal={setShowLoginModal}
          />
          <HeaderMobile />
        </>
      )}
      <Switch>
        <Route
          render={() => <Home setShowLoginModal={setShowLoginModal} />} // hidden page
          path="/"
          exact
        />

        <Route component={CheckoutPayment} path="/checkoutpayment" />

        <Route component={FinalInvoice} path="/orderinvoice/:id" />
        <Route
          component={SubscriptionInvoice}
          path="/subscriptionInvoice/:id"
        />
        <Route component={SignUp} path="/Signup" />
        <Route component={ContactUs} path="/ContactUs" />
        <Route component={ProductDetails} path="/ProductDetails/:id" />

        <Route
          component={ProductDetailsBundle}
          path="/ProductDetailsBundle/:id"
        />
        <Route
          component={ProductsCategory}
          path="/ProductsCategory/:machineName/:page"
        />
        <Route component={ProfilePage} path="/profile" />
        <Route component={PaymentSuccess} path="/payment-status/success" />
        <Route component={PaymentFail} path="/payment-status/fail" />
        <Route component={ProfileSettings} path="/Settings" />
        <Route component={OrdersPage} path="/orders/:page" />
        <Route component={LayaltyProgramPage} path="/LayaltyProgram/:page?" />
        <Route component={ForgetPassword} path="/forgetpassword" />
        <Route component={AddressesPage} path="/Address" />

        <Route component={Cart} path="/Cart" />
        <Route component={Favourite} path="/Favourite" />
        <Route component={PrivateCollections} path="/PrivateCollections" />
        <Route component={Sale} path="/Sale/:page" />
        <Route component={Checkout} path="/Checkout" />
        <Route component={Search} path="/Search/:key/:catId/:page" />
        <Route component={Subscriptions} path="/Subscriptions/:page?" />
        <Route
          component={SubscriptionsProfilePage}
          path="/SubscriptionsProfilePage/:page?"
        />
        <Route component={WalletPage} path="/WalletPage/:page" />
        <Route component={GiftCardPage} path="/GiftCardPage" />
        <Route path="/SocialLogin" component={SocialLogin} />
        <Route component={GiftCardsDetails} path="/GiftCardsDetails/:id" />
        <Route component={GiftCardProfilePage} path="/GiftCardProfilePage" />
        <Route
          component={ReturnRequestsProfilePage}
          path="/ReturnRequestsProfilePage"
        />
        <Route
          component={ExchangeRequestsProfilePage}
          path="/ExchangeRequestsProfilePage"
        />
        <Route component={FeaturedCollections} path="/FeaturedCollections" />
        <Route
          component={PublicPagesData}
          path="/PublicPagesData/:machineName"
        />
        <Route
          component={MySteryBoxPage}
          path="/MySteryBoxPage/:machine_name/:page"
        />
        <Route exact path="/notfound" component={NotFound} />
        <Redirect to="/notfound" />
      </Switch>
      {!pathname.includes("payment-status") && (
        <>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;

const Test = () => {
  const [text, setText] = React.useState([]);

  const test = (comm, lines) => {
    const myLines = lines.map((item) =>
      item
        .split("")
        .filter((item) => item !== "(" && item !== ")")
        .join("")
    );
    const myComment = comm
      .split("")
      .filter((item) => item !== "(" && item !== ")")
      .join("");
    for (let i = 0; i < lines.length; i++) {
      if (myLines[i] && myComment) {
        try {
          let position = myLines[i].search(myComment);
          if (position >= 0) {
            lines[i] = ";lkjhgfdxxcvbn";
            return i + 1;
          }
        } catch {}
      }
    }
  };

  function compare(a, b) {
    if (a.line < b.line || !a.line || !b.line) {
      return -1;
    }
    if (a.line > b.line) {
      return 1;
    }
    return 0;
  }

  return (
    <Container>
      <textarea
        className="w-100 m-5"
        style={{ textTransform: "none", border: "1px solid black" }}
        onChange={(event) => {
          const lines = event.target.value?.split("\n");
          const oneLineComm = event.target.value
            ?.split("//")
            .map((item) => item.split("\n")[0])
            .slice(1)
            .map((item) => ({ text: item, line: test(item, lines) }));
          const twoLineComm = event.target.value
            ?.split("/*")
            .map((item) => item.split("*/")[0])
            .slice(1)
            .map((item) => ({ text: item, line: test(item, lines) }));

          setText([...twoLineComm, ...oneLineComm]);
        }}
      ></textarea>
      <p style={{ textTransform: "none" }}>
        {text
          .filter((item) => item.line)
          ?.sort(compare)
          .map((item, index) => (
            <>
              Line {item.line}:{item.text} <br />
            </>
          ))}
        {text
          .filter((item) => !item.line)
          ?.sort(compare)
          .map((item, index) => (
            <>
              Line {item.line}:{item.text}
              <br />
            </>
          ))}
      </p>
    </Container>
  );
};
