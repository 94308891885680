import React, { Component } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )

class ProductOptions extends Component {
  state = {
    isLoading: false,
    activeTab: 1,
    images: [],
    product_option_group: [],
    product_option_group_obj: [],
    selectedRate: 0,
    showShears: false,
    firstTimeShow: false,
    options: [],
    optionSelected: "",
    qty: 1,
    isCheckOutSideShow: false,
    CompareProducts: [],
    showInvoice: window.innerWidth > 992 ? true : false,
    productDetails: {},
    pageLoader: true,
    relatedProducts: [],
    min_order: 1,
    start_Bar: [1, 2, 3, 4, 5],
    ReviewData: [],
    reviewRates: [],
    currentPage: 1,
    reviewMeta: {},
    compareLoader: true,
    compare: false,
    ordersCount: "",
    lastOptionId: -1,
    upSelling: [],
    selectedOptions: [],
    selectedValues: [],
    currentOptionGroup: null,
    bundleOptions: {},
  };

  componentDidMount() {
    const data = this.props.productDetails;
    let ActiveGroupObj = [];
    if (data.options_groups.length > 0) {
      ActiveGroupObj = data.options_groups[0];
    }
    this.props.setData({
      ...ActiveGroupObj,
      proid: this.props?.productDetails?.id,
    });

    this.setState({
      productDetails: data,
      product_option_group: data.options_groups,
      product_option_group_obj: ActiveGroupObj,
      currentOptionGroup: ActiveGroupObj,
      pageLoader: false,
      min_order: data.options_groups[0].min_order,
      qty:
        data.options_groups[0].qty === 0 &&
        data.options_groups[0].preOrderQTY > 0
          ? 1
          : data.options_groups[0].min_order > data.options_groups[0].qty
          ? data.options_groups[0].qty
          : data.options_groups[0].min_order,

      reviewRates: data.rates,
      compare: data.in_compare,
      ordersCount: data.store.ordersCount,
    });
  }

  render() {
    const { productDetails } = this.props;
    return (
      <div className="mb-4 ">
        <Row className="product-details__options ">
          <Col xs={12}>
            <h5>{productDetails?.title}</h5>
          </Col>
          {productDetails?.options?.map(({ name, values }, index) => (
            <Col xs={6} key={index}>
              <Dropdown>
                {values.length < 1 ? null : (
                  <Dropdown.Toggle
                    variant="none"
                    id="dropdown-basic"
                    className="w-100 border border-black text-start"
                  >
                    {name}
                    <br />
                    {values.map((item) => {
                      return (
                        this.state.currentOptionGroup?.values.indexOf(item.id) >
                          -1 && item.name
                      );
                    })}
                  </Dropdown.Toggle>
                )}

                <Dropdown.Menu className="w-100 py-0 overflow-hidden">
                  {values.map((item) => {
                    return (
                      <Dropdown.Item
                        key={item.id}
                        className={`${item.image && "option-img"} ${
                          this.state.currentOptionGroup &&
                          this.state.currentOptionGroup.values.indexOf(
                            item.id
                          ) > -1 &&
                          "bg-secondary text-white"
                        }`}
                        onClick={() => this.handleClickOption(item)}
                      >
                        {item.image ? (
                          <div>
                            <img
                              src={apiService.imageLink + item.image}
                              alt="l"
                              style={{
                                height: "30px",
                                width: "30px",
                              }}
                            />
                            <span className="mx-2">{item.name}</span>
                          </div>
                        ) : (
                          <span>{item.name}</span>
                        )}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  componentDidUpdate(ppp) {
    if (ppp?.bundleOptions !== this.props.bundleOptions) {
      if (!this.props.bundleOptions[this.state.productDetails.machine_name])
        this.props.setData({
          ...this.state.currentOptionGroup,
          proid: this.props?.productDetails?.id,
        });
    }
  }

  handleClickOption = (item) => {
    let options_groups = this.state.product_option_group;
    let itemId = item.id;
    let itemOptionId = item.option_id;
    let selectedValuesLocal = this.state.selectedValues;
    let selectedOptionsLocal = this.state.selectedOptions;

    // if item id in selectedValues
    if (selectedValuesLocal.indexOf(itemId) < 0) {
      // if item itemOptionId is not in selectedOptionsLocal then add item id to selectedValues and itemOptionId to selectedOptions
      if (selectedOptionsLocal.indexOf(itemOptionId) < 0) {
        selectedValuesLocal.push(itemId);
        this.setState({ selectedValues: selectedValuesLocal });
        selectedOptionsLocal.push(itemOptionId);
        this.setState({ selectedOptions: selectedOptionsLocal });
      } else {
        // else splice selectedValuesLocal and selectedOptionsLocal to itemOptionId index and then add item id to selectedValues and itemOptionId to selectedOptions
        let indexToReplace = selectedOptionsLocal.indexOf(itemOptionId);
        selectedValuesLocal.splice(indexToReplace, 1);
        this.setState({ selectedValues: selectedValuesLocal });

        selectedOptionsLocal.splice(indexToReplace, 1);
        this.setState({ selectedOptions: selectedOptionsLocal });

        selectedValuesLocal.push(itemId);
        this.setState({ selectedValues: selectedValuesLocal });

        selectedOptionsLocal.push(itemOptionId);
        this.setState({ selectedOptions: selectedOptionsLocal });
      }
    }

    let targetGroup = null;

    // search to the object group with selected value
    for (let i = 0; i < options_groups.length; i++) {
      let optGroup = options_groups[i];
      let doesAllValuesExist = selectedValuesLocal.every((val) =>
        optGroup.values.includes(val)
      );

      if (doesAllValuesExist && !targetGroup) {
        targetGroup = optGroup;
      }
    }

    // select object group with selected value
    while (!targetGroup) {
      selectedValuesLocal.shift();
      this.setState({ selectedValues: selectedValuesLocal });

      selectedOptionsLocal.shift();
      this.setState({ selectedOptions: selectedOptionsLocal });

      for (let i = 0; i < options_groups.length; i++) {
        let optGroup = options_groups[i];
        let doesAllValuesExist = selectedValuesLocal.every((val) =>
          optGroup.values.includes(val)
        );

        if (doesAllValuesExist && !targetGroup) {
          targetGroup = optGroup;
        }
      }
    }

    this.setState({
      currentOptionGroup: targetGroup,
      product_option_group_obj: targetGroup,
    });

    this.props.setData({
      ...targetGroup,
      proid: this.props?.productDetails?.id,
    });
  };
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
  currency,
});

export default connect(mapStateToProps, { setUser })(ProductOptions);
