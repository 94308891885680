import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

/**
 component to show subscription Invoice option details
 */
export class AnsweredFormTable extends Component {
  render() {
    const { data } = this.props;

    let rowCount = data ? Math.ceil(data.length / 3) : 0;
    return (
      data && (
        <Table className="answered-form mb-4" responsive>
          <tbody className="answered-form__body">
            {[...Array(rowCount)?.keys()]?.map((_, rowCountIndex) => {
              return (
                <tr className="answered-form__body__row" key={rowCountIndex}>
                  {data
                    ?.filter(
                      (_, index) =>
                        rowCountIndex * 3 <= index &&
                        index < (rowCountIndex + 1) * 3
                    )
                    ?.map(({ field, value }, index) => {
                      return (
                        <td
                          className="answered-form__body__row__cell"
                          key={index}
                        >
                          <span>{field?.label}:</span>
                          {value}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps)(AnsweredFormTable);
