import React from "react";
import { useLocation } from "react-router-dom";

export default function ProductContainer({ children, hash }) {
  const location = useLocation();
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (location.hash === "#" + hash) {
      ref.current.scrollIntoView(false);
    }
  }, [location, hash]);
  
  return <div ref={ref}>{children}</div>;
}
