import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService"; // api service class that contains general services ( functions and variables )

class HomeService {
  // guest Id getter function
  get guestId() {
    return localStorage.getItem("guestId");
  }

  // get Home Page Data function
  async getHomePageData() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.home.getHomePageData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
      // guest Id getter function
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Home TDR Deals function
  async getHomeTDRDeals(type) {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.home.getHomeTDRDeals}?type=${type}&guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Single Brand Products function
  async getSingleBrandProducts(page, machine_name) {
    try {
      const data = await apiService
        .authenticated()
        .post(
          `${apiEndPoints.home.getSingleBrandProducts}?page=${page}`,
          machine_name
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Home Categories With Products function
  async getHomeCategoriesWithProducts() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.home.getHomeCategoriesWithProducts)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Collections function
  async getCollections(position) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.home.getCollections}?position=${0}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const homeService = new HomeService();
