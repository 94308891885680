import React from "react";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import SummaryProduct from "../../blocks/SummaryProduct";
import { displayAlert } from "../../../utils/misc";
import { generalServices } from "../../../services/GeneralService";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { isValueEnglish } from "../../../utils/isValueEnglish";
import { findTranslatedLabel } from "../../../utils/findTranslatedLabel";

export default function Summary({
  cartAmount,
  cart,
  FirstBlock,
  inCheckout,
  selectedLocation,
  handleAddLocation,
  loading,
  setLoading,
  email,
  setCompleteInformation,
}) {
  const { langCode } = useSelector(({ language }) => language);
  const { currencyName, currencyFactor } = useSelector(
    ({ currency }) => currency
  );
  const {
    error,
    MustSelectLocation,
    cartSummary,
    palcesLevel,
    SubTotal,
    TotalPrice,
    Area,
    ContinueToPayment,
    ReturnToCart,
    pleaseFillFollowingFieldsInEnglishFromLocation,
    deliveryAmount,
    feesAmount,
    taxesAmount,
    DiscountAmount,
    CouponsSlashGiftCards,
  } = Global[langCode === "ltr" ? "en" : "ar"];

  return (
    <div className="cart-summary">
      <section className="cart-summary__section">
        {/* summary title start  */}
        <header className="cart-summary__section__header">
          <h5>{cartSummary}</h5>
        </header>
        {/* summary title end  */}

        <div className="cart-summary__section__body p-0">
          {FirstBlock === "products" &&
            cart.map((item) => {
              return (
                <div className="cart-summary__section__body__summary-field flex-col">
                  <div className="product-container w-100">
                    <SummaryProduct
                      product={item}
                      product_option={item.product_option.values_names}
                      invoice={false}
                      is_subsecribtion={false}
                    />
                  </div>
                </div>
              );
            })}

          {/* package info start  */}

          {/* package info end  */}

          {/* Check out amount summary start */}
          <div
            className="d-flex justify-content-between flex-column"
            style={{ height: "100%" }}
          >
            <div>
              <div className="cart-summary__section__body__summary-field">
                <div className="summary-field__title">{SubTotal}</div>
                <div className="summary-field__value">
                  <span>{currencyName}</span>{" "}
                  {cartAmount?.items_amount &&
                    (cartAmount?.items_amount * currencyFactor).toFixed(2)}
                </div>
              </div>

              {cartAmount?.delivery_amount > 0 && (
                <div className="cart-summary__section__body__summary-field">
                  <div className="summary-field__title">{deliveryAmount}</div>
                  <div className="summary-field__value">
                    <span>{currencyName}</span> {"  "}{" "}
                    {(cartAmount?.delivery_amount * currencyFactor).toFixed(2)}
                  </div>
                </div>
              )}

              {cartAmount?.fees_amount > 0 && (
                <div className="cart-summary__section__body__summary-field">
                  <div className="summary-field__title">{feesAmount}</div>
                  <div className="summary-field__value">
                    <span>{currencyName}</span> {"  "}{" "}
                    {(cartAmount?.fees_amount * currencyFactor).toFixed(2)}
                  </div>
                </div>
              )}

              {cartAmount?.taxes_amount > 0 && (
                <div className="cart-summary__section__body__summary-field">
                  <div className="summary-field__title">{taxesAmount}</div>
                  <div className="summary-field__value">
                    <span>{currencyName}</span> {"  "}{" "}
                    {(cartAmount?.taxes_amount * currencyFactor).toFixed(2)}
                  </div>
                </div>
              )}

              {cartAmount?.discount_amount > 0 && (
                <div className="cart-summary__section__body__summary-field">
                  <div className="summary-field__title">
                    {DiscountAmount}
                    <p
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      ({CouponsSlashGiftCards})
                    </p>
                  </div>
                  <div className="summary-field__value">
                    <span>{currencyName}</span> {"  "}{" "}
                    {(cartAmount?.discount_amount * currencyFactor).toFixed(2)}
                  </div>
                </div>
              )}

              {/* total_amount start  */}
              <div className="cart-summary__section__body__summary-field total-field">
                <div className="summary-field__title">{TotalPrice}</div>
                <div className="summary-field__value">
                  <span>{currencyName}</span>{" "}
                  {cartAmount?.total_amount &&
                    (cartAmount?.total_amount * currencyFactor).toFixed(2)}
                </div>
              </div>
            </div>
            {/* total_amount end  */}

            {/* Check Out control buttons start */}
            <div className={`cart-summary__section__footer`}>
              <div className="CheckoutButt">
                {/* if we are in cart page (summary buttons) */}
                <>
                  <button
                    className="CheckoutButt-btn d-block w-100"
                    onClick={async () => {
                      if (handleAddLocation) {
                        handleAddLocation();
                      } else {
                        if (!loading) {
                          if (!selectedLocation) {
                            displayAlert(error, MustSelectLocation, "error");
                          } else {
                            const { data, success } =
                              await generalServices.getAllCountries(
                                selectedLocation?.place?.id
                              );
                            if (data?.length > 0)
                              displayAlert(
                                error,
                                palcesLevel + " ( " + Area + " ) ",
                                "error"
                              );
                            else {
                              window.location.href = "/checkoutpayment";
                            }
                          }
                        }
                      }
                      if (email) {
                        if (!loading) {
                          if (!selectedLocation) {
                            displayAlert(error, MustSelectLocation, "error");
                          } else {
                            if (
                              selectedLocation?.place?.id !==
                              "Put 1 as integer to make it outside kuwait"
                            ) {
                              let fieldArr = Object.keys(selectedLocation);
                              // check that all fields including place fields are filled in English
                              let englishErrors = [];
                              fieldArr.forEach((item) => {
                                if (item === "extra_fields") {
                                  selectedLocation[item].forEach((field) => {
                                    if (!isValueEnglish("", field.value_text)) {
                                      englishErrors.push(
                                        field?.place_field?.label
                                      );
                                    }
                                  });
                                  return;
                                }
                                if (
                                  !isValueEnglish(item, selectedLocation[item])
                                ) {
                                  englishErrors.push(
                                    findTranslatedLabel(item, langCode)
                                  );
                                }
                              });

                              if (englishErrors.length > 0) {
                                Swal.fire({
                                  icon: "error",
                                  text:
                                    pleaseFillFollowingFieldsInEnglishFromLocation +
                                    ": " +
                                    englishErrors.join(", "),
                                });
                                return true;
                              }
                            }
                            setLoading(true);
                            setCompleteInformation(true);
                          }
                        }
                      }
                    }}
                  >
                    {loading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      ContinueToPayment
                    )}
                  </button>

                  <a className="CheckoutButt-btn" href="/Cart">
                    {ReturnToCart}
                  </a>
                </>
              </div>
            </div>
          </div>
          {/* Check out amount summary end */}
        </div>
      </section>
    </div>
  );
}
