import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

function PaginationBlack({ itemsPerPage, changePage, items }) {
  // We start with an empty list of items.
  const [pageCount, setPageCount] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(1);
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const { next, previous } = i18n;

  const params = useParams();

  useEffect(() => {
    // Fetch items from another resources.
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    changePage(event.selected);
    setItemOffset(newOffset);
  };

  return (
    <>
      <ReactPaginate
        nextLabel={<div> {next + " >"}</div>}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel={<div> {"< " + previous} </div>}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageActive={(e) => {}}
        forcePage={+params.page - 1}
      />
    </>
  );
}

export default PaginationBlack;
