import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService"; // api service class that contains general services ( functions and variables )

class LocationService {
  // guest Id getter function
  get guestId() {
    return localStorage.getItem("guestId");
  }

  // get User Location Function
  async getUserLocation() {
    try {
      const data = await apiService
        .authenticated()
        .get(apiEndPoints.location?.getUserLocation)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // add Location Submit Function
  async addLocationSubmit(locationData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.location.addLocationSubmit, {
          ...locationData,
          guest_id: this.guestId ?? -1,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // check Guest Location Validation Function
  async checkGuestLocationValidation(locationData) {
    try {
      const data = await apiService
        .authenticated()
        .post("checkGuestLocationValidation", {
          ...locationData,
          guest_id: this.guestId ?? -1,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // delete User Location Function
  async deleteUserLocation(locationData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.location.deleteUserLocation, locationData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // add User Location Function
  async addUserLocation(locationData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.location.addUserLocation, locationData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // edit User Location Function
  async editUserLocation(locationData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.location.editUserLocation, locationData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const locationService = new LocationService();
