import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import AccountPageList from "../../../blocks/AccountPageList";
import AccountPageMobileList from "../../../blocks/AccountPageMobileList";
import PageTitle from "../../../blocks/PageTitle";
import SubscriptionsProfile from "./SubscriptionsProfile";

class SubscriptionsProfilePage extends Component {
  state = {
    remindMe: false,
  };

  render() {
    const {
      language,
      i18n: { Home, Subscriptions, MyAccount },
    } = this.props;

    return (
      <>
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={MyAccount}
          links={[
            { name: Home, url: "/" },
            { name: MyAccount, url: "/profile" },

            { name: Subscriptions, url: "/SubscriptionsProfilePage" },
          ]}
        />
        <Container>
          <div
            className={`account-page ${
              language === "rtl" && "account-page-arabic"
            }`}
          >
            {/* Mobile Side Menu Start */}
            <AccountPageMobileList />
            {/*Mobile Side Menu End */}

            <Row>
              {/*web Menu list Start */}
              <Col className="board" md={3}>
                <AccountPageList />
              </Col>
              {/*web Menu list end */}

              {/*Subscriptions section Start */}
              <Col md={9}>
                <SubscriptionsProfile />
              </Col>
              {/*Subscriptions section end */}
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(
  SubscriptionsProfilePage
);
