import { apiService } from "./ApiService"; // api service class that contains general services ( functions and variables )
import { handleResponse } from "../utils/misc";
import { apiEndPoints } from "../api";
class ProductService {
  // guest Id getter function
  get guestId() {
    return localStorage.getItem("guestId");
  }

  // add Or Remove Favorite function
  async addOrRemoveFavorite(product_id) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.product.addOrRemoveFavorite, {
          product_id,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // add Remove Compares function
  async addRemoveCompares(product_id) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.product.addRemoveCompares, {
          product_id,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Product Details function
  async getProductDetails(machineName) {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${
            apiEndPoints.product.getProductDetails
          }?machine_name=${machineName}&guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get User Product Compared function
  async getUserProductCompared() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.product.getUserProductCompared}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // add To Cart function
  async addToCart(product_option_id, qty, is_pre_order = 0, bundle_id) {
    try {
      let data;
      if (bundle_id) {
        data = await apiService
          .authenticated()
          .post(
            `${apiEndPoints.product.addToCart}?guest_id=${
              apiService.accessToken === 0 ? this.guestId : 0
            }`,
            {
              product_option_id,
              qty,
              guest_id: apiService.accessToken === 0 ? this.guestId : 0,
              is_pre_order,
              bundle_id,
            }
          )
          .then(({ data }) => data);
      } else {
        data = await apiService
          .authenticated()
          .post(
            `${apiEndPoints.product.addToCart}?guest_id=${
              apiService.accessToken === 0 ? this.guestId : 0
            }`,
            {
              product_option_id,
              qty,
              guest_id: apiService.accessToken === 0 ? this.guestId : 0,
              is_pre_order,
            }
          )
          .then(({ data }) => data);
      }
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Products Customers Viewed function
  async getProductsCustomersViewed(product_id) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.product.getProductsCustomersViewed}?product_id=${product_id}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Product Related function
  async getProductRelated(machine_name) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.product.getProductRelated}?machine_name=${machine_name}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Product Sold With function
  async getProductSoldWith(machine_name) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.product.getProductSoldWith}?machine_name=${machine_name}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Product Reviews function
  async getProductReviews(machine_name, page) {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${
            apiEndPoints.product.getSingleProductReviews
          }?machine_name=${machine_name}&page=${page}&guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // add Product Review function
  async addProductReview(reviewData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.product.addProductReview, {
          ...reviewData,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // submit Notify Me function
  async submitNotifyMe(product_option_id, email) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.product.submitNotifyMe, {
          product_option_id,
          email,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const productService = new ProductService();
