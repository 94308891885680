import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService"; // api service class that contains general services ( functions and variables )

class CategoryService {
  // guest Id function
  get guestId() {
    return localStorage.getItem("guestId");
  }

  // get Single CategoryProducts function
  async getSingleCategoryProducts(categoryData) {
    try {
      const data = await apiService
        .authenticated()
        .post(
          apiEndPoints.category.getSingleCategoryProducts +
            "?page=" +
            categoryData?.page,
          {
            guest_id: apiService.accessToken === 0 ? this.guestId : 0,
            ...categoryData,
          }
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Single Category Info And Filters function
  async getSingleCategoryInfoAndFilters(machine_name) {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${
            apiEndPoints.category.getSingleCategoryInfoAndFilters
          }?machine_name=${machine_name}&?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const categoryService = new CategoryService();
