import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Filter from "./Filter";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { connect } from "react-redux";

class FilterModal extends Component {
  state = {
    displayFilterModal: false,
  };

  render() {
    const {
      language,
      i18n: { filterItems },
    } = this.props;

    return (
      <Modal
        onHide={this.props.closeModal}
        show={this.props.filterModal}
        className={language === "rtl" && "modal-arabic"}
      >
        <Modal.Header closeButton onClick={this.props.closeModal}>
          <Modal.Title>{filterItems}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Filter //Filter component
            pathName={this.props.pathName}
            data={this.props.data}
            getFilterData={this.props.getFilterData}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});

export default connect(mapStateToProps)(FilterModal);
