import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";
import { generalServices } from "../../../services/GeneralService";
import PageTitle from "../../blocks/PageTitle";
import GiftCardTable from "./GiftCardTable";
import ReactToPrint from "react-to-print";
import Pdf from "react-to-pdf";
export class GiftCardsDetails extends Component {
  state = {
    loaderPage: true,
    giftCard: {},
  };

  componentDidMount() {
    this.getGiftCardsOrderDetails();
  }

  getGiftCardsOrderDetails = async () => {
    // function to get Gift Cards Order Details and set it to the current state
    const { success, data } = await generalServices.getGiftCardsOrderDetails(
      this.props.match.params.id
    );
    if (!success) return;

    this.setState({
      giftCard: data,
      loaderPage: false,
    });
  };

  componentRef = React.createRef();

  render() {
    const { loaderPage, giftCard } = this.state;
    const {
      i18n: {
        ThankYou,
        PaymentMethod,
        GIFTCARDS,
        Download,
        Print,
        PaymentStatus,
        successPayment,
        Recipient,
        ThankYouOrderYourBeenActivated,
        Home,
      },
    } = this.props;

    const options = {
      format: "a1",
    };

    return (
      <div className="gift-cards-details" ref={this.componentRef}>
        {/* Title */}
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={ThankYou}
          links={[
            { name: Home, url: "/" },
            { name: GIFTCARDS, url: "/GiftCardPage" },
          ]}
        />
        {/* Title end */}

        <Container>

          {/* thank you block start  */}
          <div className="thank-you-block flex mb-5">
            <h3>{ThankYou}!</h3>
            <p>{ThankYouOrderYourBeenActivated}</p>
          </div>
          {/* thank you block end  */}

          {/* gift cards table start  */}
          {loaderPage ? (
            <>
              <Skeleton height={200} className="mb-3" />
            </>
          ) : (
            <GiftCardTable data={giftCard} />
          )}
          {/* gift cards table end  */}

          {loaderPage ? (
            <>
              <Skeleton height={200} className="mb-3" />
            </>
          ) : (
            <Row>
              <Col sm={3}>
                <div className="gift-card-label-value">
                  <p>{Recipient}</p>
                  <span>{giftCard?.receiver_email}</span>
                </div>
              </Col>
              <Col sm={9}>
                <div className="gift-card-label-value">
                  <p>{PaymentMethod}</p>
                  <span>{giftCard?.payment_method?.name}</span>
                </div>
                <div className="gift-card-label-value">
                  <p>{PaymentStatus}</p>
                  <span>{successPayment}</span>
                </div>
              </Col>
            </Row>
          )}

          <div className="gift-card__buttons flex my-5">

            {/* download button start  */}
            <Pdf
              targetRef={this.componentRef}
              filename="div-blue.pdf"
              x={0.5}
              scale={1}
              options={options}
            >
              {({ toPdf }) => (
                <button className="submit-button2" onClick={toPdf}>
                  {Download}
                </button>
              )}
            </Pdf>
            {/* download button end  */}

            {/* print button start  */}
            <ReactToPrint
              trigger={() => <button className="submit-button">{Print}</button>}
              content={() => this.componentRef.current}
            />
            {/* print button end  */}
            
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setUser })(GiftCardsDetails);
