import React, { Component } from "react";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";
import { Row, Col } from "react-bootstrap";
import { productService } from "../../../services/ProductService";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { generalServices } from "../../../services/GeneralService";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

class CartProductBlock extends Component {
  state = {
    qty: this.props.data?.qty,
    qtySelect: 1,
    qtyLoader: true,
  };

  render() {
    const {
      language,
      data,
      i18n: { materials, Est, days, preOrder },
      reFetchData,
    } = this.props;

    // qty Decrement function start
    const qtyDecrement = () => {
      if (data?.is_pre_order) {
        if (+data?.qty > 1) {
          this.setState(
            {
              qty: +data?.qty - 1,
            },
            () => {
              this.addToCart(
                data?.product_option?.id,
                +this.state.qty,
                reFetchData
              );
            }
          );
        }
      } else {
        if (+data?.qty > data?.product_option?.min_order) {
          this.setState(
            {
              qty: +data?.qty - 1,
            },
            () => {
              this.addToCart(
                data?.product_option?.id,
                +this.state.qty,
                reFetchData
              );
            }
          );
        }
      }
    };
    // qty Decrement function end

    // qty Increment function start
    const qtyIncrement = () => {
      if (data?.is_pre_order) {
        if (+data?.qty < data?.product_option?.preOrderQTY) {
          this.setState(
            {
              qty: +data?.qty + 1,
            },
            () => {
              this.addToCart(
                data?.product_option?.id,
                +this.state.qty,
                reFetchData
              );
            }
          );
        }
      } else {
        if (+data?.qty < data?.product_option?.qty) {
          this.setState(
            {
              qty: +data?.qty + 1,
            },
            () => {
              this.addToCart(
                data?.product_option?.id,
                +this.state.qty,
                reFetchData
              );
            }
          );
        } else {
        }
      }
    };

    return (
      <div
        className={`cart-product  ${
          language === "rtl" && "cart-product-arabic"
        }`}
      >
        <Row className="CartRow">
          <Col lg={3} xl={3} md={3} xs={12} className="cart-product__image">
            <Link
              to={
                data?.product?.bundle
                  ? `/ProductDetailsBundle/${data?.product?.machine_name}`
                  : `/productDetails/${data?.product?.machine_name}`
              }
            >
              <img
                src={
                  data.product?.main_image
                    ? apiService.imageLink + data.product?.main_image
                    : "/images/placeholder.png"
                }
                alt={data?.product.machine_name}
                className="cart-product__image__img mw-100"
              />
            </Link>
          </Col>

          <Col lg={8} xl={8} md={8} xs={12} className="info-container">
            <div className="cart-product__info">
              <a
                href={
                  data?.product?.bundle
                    ? `/ProductDetailsBundle/${data?.product?.machine_name}`
                    : `/productDetails/${data?.product?.machine_name}`
                }
                className="cart-product__info__link"
              >
                <h5 className="mt-0 cart-product__info__title">
                  {data.product?.title}
                </h5>

                {Boolean(data.is_pre_order) && (
                  <span className="cart-product__info__link__preOrder">
                    {preOrder}
                    {data.product_option.preparing_days > 0 &&
                      `(${Est}: ${data.product_option.preparing_days} ${days})`}
                  </span>
                )}
              </a>

              <div className="flex cart-product__material-sku">
                {/* {data.product_option?.materials?.length > 0 && (
                  <span className="cart-product__info__options">
                    {materials}: {data.product_option?.materials.join(", ")}
                  </span>
                )} */}
                {data.product_option?.serial_number && (
                  <span className="cart-product__info__options">
                    SKU: {data.product_option?.serial_number}
                  </span>
                )}
              </div>

              {!data?.product?.bundle && (
                <p className="cart-product__info__options">
                  {data.product_option?.values_names.join(", ")}
                </p>
              )}

              {!data.bundle_id && (
                <div className="cart-product__info__price">
                  <span>
                    {this.props.currency.currencyName}
                    {(
                      data?.product_option.price_after_discount *
                      this.props.currency.currencyFactor
                    ).toFixed(2)}
                  </span>
                </div>
              )}

              {this.props.loader ? (
                <div>
                  <Skeleton height={48} className="mt-4" width={158} />
                </div>
              ) : (
                <div className="cart-product__info__quantity mt-4">
                  {!data.bundle_id && (
                    <div>
                      <div className="quantity-input">
                        <button
                          className="quantity-input__modifier quantity-input__modifier--left"
                          onClick={() => qtyDecrement()}
                        >
                          &mdash;
                        </button>

                        <input
                          className="quantity-input__screen"
                          type="text"
                          value={
                            +data?.qty <= 9 ? "0" + data?.qty : "" + data?.qty
                          }
                          disabled
                        />

                        <button
                          className="quantity-input__modifier quantity-input__modifier--right"
                          onClick={() => qtyIncrement()}
                        >
                          &#xff0b;
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
          {!data.bundle_id && (
            <div
              className="removeFromCartBtn"
              onClick={() => {
                this.addToCart(data?.product_option?.id, 0, reFetchData);
              }}
            >
              <img src="/images/removeFromCartX.png" />
            </div>
          )}
        </Row>
      </div>
    );
  }

  getIconData = async () => {
    // function to get Fav Cart compare Counters from the server and set it to user redux store data
    const { data, success } = await generalServices.getIconData();
    if (!success) return;
    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
      compare: data.compare_counter,
    });
  };

  getCartIDs = async () => {
    // function to get user cart from the server and set it to user redux store data
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };

  addToCart = async (id, qty, reFetchData) => {
    // add to cart
    const { data, success } = await productService.addToCart(
      id,
      qty,
      this.props.data.is_pre_order
    );
    if (!success) return;

    await reFetchData();
    await this.setState({
      // qtySelect: qty,
      qty,
    });
    this.getCartIDs();
    this.getIconData();
  };
  // addToCart end
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
  currency,
});
export default connect(mapStateToProps, { setUser })(CartProductBlock);
