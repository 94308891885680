import React, { Component } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { setUser } from "../../../redux/actions-reducers/user";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import SummaryProduct from "../../blocks/SummaryProduct";
import { ErrorMessage } from "../../blocks/ErrorMessage";
import { cartService } from "../../../services/CartService";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { userService } from "../../../services/UserService";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import { subscriptionsService } from "../../../services/SubscriptionsService";
import SummaryPackage from "../Subscriptions/SummaryPackage";
import { generalServices } from "../../../services/GeneralService";
import Swal from "sweetalert2";
import test from "./test.json";

export class CheckoutSummaryBlock extends Component {
  state = {
    fields: {
      promocode: "",
    },
    Coupon: [],
    CouponFields: {},
    couponError: false,
    couponErrorText: "",
    couponInputIsEmpty: true,
  };

  render() {
    const {
      i18n: {
        CouponText,
        CouponCode,
        Delete,
        error,
        feesAmount,
        DiscountAmount,
        CouponsSlashGiftCards,
        PlaceOrder,
        MustSelectLocation,
        taxesAmount,
        AddPromo,
        Apply,
        cartSummary,
        palcesLevel,
        SubTotal,
        deliveryAmount,
        deliveryAmountKW,
        deliveryAmountKWRemote,
        deliveryAmountKWFast,
        TotalPrice,
        Area,
        Subscribe,
        continueToCheckOut,
        ReturnToPackages,
        ReturnToServay,
        ContinueToPayment,
        ReturnToCheckOut,
        ReturnToCart,
      },
      cartAmount,
      selectPayment,
      withPromocode,
      cart,
      FirstBlock,
      WithAmountDetails = true,
      inSubscriptions = false,
      subscriptionsControlObj,
      currentStep,
      language,
      fromPayment,
      packageInfo,
      selectedMonths,
    } = this.props;

    const {
      couponErrorText,
      couponError,
      couponInputIsEmpty,
      Coupon,
      CouponFields,
    } = this.state;

    return (
      <div className="cart-summary">
        {/* when loading show a pop up that is unclosable */}

        {(FirstBlock === "promocode" || withPromocode) && (
          <section className="cart-summary__section">
            {/* Add Promo title start */}
            <header className="cart-summary__section__header">
              <h5>{AddPromo}</h5>
            </header>
            {/* Add Promo title end */}

            <div className="cart-summary__section__body">
              <div className="mb-3">
                {/* Code input Start */}
                <div>
                  <InputGroup className="add-promo-input-group">
                    <FormControl
                      name={`couponCode-${Coupon.length}`}
                      value={CouponFields[`couponCode-${Coupon.length - 1}`]}
                      placeholder={CouponCode}
                      aria-label={CouponCode}
                      aria-describedby="basic-addon2"
                      onChange={({ target: { name, value } }) =>
                        this.onCouponFieldChange(name, value)
                      }
                    />
                    <Button
                      disabled={
                        !selectPayment?.hasOwnProperty("id") ||
                        couponInputIsEmpty
                      }
                      variant="outline-secondary"
                      id="button-addon2"
                      className={` submit-promocode ${
                        language === "ar" && "buttAr"
                      }`}
                      onClick={this.checkCouponIfValid}
                    >
                      {Apply}
                    </Button>
                  </InputGroup>
                </div>

                {couponError && (
                  <ErrorMessage message={couponErrorText} isSelect />
                )}
                {/* Code input End */}

                {/* valid coupons start  */}
                <ul className="valid-coupon-list mt-4">
                  {Coupon.map((item, index) => {
                    return (
                      <li data-index={index} key={index} className="mb-1">
                        <span>
                          {CouponText} : {item}
                        </span>
                        <span onClick={() => this.deleteValidCoupon(item)}>
                          {Delete}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                {/* valid coupons end  */}
              </div>
            </div>
          </section>
        )}
        <section className="cart-summary__section">
          {/* summary title start  */}
          <header className="cart-summary__section__header">
            <h5>{cartSummary}</h5>
          </header>
          {/* summary title end  */}

          <div className="cart-summary__section__body p-0">
            {/* Checkout cart products start */}
            {FirstBlock === "products" &&
              cart.map((item) => {
                return (
                  <div className="cart-summary__section__body__summary-field flex-col">
                    <div className="product-container w-100">
                      <SummaryProduct
                        product={item}
                        product_option={item.product_option.values_names}
                        invoice={false}
                        is_subsecribtion={false}
                      />
                    </div>
                  </div>
                );
              })}

            {/* Checkout cart products end */}

            {/* package info start  */}
            {FirstBlock === "package" && (
              <div className="cart-summary__section__body__summary-field flex-col">
                <div className="product-container w-100">
                  <SummaryPackage
                    packageInfo={packageInfo}
                    invoice={false}
                    is_subsecribtion={true}
                    selectedMonths={selectedMonths}
                  />
                </div>
              </div>
            )}
            {/* package info end  */}

            {/* Check out amount summary start */}
            {WithAmountDetails ? (
              <div
                className="d-flex justify-content-between flex-column"
                style={{ height: "100%" }}
              >
                <div>
                  {/* SubTotal start  */}
                  <div className="cart-summary__section__body__summary-field">
                    <div className="summary-field__title">{SubTotal}</div>
                    <div className="summary-field__value">
                      <span>{this.props.currency.currencyName}</span>{" "}
                      {cartAmount?.items_amount &&
                        (
                          cartAmount?.items_amount *
                          this.props.currency.currencyFactor
                        ).toFixed(2)}
                    </div>
                  </div>
                  {/* SubTotal end  */}

                  {/* delivery_amount start  */}
                  {cartAmount?.delivery_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field">
                      <div className="summary-field__title">
                        {this.props.currentUser?.selectedLocation?.place?.id ===
                          1 ||
                        this.props.currentUser?.selectedLocation?.place
                          ?.parent_id === 1 ? (
                          <>
                            {this.props.deliveryTypeName
                              ?.toLowerCase()
                              .includes("remote") && deliveryAmountKWRemote}
                            {this.props.deliveryTypeName
                              ?.toLowerCase()
                              .includes("fast") && deliveryAmountKWFast}
                            {!this.props.deliveryTypeName
                              ?.toLowerCase()
                              .includes("remote") &&
                              !this.props.deliveryTypeName
                                ?.toLowerCase()
                                .includes("fast") &&
                              deliveryAmount}
                          </>
                        ) : (
                          deliveryAmount
                        )}
                      </div>
                      <div className="summary-field__value">
                        <span>{this.props.currency.currencyName}</span>{" "}
                        {(
                          cartAmount?.delivery_amount *
                          this.props.currency.currencyFactor
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {/* delivery_amount end  */}

                  {/* fees_amount start  */}
                  {cartAmount?.fees_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field">
                      <div className="summary-field__title">{feesAmount}</div>
                      <div className="summary-field__value">
                        <span>{this.props.currency.currencyName}</span>
                        {"  "}{" "}
                        {(
                          cartAmount?.fees_amount *
                          this.props.currency.currencyFactor
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {/* fees_amount end  */}

                  {/* taxes_amount start  */}
                  {cartAmount?.taxes_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field">
                      <div className="summary-field__title">{taxesAmount}</div>
                      <div className="summary-field__value">
                        <span>{this.props.currency.currencyName}</span>
                        {"  "}{" "}
                        {(
                          cartAmount?.taxes_amount *
                          this.props.currency.currencyFactor
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {/* taxes_amount end  */}

                  {/* taxes_amount start  */}
                  {cartAmount?.discount_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field">
                      <div className="summary-field__title">
                        {DiscountAmount}
                        <p
                          style={{
                            fontSize: "11px",
                          }}
                        >
                          ({CouponsSlashGiftCards})
                        </p>
                      </div>
                      <div className="summary-field__value">
                        <span>{this.props.currency.currencyName}</span>
                        {"  "}{" "}
                        {(
                          cartAmount?.discount_amount *
                          this.props.currency.currencyFactor
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}
                  {/* taxes_amount end  */}

                  {/* total_amount start  */}
                  <div className="cart-summary__section__body__summary-field total-field">
                    <div className="summary-field__title">{TotalPrice}</div>
                    <div className="summary-field__value">
                      <span>{this.props.currency.currencyName}</span>{" "}
                      {cartAmount?.total_amount &&
                        (
                          cartAmount?.total_amount *
                          this.props.currency.currencyFactor
                        ).toFixed(2)}
                    </div>
                  </div>
                </div>
                {/* total_amount end  */}

                {/* Check Out control buttons start */}
                <div
                  className={`cart-summary__section__footer ${
                    !WithAmountDetails && "m-0"
                  }`}
                >
                  <div className="CheckoutButt">
                    {/* if we are in cart page (summary buttons) */}
                    {!inSubscriptions ? (
                      //  if we are in payment start
                      fromPayment ? (
                        <>
                          <a
                            role="button"
                            onClick={() => {
                              if (!this.props.loading) {
                                if (this.props.handlePaymentSubmit) {
                                  this.props.handlePaymentSubmit(() => {
                                    this.checkoutSubmit();
                                  });
                                } else {
                                  this.checkoutSubmit();
                                }
                              }
                            }}
                          >
                            {PlaceOrder}
                          </a>

                          <a href="/Checkout">{ReturnToCheckOut}</a>
                        </>
                      ) : (
                        //  if we are not in  payment start

                        <>
                          <a
                            onClick={async () => {
                              if (this.props.handleAddLocation) {
                                this.props.handleAddLocation();
                              } else {
                                if (!this.props.loading) {
                                  if (!this.props.selectedLocation) {
                                    displayAlert(
                                      error,
                                      MustSelectLocation,
                                      "error"
                                    );
                                  } else {
                                    const { data, success } =
                                      await generalServices.getAllCountries(
                                        this.props.selectedLocation?.place?.id
                                      );
                                    if (data?.length > 0)
                                      displayAlert(
                                        error,
                                        palcesLevel + " ( " + Area + " ) ",
                                        "error"
                                      );
                                    else {
                                      window.location.href = "/checkoutpayment";
                                    }
                                  }
                                }
                              }
                              if (this.props.email) {
                                if (!this.props.loading) {
                                  if (!this.props.selectedLocation) {
                                    displayAlert(
                                      error,
                                      MustSelectLocation,
                                      "error"
                                    );
                                  } else {
                                    const { data, success } =
                                      await generalServices.getAllCountries(
                                        this.props.selectedLocation?.place?.id
                                      );
                                    if (data?.length > 0)
                                      this.props.setModal(true);
                                    else {
                                      window.location.href = "/checkoutpayment";
                                    }
                                  }
                                }
                              }
                            }}
                          >
                            {ContinueToPayment}
                          </a>

                          <a href="/Cart">{ReturnToCart}</a>
                        </>
                      )
                    ) : (
                      //if we are in cart page (summary buttons)  end

                      //if we are in subscription page (summary buttons)  start
                      <>
                        {!this.props.subscriptions && currentStep !== 4 && (
                          <a
                            onClick={() => {
                              if (currentStep === 4) {
                                // this.props.history.goback();
                              } else {
                                subscriptionsControlObj.prevStep();
                              }
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {currentStep === 3 && ReturnToServay}
                            {currentStep === 2 && ReturnToPackages}
                            {currentStep === 4 && ReturnToCheckOut}
                          </a>
                        )}

                        <a
                          onClick={() => {
                            if (currentStep === 4) {
                              this.subscriptionCheckoutSubmit();
                            } else if (currentStep === 2) {
                              this.validateSubscriptionFields();
                            } else if (currentStep === 3) {
                              if (this.props.handleAddLocation) {
                                this.props.handleAddLocation();
                              } else {
                                if (!this.props.selectedLocation) {
                                  displayAlert(
                                    error,
                                    MustSelectLocation,
                                    "error"
                                  );
                                }
                              }
                              this.props.selectedLocation &&
                                subscriptionsControlObj.nextStep();
                            } else {
                            }
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {currentStep === 3 && ContinueToPayment}
                          {currentStep === 2 && continueToCheckOut}
                          {currentStep === 4 && Subscribe}
                        </a>
                      </>
                      //if we are in subscription page (summary buttons) end
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="cart-summary__section__body__summary-field">
                <div className="summary-field__title">
                  <b>{SubTotal}</b>
                </div>
                <div className="summary-field__value">
                  <span style={{ fontWeight: "bold" }}>
                    {this.props.currency.currencyName} {"  "}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {cartAmount?.items_amount}
                  </span>
                </div>
              </div>
            )}
            {/* Check out amount summary end */}
          </div>
        </section>
      </div>
    );
  }

  createLoader = () => {
    Swal.fire({
      title: this.props.i18n.pleaseWait,
      html: this.props.i18n.pleaseWaitWhileWeProcessYourRequest,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
    });
  };

  onCouponFieldChange = (name, value) => {
    // Field Change handler
    if (value === "") return this.setState({ couponInputIsEmpty: true });
    this.setState({
      couponError: false,
      couponInputIsEmpty: false,
      CouponFields: {
        [name]: value,
      },
    });
  };

  deleteValidCoupon = (coupon) => {
    let newCoupon = this.state.Coupon.filter((item) => item !== coupon);
    this.setState(
      {
        Coupon: newCoupon,
      },
      () => {
        localStorage.removeItem("beam_coupon");
        this.getCheckoutAmounts();
      }
    );
  };

  checkCouponIfValid = async () => {
    // Check if coupon is valid
    const {
      selectedPayment,
      Coupon,
      CouponFields,
      selectedLocation,
      guestLocation,
      delivery_company_id,
    } = this.state;
    let couponReadyToSend = [];
    for (const item in CouponFields) {
      couponReadyToSend.push(CouponFields[item]);
    }

    const { data } = await cartService.checkCouponIfValid({
      guest_id: apiService.accessToken === 0 ? this.guestId : 0,
      payment_id: selectedPayment ?? "",
      delivery_company_id: delivery_company_id,
      place_id: selectedLocation?.place?.id,
      coupon: CouponFields[`couponCode-${Coupon.length}`],
    });

    if (!data)
      return this.setState({
        couponError: true,
        couponErrorText: "Invalid Coupon Code",
      });

    if (Coupon.includes(CouponFields[`couponCode-${Coupon.length}`]))
      return this.setState({
        couponError: true,
        couponErrorText: "Coupon Used",
        CouponFields: { [`couponCode-${Coupon.length}`]: "" },
      });

    this.setState(
      {
        CouponFields: { [`couponCode-${Coupon.length}`]: "" },
        couponInputIsEmpty: true,
        Coupon: [...Coupon, ...couponReadyToSend],
      },
      () => {
        this.props.checkOutAmount([...Coupon, ...couponReadyToSend]);
      }
    );
  };
  getPlaceFields = async (placeId) => {
    // get Place Fields and return its ids as array
    const { data, success } = await generalServices.getPlaceFields(placeId, 0);
    if (!success) return;

    return data.map(({ id }) => id);
  };

  // cart checkout Submit start
  checkoutSubmit = async () => {
    // checkout Submit
    const { fields, Coupon, guestLocation } = this.state;

    const { currentUser, selectPayment, withPrice, delivery_company_id } =
      this.props;

    const {
      success: successText,
      OrderCheckoutSuccessfully,
      error: errorTag,
      SelectAllFields,
      SelectCity,
      thisFieldIsRequired,
      City,
      cancel,
      submit,
    } = this.props.i18n;

    let dataSendToApi = {};
    if (apiService.accessToken === 0) {
      if (
        currentUser?.selectedLocation?.country &&
        Number(currentUser?.selectedLocation?.country) > 0
      ) {
        const ids = await this.getPlaceFields(
          currentUser?.selectedLocation?.country
        );
        dataSendToApi = {
          preferred_delivery_date: "",
          payment_method_id: selectPayment?.id,
          preferred_time_slot: "",
          coupons: [localStorage.getItem("beam_coupon")],
          notes: "",
          user_location_id: 0,
          delivery_company_id: delivery_company_id,
          hide_prices: !withPrice,
          guest_details: {
            ...currentUser?.selectedLocation,
            place_id: currentUser?.selectedLocation?.place_id,
            person_name: currentUser?.selectedLocation?.nickname,
            latitude: currentUser?.selectedLocation?.latitude,
            longitude: currentUser?.selectedLocation?.longitude,
            building: currentUser?.selectedLocation?.building,
            floor: currentUser?.selectedLocation?.floor,
            appartment: currentUser?.selectedLocation?.appartment,
            address: currentUser?.selectedLocation?.address,
            phone: currentUser?.selectedLocation?.phone,
            email: currentUser?.selectedLocation?.email,
            extra_data: currentUser?.selectedLocation?.extra_data?.filter(
              ({ place_field_id }) => ids.includes(Number(place_field_id))
            ),
          },
        };
      } else {
        dataSendToApi = {
          preferred_delivery_date: "",
          payment_method_id: selectPayment?.id,
          preferred_time_slot: "",
          coupons: [localStorage.getItem("beam_coupon")],
          notes: "",
          user_location_id: 0,
          delivery_company_id: delivery_company_id,
          hide_prices: !withPrice,
          guest_details: {
            ...currentUser?.selectedLocation,
            place_id: currentUser?.selectedLocation?.place_id,
            person_name: currentUser?.selectedLocation?.nickname,
            latitude: currentUser?.selectedLocation?.latitude,
            longitude: currentUser?.selectedLocation?.longitude,
            building: currentUser?.selectedLocation?.building,
            floor: currentUser?.selectedLocation?.floor,
            appartment: currentUser?.selectedLocation?.appartment,
            address: currentUser?.selectedLocation?.address,
            phone: currentUser?.selectedLocation?.phone,
            email: currentUser?.selectedLocation?.email,
            extra_data: currentUser?.selectedLocation?.extra_data,
          },
        };
      }
    } else {
      const { data: user, success: userSS } =
        await userService.getUserInformation();
      if (userSS) {
        this.props.setUser({ ...user });
      }

      dataSendToApi = {
        preferred_delivery_date: "",
        payment_method_id: selectPayment?.id,

        preferred_time_slot: "",
        coupons: [localStorage.getItem("beam_coupon")],
        notes: "",
        user_location_id: currentUser?.selectedLocation?.id,
        delivery_company_id: delivery_company_id,
        guest_details: {},
        hide_prices: !withPrice,
      };
    }

    const { data: placeData } = await generalServices.getPlaceDetails(
      currentUser.selectedLocation.place?.id
        ? currentUser.selectedLocation.place?.parent_id ||
            currentUser.selectedLocation.place?.id
        : currentUser.selectedLocation.country
    );
    const cities = test
      .filter(
        (item) => item[0]?.toLowerCase() === placeData?.code?.toLowerCase()
      )
      ?.map((item) => item && item[4].toLowerCase());
    if (cities?.length > 0 && placeData?.id !== 1) {
      Swal.fire({
        title: SelectCity,
        input: "select",
        inputOptions: cities,
        inputPlaceholder: City,
        showCancelButton: true,
        cancelButtonText: cancel,
        confirmButtonText: submit,
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value !== "") {
              resolve();
            } else {
              resolve(thisFieldIsRequired);
            }
          });
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (
            (currentUser?.selectedLocation?.nickname ||
              guestLocation?.place_id) &&
            2
          ) {
            const selectedCity = test.filter(
              (item) =>
                item[4]?.toLowerCase() === cities[Number(result.value)] &&
                item[0]?.toLowerCase() === placeData?.code?.toLowerCase()
            );
            this.createLoader();
            let { success, message, data } = await userService.checkoutSubmit({
              ...dataSendToApi,
              city_name: selectedCity[0][4],
              dhl_plt_available:
                selectedCity[0][9].toLowerCase() === "y" ? 1 : 0,
            });

            // if the above api call fails, we need to try up to 3 times
            if (!success) {
              let count = 0;
              while (!success && count < 2) {
                const trial = await userService.checkoutSubmit({
                  ...dataSendToApi,
                  city_name: selectedCity[0][4],
                  dhl_plt_available:
                    selectedCity[0][9].toLowerCase() === "y" ? 1 : 0,
                });
                success = trial.success || false;
                message =
                  trial.message || "Something went wrong, please try again";
                data = trial.data || {};
                if (trial.success) {
                  break;
                }
                count++;
              }
            }

            if (!success) {
              // return displayAlert(errorTag, message, "error").then((ok) => {
              //   if (ok) {
              //     window.location.href = "/cart";
              //   }
              // });
              return displayAlert(
                errorTag,
                this.props.i18n.pleaseCheckYourConnectionAndTryAgain,
                "error"
              );
            }

            if (data.has_payment_link) {
              window.location.href = apiService.webURL + data.payment_link;
            } else {
              displayAlert(
                successText,
                OrderCheckoutSuccessfully,
                "success"
              ).then((ok) => {
                if (ok) {
                  window.location.href =
                    "/orderinvoice/" + data.order_reference;
                }
              });
            }
          } else {
            displayAlert(errorTag, SelectAllFields, "error");
          }
        }
      });
    } else {
      if (
        (currentUser?.selectedLocation?.nickname || guestLocation?.place_id) &&
        2
      ) {
        this.createLoader();
        let { success, message, data } = await userService.checkoutSubmit({
          ...dataSendToApi,
          city_name: "",
          dhl_plt_available: 0,
        });

        // if the above api call fails, we need to try up to 3 times
        if (!success) {
          let count = 0;
          while (!success && count < 2) {
            const trial = await userService.checkoutSubmit({
              ...dataSendToApi,
              city_name: "",
              dhl_plt_available: 0,
            });
            success = trial.success || false;
            message = trial.message || "Something went wrong, please try again";
            data = trial.data || {};
            if (trial.success) {
              break;
            }
            count++;
          }
        }

        if (!success) {
          // return displayAlert(errorTag, message, "error").then((ok) => {
          //   if (ok) {
          //     window.location.href = "/cart";
          //   }
          // });
          return displayAlert(
            errorTag,
            this.props.i18n.pleaseCheckYourConnectionAndTryAgain,
            "error"
          );
        }

        if (data.has_payment_link) {
          window.location.href = apiService.webURL + data.payment_link;
        } else {
          displayAlert(successText, OrderCheckoutSuccessfully, "success").then(
            (ok) => {
              if (ok) {
                window.location.href = "/orderinvoice/" + data.order_reference;
              }
            }
          );
        }
      } else {
        displayAlert(errorTag, SelectAllFields, "error");
      }
    }
  };

  // cart checkout Submit end

  // subscription Checkout Submit start
  subscriptionCheckoutSubmit = async () => {
    // subscription Checkout Submit
    const { guestLocation } = this.state;

    const {
      currentUser,
      selectPayment,
      withPrice,
      months,
      extraData,
      PackageSelected,
    } = this.props;

    const {
      success: successText,
      subscriptionSuccessfully,
      error: errorTag,
      SelectAllFields,
    } = this.props.i18n;

    let dataSendToApi = {};
    if (apiService.accessToken === 0) {
      dataSendToApi = {
        payment_method_id: selectPayment?.id,
        notes: "",
        user_location_id: 0,
        delivery_company_id: "",
        hide_prices: !withPrice,
        months: months ?? [],
        fields: extraData,
        subscription_id: PackageSelected,

        guest_details: {
          ...currentUser?.selectedLocation,
          place_id: currentUser?.selectedLocation?.place_id,
          person_name: currentUser?.selectedLocation?.nickname,
          latitude: currentUser?.selectedLocation?.latitude,
          longitude: currentUser?.selectedLocation?.longitude,
          building: currentUser?.selectedLocation?.building,
          floor: currentUser?.selectedLocation?.floor,
          appartment: currentUser?.selectedLocation?.appartment,
          address: currentUser?.selectedLocation?.address,
          phone: currentUser?.selectedLocation?.phone,
          email: currentUser?.selectedLocation?.email,
          extra_data: currentUser?.selectedLocation?.extra_data,
        },
      };
    } else {
      dataSendToApi = {
        payment_method_id: selectPayment?.id,
        notes: "",
        user_location_id: currentUser?.selectedLocation?.id,
        delivery_company_id: "",
        guest_details: {},
        hide_prices: !withPrice,
        months: months ?? [],
        fields: extraData,
        subscription_id: PackageSelected,
      };
    }

    if (
      (currentUser?.selectedLocation?.nickname || guestLocation?.place_id) &&
      2
    ) {
      const { success, message, data } =
        await subscriptionsService.subscriptionCheckoutSubmit(dataSendToApi);

      if (!success)
        return displayAlert(errorTag, message, "error").then((ok) => {
          if (ok) {
            window.location.href = "/cart";
          }
        });

      if (data.has_payment_link) {
        window.location.href = apiService.webURL + data.payment_link;
      } else {
        displayAlert(successText, subscriptionSuccessfully, "success").then(
          (ok) => {
            if (ok)
              window.location.href =
                "/subscriptionInvoice/" + data.order_reference;
          }
        );
      }
    } else {
      displayAlert(errorTag, SelectAllFields, "error");
    }
  };
  // subscription Checkout Submit end

  // validate Subscription Fields start
  validateSubscriptionFields = async () => {
    // validate Subscription Fields
    const {
      extraData,
      subscriptionsControlObj,
      i18n: { error: errorTag },
    } = this.props;

    const { success, errors, message } =
      await subscriptionsService.validateSubscriptionFields({
        subscription_id: this.props.packageInfo.id,
        fields: extraData,
      });
    if (!success) {
      if (typeof errors === "object") {
        this.props.putErrors(getResponseErrors(errors));
      }
      return displayAlert(
        errorTag,
        `${
          typeof errors === "object"
            ? errors[Object.keys(errors)[0]][0]
            : message
        }`,
        "error"
      );
    }

    subscriptionsControlObj.nextStep();
  };
  // validate Subscription Fields start
  // close modal start
  closeModal = () => {
    this.setState({ addAddress: false });
  };

  // close modal end
}

// export default CheckoutSummaryBlock;

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.lang,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
  currency,
});

export default connect(mapStateToProps, { setUser })(CheckoutSummaryBlock);
