import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

const Topbar = (props) => {
  const { settings } = props;
  const { top_message } = settings;
  const languageCode = useSelector((state) => state.language.langCode);
  const i18n = languageCode === "rtl" ? Global.ar : Global.en;
  const [showTopbar, setShowTopbar] = useState(true);

  const closeTopbar = () => {
    setShowTopbar(false);
  };

  if (!top_message || top_message === "") return null;
  return (
    <div
      style={{
        backgroundColor: "#EFD7E5",
        height: showTopbar ? "50px" : "0",
        overflow: "hidden",
        transition: "all 0.5s ease-in-out",
      }}
      className="position-relative d-flex align-items-center justify-content-center text-black"
    >
      <div
        style={{
          // make space for close icon
          padding: "0 30px", // 26+4
          // text overflow 1 line
          WebkitLineClamp: 1,
          display: "-webkit-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
          wordBreak: "break-all",
        }}
      >
        {top_message}
      </div>

      {/* Close Icon absolute */}
      <div
        onClick={closeTopbar}
        className="position-absolute"
        style={{
          top: "0",
          left: languageCode === "rtl" ? "0" : "unset",
          right: languageCode === "rtl" ? "unset" : "0",
          padding: "10px",
          cursor: "pointer",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M13.854 1.646a.5.5 0 0 1 0 .708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854a.5.5 0 0 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </div>
    </div>
  );
};

export default Topbar;
