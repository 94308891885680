import swal from "sweetalert";

// if screen larger then 992 ( desktop size )
export const desktopScreen = 992 < window.innerWidth;

// if screen 768 then 992 and lesser then 992 ( laptop size )
export const laptopScreen = 768 < window.innerWidth && window.innerWidth < 992;

// if screen 576 then 992 and lesser then 768 ( tablet size )
export const tabletScreen = 576 < window.innerWidth && window.innerWidth < 768;

// if screen lesser then 576 ( phone size )
export const phoneScreen = window.innerWidth < 576;

// current screen Width
export const screenInnerWidth = window.innerWidth;

// status Types by number
export const statusTypes = {
  0: "Closed",
  1: "Pending",
  2: "Pickup",
  3: "In Process",
  4: "Delivered",
  5: "Completed",
  "-2": "Canceled",
};

// status Types color by number
export const statusTypesColor = {
  0: "red",
  1: "#ff8000",
  2: "#000",
  3: "#000",
  4: "Delivered",
  5: "#00FF66",
  "-2": "gray",
};

// handle Response
export const handleResponse = ({ success, ...rest }) => ({ success, ...rest });

// display Alert handle swal fire
export const displayAlert = (
  title,
  text,
  icon,
  className = "",
  timer,
  buttons
) => swal({ title, text, icon, buttons, className, timer });

// map Select Data ( name, id ) => { label, value }
export const mapSelectData = (data) =>
  Array.isArray(data)
    ? data.map(({ name: label, id: value, ...rest }) => ({
        value,
        label,
        ...rest,
      }))
    : [];

// get Response Errors errors from api is un object with keys and values and values are array that why taking first error from the errors array
export const getResponseErrors = (responseErrors) => {
  const errors = Object.entries(responseErrors).map(
    ([fieldName, fieldValue]) => [fieldName, fieldValue[0]]
  );

  return Object.fromEntries(errors);
};

// scroll to Top
export const toTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};
