import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import { cartService } from "../../../services/CartService";
import FavoriteProductBlock from "./FavoriteProductBlock";
import Skeleton from "react-loading-skeleton";
import PageTitle from "../../blocks/PageTitle";

class Favourite extends Component {
  state = {
    favorites: [],
    loaderPage: true,
  };

  componentDidMount() {
    Promise.all([this.getUserFavorites()]);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentUser?.email !== this.props.currentUser?.email) {
      Promise.all([this.getUserFavorites()]);
    }
  }

  // get User Favorites start
  getUserFavorites = async () => {
    // function to get User Favorites data
    const { data, success } = await cartService.getUserFavorites();
    if (!success) return;
    this.setState({
      favorites: data,
      loaderPage: false,
    });
  };
  // get User Favorites end

  render() {
    const { favorites, loaderPage } = this.state;
    const {
      i18n: { Wishlist, favEmpty, Home, Favourite },
    } = this.props;

    return (
      <div className="Cart">
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={Wishlist}
          links={[
            { name: Home, url: "/" },
            { name: Favourite, url: "/favourite" },
          ]}
        />

        {/* Start */}
        <Container>
          <Row>
            {/* favorites products start  */}

            {loaderPage ? (
              <>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
              </>
            ) : favorites.length === 0 ? (
              <Col md={12}>
                <div className="empty-products mb-5">
                  <img src="/img/favEmpty.svg" alt="k" />
                  <p> {favEmpty} </p>{" "}
                </div>
              </Col>
            ) : (
              favorites.map((item, index) => {
                return (
                  <Col lg={3} md={4} sm={6} className="mb-5">
                    <FavoriteProductBlock
                      data={item}
                      key={index}
                      reFetchData={() => this.getUserFavorites()}
                    />
                  </Col>
                );
              })
            )}
            {/* favorites products end  */}
          </Row>
        </Container>
        {/* End */}
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});

export default connect(mapStateToProps, { setLanguage })(Favourite);
