import React, { Component } from "react";
import { Alert, Col, Form, InputGroup, Row } from "react-bootstrap";
import TextInput from "../../../blocks/TextInput";
import SelectInput from "../../../blocks/SelectInput";
import { connect } from "react-redux";
import { setLanguage } from "../../../../redux/actions-reducers/language";
import { setUser } from "../../../../redux/actions-reducers/user";
import { locationService } from "../../../../services/LocationService";
import {
  displayAlert,
  getResponseErrors,
  mapSelectData,
} from "../../../../utils/misc";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { generalServices } from "../../../../services/GeneralService";
import UploadFile from "../../../blocks/UploadFile";
import { apiService } from "../../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import PhoneInput from "../../../blocks/PhoneInput";
import Label from "../../../blocks/Label";
var isFirstTime = true;
class EditAddLocation extends Component {
  state = {
    /* Default position */
    defaultPosition: {
      lat: 31.963158,
      lng: 35.930359,
    },
    position: {
      lat: 31.963158,
      lng: 35.930359,
    },
    fields: {
      firstName: "",
      middleName: "",
      lastName: "",
      address: "",
      building: "",
      phone: "",
      floor: "",
      appartment: "",
      IDpicture: [],
      buildingType: 1,
    },
    errors: {
      firstName: "",
      lastName: "",
      address: "",
      building: "",
      phone: "",
      floor: "",
      appartment: "",
      IDpicture: "",
    },

    userAddressesData: [],
    places: [],
    loader: true,
    areas_list: [],
    placesArray: [],
    showSelect: true,
    placesIds: [],
    parentPlace: {},
    extra_data: [],
    imageLoader: false,
  };
  componentDidMount() {
    isFirstTime = true;

    this.getPlacesFunction(0, this.state.placesArray);

    //this condition to know if is edit or not
    if (this.props.data.hasOwnProperty("nickname")) {
      this.getPlaceFields(this.props.data.main_place, this.props.data.id).then(
        () => {}
      );

      const names = this.props.data.nickname
        ? this.props.data.nickname.split(" ")
        : [];

      this.setState({
        fields: {
          ...this.state.fields,
          firstName: names[0],
          middleName: names.slice(1, names.length - 1)
            ? names.slice(1, names.length - 1).join(" ")
            : "",
          lastName: names.slice(names.length - 1, names.length)
            ? names.slice(names.length - 1, names.length).join(" ")
            : "",
          address: this.props.data.address,
          building: this.props.data.building,
          phone: this.props.data.phone,
          floor: this.props.data.floor,
          email: this.props.data.email,
          appartment: this.props.data.appartment,
          place_id: { label: "", value: this.props.data.place.id },
        },
        parentPlace: {
          ...this.state.parentPlace,
          zipcode: this.props.data.zipcode,
        },
        showSelect: false,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.handleAddLocation) {
      this.addUserLocation();
      this.props.restHandleAddLocation();
    }
  }

  render() {
    const {
      language,
      isAdd,
      fromCheckOut,
      i18n: {
        Country,
        Area,
        Email,
        Mobile,
        PlacesText,
        FirstName,
        middleName,
        LastName,
        BuildingNumber,
        change,
        FloorNumber,
        ApartmentNumber,
        specialCharactersWarren,
        ContactInfo,
        AddLocationButt,
        EditLocationButt,
        addNewAddress,
        editAddress,
        FileSelected,
        House,
        Building,
        BuildingType,
        HouseNumber,
      },
    } = this.props;
    const {
      fields,
      errors,
      placesArray,
      showSelect,
      placesIds,
      placeFields,
      parentPlace,
    } = this.state;

    return (
      <div
        className={`signup-login newCheckOut ${
          language === "rtl" && "signup-login-arabic"
        }`}
      >
        {/* form title start  */}
        <header>
          <h2 className="general-info__title add-edit-title mb-3">
            {fromCheckOut ? ContactInfo : isAdd ? addNewAddress : editAddress}
          </h2>
        </header>
        {/* form title end  */}
        <div>
          <Alert variant="warning">{specialCharactersWarren}</Alert>
        </div>

        <div className="signup-login__form-container confirm-checkout-container p-0">
          <Row>
            {/* nickname input start  */}
            <Col lg={6} md={6} sm={6} className="mb-4">
              <TextInput
                name="firstName"
                maxLength={20}
                label={FirstName}
                placeholder={FirstName}
                value={fields.firstName}
                onFieldChange={this.onFieldChange}
                validate={errors.firstName || errors.first_name}
              />
            </Col>
            {/* nickname input start  */}
            <Col lg={6} md={6} sm={6} className="mb-4">
              <TextInput
                name="middleName"
                maxLength={20}
                label={middleName}
                placeholder={middleName}
                value={fields.middleName}
                onFieldChange={this.onFieldChange}
                validate={errors.middleName || errors.middle_name}
              />
            </Col>
            <Col lg={6} md={6} sm={6} className="mb-4">
              <TextInput
                name="lastName"
                maxLength={20}
                label={LastName}
                placeholder={LastName}
                value={fields.lastName}
                onFieldChange={this.onFieldChange}
                validate={errors.lastName || errors.last_name}
              />
            </Col>
            {/* nickname input end  */}
            {/* email input start  */}
            <Col lg={6} md={6} sm={6} className="mb-4">
              <TextInput
                name="email"
                label={Email}
                placeholder={Email}
                value={this.props?.currentUser?.email}
                onFieldChange={this.onFieldChange}
                validate={errors.email}
                disabled
              />
            </Col>
            {/* email input end  */}
            {/* places select input start  */}
            {showSelect &&
              placesArray?.map((item, index) => {
                return (
                  <Col md={6} className="mb-4" key={index}>
                    <SelectInput
                      name={
                        item.filter(({ value }) => value == placesIds[index])
                          .label
                      }
                      label={index === 0 ? Country : Area}
                      placeholder={index === 0 ? Country : Area}
                      value={
                        item.filter(({ value }) => value == placesIds[index])
                          .value
                      }
                      onFieldChange={(name, value) =>
                        this.selectChange(value, index)
                      }
                      data={item}
                      validate={
                        index === placesArray.length - 1 && errors.place_id
                      }
                    />
                  </Col>
                );
              })}
            {/* places select input end  */}
            {/* phone  input start  */}
            <Col lg={6} md={6} sm={6} className="mb-4">
              <div>
                <Label label={Mobile} isRequired={false} />
              </div>
              <div>
                <Form.Group className="phone-number d-flex w-100">
                  <InputGroup.Text className="phone-zipcode text-center">
                    <div className="">{parentPlace?.zipcode}</div>
                  </InputGroup.Text>
                  <PhoneInput
                    type="number"
                    placeholder={Mobile + " *"}
                    name="phone"
                    onFieldChange={this.onFieldChange}
                    value={fields.phone}
                    className="signup-login__form-container__field phone_field"
                    digits={parentPlace.digits}
                  />
                </Form.Group>
              </div>
              <smal
                style={{
                  fontSize: "0.875em",
                  color: "red",
                  letterSpacing: "0px",
                }}
              >
                {errors.phone}
              </smal>
            </Col>
            {placesIds[0] === 1 && (
              <Col md={6} className="mb-4">
                <SelectInput
                  name={
                    this.state?.fields?.buildingType == 1
                      ? { value: 1, label: Building }
                      : { value: 2, label: House }
                  }
                  label={BuildingType}
                  placeholder={BuildingType}
                  value={
                    this.state?.fields?.buildingType == 1
                      ? { value: 1, label: Building }
                      : { value: 2, label: House }
                  }
                  onFieldChange={(name, value) => {
                    this.setState({
                      ...this.state,
                      fields: {
                        ...fields,
                        buildingType: value?.value,
                      },
                    });
                  }}
                  data={[
                    { value: 1, label: Building },
                    { value: 2, label: House },
                  ]}
                />
              </Col>
            )}
            {(this.state?.fields?.buildingType == 1 || placesIds[0] !== 1) && (
              <>
                <Col lg={6} md={6} sm={6} className="mb-4">
                  <TextInput
                    name="building"
                    type="number"
                    label={BuildingNumber}
                    placeholder={BuildingNumber}
                    value={fields.building}
                    onFieldChange={this.onFieldChange}
                    validate={errors.building}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} className="mb-4">
                  <TextInput
                    name="floor"
                    type="number"
                    label={FloorNumber}
                    placeholder={FloorNumber}
                    value={fields.floor}
                    onFieldChange={this.onFieldChange}
                    validate={errors.floor}
                  />
                </Col>
                <Col lg={6} md={6} sm={6} className="mb-4">
                  <TextInput
                    name="appartment"
                    type="number"
                    label={ApartmentNumber}
                    placeholder={ApartmentNumber}
                    value={fields.appartment}
                    onFieldChange={this.onFieldChange}
                    validate={errors.appartment}
                  />
                </Col>
              </>
            )}
            {this.state?.fields?.buildingType == 2 && placesIds[0] === 1 && (
              <>
                <Col lg={6} md={6} sm={6} className="mb-4">
                  <TextInput
                    name="building"
                    type="number"
                    label={HouseNumber}
                    placeholder={HouseNumber}
                    value={fields.building}
                    onFieldChange={this.onFieldChange}
                    validate={errors.building}
                  />
                </Col>
              </>
            )}
            {/* Apartment  input start  */}
            {/* place name (show in edit)  start  */}
            {!isAdd && !showSelect && (
              <Col lg={12} md={12} sm={12} className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                  <p>{PlacesText}</p>
                  <button
                    className="places-delete-btn"
                    onClick={() => {
                      this.setState(
                        {
                          showSelect: true,
                          placesArray: [],
                          extra_data: [],
                          placeFields: [],
                          fields: {
                            ...this.state.fields,
                            firstName: fields.firstName,
                            middleName: fields.middleName,
                            lastName: fields.lastName,
                            appartment: fields.appartment,
                            building: fields.building,
                            floor: fields.floor,
                            phone: fields.phone,
                            email: fields.email,
                            place_id: fields.place_id,
                          },
                        },
                        () => {
                          this.getPlacesFunction(0, []);
                        }
                      );
                    }}
                  >
                    {change}
                  </button>
                </div>
                <TextInput
                  name={PlacesText}
                  value={this.props.data.place?.name}
                  disabled={true}
                />
              </Col>
            )}
            {placeFields?.map((item, index) => {
              return +item.type === 1 || +item.type === 3 ? (
                <Col lg={6} md={6} sm={6} className="mb-4" key={index}>
                  <TextInput
                    name={item.label}
                    label={item.label}
                    type={+item.type === 3 ? "number" : "text"}
                    placeholder={item.label}
                    value={fields[item.label]}
                    onFieldChange={(name, value) =>
                      this.onExtraDataChange(+item.type, value, item)
                    }
                    validate={errors[item.label]}
                  />
                </Col>
              ) : +item.type === 2 ? (
                <Col lg={6} md={6} sm={6} className="mb-4" key={index}>
                  <SelectInput
                    name={item.label}
                    label={item.label}
                    placeholder={item.label}
                    value={fields[item.label]}
                    onFieldChange={(name, value) =>
                      this.onExtraDataChange(+item.type, value, value)
                    }
                    data={item.dropdown_values.map(
                      ({ value: label, id: value }) => ({
                        value,
                        label,
                        id: item.id,
                      })
                    )}
                    validate={errors[item.label]}
                  />
                </Col>
              ) : +item.type === 4 ? (
                <Col
                  sm={6}
                  md={6}
                  className=" align-items-center c-f-u-c"
                  key={index}
                >
                  <UploadFile
                    name={item.label}
                    uploadImage={(fileImage) =>
                      this.uploadImage(item.type, item, fileImage)
                    }
                    value={fields[item.label]}
                    placeholder={item.label}
                    label={item.label}
                    loading={this.state.imageLoader}
                    validate={errors[item.label]}
                  />

                  {this.state.extra_data_images &&
                  this.state.extra_data_images[item.label] ? (
                    <span
                      style={{
                        color: "#009952",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {FileSelected}
                    </span>
                  ) : null}
                </Col>
              ) : null;
            })}{" "}
            <Col lg={12} md={12} sm={12} className="locationButton">
              {isAdd ? (
                !this.props.fromGuest && (
                  <button
                    className="submit-button2"
                    onClick={() => this.addUserLocation()}
                  >
                    {AddLocationButt}
                  </button>
                )
              ) : (
                <button
                  className="submit-button2"
                  onClick={() => this.UpdateLocation(this.props.data.id)}
                >
                  {EditLocationButt}
                </button>
              )}
            </Col>
            {/* add & edit button end */}
          </Row>
        </div>
      </div>
    );
  }

  // upload Image start
  uploadImage = async (type, item, fileImage) => {
    // upload Image function
    this.setState({
      imageLoader: true,
    });
    const formData = new FormData();
    formData.append("file", fileImage);
    const { data } = await generalServices.uploadImage(formData);

    let arrayImages = this.state.extra_data_images
      ? this.state.extra_data_images
      : [];
    arrayImages[item.label] = data;
    this.setState({
      image: data,
      extra_data_images: arrayImages,
      imageLoader: false,
    });

    this.onExtraDataChange(type, data, item);
  };
  // upload Image end

  // on select input change function start
  selectChange = (value, index) => {
    const { fields, placesArray, placesIds } = this.state;

    if (placesArray[index]) {
      let arr = placesArray.slice(0, index + 1);
      this.setState({
        placesArray: arr,
      });
      this.getPlacesFunction(value.value, arr);
    } else {
      this.getPlacesFunction(value.value, placesArray);
    }
    // setValue(fieldData.fieldName, target.value);

    if (placesIds[index]) {
      let arr = placesIds;
      arr[index] = value.value;
      this.setState({
        placesIds: arr,
      });
    } else {
      this.setState({
        placesIds: [...placesIds, value.value],
      });
    }

    this.setState(
      {
        parentPlace: index === 0 ? value : this.state.parentPlace,
        fields: { ...fields, place_id: value },
        errors: {
          place_id: "",
        },
      },
      () => {
        if (index === 0) {
          this.getPlaceFields();
        }
      }
    );
  };
  // on select input change function end

  // on fields input change function start

  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        firstName: "",
        lastName: "",
        address: "",
        building: "",
        phone: "",
        floor: "",
        appartment: "",
      },
    });
  };
  // on fields input change function end

  // on extra data input change function start (extra place fields )
  onExtraDataChange = (type, value, item) => {
    const { extra_data, fields } = this.state;

    const addNew = (data) => {
      if (type !== 2) {
        this.setState({
          fields: {
            ...fields,
            [item.label]: value,
          },

          extra_data: [
            ...data,
            {
              place_field_id: item.id,
              place_field_value_id: "",
              value_text: value,
            },
          ],
        });
      } else {
        this.setState({
          fields: {
            ...fields,
            [item.label]: value,
          },
          extra_data: [
            ...data,
            {
              place_field_id: value.id,
              place_field_value_id: value.value,
              value_text: value.label,
            },
          ],
        });
      }
    };

    if (
      extra_data.findIndex(
        ({ place_field_id }) => place_field_id === item.id
      ) === -1
    ) {
      addNew(extra_data);
    } else {
      let newExtraData = extra_data.filter(
        ({ place_field_id }) => place_field_id !== item.id
      );

      addNew(newExtraData);
    }
  };
  // on extra data input change function end

  // add location function start

  addUserLocation = async () => {
    // add location function
    const {
      placesArray,
      extra_data,
      placeFields,
      fields,
      position,
      placesIds,
    } = this.state;
    const {
      success: successText,
      LocationAdded,
      error: errorTag,
      FillAllFields,
      LocationAddedSuccessfully,
      NameShouldTwoParts,
      palcesLevel,
    } = this.props.i18n;

    //check if all filled
    let canGo = true;
    if (placeFields?.length > 0) {
      placeFields.map((item_one) => {
        if (item_one.is_required == 1) {
          let isFound = false;
          if (extra_data) {
            extra_data.map((item_two) => {
              if (item_one.id == item_two.place_field_id) {
                isFound = true;
                if (!item_two.value_text || item_two.value_text == "") {
                  canGo = false;
                }
              }
            });
          }

          if (!isFound) {
            canGo = false;
          }
        }
      });
    }

    if (!canGo) {
      displayAlert(errorTag, FillAllFields, "error");
      return true;
    }

    if (placesIds?.length !== placesArray.length) {
      displayAlert(errorTag, palcesLevel, "error");
      return true;
    }
    // if is user
    if (+apiService.accessToken !== 0) {
      const { success, message, errors } =
        await locationService.addUserLocation({
          nickname:
            fields.firstName + " " + fields.middleName + " " + fields.lastName,
          address: fields.address,
          latitude: position.lat,
          longitude: position.lng,
          building: fields.building,
          email: fields.email,
          phone: fields.phone,
          floor:
            placesIds[0] == 1 && fields?.buildingType == 2 ? "0" : fields.floor,
          appartment:
            placesIds[0] == 1 && fields?.buildingType == 2
              ? "0"
              : fields.appartment,
          place_id: fields.place_id?.value,
          extra_data,
        });
      if (!success) {
        if (errors) {
          const handelErrors = getResponseErrors(errors);

          this.setState({
            errors: handelErrors,
          });

          return displayAlert(errorTag, FillAllFields, "error");
        } else {
          return displayAlert("Error", message, "error");
        }
      }

      displayAlert(successText, LocationAdded, "success");
      this.props.reFetchData();
      this.props.closeModal();

      this.setState({
        placesArray: [placesArray[0]],
        fields: {
          ...this.state.fields,
          firstName: "",
          middleName: "",
          lastName: "",
          address: "",
          building: "",
          phone: "",
          floor: "",
          appartment: "",
          buildingType: 1,
        },
      });
    } else {
      if (
        fields.email &&
        fields.firstName &&
        fields.lastName &&
        fields.phone &&
        fields.place_id &&
        fields.building &&
        ((fields.floor && fields.appartment) || fields.buildingType == 2)
      ) {
        //check name if two parts
        if (!fields.firstName || !fields.lastName) {
          displayAlert(errorTag, NameShouldTwoParts, "error");
          return true;
        }
        //check name if two parts

        this.setState({
          guestLocation: {
            email: fields.email,
            nickname:
              fields.firstName +
              " " +
              fields.middleName +
              " " +
              fields.lastName,
            address: fields.address,
            latitude: position.lat,
            longitude: position.lng,
            email: fields.email,

            building: fields.building,
            phone: fields.phone,
            floor:
              placesIds[0] == 1 && fields?.buildingType == 2
                ? "0"
                : fields.floor,
            appartment:
              placesIds[0] == 1 && fields?.buildingType == 2
                ? "0"
                : fields.appartment,
            place_id: fields.place_id?.value,
            extra_data,
          },
        });
        // store  location in redux
        this.props.setUser({
          selectedLocation: {
            email: fields.email,
            email: fields.email,

            nickname:
              fields.firstName +
              " " +
              fields.middleName +
              " " +
              fields.lastName,
            address: fields.address,
            latitude: position.lat,
            longitude: position.lng,
            building: fields.building,
            phone: fields.phone,
            floor:
              placesIds[0] == 1 && fields?.buildingType == 2
                ? "0"
                : fields.floor,
            appartment:
              placesIds[0] == 1 && fields?.buildingType == 2
                ? "0"
                : fields.appartment,
            place_id: fields.place_id?.value,
            extra_data,
          },
        });
        // store  location in redux
        this.props.storeLocation({
          email: fields.email,
          nickname:
            fields.firstName + " " + fields.middleName + " " + fields.lastName,
          address: fields.address,
          latitude: position.lat,
          longitude: position.lng,
          building: fields.building,
          phone: fields.phone,
          floor:
            placesIds[0] == 1 && fields?.buildingType == 2 ? "0" : fields.floor,
          appartment:
            placesIds[0] == 1 && fields?.buildingType == 2
              ? "0"
              : fields.appartment,
          place_id: fields.place_id?.value,
          extra_data,
        });
        displayAlert(successText, LocationAddedSuccessfully, "success").then(
          () => {
            if (this.props.subscriptionsControlObj) {
              return this.props.subscriptionsControlObj.nextStep();
            }
          }
        );
      } else {
        displayAlert(errorTag, FillAllFields, "error");
      }
    }

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        buildingType: 1,
      },
    });
  };
  // add location function end

  // on marker map change function start
  handleLocationChange = ({ position, address }) => {
    // Set new location
    const { fields } = this.state;
    this.setState({ position, fields: { ...fields, address } });
  };
  // on marker map change function end

  // get  places function start
  getPlacesFunction = async (id, placesArrayParameter) => {
    // function to get places from the server and set it to the current state
    const { data, success } = await generalServices.getAllCountries(id);
    if (!success) return;
    if (data?.length > 0 && !this.state.showSelect) {
      this.setState({
        placesArray: [
          mapSelectData(data)?.map((item, index) => ({
            value: item.id,
            label: item.name,
            ...item,
          })),
        ],
      });
    }
    if (data?.length > 0 && this.state.showSelect) {
      this.setState({
        placesArray: [
          ...placesArrayParameter,
          data?.map((item, index) => ({
            value: item.id,
            label: item.name,
            ...item,
          })),
        ],
      });
    }
  };
  // get  places function end

  // get places fields start (for edit )
  getPlaceFields = async (placeId, userLocationId = 0) => {
    // get Place Fields and if the items have user_value then add the item label and item value to the fields and extra data
    const { data, success } = await generalServices.getPlaceFields(
      placeId ?? this.state.parentPlace?.id,
      userLocationId
    );
    if (!success) return;

    this.setState({
      placeFields: data,
    });

    if (!this.props.isAdd && isFirstTime) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].user_value) {
          this.setState({
            fields: {
              ...this.state.fields,
              [data[i].label]:
                data[i].type !== 2
                  ? data[i].user_value?.value_text
                  : {
                      label: data[i].user_value?.place_field_value.value,
                      value: data[i].user_value?.place_field_value?.id,
                      id: data[i].user_value?.place_field.id,
                    },
            },
            extra_data: [
              ...this.state.extra_data,
              {
                place_field_id: data[i].user_value?.place_field?.id,
                place_field_value_id:
                  data[i].user_value?.place_field_value?.id ?? "",
                value_text: data[i].user_value?.value_text,
              },
            ],
          });
        }
      }
      isFirstTime = false;
    }
  };
  // get places fields end

  // update location start
  UpdateLocation = async (id) => {
    // Update user location Location
    const { fields, position, extra_data, placesIds, placesArray, showSelect } =
      this.state;
    const {
      error: errorTag,
      success: successText,
      FillAllFields,
      LocationEdited,
      palcesLevel,
    } = this.props.i18n;
    if (placesIds?.length !== placesArray.length && showSelect) {
      displayAlert(errorTag, palcesLevel, "error");
      return true;
    }
    const { success, message, errors } = await locationService.editUserLocation(
      {
        user_location_id: id,
        nickname:
          fields.firstName + " " + fields.middleName + " " + fields.lastName,
        address: fields.address,
        latitude: position.lat,
        longitude: position.lng,
        building: fields.building,
        phone: fields.phone,
        email: fields.email,

        place_id: fields.place_id?.value,
        floor:
          placesIds[0] == 1 && fields?.buildingType == 2 ? "0" : fields.floor,
        appartment:
          placesIds[0] == 1 && fields?.buildingType == 2
            ? "0"
            : fields.appartment,
        extra_data,
      }
    );
    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return displayAlert(errorTag, FillAllFields, "error");
      } else {
        return displayAlert("Error", message, "error");
      }
    }

    displayAlert(successText, LocationEdited, "success");
    this.props.reFetchData();
    this.props.closeModal();
  };
  // update location end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  countryId: country.id,
  country,
});

export default connect(mapStateToProps, { setLanguage, setUser })(
  EditAddLocation
);
