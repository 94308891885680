import React, { Component } from "react";
import {
  DropdownButton,
  FormControl,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../redux/actions-reducers/language";
import { setUser } from "../../redux/actions-reducers/user";
import { setCurrency } from "../../redux/actions-reducers/currency";
import { setCountry } from "../../redux/actions-reducers/country";
import { generalServices } from "../../services/GeneralService";
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import { default as DropDownLibrary } from "react-multilevel-dropdown";
import LoginModal from "./LoginModal";
import PushMenu from "./PushMenu";
import CurrencyFlag from "react-currency-flags";
import Topbar from "./Topbar";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showCategory: false,
    showMegaCategory: false,
    menuOpen: false,
    categories: [],
    topCategory: [],
    searchCategory: this.props.i18n?.all,
    topBrand: [],
    TopBarCategories: [],
    key: "",
    currentLang: this.props.language.lang,
    ads: {},
    searchCategoryId: 0,
    showLogin: false,
    currencies: [],
    currentCurrency: this.props.currency.currencyName,
    currencyLoader: true,
    searchDropdown: false,
    settingData: {},
  };

  onLanguageChange = async () => {
    // Language Change handler
    let currentLang;
    let name;
    if (this.props.language.langCode === "rtl") {
      localStorage.setItem("langCode", "ltr");
      currentLang = "ltr";
      name = "English";
    } else {
      localStorage.setItem("langCode", "rtl");
      currentLang = "rtl";
      name = "العربية";
    }

    await this.props.setLanguage({
      lang: name,
      langCode: currentLang,
    });

    setTimeout(() => {
      this.setState(
        {
          currentLang: currentLang == "rtl" ? "English" : "العربية",
        },
        () => {
          window.location.reload();
        }
      );
    }, 0);
  };

  componentDidMount() {
    // call the functions that get data
    Promise.all([
      this.getCountries(),
      this.getCurrencies(),
      this.getSettings(),
      this.getFavoritesIDs(),
      this.getIconData(),
      this.getCartIDs(),
      this.getCategories(0, 0, 0, 1, 1),
      this.getCategories(0, 0, 1, 1, 1),
    ]).then(() => {
      this.setState({
        currencyLoader: false,
      });

      fetch("https://ip.nf/me.json").then(async (res) => {
        const { ip } = await res.json();
        const country_code = ip.country_code;
        if (country_code === "KW") {
          // get list of currencies
          const { data } = await generalServices.getCurrencies();
          // pick the currency that has KWD as code
          const kwdCurrency = data.find((item) => item.code === "KWD");
          // if lastCurrencyKWChanged is not set or if it is set and it is more than 24 hours
          if (
            !localStorage.getItem("lastCurrencyKWChanged") ||
            Date.now() - localStorage.getItem("lastCurrencyKWChanged") >
              1000 * 60 * 60 * 24
          ) {
            if (kwdCurrency) {
              // set time when last changed
              localStorage.setItem("lastCurrencyKWChanged", Date.now());
              // set the currency in redux
              this.props.setCurrency({
                currencyName: kwdCurrency.code,
                currencyFactor: kwdCurrency.convert_factor,
              });
              this.setState({
                currentCurrency: kwdCurrency.code,
              });
            }
          }
        }
      });
    });

    // hidden search input in web start
    window.onclick = (event) => {
      if (
        !event.target.matches(".search-icon") &&
        !event.target.matches(".search_input-icon") &&
        !event.target.matches(".header__searchDropdown__content") &&
        !event.target.matches(".header__search") &&
        !event.target.matches(".header__searchDropdown") &&
        !event.target.matches(".ll")
      ) {
        this.setState({
          searchDropdown: false,
        });
      }
    };
  }

  async getSettings() {
    // Settings (footer data) api request
    const { success, data } = await generalServices.getFooter();
    if (!success) {
      return;
    }

    this.setState({
      settingData: data,
    });
  }

  getCountries = async () => {
    // function to get countries from the server and set the first one to the redux (setCountry from country store)
    const { data, success } = await generalServices.getAllCountries(0);
    if (!success) return;
    this.props.setCountry({
      id: data[0]?.id,
      name: data[0]?.name,
      language: data[0]?.languages,
      currency: data[0]?.currency,
      digits: data[0]?.digits,
      flag: data[0]?.flag,
      zipcode: data[0]?.zipcode,
    });
  };

  getCurrencies = async () => {
    // function to get Currencies from the server and set the first one to the redux (setCurrency from currency store) if not user currencyName
    const { data, success } = await generalServices.getCurrencies();
    if (!success) return;

    if (!this.props.currentUser?.email) {
      if (this.props.currency.currencyName?.length < 1) {
        this.props.setCurrency({
          currencyName: data[0].code,
          currencyFactor: data[0].convert_factor,
        });
        this.setState({
          currentCurrency: data[0].code,
        });
      }
    }
    this.setState({
      currencies: data,
    });
  };

  getCartIDs = async () => {
    // function to get user cart from the server and set it to user redux store data
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };

  getFavoritesIDs = async () => {
    // function to get user Favorites IDs from the server and set it to user redux store data
    const { data, success } = await generalServices.getFavoritesIDs();
    if (!success) return;

    this.props.setUser({
      favoritesId: data,
    });
  };

  getIconData = async () => {
    // function to get Fav Cart compare Counters from the server and set it to user redux store data
    const { data, success } = await generalServices.getIconData();
    if (!success) return;
    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
      compare: data.compare_counter,
    });
  };

  getCategories = async (
    parentId,
    isFeatured,
    isTopBar,
    showBrands,
    showSub
  ) => {
    // function to get categories from the server and set it to current state base isTopBar select data name
    const { data, success } = await generalServices.getCategories(
      parentId,
      isFeatured,
      0,
      showBrands,
      showSub,
      this.props.country.id
    );

    if (!success) return;
    let name = isTopBar === 0 ? "categories" : "TopBarCategories";
    this.setState({
      [name]: data,
    });
  };

  closeModal() {
    this.setState({
      showLogin: false,
    });
    this.props.setUser({ firstTime: false });
  }

  render() {
    const {
      language,
      currentUser,
      setShowLoginModal,
      showLogin,
      i18n: {
        SingIn,
        Register,
        Home,
        CATEGORIES,
        SUBSCRIPTIONS,
        SALE,
        GIFTCARDS,
        theExchange,
        theReturn,
        Profile,
        Logout,
      },
    } = this.props;
    const pathName = window.location.pathname;
    const {
      settingData,
      currencies,
      currentCurrency,
      searchDropdown,
      TopBarCategories,
    } = this.state;
    return (
      <>
        <Topbar settings={settingData} />
        <LoginModal
          loginShow={showLogin}
          closeModal={() => this.props.setShowLoginModal(false)}
        />

        <div className={`header`}>
          <div className="header__top">
            <div className="header__top__content flex ">
              <ul className="flex">
                <li className="flex header-currency">
                  <img src="/img/money (1).svg" alt="l" />
                  <DropdownButton
                    title={currentCurrency}
                    id="input-group-dropdown-1"
                  >
                    {currencies?.map(({ id, code, convert_factor }) => {
                      return (
                        <Dropdown.Item
                          key={id}
                          onClick={() =>
                            this.convertCurrency(convert_factor, code, id)
                          }
                        >
                          <CurrencyFlag
                            currency={code}
                            width={20}
                            className="me-2"
                          />
                          {code}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </li>

                <div className="vertical-hr" />
                <li className="flex login-buttons">
                  {currentUser?.email ? (
                    <DropdownButton
                      className={`header__userButton flex  ${
                        language.langCode === "rtl"
                          ? "header__location-card-arabic"
                          : ""
                      }`}
                      variant="outline-secondary"
                      title={
                        <div className="flex button-contents">
                          <img src="/img/user1.svg" alt="1" />
                          <div className="header__location-card__right-side">
                            {currentUser?.name}
                          </div>
                        </div>
                      }
                      id="input-group-dropdown-1"
                    >
                      <Dropdown.Item>
                        <Link to="/profile">{Profile}</Link>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={async () => {
                          // logout and remove user data from localStorage and refresh
                          localStorage.removeItem("beam_admin_access_token");
                          localStorage.removeItem("beamAccessToken");
                          localStorage.removeItem("persist:Beam");
                          window.location.href = "/";
                        }}
                      >
                        {Logout}
                      </Dropdown.Item>
                    </DropdownButton>
                  ) : (
                    <>
                      <img src="/img/log-in.svg" alt="l" />
                      <div>
                        <Link
                          to="/productsCategory/All_Products/1"
                          onClick={() => {
                            setShowLoginModal(true);
                          }}
                        >
                          {SingIn}
                        </Link>
                        /<a href="/signup">{Register}</a>
                      </div>
                    </>
                  )}
                </li>

                <div className="vertical-hr" />
                <li
                  className="flex header-lang "
                  onClick={this.onLanguageChange}
                >
                  <img src="/img/language.svg" alt="l" />
                  <span>
                    {this.props.language.langCode === "rtl"
                      ? "English"
                      : "العربية"}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="header__bottom">
            <div className="header__bottom-content flex">
              <PushMenu
                setShowLoginModal={() => {
                  if (!this.props.currentUser?.email) setShowLoginModal(true);
                }}
              />

              <Link
                to="/"
                className="header-logo d-flex align-items-center
                "
                style={{ minHeight: "50px" }}
              >
                {settingData.header_logo && (
                  <img
                    src={apiService.imageLink + settingData.header_logo}
                    alt="/"
                  />
                )}
              </Link>

              <ul className="flex">
                <li className={pathName.toLowerCase() === "/" ? "active" : ""}>
                  <Link to="/">{Home}</Link>
                </li>

                <li>
                  <DropDownLibrary
                    title={
                      <div className="flex">
                        <span>{CATEGORIES}</span>
                        <img src="/img/3700266371543238866.svg" alt="k" />
                      </div>
                    }
                    className="library-dropdown"
                    menuClassName="library-dropdown__menu"
                  >
                    {TopBarCategories.map(
                      ({
                        name,
                        id,
                        subcategories,
                        machine_name: parentMachineName,
                      }) => {
                        if (subcategories?.length > 0) {
                          return (
                            <DropDownLibrary.Item
                              key={id}
                              className={` p-1
                                library-dropdown__item gap-1 ${
                                  pathName
                                    .toLowerCase()
                                    .startsWith(
                                      `/productsCategory/${parentMachineName}`.toLowerCase()
                                    )
                                    ? "active"
                                    : ""
                                }
                                  `}
                            >
                              <a
                                href={`/productsCategory/${parentMachineName}/1`}
                                key={id}
                                className="w-100 px-2 py-1"
                              >
                                <span>{name}</span>
                              </a>
                              <span className="px-1">
                                <img src="/img/Path 25.svg" alt="l" width={6} />
                              </span>
                              <DropDownLibrary.Submenu className="library-dropdown__item__sub-item">
                                {subcategories.map(
                                  (
                                    {
                                      id: childId,
                                      name: childName,
                                      machine_name: childMachineName,
                                    },
                                    index
                                  ) => {
                                    return (
                                      <DropDownLibrary.Item
                                        key={index}
                                        className={`drop-down-library-child p-1 ${
                                          pathName
                                            .toLowerCase()
                                            .startsWith(
                                              `/productsCategory/${childMachineName}`.toLowerCase()
                                            )
                                            ? "active"
                                            : ""
                                        }
                                        `}
                                      >
                                        <a
                                          href={`/productsCategory/${childMachineName}/1`}
                                          key={childId}
                                          className="w-100 px-2 py-1 text-black"
                                        >
                                          <span>{childName}</span>
                                        </a>
                                      </DropDownLibrary.Item>
                                    );
                                  }
                                )}
                              </DropDownLibrary.Submenu>
                            </DropDownLibrary.Item>
                          );
                        } else {
                          return (
                            <DropDownLibrary.Item
                              key={id}
                              className={` p-0 p-1
                              library-dropdown__item gap-1 ${
                                pathName
                                  .toLowerCase()
                                  .startsWith(
                                    `/productsCategory/${parentMachineName}`.toLowerCase()
                                  )
                                  ? "active"
                                  : ""
                              }
                                `}
                            >
                              <a
                                href={`/productsCategory/${parentMachineName}/1`}
                                key={id}
                                className="w-100 px-2 py-1"
                              >
                                <span>{name}</span>
                              </a>
                            </DropDownLibrary.Item>
                          );
                        }
                      }
                    )}
                  </DropDownLibrary>
                </li>

                <li
                  className={
                    `preParams${pathName.toLowerCase()}`.startsWith(
                      "preParams/subscriptions"
                    )
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/Subscriptions">{SUBSCRIPTIONS}</Link>
                </li>

                <li
                  className={pathName.toLowerCase() === "/sale" ? "active" : ""}
                >
                  <Link to="/sale/1">{SALE}</Link>
                </li>

                <li
                  className={
                    pathName.toLowerCase().startsWith("/giftcard")
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/GiftCardPage">{GIFTCARDS}</Link>
                </li>

                <li
                  onClick={() => {
                    if (!this.props.currentUser?.email) setShowLoginModal(true);
                  }}
                >
                  <Link
                    to={
                      this.props.currentUser?.email
                        ? "/ReturnRequestsProfilePage"
                        : "#"
                    }
                  >
                    {theReturn}
                  </Link>
                </li>
                <li
                  onClick={() => {
                    if (!this.props.currentUser?.email) setShowLoginModal(true);
                  }}
                >
                  <Link
                    to={
                      this.props.currentUser?.email
                        ? "/ExchangeRequestsProfilePage"
                        : "#"
                    }
                  >
                    {theExchange}
                  </Link>
                </li>
              </ul>
              <div className="header__cart-fav-search flex">
                <div>
                  <img
                    src="/img/Forma 1.svg"
                    alt="search "
                    className="search-icon"
                    onClick={() =>
                      this.setState({ searchDropdown: !searchDropdown })
                    }
                  />
                  {searchDropdown && (
                    <div className={`header__searchDropdown`}>
                      <div className={`header__searchDropdown__content`}>
                        {this.SearchComponent()}
                      </div>
                    </div>
                  )}
                </div>

                <Link to="/favourite">
                  <img src="/img/heart.svg" alt="search" />
                  {currentUser?.fav && currentUser?.fav > 0 ? (
                    <span className="cart-fav-span">{currentUser?.fav}</span>
                  ) : null}
                </Link>

                <Link to="/cart">
                  <img src="/img/cart.svg" alt="search" />
                  {currentUser?.cart && currentUser?.cart > 0 ? (
                    <span className="cart-fav-span">{currentUser?.cart}</span>
                  ) : null}
                </Link>
              </div>

              {this.SearchComponent()}
            </div>
          </div>
        </div>
      </>
    );
  }

  convertCurrency(convertFactor, name, id) {
    // convert Currency handler
    this.props.setCurrency({
      currencyName: name,
      currencyFactor: convertFactor,
    });
    this.setState({ currentCurrency: name });
    this.updateUserGuestCurrency(id);
  }

  componentDidUpdate(prevProps, prevState) {
    // if showLogin change set the new showLogin
    if (prevProps.showLogin !== this.props.showLogin) {
      this.setState({ showLogin: this.props.showLogin });
    }

    // if the email change re request the data
    if (prevProps.currentUser?.email !== this.props.currentUser?.email) {
      Promise.all([
        this.getCountries(),
        this.getCurrencies(),
        this.getSettings(),
        this.getFavoritesIDs(),
        this.getIconData(),
        this.getCartIDs(),
        this.getCategories(0, 0, 0, 1, 1),
        this.getCategories(0, 0, 1, 1, 1),
      ]).then(() => {
        this.setState({
          currencyLoader: false,
        });
      });
    }
  }

  SearchComponent = () => {
    // Search Component ( render )
    const { search } = this.props;
    return (
      <InputGroup className="header__search add-promo-input-group">
        <FormControl
          className="ll"
          placeholder={search}
          aria-label="Username"
          onChange={({ target: { value } }) => {
            this.setState({
              key: value,
            });
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (this.state.key === "") {
              } else {
                this.Search(this.state.key);
              }
            }
          }}
          aria-describedby="basic-addon1"
        />
        <InputGroup.Text
          id="basic-addon1"
          onClick={() => {
            if (this.state.key === "") {
            } else {
              this.Search(this.state.key);
            }
          }}
        >
          <img
            src="/images/searchIcon.svg"
            alt="search"
            className="search_input-icon"
          />
        </InputGroup.Text>
      </InputGroup>
    );
  };

  updateUserGuestCurrency = async (id) => {
    // update User Guest Currency api request
    const { success } = await generalServices.updateUserGuestCurrency(id);
    if (!success) return;
  };

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  Search = (key) => {
    // go to the Search page
    const { searchCategoryId } = this.state;
    if (key.includes("%")) {
      window.location.href = `/Search/${key.replace(
        "%",
        "%25"
      )}/${searchCategoryId}/1`;
    } else {
      window.location.href = `/Search/${key}/${searchCategoryId}/1`;
    }
  };
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
  currency,
});
export default connect(mapStateToProps, {
  setLanguage,
  setCountry,
  setUser,
  setCurrency,
})(withRouter(Header));
