import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )

function SubscriptionCard({ data = [], onSelectPackage, index }) {
  const language = useSelector(({ language }) => language);
  const currency = useSelector(({ currency }) => currency);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const { StartingFrom, Select } = i18n;
  
  return (
    <Card style={{ width: "100%" }}>
      {/* subscription image start  */}
      <Card.Img
        variant="top"
        src={
          data?.main_image
            ? apiService.imageLink + data?.main_image
            : "/images/placeholder.png"
        }
      />
      {/* subscription image end  */}

      <Card.Body>
        {/* subscription title start  */}

        <Card.Title>{data?.name}</Card.Title>
        {/* subscription title end  */}
        {/* subscription description and features start  */}

        <Card.Text>
          <p>
            {StartingFrom}:
            <span>
              {currency.currencyName}{" "}
              {data?.max_price && data?.max_price * currency.currencyFactor}
            </span>
          </p>

          <ul>
            {data?.features?.map(({ id, text }) => {
              return (
                <li key={id}>
                  <img src="/img/Group 1519.svg" alt="l" />
                  <span>{text}</span>
                </li>
              );
            })}
          </ul>
        </Card.Text>
      </Card.Body>
      {/* subscription description and features end  */}

      {/* subscription button start  */}
      <Card.Footer>
        {" "}
        <button
          className="submit-btn"
          onClick={async () => {
            await onSelectPackage(data?.id);
          }}
        >
          {Select}
        </button>
      </Card.Footer>
      {/* subscription button end  */}
    </Card>
  );
}

export default SubscriptionCard;
