import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";
import { generalServices } from "../../../services/GeneralService";
import ScrollToTop from "../../blocks/ScrollToTop";
import DeliveryTypes from "../Payment/DeliveryTypes";

class PaymentMethods extends Component {
  state = {
    fields: {
      name: "",
      phone: "",
      password_confirmation: "",
      password: "",
      email: "",
      terms_conditions: false,
    },
    errors: {
      name: "",
      phone: "",
      password_confirmation: "",
      password: "",
      email: "",
      terms_conditions: "",
    },
    loginShow: false,
    termsChecked: false,
    termsCheckedError: false,
    files: [],
    radioData: "",
    paymentsMethods: [],
    selectPayment: {},
    withPrice: true,
    paymentLoader: true,
  };

  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        name: "",
        mobile: "",
        password_confirmation: "",
        password: "",
        email: "",
      },
    });
  };

  componentDidMount() {
    this.getPaymentMethodsByPlaceID();
  }

  // get Payment Methods By Place ID start
  async getPaymentMethodsByPlaceID() {
    const { success, data } = await generalServices.getPaymentMethodsByPlaceID(
      this.props.place_id
    );

    if (!success) return;
    this.setState({
      paymentsMethods: data,
      paymentLoader: false,
    });

    this.selectPayment(data[0]);
  }
  // get Payment Methods By Place ID end

  render() {
    const {
      language,
      withPriceChange,
      i18n: { OrderInvoice, WithoutPrice },
    } = this.props;

    const { withPrice } = this.state;

    return (
      <div
        className={`paymentsMethods ${
          language === "rtl" && "paymentsMethods-arabic"
        }`}
      >
        <ScrollToTop />
        <Row>
          <Col md={12}>
            <DeliveryTypes
              place={this.props.place_id}
              deliveryCompanySet={this.props.deliveryCompanySet}
              setDeliveryTypeName={this.props.setDeliveryTypeName}
            />
          </Col>
          {!this.props.subscriptions && (
            <Col md={12} className="my-5">
              <div className="d-flex payment-invoice">
                <span>{OrderInvoice}:</span>
                <label className="checkbox-container">
                  <p className="d-flex justify-content-center align-items-center">
                    {WithoutPrice}
                  </p>
                  <input
                    type="checkbox"
                    onChange={() => {
                      this.setState({ withPrice: !withPrice });
                      withPriceChange(!withPrice);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
  selectPayment(payment) {
    this.setState({ selectPayment: payment });
    this.props.selectPayment(payment);
  }
  removeFile(f) {
    this.setState({ files: this.state.files.filter((x) => x !== f) });
  }
}
const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setUser })(PaymentMethods);
