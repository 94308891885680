import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

function WalletTable({ data }) {
  const currency = useSelector(({ currency }) => currency);

  return (
    <div className="layalty-table mb-5">
      <table>
        {data?.map(({ created_at, wallet_amount, notes, direction }) => {
          return (
            <tr className="layalty-table__item">
              <td>
                {created_at ? moment(created_at).format("yy-MM-DD") : "---"}
              </td>
              <td>{notes}</td>
              <td>
                <img
                  src={`/img/${direction === 1 ? "munis" : "plus"}.svg`}
                  alt="/"
                />
                {(wallet_amount * currency.currencyFactor).toFixed(2)}{" "}
                {currency.currencyName}
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default WalletTable;
