import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import { userService } from "../../../../services/UserService";
import PaginatedItems from "../../Search/PaginationBlack";
import { withRouter } from "react-router-dom";
import ReturnRequestsProfileTable from "./ReturnRequestsProfileTable";

class ReturnRequestsProfile extends Component {
  state = {
    StatusCase: this.props.i18n.All,
    color: "",
    returnOrders: [],
    fields: {
      to: "",
      from: "",
      key: "",
      status: "",
    },
    currentPage: 1,
    loader: true,
    meta: {},
  };

  componentDidMount() {
    this.getReturnRequests();
  }
  getReturnRequests = async (page) => {
    // function to get Return Requests by call it from the server and set it to the current state
    const { data, success, meta } = await userService.getReturnRequests();

    if (!success) return;
    this.setState({
      returnOrders: data,
      pageLoader: false,
      meta: meta,
      loader: false,
    });
  };
  render() {
    const {
      language,
      i18n: { returnsEmpty },
    } = this.props;
    const { returnOrders, meta } = this.state;
    return (
      <Container>
        <div className={`orders ${language === "rtl" && "orders-arabic"}`}>
          {/* Order start */}
          {this.state.loader ? (
            <>
              <Skeleton height={400} className="mt-3" />
            </>
          ) : returnOrders.length > 0 ? (
            <ReturnRequestsProfileTable
              data={returnOrders}
              isActive={true}
              meta={meta}
              getReturnRequests={() => this.getReturnRequests()}
            />
          ) : (
            <Col md={12}>
              <div className="empty-products">
                <img src="/img/returnsEmpty.svg" alt="k" />
                <p> {returnsEmpty} </p>
              </div>
            </Col>
          )}
          {/* Order End */}
        </div>

        {/* Pagination Start */}
        {this.state.loader ? null : meta?.last_page > 1 ? (
          <Row>
            <Col md={12}>
              <div className="Pagination">
                <PaginatedItems // pagination component that handle the pages
                  items={[...Array(meta?.total).keys()]}
                  itemsPerPage={meta?.per_page}
                  changePage={(page) => this.changePage(page)}
                />
              </div>
            </Col>
          </Row>
        ) : null}

        {/* Pagination End */}
      </Container>
    );
  }

  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(`/orders/${page + 1}`);
    this.setState(
      {
        pageLoader: true,
      },
      () => {
        this.getUserOrders();
      }
    );
  }

}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(
  withRouter(ReturnRequestsProfile)
);
