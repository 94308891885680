import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import AccountPageList from "../../../blocks/AccountPageList";
import AccountPageMobileList from "../../../blocks/AccountPageMobileList";
import PageTitle from "../../../blocks/PageTitle";
import ProfileInfo from "./ProfileInfo";
import ProfileForm from "./ProfileForm";

class ProfilePage extends Component {
  state = {
    remindMe: false,
    editProfile: false,
    links: [
      { name: this.props.i18n.Home, url: "/" },
      { name: this.props.i18n.MyAccount, url: "profile" },
    ],
  };

  render() {
    const {
      language,
      i18n: {  MyAccount },
    } = this.props;
    const { editProfile, links } = this.state;

    return (
      <>
        {/* page title with breadcrumb start  */}

        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={MyAccount}
          links={links}
        />
        {/* page title with breadcrumb end  */}

        <Container>
          <div
            className={`account-page ${
              language === "rtl" && "account-page-arabic"
            }`}
          >
            {/* Mobile Side Menu list Start */}
            <AccountPageMobileList />
            {/* Mobile Side Menu list end */}
            <Row>
              {/*web Menu list Start */}

              <Col className="board" md={3}>
                <AccountPageList />
              </Col>
              {/*web Menu list end */}

              {/*profile info and profile form  start  */}
              <Col md={9}>
                {editProfile ? (
                  <ProfileForm
                    showProfileInfo={() =>
                      this.setState({
                        editProfile: false,
                        links: links.splice(0, links.length - 1),
                      })
                    }
                  />
                ) : (
                  <ProfileInfo
                    showProfileForm={() =>
                      this.setState({
                        editProfile: true,
                        links: [
                          ...links,
                          { name: this.props.i18n.EditProfile, url: "profile" },
                        ],
                      })
                    }
                  />
                )}
              </Col>
              {/*profile info and profile form  end  */}
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(ProfilePage);
