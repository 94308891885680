import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Global from "./../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { ArrowRight, ArrowLeft } from "react-bootstrap-icons";

export default function Back() {
  let history = useHistory();
  const path = useSelector(({ app }) => app.path);
  const lang = useSelector(({ language }) => language.langCode);
  const { id } = useParams();

  return (
    <Button
      variant="text"
      className="p-0"
      onClick={() => {
        history.push(
          path !== ""
            ? path + "#" + id
            : "/productsCategory/All_Products/1#" + id
        );
      }}
    >
      {Global[lang === "rtl" ? "ar" : "en"]?.back}{" "}
      {lang === "rtl" ? <ArrowLeft size={30} /> : <ArrowRight size={30} />}
    </Button>
  );
}
