import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import { setUser } from "../../../../redux/actions-reducers/user";
import { userService } from "../../../../services/UserService";

class ProfileInfo extends Component {
  state = {
    remindMe: false,
    userInfo: {},
    genders: [
      { label: "Male", value: 1 },
      { label: "Female", value: 2 },
    ],
    generalFields: {
      first_name: "",
      last_name: "",
      gender: "",
      Phone: "",
      email: "",
    },
    infoLoader: true,
  };

  componentDidMount() {
    this.getUserInformation();
  }

  getUserInformation = async () => {
    // function to get user Information from the server and set it to the current state
    const { data, success } = await userService.getUserInformation();
    if (!success) return;

    this.setState({
      userInfo: data,

      generalFields: {
        ...this.state.generalFields,
        first_name: data.name.split(" ").filter((item) => item !== "")[0] ?? "",
        last_name: data.name.split(" ").filter((item) => item !== "")[1] ?? "",
        email: data.email,
        gender: this.state.genders.find(({ value }) => +value === +data.gender),
        date: data.date_of_birth,
        Phone: data.phone,
      },
      infoLoader: false,
    });
  };

  render() {
    const {
      language,
      showProfileForm,
      i18n: {
        genderText,
        Email,
        EditProfile,
        FullName,
        AccountInformation,
        DateOfBirth,
        Phone,
      },
    } = this.props;
    const { generalFields, infoLoader } = this.state;
    return (
      <Container>
        {/* title start */}
        <div
          className={`profile accountInformation  ${
            language === "rtl" && "profile-arabic"
          }`}
        >
          {/* title start */}
          {/* general information Start section */}
          <div className="general-info">
            <h2 className="general-info__title">{AccountInformation}</h2>
            <div className="profile-info__box">
              <Row>
                {/* full name start  */}
                <Col lg={6} md={6} className="profile-info__border-bottom mt-1">
                  <span>{FullName}</span>
                  {infoLoader ? (
                    <Skeleton height={25} style={{ marginBottom: "10px" }} />
                  ) : (
                    <p>
                      {" "}
                      {generalFields?.first_name +
                        " " +
                        generalFields?.last_name}
                    </p>
                  )}
                </Col>
                {/* full name end  */}

                {/* email start  */}
                <Col lg={6} md={6} className="profile-info__border-bottom mt-1">
                  <span>{Email}</span>
                  {infoLoader ? (
                    <Skeleton height={25} style={{ marginBottom: "10px" }} />
                  ) : (
                    <p> {generalFields?.email}</p>
                  )}
                </Col>
                {/* email end  */}
                {/* phone start  */}

                <Col lg={6} md={6} className="profile-info__border-bottom mt-3">
                  <span>{Phone}</span>
                  {infoLoader ? (
                    <Skeleton height={25} style={{ marginBottom: "10px" }} />
                  ) : (
                    <p> {generalFields?.Phone}</p>
                  )}
                </Col>
                {/* phone end  */}
                {/* Date Of Birth start  */}

                <Col lg={6} md={6} className="profile-info__border-bottom mt-3">
                  <span>{DateOfBirth}</span>
                  {infoLoader ? (
                    <Skeleton height={25} style={{ marginBottom: "10px" }} />
                  ) : (
                    <p> {generalFields?.date}</p>
                  )}
                </Col>
                {/* Date Of Birth end  */}
                {/* gender start  */}
                <Col lg={6} md={6} className="mt-3">
                  <span>{genderText}</span>
                  {infoLoader ? (
                    <Skeleton height={25} style={{ marginBottom: "10px" }} />
                  ) : (
                    <p> {generalFields.gender?.label}</p>
                  )}
                </Col>
                {/* gender end  */}

                {/* edit button start  */}
                <div className="button-container mt-3">
                  <button className="submit-button2" onClick={showProfileForm}>
                    {EditProfile}
                  </button>
                </div>
                {/* edit button end  */}
              </Row>
            </div>
          </div>
          {/* general information End section */}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country: country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(ProfileInfo);
