import { createSlice } from "@reduxjs/toolkit";

const currencySlice = createSlice({
    name: "currency",
    initialState: { currencyName: "", isLoading: false, currencyFactor: 1 },

    reducers: {
        setCurrency: (currency, action) => {
            currency.currencyName = action.payload.currencyName;
            currency.currencyFactor = action.payload.currencyFactor;
        },
    },
});

export const { setCurrency } = currencySlice.actions;

export default currencySlice.reducer;
