export function isValueEnglish(item, value) {
  if (
    item?.toLowerCase() === "email" ||
    item?.toLowerCase() === "longitude" ||
    item?.toLowerCase() === "latitude" ||
    item?.toLowerCase() === "phone" ||
    typeof value === "object" ||
    Array.isArray(value) ||
    typeof value === "number"
  ) {
    return true;
  }
  // allow only english characters, numbers and spaces, and special characters
  // only allow . and - and _ and : and + and ' and dashes
  return /^[a-zA-Z0-9\s\.\-\_\'\"\`\#\@\!\%\&\*\|\;\:\+\(\)\,\/]*$/.test(value);
}
