import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../src/language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import TextInput from "../blocks/TextInput";
import { displayAlert, getResponseErrors } from "../../utils/misc";
import { setUser } from "../../redux/actions-reducers/user";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { productService } from "../../services/ProductService";
class FirstTimeModal extends Component {
  state = {
    fields: {
      email: "",
    },
    errors: {
      email: "",
    },
  };

  // onFieldChange function start
  onFieldChange = (name, value) => {
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        email: "",
      },
    });
  };
  // onFieldChange function end

  render() {
    const {
      loginShow,
      closeModal,
      type,
      i18n: {
        FirstTimeText,
        SignIn,
        notAMember,
        Register,
        NotifyWhenAv,
        NotifyMe,
        PleaseEnterYourEmailId,
        FirstTimeSubText,
      },
    } = this.props;

    const { fields, errors } = this.state;
    return (
      <>
        <Modal
          size="md"
          show={loginShow}
          onHide={closeModal}
          aria-labelledby="example-modal-sizes-title-lg"
          className="login-modal first-time-modal"
        >
          <Modal.Header
            closeButton
            className="login-modal__header"
          ></Modal.Header>
          <Modal.Body className="login-modal__body mb-3">
            <Row style={{ backgroundColor: "#fff",height:"100%" }}>
              {/* modal form start  */}
              <Col
                className="login-modal__body__left p-3 pb-0 pt-0"
                xs={12}
                sm={12}
                md={12}
              >
                <Row className="login-form p-5">
                  <header className="login-modal__body__header w-100 mb-3">
                    {type === "notify" ? NotifyWhenAv : FirstTimeText}
                  </header>
                  {/* modal note start  */}
                  {type != "notify" && <p>{FirstTimeSubText}</p>}
                  {/* modal note end  */}

                  {type === "notify" ? (
                    <>
                      <Form.Label className="p-0 m-0">
                        {PleaseEnterYourEmailId}{" "}
                      </Form.Label>
                      {/* email input start  */}
                      <Form.Group className="mt-3 signup-login__form-container__field p-0">
                        <TextInput
                          name="email"
                          value={fields.email}
                          onFieldChange={this.onFieldChange}
                          validate={errors.email}
                        />
                      </Form.Group>
                      {/* email input end  */}

                      {/* Notify button start */}
                      <button
                        className="submit-btn notify-btn p-2 w-100"
                        onClick={this.submitNotifyMe}
                      >
                        {NotifyMe}
                      </button>
                      {/* Notify button end */}
                    </>
                  ) : (
                    <>
                      {/* login button start  */}
                      <Button
                        onClick={() => {
                          this.props.setShowLoginModal(true);
                          closeModal();
                        }}
                        className="submit-btn p-2 w-100"
                        style={{ marginTop: "5rem" }}
                      >
                        {SignIn}
                      </Button>
                      {/* login button end  */}

                      {/* signup button start */}
                      <p className="p-0 mt-3" onClick={() => closeModal()}>
                        {notAMember} <Link to="/signup">{Register}</Link>
                      </p>
                      {/* signup button end */}
                    </>
                  )}
                </Row>
              </Col>
              {/* modal form end  */}


            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  // notify submit function start
  submitNotifyMe = async () => {
    // function to send addProductNotifyMe api request
    const { done, weWillNotifyYou } = this.props.i18n;

    const { success, data, error } = await productService.submitNotifyMe(
      this.props.productId,
      this.state.fields.email
    );

    if (!data) {
      if (error) return this.setState({ errors: getResponseErrors(error) });
      return;
    }
    displayAlert(done, weWillNotifyYou, "success");

    this.props.closeModal();
  };
  // notify submit function end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setUser })(
  withRouter(FirstTimeModal)
);
