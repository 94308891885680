import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CheckBoxProduct from "./CheckBoxProduct";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { userService } from "../../services/UserService";
import { displayAlert, mapSelectData } from "../../utils/misc";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { generalServices } from "../../services/GeneralService";
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )

function ExchangeModal({
  controlExchangeModal,
  openExchangeModal,
  type,
  orderId,
  thisOrder,
}) {
  const [radioData, setRadioData] = useState(false);
  const [loader, setLoader] = useState(true);
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const currency = useSelector(({ currency }) => currency);
  const [OrderDetails, setOrderDetails] = useState({});
  const [RefundReasons, setRefundReasons] = useState([]);
  const [fields, setFields] = useState({ reason: null, note: "" });
  const [itemsAmount, setItemsAmount] = useState(0);
  const [collection, setCollection] = useState(0);
  const [itemsChecked, setItemsChecked] = useState([]);
  const [optionsChange, setOptionsChange] = useState([]);

  const history = useHistory();
  const {
    SelectToExchange,
    OrderID,
    RefundMethod,
    RefundSubTotal,
    exchangeRequestDoneSuccessfully,
    returnAmount,
    returnBackAmount,
    exchangeAmount,
    submit,
    SelectToReturn,
    RefundReason,
    myfatoorah,
    Note,
    error,
    mustSelectReason,
    success: successText,
    returnRequestDoneSuccessfully,
    cancel,
    chargedbeforerefund,
    becareful,
    returnConditionsTitle,
    exchangeConditionsTitle,
    returnConditionsText,
    exchangeConditionsText,
  } = i18n;

  useEffect(() => {
    Promise.all([getOrderInvoice(), getRefundReasons()]).then(() => {
      setLoader(false);
    });
  }, [type]);

  // getOrderInvoice start
  const getOrderInvoice = async () => {
    // function to get order invoices from the server and set them on setOrderDetails and get the collection fees
    const { data, success } = await userService.getOrderInvoice(orderId);
    if (!success) return;
    setOrderDetails(data);

    const { data: placeData } = await generalServices.getPlaceDetails(
      data?.order_information?.receiver_details?.place_id
    );

    setCollection(placeData.collection_fees);
  };
  // getOrderInvoice end

  // get Refund Reasons start
  const getRefundReasons = async () => {
    // function to get Refund Reasons from the server and set them on RefundReasons
    const { data, success } = await userService.getRefundReasons();
    if (!success) return;
    setRefundReasons(mapSelectData(data));
    // setLoader(false);
  };
  // get Refund Reasons end

  // onFieldChange function start
  const onFieldChange = async (name, value) => {
    // Field Change handler
    setFields({ ...fields, [name]: value });
  };
  // onFieldChange function end

  // handle Change Checked Items function start
  const handleChangeCheckedItems = (items) => {
    setItemsChecked(items);
    let newItemsAmount = items.reduce((acc, currentValue, index) => {
      return acc + currentValue?.total_price;
    }, 0);
    setItemsAmount(newItemsAmount);
  };
  // handle Change Checked Items function end

  // submit Return Request function start
  const submitReturnRequest = async () => {
    // submit Return Request by calling submitReturnRequest endpoint from the api after Alert
    displayAlert(
      becareful,
      `${chargedbeforerefund} \n${returnConditionsTitle}: ${returnConditionsText}`,
      "warning",
      "",
      null,
      [cancel, submit]
    ).then(async (ok) => {
      if (ok) {
        let orderItems = itemsChecked.map(({ id }) => id);
        if (fields.reason?.value) {
          const { success } = await userService.submitReturnRequest({
            order_items: orderItems,
            order_refund_reason_id: fields.reason.value,
            refund_method: 1,
            notes: fields.note,
          });

          if (!success) return;

          displayAlert(
            successText,
            returnRequestDoneSuccessfully,
            "success"
          ).then(() => {
            history.push("/ReturnRequestsProfilePage");
          });
        } else {
          displayAlert(error, mustSelectReason, "error");
        }
      }
    });
  };
  // submit Return Request function end

  // submit Exchange function start
  const submitExchange = async () => {
    // submit Exchange by calling submitExchange endpoint from the api
    const { data, success } = await userService.submitExchange({
      items: optionsChange,
      notes: "",
      order_id: thisOrder?.id,
    });
    if (!success) return;

    displayAlert(successText, exchangeRequestDoneSuccessfully, "success").then(
      () => {
        window.location.href = apiService.webURL + data.payment_link;
        setOptionsChange([]);
        controlExchangeModal();
      }
    );
  };
  // submit Exchange function end

  // handle Submit start
  const handleSubmit = () => {
    if (type === "return") {
      submitReturnRequest();
    } else {
      submitExchange();
    }
  };
  // handle Submit end

  // change Item Option function start
  const changeItemOption = (optionData) => {
    if (
      optionsChange.findIndex(
        ({ order_item_id }) => +order_item_id === +optionData.item_id
      ) === -1
    ) {
      setOptionsChange([
        ...optionsChange,
        {
          order_item_id: optionData.item_id,
          product_option_id: optionData?.ActiveGroupObj_obj?.id,
        },
      ]);
    } else {
      let newData = optionsChange.filter(
        ({ order_item_id }) => +order_item_id !== +optionData.item_id
      );

      setOptionsChange([
        ...newData,
        {
          order_item_id: optionData.item_id,
          product_option_id: optionData?.ActiveGroupObj_obj?.id,
        },
      ]);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={openExchangeModal}
        onHide={() => {
          setOptionsChange([]);
          controlExchangeModal();
        }}
        aria-labelledby="example-modal-sizes-title-sm"
        className="exchange-modal"
      >
        {/* modal title start  */}
        <Modal.Header
          closeButton
          className="exchange-modal__header border-none"
        >
          <Modal.Title
            id="example-modal-sizes-title-sm"
            className="exchange-modal__header__title text-center w-100"
          >
            {type == "exchange" ? SelectToExchange : SelectToReturn}
            <br /> {OrderID}: #{OrderDetails?.order_information?.id}
          </Modal.Title>
        </Modal.Header>
        {/* modal title end  */}

        <Modal.Body className="exchange-modal__body">
          {/* order products start  */}
          {loader ? (
            <div>
              <Skeleton height={150} />
            </div>
          ) : (
            OrderDetails?.items?.map((item, index) => {
              return (
                <CheckBoxProduct
                  type={type}
                  data={item}
                  key={index}
                  itemsCheckedChange={handleChangeCheckedItems}
                  itemsChecked={itemsChecked}
                  changeItemOption={changeItemOption}
                  optionsChange={optionsChange}
                />
              );
            })
          )}
          {/* order products end  */}

          {/* Collection Fees start  */}
          {type == "exchange" && (
            <div className="exchange-footer">
              <Row className="exchange-footer__row">
                <Col xs={12} md={6}>
                  <div className="exchange-footer__fees d-flex justify-content-between">
                    <p>{exchangeAmount}</p>
                    <p>
                      {currency.currencyName}{" "}
                      {collection * currency.currencyFactor}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {/* Collection Fees end  */}

          {type == "return" && (
            <div className="return-footer">
              <Row className="return-footer__row">
                <Col md={12} lg={6}>
                  <div className="return-footer__form  mt-5">
                    {/* refund reasons start  */}
                    <SelectInput
                      name="reason"
                      placeholder={RefundReason}
                      value={fields.reason}
                      onFieldChange={onFieldChange}
                      data={RefundReasons}
                    />

                    <TextInput
                      placeholder={Note}
                      name="note"
                      value={fields.note}
                      onFieldChange={onFieldChange}
                      type="text"
                      isTextArea
                    />
                  </div>
                </Col>
                <Col md={12} lg={6} className="d-flex justify-content-end">
                  <div className="return-footer__metods">
                    <header className="return-footer__metods__header">
                      {RefundMethod}
                    </header>
                    <div
                      className={`d-flex justify-contet-between subscription__questions mt-3 ${
                        language.langCode === "rtl" &&
                        "subscription__questions__arabic"
                      }`}
                    >
                      <div className="agree-check-container agree-signup w-100">
                        <label
                          className="agree-check-container  checkbox-container mt-0 ps-4"
                          onClick={() => setRadioData(!radioData)}
                        >
                          <p className="d-flex justify-content-center align-items-center">
                            {myfatoorah}
                          </p>
                          <div className={`div-radio ${"div-radio-active"}`}>
                            <div></div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="return-footer__statistics mt-1">
                      <div className="return-footer__statistics__field border-bot d-flex justify-content-between p-3 ps-0 pe-0">
                        <p className="m-0">{RefundSubTotal}</p>
                        <p className="m-0">
                          {currency.currencyName}{" "}
                          {itemsAmount * currency.currencyFactor}
                        </p>
                      </div>

                      <div className="return-footer__statistics__field border-bot d-flex justify-content-between p-3 ps-0 pe-0">
                        <p className="m-0">{returnAmount}</p>
                        <p className="m-0">
                          {currency.currencyName}{" "}
                          {collection * currency.currencyFactor}
                        </p>
                      </div>

                      <div className="return-footer__statistics__field border-bot d-flex justify-content-between p-3 ps-0 pe-0">
                        <p className="m-0">{returnBackAmount}</p>
                        <p className="m-0">
                          {currency.currencyName}{" "}
                          {(itemsAmount - collection) * currency.currencyFactor}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="exchange-modal__footer border-none w-100">
          <div className="exchange-modal__footer__submit">
            {type == "exchange" && (
              <>
                <p>{exchangeConditionsTitle}</p>
                <p>{exchangeConditionsText}</p>
              </>
            )}

            {/* submit button start  */}
            <button
              className="exchange-modal__footer__submit__btn"
              onClick={handleSubmit}
            >
              {submit}
            </button>
            {/* submit button end  */}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExchangeModal;
