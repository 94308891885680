import { createSlice } from "@reduxjs/toolkit";

const countrySlice = createSlice({
    name: "country",
    initialState: { id: 1, language: [], name: "" },

    reducers: {
        setCountry: (country, { payload }) => {
            Object.assign(country, payload);
        },
    },
});

export const { setCountry } = countrySlice.actions;

export default countrySlice.reducer;
