import React, { Component } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { authService } from "../../../services/AuthService";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import { Form } from "react-bootstrap";
import PageTitle from "../../blocks/PageTitle";
import LoginModal from "../../blocks/LoginModal";
import { generalServices } from "../../../services/GeneralService";
import PhoneInput from "../../blocks/PhoneInput";

class SignUp extends Component {
  state = {
    fields: {
      name: "",
      phone: "",
      password_confirmation: "",
      password: "",
      email: "",
      terms_conditions: false,
    },
    errors: {
      name: "",
      phone: "",
      password_confirmation: "",
      password: "",
      email: "",
      terms_conditions: "",
    },
    loginShow: false,
    termsChecked: false,
    termsCheckedError: false,
    agreeCond: false,
    agreeError: false,
    countries: [],
    selectedCountry: {},
    submitLoader: false,
    googleLoginBtnLoad: false,
  };

  responseFacebook = async (response) => {
    // response Facebook for login with Facebook
    const { error: errorTag } = this.props.i18n;
    this.setState({
      fields: {
        email: response.email ?? "",
        name: response.name ?? "",
        phone: response.phone ?? "",
        password_confirmation: "",
        password: "",
        terms_conditions: false,
      },
    });

    const { success, data, message, errors } = await authService.socialLogin({
      phone: "",
      place_id: this.props.country.id,
      email: response.email ?? "",
      name: response.name ?? "",
      need_to_email_verification: false,
      password: "",
      social_image: response.image ?? "",
      social_provider_id: response.id ?? "",
      social_provider: "facebook",
    });

    if (!success) {
      if (errors) {
        this.props.setUser({
          socialMedia: {
            email: response?.email ?? "",
            name: response?.name ?? "",
            phone: response?.phone ?? "",
            password_confirmation: "",
            password: "",
            terms_conditions: false,
            social_image: response?.image ?? "",
            social_provider_id: response?.id ?? "",
            social_provider: "facebook",
          },
        });
        setTimeout(() => {
          window.location.href = "/SocialLogin";
        }, 0);
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }

    apiService.storeToken(data.access_token);
    this.props.setUser({ ...data.user });
    window.location.href = "/";
  };

  responseGoogle = async (response) => {
    // response Google for login with Google
    const { error: errorTag } = this.props.i18n;

    this.setState({
      fields: {
        email: response.profileObj.email ?? "",
        name: response.profileObj.name ?? "",
        phone: response.profileObj.phone ?? "",
        password_confirmation: "",
        password: "",
        terms_conditions: false,
      },
    });

    const { success, data, message, errors } = await authService.socialLogin({
      phone: "",
      password: "",
      place_id: this.props.country.id,
      email: response.profileObj.email ?? "",
      name: response.profileObj.name ?? "",
      need_to_email_verification: false,
      social_image: response.profileObj.image ?? "",
      social_provider_id: response.profileObj.googleId ?? "",
      social_provider: "google",
    });

    if (!success) {
      if (errors) {
        this.props.setUser({
          socialMedia: {
            email: response?.profileObj?.email ?? "",
            name: response?.profileObj?.name ?? "",
            phone: response?.profileObj?.phone ?? "",
            password_confirmation: "",
            password: "",
            terms_conditions: false,
            social_image: response?.profileObj?.image ?? "",
            social_provider_id: response.profileObj.googleId ?? "",
            social_provider: "google",
          },
        });

        setTimeout(() => {
          window.location.href = "/SocialLogin";
        }, 0);
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }
    apiService.storeToken(data.access_token);
    this.props.setUser({ ...data.user });
    window.location.href = "/";
  };

  getCountries = async () => {
    // function to get countries from the server and set it and the first one to the current state
    const { data, success } = await generalServices.getAllCountries(0);
    if (!success) return;
    this.setState({
      countries: data,
      selectedCountry: data[0],
    });
  };

  componentDidMount() {
    if (this.props.currentUser?.email && this.props.currentUser?.email !== "")
      window.location.href = "/";

    this.getCountries();

    setTimeout(() => {
      this.setState({
        googleLoginBtnLoad: true,
      });
    }, 1000);
  }

  render() {
    const closeModal = () => {
      this.setState({
        loginShow: false,
      });
    };
    const {
      language,
      i18n: {
        Home,
        PasswordPlace,
        PleaseAgreeOurTermsAndConditionBefore,
        Mobile,
        Password,
        ConfirmPassword,
        ConfirmPasswordPlace,
        FullName,
        EnterFullName,
        EnterEmail,
        EmailID,
        EnterMobile,
        Register,
        SiginInHere,
        signupTitle,
        socialRegisterText,
        PasswordConst,
        AgreeTerms,
        Facebook,
        Google,
      },
    } = this.props;
    const { errors, fields, countries, submitLoader } = this.state;

    return (
      <>
        <LoginModal loginShow={this.state.loginShow} closeModal={closeModal} />
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={Register}
          links={[
            { name: Home, url: "/" },
            { name: Register, url: "/signup" },
          ]}
        />

        <Container>
          <div
            className={`signup-login p-3 ${
              language === "rtl" && "signup-login-arabic"
            }`}
          >
            <div className="signup-login__form-container shadow mt-5 mb-5">
              <Row>
                <Col md={12}>
                  <p className="header-paragraph p-3">
                    {signupTitle}{" "}
                    <a
                      onClick={() =>
                        this.setState({
                          loginShow: true,
                        })
                      }
                      className="sign-up-login__switcher"
                    >
                      {SiginInHere}
                    </a>
                  </p>
                </Col>
                <Col sm={12} md={6} className="mt-3 ps-4 pe-4">
                  <Form.Label>
                    {FullName} <span className="red-astrick">*</span>
                  </Form.Label>
                  <Form.Group className="mt-3 signup-login__form-container__field">
                    <TextInput
                      placeholder={EnterFullName}
                      name="name"
                      value={fields.name}
                      onFieldChange={this.onFieldChange}
                      validate={errors.name}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="mt-3 ps-4 pe-4">
                  <Form.Label>
                    {EmailID} <span className="red-astrick">*</span>
                  </Form.Label>
                  <Form.Group className="mt-3 signup-login__form-container__field">
                    <TextInput
                      placeholder={EnterEmail}
                      name="email"
                      value={fields.email}
                      onFieldChange={this.onFieldChange}
                      validate={errors.email}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="mt-3 ps-4 pe-4">
                  <Form.Label>
                    {Mobile} <span className="red-astrick">*</span>
                  </Form.Label>
                  <Form.Group className="phone-number d-flex">
                    <InputGroup.Text className="phone-zipcode register-phone-zipCode text-center">
                      <select
                        onChange={({ target }) => {
                          this.setState({
                            selectedCountry: JSON.parse(target.value),
                          });
                        }}
                      >
                        {countries.map((item) => {
                          if (
                            JSON.stringify(item) ===
                            JSON.stringify(this.state.selectedCountry)
                          ) {
                            return (
                              <option
                                key={item.id}
                                value={JSON.stringify(item)}
                                selected
                                hidden
                              >
                                {item.zipcode}
                              </option>
                            );
                          }
                          return (
                            <option key={item.id} value={JSON.stringify(item)}>
                              {item.name} ({item.zipcode})
                            </option>
                          );
                        })}
                      </select>
                    </InputGroup.Text>

                    <PhoneInput
                      type="number"
                      placeholder={EnterMobile}
                      name="phone"
                      onFieldChange={this.onFieldChange}
                      value={fields.phone}
                      className="signup-login__form-container__field phone_field"
                      digits={this.state.selectedCountry.digits}
                    />
                  </Form.Group>
                  <small
                    style={{
                      fontSize: "0.875em",
                      color: "red",
                      letterSpacing: "0px",
                    }}
                  >
                    {errors.phone}
                  </small>
                </Col>
                <Col sm={12} md={6} className="mt-3 ps-4 pe-4">
                  <Form.Label>
                    {Password} <span className="red-astrick">*</span>
                  </Form.Label>
                  <Form.Group className="mt-3 signup-login__form-container__field">
                    <TextInput
                      placeholder={PasswordPlace}
                      name="password"
                      value={fields.password}
                      type="password"
                      onFieldChange={this.onFieldChange}
                      validate={errors.password}
                      withImg
                    />
                  </Form.Group>
                  <Form.Text
                    className="text-muted mt-3"
                    style={{ color: "#000 !important" }}
                  >
                    {PasswordConst}
                  </Form.Text>
                </Col>
                <Col sm={12} md={6} className="mt-2 ps-4 pe-4">
                  <Form.Label>
                    {ConfirmPassword} <span className="red-astrick">*</span>
                  </Form.Label>
                  <Form.Group className="mt-3 signup-login__form-container__field">
                    <TextInput
                      placeholder={ConfirmPasswordPlace}
                      name="password_confirmation"
                      value={fields.password_confirmation}
                      type="password"
                      onFieldChange={this.onFieldChange}
                      validate={errors.password_confirmation}
                      withImg
                    />
                  </Form.Group>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="mt-3 ps-4 pe-4 d-flex align-items-center"
                >
                  <div className="agree-signup">
                    <label className="checkbox-container">
                      <p className="d-flex justify-content-center align-items-center">
                        {AgreeTerms}
                      </p>

                      <input
                        type="checkbox"
                        className={`${
                          this.state.termsCheckedError && "false-checkbox"
                        } `}
                        onChange={() => {
                          this.setState({
                            termsChecked: !this.state.termsChecked,
                            agreeError: false,
                          });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {this.state.agreeError && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginTop: "-22px",
                          display: "block",
                        }}
                      >
                        {PleaseAgreeOurTermsAndConditionBefore}
                      </span>
                    )}
                  </div>
                </Col>
                <Col sm={12} md={6} className="ps-4 pe-4 mt-3">
                  <button
                    className="submit-btn mt-3 p-3"
                    onClick={() => {
                      if (this.state.termsChecked) {
                        this.submit();
                      } else {
                        this.setState({
                          agreeError: true,
                        });
                      }
                    }}
                  >
                    {submitLoader ? (
                      <div className="button">
                        <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    ) : (
                      <>{Register}</>
                    )}
                  </button>
                </Col>
                <Col md={12} className="mt-5">
                  <Row className="align-items-center">
                    <Col sm={12} md={12} lg={6}>
                      <p className="m-0 ps-3 header-paragraph">
                        {socialRegisterText}
                      </p>
                    </Col>
                    <Col sm={12} md={12} lg={6} className="social-col">
                      <div className="social-btns d-flex mt-3 social-btns-signUp">
                        <div
                          className=""
                          style={{
                            position: "relative",
                          }}
                        >
                          <label
                            for="fb"
                            style={{
                              display: "block",
                              position: "absolute",
                              top: "0",
                              marginTop: "-20px",
                            }}
                            className="mb-3"
                          >
                            <button
                              className="p-3 w-100 d-flex align-items-center social-btn"
                              id="facebook"
                              htmlFor="fb"
                            >
                              <img src="/images/Path 1.png" />
                              <p className="m-0 w-100">{Facebook}</p>
                            </button>
                          </label>
                          <FacebookLogin
                            appId="1004135036854983"
                            autoLoad={false}
                            fields="id,name,email,picture"
                            callback={this.responseFacebook}
                            onFailure={(error) => {}}
                            cssclassName=""
                            isMobile={false}
                            icon="fa fa-facebook-f fa-2x"
                            textButton=""
                            className="socialBtn fb-btn p-0"
                            id="fb"
                          />
                        </div>
                        <div
                          className=""
                          style={{
                            position: "relative",
                          }}
                        >
                          <div className="mb-3">
                            <GoogleLogin
                              clientId="751367658206-iv22mmduam9na7qs1u6q7viqctlabdf3.apps.googleusercontent.com"
                              buttonText={``}
                              onSuccess={this.responseGoogle}
                              onFailure={(error) => {}}
                              disabled={false}
                              render={(propsGoogle) => {
                                // if (
                                //   propsGoogle.disabled === true &&
                                //   this.state.googleLoginBtnLoad
                                // ) {
                                //   window.location.reload();
                                // }
                                return (
                                  <label
                                    for="google"
                                    style={{
                                      display: "block",

                                      marginTop: "-20px",
                                    }}
                                    className="gl-label login-google-label"
                                  >
                                    <button
                                      className="p-3 w-100 d-flex align-items-center social-btn"
                                      id="google"
                                      onClick={propsGoogle.onClick}
                                      disabled={propsGoogle.disabled}
                                    >
                                      <img src="/images/google_plus.png" />
                                      <p className="m-0 w-100">{Google}</p>
                                    </button>
                                  </label>
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }

  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        name: "",
        mobile: "",
        password_confirmation: "",
        password: "",
        email: "",
      },
    });
  };

  submit = async () => {
    // sign up api request ( create a new user request ) in ok go to the home page
    this.setState({ submitLoader: true });
    const { fields } = this.state;
    const { country } = this.props;
    const { success, message, errors } = await authService.SignUp({
      password_confirmation: fields.password_confirmation,
      place_id: country?.language[0]?.id,
      password: fields.password,
      email: fields.email,
      phone: fields.phone,
      name: `${fields.name}`,
      terms_conditions: fields.terms_conditions,
      need_to_email_verification: true,
    });
    const { error: errorTag } = this.props.i18n;
    this.setState({ submitLoader: false });

    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert(errorTag, message, "error");
      }
    }

    displayAlert("Done", message, "success").then(
      () => (window.location.href = "/")
    );
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(SignUp);
