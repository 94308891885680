import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import Product from "../../blocks/Product";
import { generalServices } from "../../../services/GeneralService";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../../utils/misc";
import PaginatedItems from "../../Pages/Search/PaginationBlack";
import { withRouter } from "react-router-dom";
class Search extends Component {
  state = {
    products: [],
    pageLoader: true,
    meta: {},
  };

  componentDidMount() {
    this.search();
  }
  search = async () => {
    // request product data with search parameters  
    const { success, data, meta } = await generalServices.search(
      this.props.match.params.key,
      this.props.match.params.catId,
      this.props.match.params.page
    );
    if (!success) return;
    this.setState({
      products: data,
      meta,
      pageLoader: false,
    });
  };
  render() {
    const {
      language,
      i18n: { SearchResultsby, noSearchResult },
    } = this.props;
    const { products, pageLoader, meta } = this.state;

    return (
      <Container>
        <div className={`search  ${language === "rtl" && "search-arabic"}`}>
          {/* search key start  */}
          <h2 className="search-title">
            {SearchResultsby} :{" "}
            <span className="search-title__Word">
              `{this.props.match.params.key}`
            </span>
          </h2>
          {/* search key end  */}

          {/* search products start  */}
          {pageLoader ? (
            <Row className="mb-4">
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
              <Col md={4}>
                <Skeleton height={tabletScreen ? 100 : 200} />
              </Col>
            </Row>
          ) : (
            <Row>
              {products.length < 1 ? (
                <div className="empty-products">
                  <img src="/img/searchEmpty.svg" alt="k" />
                  <p>{noSearchResult}</p>{" "}
                </div>
              ) : (
                products?.map((item) => {
                  return (
                    <Col lg={3} md={4} xs={6} className="mb-4">
                      <Product // Product card that takes product as data and show product as a card with image
                        data={item}
                        reFetchData={() => this.search()}
                      />
                    </Col>
                  );
                })
              )}
            </Row>
          )}
          {/* search products end  */}

          {/* Pagination Start */}
          {products.length >= 1 && meta?.last_page > 1 ? (
            <Row>
              <Col md={12}>
                <div className="Pagination">
                  <PaginatedItems // pagination component that handle the pages
                    items={[...Array(meta?.total).keys()]}
                    itemsPerPage={meta?.per_page}
                    changePage={(page) => this.changePage(page)}
                  />
                </div>
              </Col>
            </Row>
          ) : null}
          {/* Pagination End */}

        </div>
      </Container>
    );
  }

  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(
      `/Search/${this.props.match.params.key}/${
        this.props.match.params.catId
      }/${page + 1}`
    );
    this.setState(
      {
        pageLoader: true,
      },
      () => {
        this.search();
      }
    );
  }
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(withRouter(Search));
