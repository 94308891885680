import React from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

function PaginationBlack({
  changePage,
  page,
  last_page,
}) {
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const { next, previous } = i18n;


  const handlePageClick = (event) => {
    changePage(event.selected);
  };

  return (
    <>
      <ReactPaginate
        nextLabel={<div> {next + " >"}</div>}
        onPageChange={handlePageClick}
        pageCount={last_page}
        previousLabel={<div> {"< " + previous} </div>}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageActive={(e) => {}}
        forcePage={page - 1}
      />
    </>
  );
}

export default PaginationBlack;
