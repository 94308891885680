import React, { Component } from "react";
import { Container, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import { userService } from "../../../../services/UserService";
import { withRouter } from "react-router-dom";
import GiftCardProfileTable from "./GiftCardProfileTable";
import { generalServices } from "../../../../services/GeneralService";

class GiftCardProfile extends Component {
  state = {
    StatusCase: this.props.i18n.All,
    color: "",
    orders: [],
    fields: {
      to: "",
      from: "",
      key: "",
      status: "",
    },
    currentPage: 1,
    loader: true,
    meta: {},
    giftCards: [],
  };

  componentDidMount() {
    this.getGiftCardsOrders();
  }

  // get gift cards start
  getGiftCards = async () => {
    // function to get Gift Cards by call it from the server and set it to the current state

    const { data, success, meta } = await generalServices.getGiftCards(1);
    if (!success) return;
    this.setState({
      giftCards: data,

      pageLoader: false,
      meta: meta,
      loader: false,
    });
  };
  // get gift cards end

  // get gift cards orders start

  getGiftCardsOrders = async () => {
    // function to get Gift Cards orderss by call it from the server and set it to the current state

    const { data, success } = await userService.getGiftCardsOrders();
    if (!success) return;

    this.setState({
      giftCards: data,
      pageLoader: false,
      loader: false,
    });
  };
  // get gift cards orders end

  render() {
    const {
      language,
      i18n: { giftCardEmpty },
    } = this.props;
    const { giftCards, meta } = this.state;
    return (
      <Container>
        <div className={`orders ${language === "rtl" && "orders-arabic"}`}>
          {/* Order start */}
          {this.state.loader ? (
            <>
              <Skeleton height={400} className="mt-4" />
            </>
          ) : giftCards.length > 0 ? (
            <GiftCardProfileTable
              data={giftCards}
              isActive={true}
              meta={meta}
              getUserOrders={() => this.getGiftCards()}
            />
          ) : (
            <Col md={12}>
              <div className="empty-products">
                <img src="/img/giftEmpty.svg" alt="k" />
                <p> {giftCardEmpty} </p>
              </div>
            </Col>
          )}
          {/* Order End */}
        </div>

        {/* Pagination Start */}

        {/* Pagination End */}
      </Container>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(
  withRouter(GiftCardProfile)
);
