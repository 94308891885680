import React, { Component } from "react";
import CategoriesBlock from "../../blocks/CategoriesBlock";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import { setUser } from "../../../redux/actions-reducers/user";
import { homeService } from "../../../services/homeService";
import { generalServices } from "../../../services/GeneralService";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import ProductCarousel from "../../blocks/ProductCarousel";
import HomeCarousel from "./HomeCarousel";
import Boxes from "../../blocks/Boxes";
import { connect } from "react-redux";
import FirstTimeModal from "../../blocks/FirstTimeModal";
import MainLoader from "../../blocks/MainLoader";

class Home extends Component {
  state = {
    categories: [],
    daily_deals: [],
    new_products: [],
    sub_categories: [],
    trending_products: [],
    featured_products: [],
    adsData: [],
    images: [],
    sliders: [],
    adsLoader: true,
    girlsCategories: [],
    categoriesWithProducts: [],
    categoriesLoader: true,
    sliderLoader: true,
    mysteryBoxes: {},
    showLogin: true,
  };

  componentDidMount() {
    window.location.href = "/productsCategory/All_Products/1";
  }

  // get Home TDR Deals start
  getHomeTDRDeals = async (type) => {
    const { data, success } = await homeService.getHomeTDRDeals(type);
    let name =
      type === 1
        ? "trending_products"
        : type === 4
        ? "new_products"
        : type === 5
        ? "featured_products"
        : "daily_deals";
    if (!success) return;
    this.setState({
      [name]: data,
    });
  };
  // get Home TDR Deals end

  // get Categories start
  getCategories = async (parentId, isFeatured, topBar, showBrands, showSub) => {
    // function to get categories from the server and set it to current state base isTopBar select data name

    const { data, success } = await generalServices.getCategories(
      parentId,
      isFeatured,
      topBar,
      showBrands,
      showSub,
      this.props.country.id
    );

    if (!success) return;
    let name = isFeatured === 0 ? "categories" : "girlsCategories";

    this.setState({
      [name]: data,
      categoriesLoader: false,
    });
  };
  // get Categories end

  // get Slider start
  getSlider = async () => {
    const { data, success } = await generalServices.getSlider(0);

    if (!success) return;
    this.setState({
      images: data.map(({ image }) => apiService.imageLink + image),
      sliders: data,
      sliderLoader: false,
    });
  };
  // get Slider end

  // get Home Categories With Products start
  getHomeCategoriesWithProducts = async () => {
    const { data, success } = await homeService.getHomeCategoriesWithProducts();

    if (!success) return;
    this.setState({
      categoriesWithProducts: data,
    });
  };
  // get Home Categories With Products end

  // get Mystery Boxes start
  getMysteryBoxes = async () => {
    const { data, success } = await generalServices.getMysteryBoxes();

    if (!success) return;
    this.setState({
      mysteryBoxes: data,
    });
  };
  // get Mystery Boxes end

  // get Home Ads start
  getHomeAds = async () => {
    const { data, success } = await generalServices.getHomeAds(
      this.props.country.id
    );

    if (!success) return;
    this.setState({
      adsData: data,
      adsLoader: false,
    });
  };
  // get Home Ads end

  closeModal() {
    this.setState({
      showLogin: false,
    });
    this.props.setUser({ firstTime: false });
  }

  render() {
    return <MainLoader />;
    const {
      currentUser,
      i18n: {
        THELATEST,
        WINTERMYSTERYBOX,
        featuredCollections,
        TRENDINGPRODUCTS,
        NEWPRODUCTS,
        MYSTERYBOX,
      },
    } = this.props;

    const {
      showLogin,
      mysteryBoxes,
      sliderLoader,
      new_products,
      featured_products,
      trending_products,
      sliders,
    } = this.state;

    return (
      <div className="home">
        {currentUser?.firstTime && (
          <FirstTimeModal // modal that show login form or notify form
            setShowLoginModal={this.props.setShowLoginModal}
            loginShow={showLogin}
            closeModal={() => this.closeModal()}
          />
        )}

        <HomeCarousel sliders={sliders} sliderLoader={sliderLoader} />
        {/* slider end  */}

        {/* new products  start  */}
        <CategoriesBlock
          labelTitle={THELATEST}
          title={NEWPRODUCTS}
          data={new_products.filter(({ option }) => option.qty > 0)}
          reFetchData={() => this.getHomeTDRDeals(4)}
        />
        {/* new products  end  */}

        {/* featured Collections  start  */}
        <ProductCarousel
          labelTitle={THELATEST}
          title={featuredCollections}
          data={featured_products.filter(({ option }) => option.qty > 0)}
          reFetchData={() => this.getHomeTDRDeals(5)}
          rowCount={2}
          viewAllLink="/FeaturedCollections"
        />
        {/* featured Collections  end  */}

        {/* trending products  start  */}
        <CategoriesBlock
          labelTitle={""}
          title={TRENDINGPRODUCTS}
          data={trending_products.filter(({ option }) => option.qty > 0)}
          reFetchData={() => this.getHomeTDRDeals(4)}
        />
        {/* trending products  end  */}

        {/* mystery boxes start  */}
        {mysteryBoxes?.latest_boxes?.length > 0 && (
          <Boxes
            labelTitle={MYSTERYBOX}
            title={WINTERMYSTERYBOX}
            data={mysteryBoxes}
          />
        )}
        {/* mystery boxes end  */}

        {/* just for you section start  */}
        {/*apiService.accessToken === 0 && <JustForYou />*/}
        {/* just for you section end  */}

        {/* private collection section start  */}
        {/*apiService.accessToken !== 0 && <PrivateCollectionsBlock />*/}
        {/* private collection section end  */}

        {/* ads section start  */}
        {/* <AdsBlock /> */}
        {/* ads section end  */}
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setLanguage, setUser })(Home);
