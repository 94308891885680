import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ErrorMessage } from "./ErrorMessage";
import Label from "./Label";

const PhoneInput = ({
  label,
  placeholder,
  tooltip,
  validate,
  maxLength,
  value,
  name,
  onFieldChange,
  isRequired,
  disabled = false,
  max,
  min,
  digits = 10,
  zipCode,
  type,
}) => {
  const language = useSelector(({ language }) => language);
  const onChange = ({ target: { name, value } }) => {
    onFieldChange(name, value);
  };
  return (
    <div
      className={`text-input-block phone-input ${
        language === "rtl" && "phone-input-arabic"
      }`}
    >
      {label && (
        <Label label={label} tooltip={tooltip} isRequired={isRequired} />
      )}

      <InputGroup className="">
        {/* zip code start  */}
        {zipCode && (
          <div className="phone-input__content__country--code">
            {zipCode ?? "+962"}
          </div>
        )}
        {/* zip code end  */}

        {/* phone input start  */}
        <FormControl
          className="phone-input__content__container__field"
          name={name}
          placeholder={placeholder ?? label}
          value={value}
          max={max ?? null}
          min={min ?? null}
          maxLength={maxLength}
          onChange={onChange}
          aria-label="Username"
          aria-describedby="basic-addon1"
          type={type}
          disabled={disabled}
          onInput={({ target }) =>
            (target.value = Math.max(0, parseInt(target.value))
              .toString()
              .slice(0, digits))
          }
        />
        {/* phone input end  */}
      </InputGroup>
      {validate && <ErrorMessage message={validate} />}
    </div>
  );
};

export default PhoneInput;
