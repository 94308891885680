import React from "react";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

const PaymentSuccess = () => {
  const languageCode = useSelector((state) => state.language.langCode);
  const i18n = languageCode === "rtl" ? Global.ar : Global.en;

  const orderReferenceFromQuery = new URLSearchParams(
    window.location.search
  ).get("order_reference");

  return (
    <div className="bg-white vh-100">
      <div className="h-100 d-flex justify-content-center align-items-center flex-column">
        <div>
          <img
            src="/images/payment-status.svg"
            alt="payment-status"
            style={{
              maxWidth: "100%",
              height: "auto",
              padding: "0 10px",
            }}
          />
        </div>
        <div className="text-center mt-5">
          <h1 className="text-black fw-bold">{i18n.thankYou}</h1>
          <p className="text-black fw-medium">{i18n.paymentDoneSuccessfully}</p>
          {orderReferenceFromQuery ? (
            <button
              className="submit-btn text-capitalize rounded-1 py-2 px-3 mt-2"
              onClick={() => {
                window.location.href =
                  "/orderinvoice/" + orderReferenceFromQuery;
              }}
            >
              {i18n.viewOrderDetails}
            </button>
          ) : (
            <button
              className="submit-btn text-capitalize rounded-1 py-2 px-3 mt-2"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              {i18n.backToHome}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
