import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )

function BoxCard({ data }) {
  let windowSize500 = window.innerWidth < 500;
  const currency = useSelector(({ currency }) => currency);
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const { SHOPNOW } = i18n;

  return (
    <div
      className="box-card"
      style={{
        backgroundImage: `url(${apiService.imageLink + data?.main_image})`,
        backgroundPosition: windowSize500 ? "center 0" : "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="box-card__contents">
        {/* box title start  */}
        <h2>{data?.title}</h2>
        {/* box title end  */}

        {/* box price start  */}
        <h5>
          {currency.currencyName}{" "}
          {(
            +data?.option?.price_after_discount * currency.currencyFactor
          )?.toFixed(2)}{" "}
        </h5>
        {/* box price end  */}
      </div>
      {/* box subtitle and shop Button start  */}
      <div className="box-card__overlay">
        <p>{data?.subtitle}</p>
        {data.bundle ? (
          <Link to={`/ProductDetailsBundle/${data?.machine_name}`}>
            {SHOPNOW}
          </Link>
        ) : (
          <Link to={`/productDetails/${data?.machine_name}`}>{SHOPNOW}</Link>
        )}
      </div>
      {/* box subtitle and shop Button end  */}
    </div>
  );
}

export default BoxCard;
