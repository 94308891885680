import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: { path: "" },

  reducers: {
    setPath: (state, { payload }) => {
      Object.assign(state, { path: payload });
    },
  },
});

export const { setPath } = appSlice.actions;

export default appSlice.reducer;
