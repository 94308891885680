import React, { useState } from "react";
import { useSelector } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import moment from "moment";
import { useHistory } from "react-router-dom";

function SubscriptionsProfileTable({ historyOrders, activeOrders }) {
  const language = useSelector(({ language }) => language);

  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const { Status, Invoice, ACTIVE, HISTORY, ID, DateTime, Price } = i18n;
  const [IsActive, setIsActive] = useState(true);
  const country = useSelector(({ country }) => country);
  const history = useHistory();

  const renderOrders = () => {
    let dataReturn = IsActive ? activeOrders : historyOrders;

    return dataReturn?.map(
      ({
        id,
        created_at,
        statusText,
        total_amount,
        statusColor,
        order_reference,
      }) => {
        return (
          <tr className="order-table__item">
            <td>#{id}</td>
            <td>{moment(created_at).format("yy-MM-DD")}</td>
            <td style={{ color: statusColor }}>
              {language.langCode === "ltr"
                ? statusText
                : statusText == "Pending"
                ? "قيد الانتظار"
                : statusText == "Cacnelled"
                ? "ملغي"
                : statusText == "Accepted"
                ? "تم القبول"
                : statusText == "On the Way"
                ? "في الطريق"
                : statusText == "Arrived"
                ? "تم التوصيل"
                : statusText == "Completed"
                ? "مكتمل"
                : null}
            </td>
            <td>
              {total_amount} {country.currency}
            </td>
            <td className="order-table__buttons">
              <button
                className="submit-btn"
                onClick={() =>
                  history.push(`/subscriptionInvoice/${order_reference}`)
                }
              >
                {Invoice}
              </button>
            </td>
          </tr>
        );
      }
    );
  };

  return (
    <>
      <div className="order-tabs flex">
        <p
          className={IsActive && "order-tab-active"}
          onClick={() => setIsActive(true)}
        >
          {ACTIVE}
        </p>
        <p
          className={!IsActive && "order-tab-active"}
          onClick={() => setIsActive(false)}
        >
          {HISTORY}
        </p>
      </div>

      <div className="order-table">
        <table>
          <tr className="table-header">
            <th>{ID}</th>
            <th>{DateTime}</th>
            <th>{Status}</th>
            <th>{Price}</th>
            <th></th>
          </tr>

          {renderOrders()}
        </table>
      </div>
    </>
  );
}

export default SubscriptionsProfileTable;
