import { combineReducers } from "redux";
import languageReducer from "./actions-reducers/language";
import currentUserReducer from "./actions-reducers/user";
import countryReducer from "./actions-reducers/country";
import currencyReducer from "./actions-reducers/currency";
import appReducer from "./actions-reducers/app";

const rootReducer = combineReducers({
  language: languageReducer,
  currentUser: currentUserReducer,
  country: countryReducer,
  currency: currencyReducer,
  app: appReducer,
});

export default rootReducer;
