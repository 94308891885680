import React from "react";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import SelectInput from "../../blocks/SelectInput";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { useSelector } from "react-redux";

export default function DeliveryTypes({
  place,
  deliveryCompanySet,
  setDeliveryTypeName,
}) {
  const [deliveryTypes, setDeliveryTypes] = React.useState([]);
  const [deliveryType, setDeliveryType] = React.useState([]);
  const langCode = useSelector(({ language }) => language.langCode);

  const getDeliveryCompanies = React.useCallback(async () => {
    // send api request to call Delivery Companies By Place ID and set the data to setDeliveryTypes if the data length is 1 deliveryCompanySet setDeliveryType for the data first ( select it without the user chose )
    try {
      apiService
        .unauthenticated()
        .get(`getDeliveryCompaniesByPlaceID?place_id=${place}`)
        .then(({ data }) => {
          setDeliveryTypes(data?.data);
          if (data?.data.length == 1) {
            setTimeout(() => {
              deliveryCompanySet(data.data[0].id);
              setDeliveryType(data.data[0].id);
            }, 1000);
          }
        });
    } catch ({ response }) {}
  }, [place, deliveryCompanySet]);

  React.useEffect(() => {
    getDeliveryCompanies();
  }, [getDeliveryCompanies]);

  return (
    <div>
      {deliveryTypes?.length > 0 && (
        <div>
          <SelectInput
            name={
              deliveryTypes.filter(({ value }) => value == deliveryType).label
            }
            label={
              Global[langCode === "ltr" ? "en" : "ar"]?.selectDeliveryCompany
            }
            placeholder={
              Global[langCode === "ltr" ? "en" : "ar"]?.selectDeliveryCompany
            }
            value={
              deliveryTypes.filter(({ value }) => value == deliveryType).value
            }
            onFieldChange={(name, value) => {
              deliveryCompanySet(value?.value);
              setDeliveryType(value?.value);
              setDeliveryTypeName(value?.label);
            }}
            data={deliveryTypes.map(({ name, id }) => ({
              value: id,
              label: name,
            }))}
          />
        </div>
      )}
    </div>
  );
}
