import React from "react";
import { useSelector } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import moment from "moment";
import { useHistory } from "react-router-dom";

function RewardsTable({ data }) {
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const history = useHistory();
  const {
    FreeProduct,
    discount,
    Rewards,
    ShopNow,
    ExpiresIn,
    day,
    FREEDELIVERY,
    PromoCode,
  } = i18n;

  return (
    <>
      <div className="rewards-title">{Rewards}</div>
      <div className="rewards-table">
        <table>
          {data?.map(({ id, validation_date, coupon, product }) => {
            return (
              <tr className="rewards-table__item">
                {/* discount or free delivered or free product  */}
                <td>
                  <span>
                    {coupon
                      ? coupon?.by_delivery && coupon?.percentage === 100
                        ? FREEDELIVERY
                        : discount
                      : FreeProduct}
                    !
                  </span>{" "}
                  {coupon ? (
                    <>
                      ({PromoCode}:{" "}
                      <span className="FREEDEL">{coupon?.code}</span>)
                    </>
                  ) : (
                    <>
                      (<span className="FREEDEL">{product?.title}</span>)
                    </>
                  )}
                </td>
                {/* discount or free delivered or free product  */}

                {/* Expire Date start   */}
                <td>
                  {ExpiresIn}{" "}
                  <span>
                    {" "}
                    {Math?.ceil(
                      moment
                        ?.duration(
                          moment(validation_date)?.diff(moment(new Date()))
                        )
                        ?.asDays()
                    )}{" "}
                    {day}
                  </span>
                </td>
                {/* Expire Date end   */}

                {/* shop now button start  */}
                <td className="order-table__buttons">
                  <button
                    className="submit-btn"
                    onClick={() => {
                      if (coupon) {
                        history.push("/");
                      } else {
                        history.push(
                          "/productDetails/" + product?.machine_name
                        );
                      }
                    }}
                  >
                    {ShopNow}
                  </button>
                </td>
                {/* shop now button start  */}
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
}

export default RewardsTable;
