import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BoxCard from "./BoxCard";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { Link } from "react-router-dom";

class Boxes extends Component {
  state = {
    responsive: {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 500, min: 0 },
        items: 1,
      },
    },
  };

  render() {
    const { language, data = [] } = this.props;
    const { responsive } = this.state;
    const { VIEWALL } = this.props.i18n;

    return (
      <div className={`boxes  ${language === "rtl" && "boxes-arabic"}`}>
        <Container>
          {/* mystery boxes title start */}
          <div className="categories-block__header">
            <h3>{data?.mystery_box_info?.name}</h3>
          </div>
          {/* mystery boxes title end */}

          <Carousel // slider components
            responsive={responsive}
            isRTL={language === "rtl" && true}
            itemClass="carousel-item-margin-25-px"
            showDots={true}
            arrows={false}
            draggable
          >
            {data?.latest_boxes?.map((item, index) => {
              return <BoxCard key={index} data={item} />;
            })}
          </Carousel>
          {/* boxes carousel end  */}

          {/* view all button start  */}
          <div className="categories-block__viewAll">
            <Link
              to={`/MySteryBoxPage/${data?.mystery_box_info?.machine_name}/1`}
            >
              {VIEWALL}
            </Link>
          </div>
          {/* view all button start  */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps)(Boxes);
