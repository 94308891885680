import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

const NotFound = () => (
  <div
    className="notfound container my-4"
    style={{ backgroundImage: 'url("/img/not-found.svg")' }}
  >
    <Link to="/">
      {
        useSelector(({ language }) =>
          language.langCode === "rtl" ? Global.ar : Global.en
        ).backToHome
      }
    </Link>
  </div>
);

export default NotFound;
