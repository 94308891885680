import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "currentUser",
  initialState: {
    country_id: 0,
    phone: "",
    name: "",
    email: "",
    image: null,
    id: null,
    cart: 0,
    fav: 0,
    favoritesId: [],
    comparesId: [],
    firstTime: true,
  },

  reducers: {
    setUser: (user, { payload }) => {
      Object.assign(user, payload);
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
