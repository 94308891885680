import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import moment from "moment";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import PaginatedItems from "../../../Pages/Search/PaginationBlack";
import { Col, Row } from "react-bootstrap";
import { useRef } from "react";
import { displayAlert } from "../../../../utils/misc";

function OrderTable({
  refetchData,
  controlExchangeModal,
  historyOrders,
  activeOrders,
  activeMeta,
  historyMeta,
  changePage,
}) {
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const {
    Status,
    returnOrder,
    Exchange,
    ACTIVE,
    HISTORY,
    OrderId,
    Date: textDate,
    Price,
    Sorry,
    sorryYouCantReturn,
    sorryYouCantReturnAfter14Days,
  } = i18n;
  const [IsActive, setIsActive] = useState(true);
  const [loader, setLoader] = useState(false);
  const currency = useSelector(({ currency }) => currency);
  const history = useHistory();
  const isFirst = useRef(true);
  const params = useParams();

  useEffect(() => {
    if (!isFirst.current) {
      refetchData();
    }
    isFirst.current = false;
  }, [params]);

  // function to render table body data  start
  const renderOrders = () => {
    // render Orders rows select the orders array base IsActive
    let dataReturn = IsActive ? activeOrders : historyOrders;
    return dataReturn?.map(
      ({
        id,
        created_at,
        statusText,
        total_amount,
        statusColor,
        order_reference,
        status,
        ...order
      }) => {
        return (
          <tr className="order-table__item">
            <td>
              <Link to={`/orderinvoice/${order_reference}`}> #{id}</Link>
            </td>
            <td style={{ color: statusColor }}>
              {language.langCode === "ltr"
                ? statusText
                : statusText == "Pending"
                ? "قيد الانتظار"
                : statusText == "Cacnelled"
                ? "ملغي"
                : statusText == "Accepted"
                ? "تم القبول"
                : statusText == "On the Way"
                ? "في الطريق"
                : statusText == "Arrived"
                ? "تم التوصيل"
                : statusText == "Completed"
                ? "مكتمل"
                : null}
            </td>
            <td>
              {(total_amount * currency.currencyFactor).toFixed(2)}{" "}
              {currency.currencyName}
            </td>
            <td>{moment(created_at).format("yy-MM-DD")}</td>

            {!IsActive &&
              moment
                .duration(moment(new Date())?.diff(moment(created_at)))
                .asDays() < 14 &&
              status == 5 && (
                <td className="order-table__buttons flex">
                  <button
                    className="submit-btn"
                    onClick={() => {
                      // 05.06.2023 Bayan request to remove this condition
                      // if (
                      //   moment
                      //     .duration(
                      //       moment(new Date())?.diff(moment(created_at))
                      //     )
                      //     .asDays() < 2
                      // )
                      //   return displayAlert(Sorry, sorryYouCantReturn, "error");
                      if (
                        moment
                          .duration(
                            moment(new Date())?.diff(moment(created_at))
                          )
                          .asDays() > 14
                      )
                        return displayAlert(
                          Sorry,
                          sorryYouCantReturnAfter14Days,
                          "error"
                        );
                      controlExchangeModal("return", order_reference, {
                        id: id,
                        ...order,
                      });
                    }}
                  >
                    {returnOrder}
                  </button>
                  <button
                    className="submit-btn"
                    onClick={() => {
                      // 05.06.2023 Bayan request to remove this condition
                      // if (
                      //   moment
                      //     .duration(
                      //       moment(new Date())?.diff(moment(created_at))
                      //     )
                      //     .asDays() < 2
                      // )
                      //   return displayAlert(Sorry, sorryYouCantReturn, "error");
                      if (
                        moment
                          .duration(
                            moment(new Date())?.diff(moment(created_at))
                          )
                          .asDays() > 14
                      )
                        return displayAlert(
                          Sorry,
                          sorryYouCantReturnAfter14Days,
                          "error"
                        );
                      controlExchangeModal("exchange", order_reference, {
                        id: id,
                        ...order,
                      });
                    }}
                  >
                    {Exchange}
                  </button>
                </td>
              )}
          </tr>
        );
      }
    );
  };
  // function to render table body data  end

  return (
    <>
      {/* select the orders type ACTIVE or HISTORY */}
      <div className="order-tabs flex">
        <p
          className={IsActive && "order-tab-active"}
          onClick={() => {
            setLoader(true);
            setIsActive(true);
            setTimeout(() => {
              setLoader(false);
            }, 0);
            history.push("/orders/1");
          }}
        >
          {ACTIVE}
        </p>
        <p
          className={!IsActive && "order-tab-active"}
          onClick={() => {
            setLoader(true);
            setIsActive(false);
            setTimeout(() => {
              setLoader(false);
            }, 0);
            history.push("/orders/1");
          }}
        >
          {HISTORY}
        </p>
      </div>

      <div className="order-table">
        {loader ? null : (
          <table>
            {/* table header start  */}
            <tr className="table-header">
              <th>{OrderId}</th>
              <th>{Status}</th>
              <th>{Price}</th>
              <th>{textDate}</th>
              <th></th>
            </tr>
            {/* table header end  */}

            {/* table body start  */}
            {renderOrders()}
            {/* table body end  */}
          </table>
        )}
      </div>

      {/* Pagination for history & active orders Start */}
      {loader
        ? null
        : IsActive
        ? activeMeta?.last_page > 1 && (
            <Row>
              <Col md={12}>
                <div className="Pagination">
                  <PaginatedItems // pagination component that handle the pages
                    items={[...Array(activeMeta?.total).keys()]}
                    itemsPerPage={activeMeta?.per_page}
                    changePage={(page) => changePage(page)}
                  />
                </div>
              </Col>
            </Row>
          )
        : historyMeta?.last_page > 1 && (
            <Row>
              <Col md={12}>
                <div className="Pagination">
                  <PaginatedItems // pagination component that handle the pages
                    items={[...Array(historyMeta?.total).keys()]}
                    itemsPerPage={historyMeta?.per_page}
                    changePage={(page) => changePage(page)}
                  />
                </div>
              </Col>
            </Row>
          )}
      {/* Pagination for history & active orders end */}
    </>
  );
}

export default withRouter(OrderTable);
