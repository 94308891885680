import React from "react";
import { Col, Form, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";
import SelectInput from "../../blocks/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/actions-reducers/user";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { generalServices } from "../../../services/GeneralService";
import UploadFile from "../../blocks/UploadFile";
import PhoneInput from "../../blocks/PhoneInput";
import Label from "../../blocks/Label";
import { locationService } from "../../../services/LocationService";
import { isValueEnglish } from "../../../utils/isValueEnglish";
import { findTranslatedLabel } from "../../../utils/findTranslatedLabel";
import Swal from "sweetalert2";

const position = {
  lat: 31.963158,
  lng: 35.930359,
};

const haveToEnter = [
  "firstName",
  "middleName",
  "lastName",
  "phone",
  "country",
  "building",
  "buildingType",
];

export default function AddLocationUser({
  storeLocation,
  setLoading,
  handleAddLocation,
  restHandleAddLocation,
  loading,
}) {
  const dispatch = useDispatch();
  const { langCode } = useSelector(({ language }) => language);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const {
    middleName,
    Country,
    completeYourInformation,
    Save,
    Area,
    Email,
    Mobile,
    FirstName,
    LastName,
    BuildingNumber,
    change,
    FloorNumber,
    ApartmentNumber,
    ContactInfo,
    FileSelected,
    changethelocation,
    House,
    Building,
    BuildingType,
    thisFieldIsRequired,
    HouseNumber,
    success: successText,
    LocationAddedSuccessfully,
    Sorry,
    thereAreSomeFieldsThatNeedToBeFilledInCorrectly,
    pleaseFillFollowingFieldsInEnglish,
  } = Global[langCode === "ltr" ? "en" : "ar"];

  const [fields, setFields] = React.useState({
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    country: "",
    city: "",
    building: "",
    buildingType: 1,
  });
  const [errors, setErrors] = React.useState({});
  const [city, setCity] = React.useState({});
  const [country, setCountry] = React.useState({});
  const [showSelect, setShowSelect] = React.useState(true);
  const [placeFields, setPlaceFields] = React.useState([]);
  const [extra_data, setExtra_data] = React.useState([]);
  const [extra_data_images, setExtra_data_images] = React.useState({});
  const [imageLoader, setImageLoader] = React.useState(false);
  const [cityModalOpen, setCityModalOpen] = React.useState(0);

  const [countries, setCountries] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  const onFieldChange = (name, value) => {
    // Field Change handler
    setFields((prev) => ({ ...prev, [name]: value }));
    setErrors({
      firstName: "",
      lastName: "",
      address: "",
      building: "",
      phone: "",
      floor: "",
      appartment: "",
    });
  };

  const addUserLocation = React.useCallback(async () => {
    let fieldsErrors = {};

    // chick the required field
    haveToEnter.forEach((item) => {
      if (
        !fields[item] ||
        fields[item] === "" ||
        (typeof fields[item] === "string" && !fields[item]?.trim())
      )
        fieldsErrors[item] = thisFieldIsRequired;
    });

    // if buildingType is a building chick on floor & appartment
    if (fields.buildingType !== 2) {
      ["floor", "appartment"].forEach((item) => {
        if (
          !fields[item] ||
          fields[item] === "" ||
          (typeof fields[item] === "string" && !fields[item]?.trim())
        )
          fieldsErrors[item] = thisFieldIsRequired;
      });
    }

    // place chick the required field
    if (placeFields?.length > 0) {
      placeFields.map((item_one) => {
        if (item_one.is_required == 1) {
          let isFound = false;
          if (extra_data) {
            extra_data.map((item_two) => {
              if (item_one.id == item_two.place_field_id) {
                isFound = true;
                if (
                  !item_two.value_text ||
                  item_two.value_text == "" ||
                  (typeof item_two.value_text === "string" &&
                    !item_two.value_text?.trim())
                ) {
                  fieldsErrors["placeFields_" + item_one.id] =
                    thisFieldIsRequired;
                }
              }
            });
          }

          if (!isFound) {
            fieldsErrors["placeFields_" + item_one.id] = thisFieldIsRequired;
          }
        }
      });
    }

    // if there are cities and not city value
    if (cities && cities.length > 0 && !fields?.city) {
      if (!showSelect) {
        setCityModalOpen(true);
        return true;
      }
      fieldsErrors["city"] = thisFieldIsRequired;
    }

    // set the errors
    setErrors(fieldsErrors);

    // id there are errors exit
    if (Object.keys(fieldsErrors).length > 0) {
      return true;
    }

    if (fields?.country !== "Put 1 as integer to make it outside kuwait") {
      // check that all fields including place fields are filled in English
      let englishErrors = [];
      haveToEnter.forEach((item) => {
        if (!isValueEnglish(item, fields[item])) {
          englishErrors.push(findTranslatedLabel(item, langCode));
        }
      });

      // check placeFields are filled in English with isValueEnglish function
      if (placeFields?.length > 0) {
        placeFields.map((item_one) => {
          if (item_one.is_required == 1) {
            if (extra_data) {
              extra_data.map((item_two) => {
                if (item_one.id == item_two.place_field_id) {
                  if (!isValueEnglish(item_one.label, item_two.value_text)) {
                    englishErrors.push(item_one.label);
                  }
                }
              });
            }
          }
        });
      }

      if (englishErrors.length > 0) {
        Swal.fire({
          icon: "error",
          text:
            pleaseFillFollowingFieldsInEnglish +
            ": " +
            englishErrors.join(", "),
        });
        return true;
      }
    }

    setLoading(true);

    // add user location api request
    const { success, message, errors, data } =
      await locationService.addUserLocation({
        ...fields,
        nickname:
          fields.firstName + " " + fields.middleName + " " + fields.lastName,
        first_name: fields.firstName,
        middle_name: fields.middleName,
        last_name: fields.lastName,
        latitude: position.lat,
        longitude: position.lng,
        building: fields.building,
        phone: fields.phone,
        floor:
          fields.country == 1 && fields?.buildingType === 2
            ? "0"
            : fields.floor,
        appartment:
          fields.country == 1 && fields?.buildingType === 2
            ? "0"
            : fields.appartment,
        place_id: fields.city ? fields.city : fields.country,
        extra_data,
      });
    setLoading(false);

    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        setErrors(handelErrors);
        return;
      } else {
        return displayAlert("Error", message, "error");
      }
    }

    // set data the redux and local storage
    dispatch(
      setUser({
        selectedLocation: {
          ...fields,
          id: data?.id,
          nickname:
            fields.firstName + " " + fields.middleName + " " + fields.lastName,
          first_name: fields.firstName,
          middle_name: fields.middleName,
          last_name: fields.lastName,
          latitude: position.lat,
          longitude: position.lng,
          building: fields.building,
          phone: fields.phone,
          floor:
            fields.country == 1 && fields?.buildingType === 2
              ? "0"
              : fields.floor,
          appartment:
            fields.country == 1 && fields?.buildingType === 2
              ? "0"
              : fields.appartment,
          place_id: fields.city ? fields.city : fields.country,
          extra_data,
          placeFields,
        },
      })
    );
    storeLocation({
      ...fields,
      id: data?.id,
      nickname:
        fields.firstName + " " + fields.middleName + " " + fields.lastName,
      first_name: fields.firstName,
      middle_name: fields.middleName,
      last_name: fields.lastName,
      latitude: position.lat,
      longitude: position.lng,
      building: fields.building,
      phone: fields.phone,
      floor:
        fields.country == 1 && fields?.buildingType === 2 ? "0" : fields.floor,
      appartment:
        fields.country == 1 && fields?.buildingType === 2
          ? "0"
          : fields.appartment,
      place_id: fields.city ? fields.city : fields.country,
      extra_data,
      placeFields,
    });
    displayAlert(successText, LocationAddedSuccessfully, "success").then(
      (ok) => {
        if (ok) {
          window.location.href = "/checkoutpayment";
        }
      }
    );

    // reset buildingType to 1
    setFields((prev) => ({
      ...prev,
      buildingType: 1,
    }));
  }, [
    dispatch,
    extra_data,
    fields,
    placeFields,
    storeLocation,
    LocationAddedSuccessfully,
    successText,
    cities,
    showSelect,
  ]);

  const getPlaceFields = React.useCallback(
    async (placeId, userLocationId = 0) => {
      // get Place Fields and if the items have user_value then add the item label and item value to the fields and extra data
      setLoading(true);
      const { data, success } = await generalServices.getPlaceFields(
        placeId,
        userLocationId
      );
      setLoading(false);

      if (!success) return;

      setPlaceFields(data);

      for (let i = 0; i < data.length; i++) {
        if (data[i].user_value) {
          setFields((prev) => ({
            ...prev,
            [data[i].label]:
              data[i].type !== 2
                ? data[i].user_value?.value_text
                : {
                    label: data[i].user_value?.place_field_value.value,
                    value: data[i].user_value?.place_field_value?.id,
                    id: data[i].user_value?.place_field.id,
                  },
          }));
          setExtra_data((prev) => [
            ...prev,
            {
              place_field_id: data[i].user_value?.place_field?.id,
              place_field_value_id:
                data[i].user_value?.place_field_value?.id ?? "",
              value_text: data[i].user_value?.value_text,
            },
          ]);
        }
      }
    },
    []
  );

  const getPlacesFunction = React.useCallback(
    async (id) => {
      // function to get places from the server and set it to Countries if id === 0 else to Cities and call getPlaceFields to get current Place Fields
      setLoading(true);
      const { data, success } = await generalServices.getAllCountries(id);
      setLoading(false);

      if (!success) return;

      if (id === 0) {
        setCountries(
          data?.map((item, index) => ({
            value: item.id,
            label: item.name,
            ...item,
          }))
        );
      } else {
        setCities(
          data?.map((item, index) => ({
            value: item.id,
            label: item.name,
            ...item,
          }))
        );

        getPlaceFields(id);
      }
    },
    [getPlaceFields]
  );

  const onExtraDataChange = (type, value, item) => {
    // on Extra Data Change place Extra Fields
    setErrors({
      firstName: "",
      lastName: "",
      address: "",
      building: "",
      phone: "",
      floor: "",
      appartment: "",
    });
    const addNew = (data) => {
      if (type !== 2) {
        setFields((prev) => ({
          ...prev,
          [item.label]: value,
        }));

        setExtra_data((prev) => [
          ...data,
          {
            place_field_id: item.id,
            place_field_value_id: "",
            value_text: value,
          },
        ]);
      } else {
        setFields((prev) => ({
          ...prev,
          [item.label]: value,
        }));

        setExtra_data((prev) => [
          ...data,
          {
            place_field_id: value.id,
            place_field_value_id: value.value,
            value_text: value.label,
          },
        ]);
      }
    };

    if (
      extra_data.findIndex(
        ({ place_field_id }) => place_field_id === item.id
      ) === -1
    ) {
      addNew(extra_data);
    } else {
      let newExtraData = extra_data.filter(
        ({ place_field_id }) => place_field_id !== item.id
      );

      addNew(newExtraData);
    }
  };

  const uploadImage = async (type, item, fileImage) => {
    // upload Image function
    setErrors({
      firstName: "",
      lastName: "",
      address: "",
      building: "",
      phone: "",
      floor: "",
      appartment: "",
    });
    setImageLoader(true);

    const formData = new FormData();
    formData.append("file", fileImage);
    setLoading(true);
    const { data } = await generalServices.uploadImage(formData);
    setLoading(false);

    let arrayImages = extra_data_images ? extra_data_images : [];
    arrayImages[item.label] = data;
    setExtra_data_images(arrayImages);
    setImageLoader(false);

    onExtraDataChange(type, data, item);
  };

  React.useEffect(() => {
    const getPlaceDetails = async (x, tag) => {
      // function to get Place Details and set it to country if tag is country else city
      setLoading(true);
      const { data: placeData } = await generalServices.getPlaceDetails(x);
      setLoading(false);

      if (tag === "country") setCountry(placeData);
      else setCity(placeData);
    };
    if (fields.country) getPlaceDetails(fields?.country, "country");
    if (fields.city) getPlaceDetails(fields?.city);
  }, [fields.country, fields.city]);

  React.useEffect(() => {
    if (!countries.length > 0) getPlacesFunction(0);
  }, [getPlacesFunction, countries]);

  React.useEffect(() => {
    if (
      currentUser?.selectedLocation?.nickname &&
      currentUser?.selectedLocation?.country
    ) {
      // old location ( in local storage ) handel
      getPlacesFunction(currentUser?.selectedLocation?.country);
      setFields({
        ...currentUser?.selectedLocation,
        email: currentUser.email,
      });
      setExtra_data(currentUser?.selectedLocation?.extra_data || []);
      setShowSelect(false);
    } else {
      if (
        currentUser?.selectedLocation &&
        Object.keys(currentUser?.selectedLocation).length > 0
      ) {
        // if there a local storage location and don't have nickname and country clear it
        dispatch(setUser({ selectedLocation: {} }));
      }
    }
  }, [dispatch, currentUser, getPlacesFunction]);

  React.useEffect(() => {
    if (handleAddLocation) {
      // when handleAddLocation be true
      addUserLocation();
      restHandleAddLocation();
    }
  }, [handleAddLocation, addUserLocation, restHandleAddLocation]);

  React.useEffect(() => {
    // whenever thereis an error in the form show, scroll to the top of the form
    if (Object.values(errors).some((x) => x)) {
      window.scrollTo(0, 0);
      // and show the error message
      displayAlert(
        Sorry,
        thereAreSomeFieldsThatNeedToBeFilledInCorrectly,
        "error"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="signup-login newCheckOut">
      <header>
        <h2 className="general-info__title add-edit-title mb-3">
          {ContactInfo}
        </h2>
      </header>
      <div className="signup-login__form-container confirm-checkout-container p-0">
        <Row style={{ marginBottom: 300 }}>
          <Col lg={6} md={6} sm={6} className="mb-4">
            <TextInput
              name="firstName"
              maxLength={20}
              label={FirstName}
              placeholder={FirstName}
              value={fields.firstName}
              onFieldChange={onFieldChange}
              validate={errors.firstName || errors.first_name}
            />
          </Col>
          <Col lg={6} md={6} sm={6} className="mb-4">
            <TextInput
              name="middleName"
              maxLength={20}
              label={middleName}
              placeholder={middleName}
              value={fields.middleName}
              onFieldChange={onFieldChange}
              validate={errors.middleName || errors.middle_name}
            />
          </Col>
          <Col lg={6} md={6} sm={6} className="mb-4">
            <TextInput
              name="lastName"
              maxLength={20}
              label={LastName}
              placeholder={LastName}
              value={fields.lastName}
              onFieldChange={onFieldChange}
              validate={errors.lastName || errors.last_name}
            />
          </Col>
          <Col lg={6} md={6} sm={6} className="mb-4">
            <TextInput
              name="email"
              label={Email}
              placeholder={Email}
              value={currentUser.email}
              onFieldChange={onFieldChange}
              validate={errors.email}
              disabled
            />
          </Col>
          {showSelect && ( // if country and city select not hidden
            <>
              <Col md={6} className="mb-4">
                <SelectInput
                  name={
                    countries.filter(({ value }) => value === fields?.country)
                      .length > 0
                      ? countries.filter(
                          ({ value }) => value === fields?.country
                        )[0].label
                      : null
                  }
                  label={Country}
                  placeholder={Country}
                  value={
                    countries.filter(({ value }) => value === fields?.country)
                      .length > 0
                      ? countries.filter(
                          ({ value }) => value === fields?.country
                        )[0]
                      : null
                  }
                  onFieldChange={(name, value) => {
                    onFieldChange("country", value.value);
                    onFieldChange("city", ""); // clear city on country change
                    setExtra_data([]); // clear Extra data (place field) on country change
                    getPlacesFunction(value.value); // get places data
                  }}
                  data={countries}
                  validate={errors.country}
                />
              </Col>
              {cities?.length > 0 && (
                <Col md={6} className="mb-4">
                  <SelectInput
                    name={
                      cities.filter(({ value }) => value === fields?.city)
                        .length > 0
                        ? cities.filter(
                            ({ value }) => value === fields?.city
                          )[0].label
                        : null
                    }
                    label={Area}
                    placeholder={Area}
                    value={
                      cities.filter(({ value }) => value === fields?.city)
                        .length > 0
                        ? cities.filter(
                            ({ value }) => value === fields?.city
                          )[0]
                        : null
                    }
                    onFieldChange={(name, value) => {
                      onFieldChange("city", value.value);
                    }}
                    data={cities}
                    validate={errors.city}
                  />
                </Col>
              )}
            </>
          )}
          <Col lg={6} md={6} sm={6} className="mb-4">
            <div>
              <Label label={Mobile} isRequired={false} />
            </div>
            <div>
              <Form.Group className="phone-number d-flex w-100">
                <InputGroup.Text className="phone-zipcode text-center">
                  <div className="">{country?.zipcode}</div>
                </InputGroup.Text>
                <PhoneInput
                  type="number"
                  placeholder={Mobile + " *"}
                  name="phone"
                  onFieldChange={onFieldChange}
                  value={fields?.phone}
                  className="signup-login__form-container__field phone_field"
                  digits={country?.digits}
                />
              </Form.Group>
            </div>
            <smal
              style={{
                fontSize: "0.875em",
                color: "red",
                letterSpacing: "0px",
              }}
            >
              {errors?.phone}
            </smal>
          </Col>
          {fields?.country === 1 && ( // country is the Kuwait so if the user select the Kuwait user can select building Type
            <Col md={6} className="mb-4">
              <SelectInput
                name={
                  fields?.buildingType === 1
                    ? { value: 1, label: Building }
                    : { value: 2, label: House }
                }
                label={BuildingType}
                placeholder={BuildingType}
                value={
                  fields?.buildingType === 1
                    ? { value: 1, label: Building }
                    : { value: 2, label: House }
                }
                onFieldChange={(name, value) => {
                  setFields((prev) => ({
                    ...prev,
                    buildingType: value?.value,
                  }));
                }}
                data={[
                  { value: 1, label: Building },
                  { value: 2, label: House },
                ]}
              />
            </Col>
          )}
          {(fields?.buildingType === 1 ||
            fields?.country !==
              "Put 1 as integer to make it outside kuwait") && (
            // buildingType 1 mean that the building type is Building so user have to select building, floor and appartment
            <>
              <Col lg={6} md={6} sm={6} className="mb-4">
                <TextInput
                  name="building"
                  label={BuildingNumber}
                  placeholder={BuildingNumber}
                  value={fields.building}
                  onFieldChange={onFieldChange}
                  validate={errors.building}
                />
              </Col>
              <Col lg={6} md={6} sm={6} className="mb-4">
                <TextInput
                  name="floor"
                  label={FloorNumber}
                  placeholder={FloorNumber}
                  value={fields.floor}
                  onFieldChange={onFieldChange}
                  validate={errors.floor}
                />
              </Col>
              <Col lg={6} md={6} sm={6} className="mb-4">
                <TextInput
                  name="appartment"
                  label={ApartmentNumber}
                  placeholder={ApartmentNumber}
                  value={fields.appartment}
                  onFieldChange={onFieldChange}
                  validate={errors.appartment}
                />
              </Col>
            </>
          )}
          {fields?.buildingType === 2 && fields?.country === 1 && (
            // buildingType 2 mean that the building type is House so user have to select building just but with title House Number
            <>
              <Col lg={6} md={6} sm={6} className="mb-4">
                <TextInput
                  name="building"
                  label={HouseNumber}
                  placeholder={HouseNumber}
                  value={fields.building}
                  onFieldChange={onFieldChange}
                  validate={errors.building}
                />
              </Col>
            </>
          )}
          {/* Apartment  input start  */}
          {/* place name (show in edit)  start  */}
          {!showSelect && ( // when city select hidden if the user has already select a country and city (stored in local storage)
            <Col lg={12} md={12} sm={12} className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <p>{country?.name}</p>
                <button
                  className="places-delete-btn"
                  onClick={() => {
                    displayAlert(
                      changethelocation,
                      changethelocation,
                      "warning"
                    ).then((yes) => {
                      if (yes) {
                        setFields({
                          firstName: fields.firstName,
                          middleName: fields.middleName,
                          lastName: fields.lastName,
                          appartment: fields.appartment,
                          building: fields.building,
                          floor: fields.floor,
                          phone: fields.phone,
                          email: fields.email,
                          place_id: fields.place_id,
                          address: fields.address,
                          IDpicture: fields.IDpicture,
                          buildingType: fields.buildingType,
                        });
                        setShowSelect(true);
                        setExtra_data([]);
                        setPlaceFields([]);
                        getPlacesFunction(0, []);
                      }
                    });
                  }}
                >
                  {change}
                </button>
              </div>
              <TextInput
                name={city?.name || country?.name}
                value={city?.name || country?.name}
                disabled={true}
              />
            </Col>
          )}
          {placeFields?.map((item, index) => {
            // place Fields select
            /*
            type 1: text, type 2: select, type 3: number, type 4: file
            */
            return +item.type === 1 || +item.type === 3 ? (
              <Col lg={6} md={6} sm={6} className="mb-4" key={index}>
                <TextInput
                  name={item.label}
                  label={item.label}
                  type={+item.type === 3 ? "number" : "text"}
                  placeholder={item.label}
                  value={
                    extra_data.filter(
                      ({ place_field_id }) => place_field_id == item.id
                    ) &&
                    extra_data.filter(
                      ({ place_field_id }) => place_field_id == item.id
                    ).length > 0
                      ? extra_data.filter(
                          ({ place_field_id }) => place_field_id == item.id
                        )[0]?.value_text
                      : ""
                  }
                  onFieldChange={(name, value) =>
                    onExtraDataChange(+item.type, value, item)
                  }
                  validate={errors["placeFields_" + item?.id]}
                />
              </Col>
            ) : +item.type === 2 ? (
              <Col lg={6} md={6} sm={6} className="mb-4">
                <SelectInput
                  name={item.label}
                  label={item.label}
                  placeholder={item.label}
                  value={
                    extra_data.filter(
                      ({ place_field_id }) => place_field_id == item.id
                    ) &&
                    extra_data.filter(
                      ({ place_field_id }) => place_field_id == item.id
                    ).length > 0
                      ? {
                          label: extra_data.filter(
                            ({ place_field_id }) => place_field_id == item.id
                          )[0]?.value_text,
                          value: extra_data.filter(
                            ({ place_field_id }) => place_field_id == item.id
                          )[0]?.place_field_value_id,
                        }
                      : ""
                  }
                  onFieldChange={(name, value) =>
                    onExtraDataChange(+item.type, value, value)
                  }
                  data={item.dropdown_values.map(
                    ({ value: label, id: value }) => ({
                      value,
                      label,
                      id: item.id,
                    })
                  )}
                  validate={errors["placeFields_" + item?.id]}
                />
              </Col>
            ) : +item.type === 4 ? (
              <Col sm={6} md={6} className=" align-items-center c-f-u-c">
                <UploadFile
                  name={item.label}
                  uploadImage={(fileImage) =>
                    uploadImage(item.type, item, fileImage)
                  }
                  value={
                    extra_data.filter(
                      ({ place_field_id }) => place_field_id == item.id
                    ) &&
                    extra_data.filter(
                      ({ place_field_id }) => place_field_id == item.id
                    ).length > 0
                      ? extra_data.filter(
                          ({ place_field_id }) => place_field_id == item.id
                        )[0]?.value_text
                      : ""
                  }
                  placeholder={item.label}
                  label={item.label}
                  validate={errors["placeFields_" + item?.id]}
                  loading={imageLoader}
                />

                {extra_data.filter(
                  ({ place_field_id }) => place_field_id == item.id
                ) &&
                extra_data.filter(
                  ({ place_field_id }) => place_field_id == item.id
                ).length > 0 ? (
                  <span
                    style={{
                      color: "#009952",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {FileSelected}
                  </span>
                ) : null}
              </Col>
            ) : null;
          })}{" "}
          <Modal show={cityModalOpen} onHide={() => setCityModalOpen(false)}>
            {/* if city select is hidden and there is no city*/}
            <div className="signup-login__form-container confirm-checkout-container p-4">
              <h2 className="general-info__title add-edit-title mb-3">
                {completeYourInformation}
              </h2>
              <Row>
                {cities?.length > 0 && (
                  <Col xs={12} className="mb-4">
                    <SelectInput
                      name={
                        cities.filter(({ value }) => value === fields?.city)
                          .length > 0
                          ? cities.filter(
                              ({ value }) => value === fields?.city
                            )[0].label
                          : null
                      }
                      label={Area}
                      placeholder={Area}
                      value={
                        cities.filter(({ value }) => value === fields?.city)
                          .length > 0
                          ? cities.filter(
                              ({ value }) => value === fields?.city
                            )[0]
                          : null
                      }
                      onFieldChange={(name, value) => {
                        onFieldChange("city", value.value);
                      }}
                      data={cities}
                      validate={errors.city}
                    />
                  </Col>
                )}
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  className="locationButton d-flex justify-content-center "
                >
                  {loading ? (
                    <button className="submit-button2">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      className="submit-button2"
                      onClick={addUserLocation}
                    >
                      {Save}
                    </button>
                  )}
                </Col>
              </Row>
            </div>
          </Modal>
        </Row>
      </div>
    </div>
  );
}
