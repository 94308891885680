import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container } from "react-bootstrap";
class Steps extends Component {
  state = {
    step: this.props.stepNum,
    selectedLocation: {},
    books: [],
  };

  render() {
    const {
      language,
      i18n: { SelectPackage, CUSTOMIZEIT, ProcerdtoCheckout, Payment },
      stepNum,
    } = this.props;

    return (
      <div className={`steps ${language === "rtl" && "steps-arabic"}`}>
        <Container>
          {/*  Steps Start */}
          <div className="steps">
            <ul>
              {/* step 1 label  start  */}
              <li>
                <div className={stepNum >= 1 ? "step stepActive" : "step"}>
                  <span className={stepNum >= 1 ? " bg-stepActive" : ""}>
                    {stepNum >= 1 ? <i className="fa fa-check"></i> : <div />}
                  </span>
                  <p> {SelectPackage}</p>
                </div>
              </li>
              {/* step 1 label  end  */}

              <li
                className={stepNum >= 2 ? "step-hr bg-stepActive" : "step-hr"}
              ></li>

              {/* step 2 label  start  */}
              <li>
                <div className={stepNum >= 2 ? "step stepActive" : "step"}>
                  <span className={stepNum >= 2 ? " bg-stepActive" : ""}>
                    {stepNum >= 2 ? <i className="fa fa-check"></i> : <div />}
                  </span>
                  <p>{CUSTOMIZEIT}</p>
                </div>
              </li>
              {/* step 2 label  end  */}

              <li
                className={stepNum >= 3 ? "step-hr bg-stepActive" : "step-hr"}
              ></li>

              {/* step 3 label  start  */}
              <li>
                <div className={stepNum >= 3 ? "step stepActive" : "step"}>
                  <span className={stepNum >= 3 ? " bg-stepActive" : ""}>
                    {stepNum >= 3 ? <i className="fa fa-check"></i> : <div />}
                  </span>
                  <p> {ProcerdtoCheckout}</p>
                </div>
              </li>
              {/* step 3 label  end  */}

              <li
                className={stepNum > 3 ? "step-hr bg-stepActive" : "step-hr"}
              ></li>

              {/* step 4 label  start  */}
              <li>
                <div className={stepNum >= 4 ? "step stepActive" : "step"}>
                  <span className={stepNum >= 4 ? " bg-stepActive" : ""}>
                    {stepNum >= 4 ? <i className="fa fa-check"></i> : <div />}
                  </span>
                  <p> {Payment}</p>
                </div>
              </li>
              {/* step 4 label  end  */}
            </ul>
          </div>
          {/* Steps End */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps)(withRouter(Steps));
