import React from "react";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { productService } from "../../services/ProductService";
import { displayAlert } from "../../utils/misc";

function CheckBoxProduct({
  type,
  data,
  itemsChecked,
  itemsCheckedChange,
  changeItemOption,
  optionsChange,
}) {
  var whiteOptions = [];

  const [checked, setChecked] = useState(false);
  const [updateItemModal, setUpdateItemModal] = useState(false);
  const [optionsGroup, setOptionsGroup] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionData, setOptionData] = useState({});
  const [newOption, setNewOption] = useState({});
  const currency = useSelector(({ currency }) => currency);

  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const { UpdateItem, itemOptions, QuantityIsNotEnough, error, ExchangeItem } =
    i18n;

  const getProductDetails = async () => {
    // function to get Product Details by call it from the server and set its options to options and its options groups to optionsGroup
    const { data: apiData, success } = await productService.getProductDetails(
      data?.product?.machine_name
    );
    if (!success) return;
    const newOptionGroup = apiData.options_groups.filter(
      ({ id }) => id != data?.product_option?.id
    );
    setOptionsGroup(newOptionGroup);
    setOptions(apiData.options);
  };

  return (
    <div
      className={`checkbox-product-container border-bot border-top ${
        language.langCode === "rtl" && "checkbox-product-container__arabic"
      }`}
    >
      <Row>
        <Col md={12} lg={9}>
          <label className="checkbox-container mt-0 w-100 d-block m-0">
            <div className="checkbox-product w-100">
              <Row className="w-100">
                {/* product image start  */}
                <Col md={12} lg={3} className="text-center">
                  <img
                    className="checkbox-product__image"
                    src={
                      data?.product?.main_image
                        ? apiService.imageLink + data?.product?.main_image
                        : "/images/placeholder.png"
                    }
                  />
                </Col>
                {/* product image end  */}

                <Col md={12} lg={9}>
                  <div className="checkbox-product__info">
                    {/* product title start  */}
                    <p className="checkbox-product__info__title">
                      {data?.product?.title}
                    </p>
                    {/* product title end  */}

                    <div className="checkbox-product__info__options d-flex justify-content-between">
                      {!data?.product?.bundle?.length &&
                        type === "exchange" && (
                          <>
                            {/* old option start  */}
                            {optionsChange.findIndex(
                              ({ order_item_id }) =>
                                +order_item_id === +data?.id
                            ) != -1 && (
                              <div className="checkbox-product__info__options__old">
                                <del>{data?.option_values.join(" ,")}</del>
                              </div>
                            )}
                            {/* old option end  */}

                            {/* new option start  */}
                            {!data?.product?.bundle?.length && (
                              <div className="checkbox-product__info__options__new">
                                {optionsChange.findIndex(
                                  ({ order_item_id }) =>
                                    +order_item_id === +data?.id
                                ) != -1
                                  ? newOption?.ActiveGroupObj_obj?.values_names?.join(
                                      " ,"
                                    )
                                  : data?.option_values.join(" ,")}
                              </div>
                            )}
                            {/* new option end  */}
                          </>
                        )}
                      {/* product options  (if return ) start */}
                      {!data?.product?.bundle?.length && type === "return" && (
                        <div className="checkbox-product__info__options__new">
                          {data?.option_values.join(" ,")}
                        </div>
                      )}
                      {/* product options  (if return ) end */}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* check box input start  */}
            {data?.total_price > 0 && type === "return" && data?.can_return && (
              <>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => {
                    if (checked) {
                      let newItemsChecked = itemsChecked.filter(
                        ({ id }) => id !== data?.id
                      );

                      itemsCheckedChange(newItemsChecked);
                    } else {
                      itemsCheckedChange([...itemsChecked, data]);
                    }
                    setChecked(!checked);
                  }}
                />
                <span className="checkmark"></span>
              </>
            )}
            {/* check box input end  */}
          </label>
        </Col>
        <Col md={12} lg={3}>
          <div className="checkbox-product__control d-flex justify-content-between flex-column align-items-end">
            {/* update item option button start  */}
            {type == "exchange" &&
              data?.can_exchange &&
              data?.product?.options_groups.length > 1 &&
              !data?.product?.bundle?.length && (
                <button
                  className="checkbox-product__control__btn"
                  onClick={() => {
                    getProductDetails();
                    setUpdateItemModal(true);
                  }}
                >
                  {UpdateItem}
                </button>
              )}
            {/* update item option button end  */}
            {/* price start  */}
            {data?.total_price > 0 && (
              <p className="checkbox-product__control__price">
                {data?.total_price &&
                  (data?.total_price * currency.currencyFactor).toFixed(2)}{" "}
                {currency.currencyName}
              </p>
            )}
            {/* price end  */}
          </div>
        </Col>

        {/* update item modal start  */}
        <Modal
          backdropClassName="exchange-modal__update-items-backdrop"
          size="lg"
          show={updateItemModal}
          onHide={() => setUpdateItemModal(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          className="exchange-modal exchange-modal__update-items"
        >
          <Modal.Header
            closeButton
            className="exchange-modal__header border-none"
          >
            <Modal.Title
              id="example-modal-sizes-title-sm"
              className="exchange-modal__header__title text-center w-100"
            >
              {itemOptions}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="exchange-modal__body">
            {/* products options start  */}
            <div className="product-details__options">
              {/* options list start  */}
              {options?.map(({ name, values }, index) => {
                return (
                  <React.Fragment key={index}>
                    {/* option name start   */}
                    <h6>{name}:</h6>
                    {/* option name end   */}
                    {/* option values start  */}
                    <ul className="flex mb-3">
                      {values.map(({ id, name: name2, image }) => {
                        return (
                          <li
                            key={id}
                            className={`${image && "option-img"} ${
                              optionData?.ActiveGroupObj?.indexOf(id) > -1 &&
                              "optionSelected"
                            }`}
                            onClick={() => {
                              var current_value = id;
                              let stopLoop = false;

                              optionsGroup.map((group) => {
                                if (!stopLoop) {
                                  if (
                                    group.values.includes(current_value) &&
                                    whiteOptions.includes(current_value)
                                  ) {
                                    setOptionData({
                                      ...optionData,
                                      lastOptionId: current_value,
                                      ActiveGroupObj: group.values,
                                      ActiveGroupObj_obj: group,
                                      qty: group.preOrderQTY
                                        ? 1
                                        : group.min_order < group.qty
                                        ? group.min_order
                                        : group.qty,
                                      min_order: group.min_order,
                                      preOrderQTY: group.preOrderQTY,
                                      materials: group.materials,
                                    });

                                    stopLoop = true;
                                  } else {
                                    if (group.values.includes(current_value)) {
                                      whiteOptions = group.values;

                                      setOptionData({
                                        ...optionData,
                                        lastOptionId: current_value,
                                        ActiveGroupObj: group.values,
                                        ActiveGroupObj_obj: group,
                                        qty: group.preOrderQTY
                                          ? 1
                                          : group.min_order < group.qty
                                          ? group.min_order
                                          : group.qty,
                                        min_order: group.min_order,
                                        preOrderQTY: group.preOrderQTY,
                                        materials: group.materials,
                                      });
                                    }
                                  }
                                }
                              });
                            }}
                          >
                            {image ? (
                              <>
                                <div></div>
                                <img
                                  src={apiService.imageLink + image}
                                  alt="l"
                                />
                              </>
                            ) : (
                              <span>{name2}</span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                    {/* option values end  */}
                  </React.Fragment>
                );
              })}
              {/* options list end  */}
            </div>
            {/* products options end  */}

            {/* update item submit  */}
            {optionData?.ActiveGroupObj_obj?.qty +
              optionData?.ActiveGroupObj_obj?.preOrderQTY >
              0 && (
              <button
                className="submit-button2 mt-5"
                onClick={() => {
                  if (
                    optionData?.ActiveGroupObj_obj?.qty +
                      optionData?.ActiveGroupObj_obj?.preOrderQTY >
                    data.qty
                  ) {
                    displayAlert("success", "Done", "success");
                    changeItemOption({ ...optionData, item_id: data?.id });
                    setUpdateItemModal(false);
                    setNewOption(optionData);
                  } else {
                    //setUpdateItemModal(false);
                    displayAlert(error, QuantityIsNotEnough, "error");
                  }
                }}
              >
                {ExchangeItem}
              </button>
            )}

            {/* update item submit end */}
          </Modal.Body>
        </Modal>
        {/* update item modal end  */}
      </Row>
    </div>
  );
}

export default CheckBoxProduct;
