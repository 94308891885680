import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../redux/actions-reducers/user";
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { generalServices } from "../../services/GeneralService";
import { productService } from "../../services/ProductService";
import ProductContainer from "./ProductContainer";

class Product extends Component {
  state = {
    isFav: this.props.currentUser.favoritesId.includes(this.props.data?.id)
      ? true
      : false,
  };
  componentDidUpdate(pree) {
    if (pree.currentUser.favoritesId !== this.props.currentUser.favoritesId) {
      this.setState({
        isFav: this.props.currentUser.favoritesId.includes(this.props.data?.id)
          ? true
          : false,
      });
    }
  }

  render() {
    const {
      data,
      language,
      i18n: { SoldOut },
    } = this.props;
    const { isFav } = this.state;

    return (
      <ProductContainer hash={this.props?.data?.machine_name}>
        <div className={`product  ${language === "rtl" && "product-arabic"}`}>
          <div className="product__img-container">
            <div className="product__img-container__img">
              <Link
                to={
                  data?.bundle
                    ? `/ProductDetailsBundle/${data?.machine_name}`
                    : `/productDetails/${data?.machine_name}`
                }
              >
                <img
                  src={
                    data?.main_image
                      ? apiService.imageLink + data?.main_image
                      : "/images/placeholder.png"
                  }
                  alt="l"
                  style={{ boxShadow: "inset 0 0 10px #fff" }}
                />
              </Link>
              <div
                className={`fav-container ${
                  localStorage.langCode === "rtl" && "flex-row-reverse"
                }`}
              >
                {+data.option.qty === 0 && ( // Sold Out label display
                  <span className={`${"product-label product-label-soldOut"}`}>
                    {SoldOut}
                  </span>
                )}

                <div>
                  {data.label &&
                    data.option.qty > 0 && ( // label display
                      <span
                        className={`${"product-label py-1 px-2"}`}
                        style={{
                          backgroundColor: data.label.background_color,
                          color: data.label.text_color,
                        }}
                      >
                        {data.label.label}
                      </span>
                    )}
                </div>

                {/* <button
                  onClick={() => this.addRemoveFavorites(data?.id)}
                  className="like-container"
                >
                  <i
                    className={isFav ? "fa fa-heart" : "fa fa-heart-o"}
                    style={{
                      color: isFav ? "#fb3838" : "#000",
                    }}
                  />
                </button> */}
              </div>

              <div className="product__img-container__inhover-effect">
                <div className="add-to-cart-container"></div>
              </div>
            </div>
          </div>

          {/* the product has a bundle property if its true then go the ProductDetailsBundle page */}
          <Link
            to={
              data?.bundle
                ? `/ProductDetailsBundle/${data?.machine_name}`
                : `/productDetails/${data?.machine_name}`
            }
            className="product__body"
          >
            <p className="product__body__title">{data?.title}</p>

            <div className="product__body__price d-flex align-items-center">
              <span className="product__body__price__currency">
                {this.props.currency.currencyName}
              </span>
              <span className="product__body__price">
                {(
                  data?.option.price_after_discount *
                  this.props.currency.currencyFactor
                ).toFixed(2)}
              </span>

              <del className="product__body__price__old">
                {data?.option.price != data?.option.price_after_discount &&
                  (
                    data?.option.price * this.props.currency.currencyFactor
                  ).toFixed(2)}
              </del>
            </div>
          </Link>
        </div>
      </ProductContainer>
    );
  }

  getIconData = async () => {
    // function to get Fav Cart compare Counters from the server and set it to user redux store data
    const { data, success } = await generalServices.getIconData();
    if (!success) return;

    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
    });
  };

  getFavoritesIDs = async () => {
    // function to get user Favorites IDs from the server and set it to user redux store data
    const { data, success } = await generalServices.getFavoritesIDs();
    if (!success) return;

    this.props.setUser({
      favoritesId: data,
    });
  };

  getCartIDs = async () => {
    // function to get user cart from the server and set it to user redux store data
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };
  addRemoveFavorites = async (id) => {
    // Favorites toggle api request
    const { success } = await productService.addOrRemoveFavorite(id);
    if (!success) return;
    this.getFavoritesIDs();
    this.setState({ isFav: !this.state.isFav });
    this.getIconData();
  };
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  currency,
  country,
});
export default connect(mapStateToProps, { setUser })(Product);
