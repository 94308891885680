import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )
class AccountPageList extends Component {
  render() {
    const pathname = window.location.pathname.toLowerCase();
    const {
      i18n: {
        MyOrders,
        AddressBook,
        ReturnRequests,
        ExchangeRequests,
        LoyaltyProgram,
        Wallet,
        Subscriptions,
        GiftCards,
        Settings,
        AccountInformation,
        Logout,
      },
    } = this.props;
    return (
      <div className="menu">
        <ul>
          {/* Account Information start  */}
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname === "/profile" ? "#000" : "inherit"
                }`,
                color: `${pathname === "/profile" ? "#fff" : "inherit"}`,
              }}
            >
              <Link to="/profile">
                <h6>{AccountInformation}</h6>
                <img src="/img/Forma 1 copy 8.svg" alt="l" />
              </Link>
            </li>
          )}
          {/* Account Information end  */}

          {/* AddressBook start  */}
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname.toLowerCase() === "/address" ? "#000" : "inherit"
                }`,
                color: `${
                  pathname.toLowerCase() === "/address" ? "#fff" : "inherit"
                }`,
              }}
            >
              <Link to="/Address">
                <h6>{AddressBook}</h6>
                <img src="/img/Forma 1 copy 8.svg" alt="l" />
              </Link>
            </li>
          )}
          {/* AddressBook end  */}

          {/* MyOrders start  */}
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname.toLowerCase().startsWith("/orders")
                    ? "#000"
                    : "inherit"
                }`,
                color: `${
                  pathname.toLowerCase().startsWith("/orders")
                    ? "#fff"
                    : "inherit"
                }`,
              }}
            >
              <Link to="/orders/1">
                <h6>{MyOrders}</h6>
                <img src="/img/Forma 1 copy 8.svg" alt="l" />
              </Link>
            </li>
          )}
          {/* MyOrders end  */}

          {/* ReturnRequests start  */}
          {apiService.accessToken !== 0 && (
            <>
              <li
                style={{
                  backgroundColor: `${
                    pathname.toLowerCase() === "/returnrequestsprofilepage"
                      ? "#000"
                      : "inherit"
                  }`,
                  color: `${
                    pathname.toLowerCase() === "/returnrequestsprofilepage"
                      ? "#fff"
                      : "inherit"
                  }`,
                }}
              >
                <Link to="/ReturnRequestsProfilePage">
                  <h6>{ReturnRequests}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
              <li
                style={{
                  backgroundColor: `${
                    pathname.toLowerCase() === "/exchangerequestsprofilepage"
                      ? "#000"
                      : "inherit"
                  }`,
                  color: `${
                    pathname.toLowerCase() === "/exchangerequestsprofilepage"
                      ? "#fff"
                      : "inherit"
                  }`,
                }}
              >
                <Link to="/ExchangeRequestsProfilePage">
                  <h6>{ExchangeRequests}</h6>
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                </Link>
              </li>
            </>
          )}
          {/* ReturnRequests end  */}

          {/* LoyaltyProgram start  */}
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname.toLowerCase().startsWith("/layaltyprogram")
                    ? "#000"
                    : "inherit"
                }`,
                color: `${
                  pathname.toLowerCase().startsWith("/layaltyprogram")
                    ? "#fff"
                    : "inherit"
                }`,
              }}
            >
              <Link to="/LayaltyProgram/1">
                <h6>{LoyaltyProgram}</h6>
                <img src="/img/Forma 1 copy 8.svg" alt="l" />
              </Link>
            </li>
          )}
          {/* LoyaltyProgram end  */}

          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname.toLowerCase().startsWith("/walletpage")
                    ? "#000"
                    : "inherit"
                }`,
                color: `${
                  pathname.toLowerCase().startsWith("/walletpage")
                    ? "#fff"
                    : "inherit"
                }`,
              }}
            >
              <Link to="/WalletPage/1">
                <h6>{Wallet}</h6>
                <img src="/img/Forma 1 copy 8.svg" alt="l" />
              </Link>
            </li>
          )}

          {/* Subscriptions start  */}
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname.toLowerCase().startsWith("/subscriptionsprofilepage")
                    ? "#000"
                    : "inherit"
                }`,
                color: `${
                  pathname.toLowerCase().startsWith("/subscriptionsprofilepage")
                    ? "#fff"
                    : "inherit"
                }`,
              }}
            >
              <Link to="/SubscriptionsProfilePage/1">
                <h6>{Subscriptions}</h6>
                <img src="/img/Forma 1 copy 8.svg" alt="l" />
              </Link>
            </li>
          )}
          {/* Subscriptions end  */}

          {/* GiftCards start  */}
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname.toLowerCase() === "/giftcardprofilepage"
                    ? "#000"
                    : "inherit"
                }`,
                color: `${
                  pathname.toLowerCase() === "/giftcardprofilepage"
                    ? "#fff"
                    : "inherit"
                }`,
              }}
            >
              <Link to="/GiftCardProfilePage">
                <h6>{GiftCards}</h6>
                <img src="/img/Forma 1 copy 8.svg" alt="l" />
              </Link>
            </li>
          )}
          {/* GiftCards end  */}

          {/* Settings start  */}
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname === "/settings" ? "#000" : "inherit"
                }`,
                color: `${pathname === "/settings" ? "#fff" : "inherit"}`,
              }}
            >
              <Link to="/Settings">
                <h6>{Settings}</h6>
                <img src="/img/Forma 1 copy 8.svg" alt="l" />
              </Link>
            </li>
          )}
          {/* Settings end  */}

          {/* Logout start  */}
          {apiService.accessToken !== 0 && (
            <li
              style={{
                backgroundColor: `${
                  pathname === "/Logout" ? "#000" : "inherit"
                }`,
                color: `${pathname === "/Logout" ? "#fff" : "inherit"}`,
              }}
              onClick={async () => {
                // logout and remove user data from localStorage and refresh
                localStorage.removeItem("beam_admin_access_token");
                localStorage.removeItem("beamAccessToken");
                localStorage.removeItem("persist:Beam");
                window.location.href = "/";
              }}
            >
              <a>
                <h6>{Logout}</h6>
                {pathname === "/Logout" && (
                  <img src="/img/Forma 1 copy 8.svg" alt="l" />
                )}
              </a>
            </li>
          )}
          {/* Logout end  */}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps)(AccountPageList);
