import React, { Component } from "react";
import PageTitle from "../../blocks/PageTitle";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { connect } from "react-redux";
import { setUser } from "../../../redux/actions-reducers/user";
import Steps from "../../blocks/Steps";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { generalServices } from "../../../services/GeneralService";
import { tabletScreen } from "../../../utils/misc";
import SubscriptionCard from "./SubscriptionCard";
import PaymentMethods from "./PaymentMethods";
import CheckoutSummaryBlock from "../BeamCheckout/CheckoutSummaryBlock";
import { cartService } from "../../../services/CartService";
import { subscriptionsService } from "../../../services/SubscriptionsService";
import PaginatedItems from "../../Pages/Search/PaginationBlack";
import TextInput from "../../blocks/TextInput";
import SelectInput from "../../blocks/SelectInput";
import UploadFile from "../../blocks/UploadFile";
import LocationBlock from "../../blocks/LocationBlock";
import EditAddLocation from "../AccountPage/addressesPage/EditAddLocation";
import { locationService } from "../../../services/LocationService";
import { ErrorMessage } from "../../blocks/ErrorMessage";

export class Subscriptions extends Component {
  state = {
    adsData: {},
    adsLoader: false,
    currentStep: 1,
    packages: [],
    cartAmount: {},
    cart: [],
    PackageSelected: 0,
    pageLoader: true,
    selectPayment: {},
    withPrice: false,
    packagesMeta: {},
    meta: {},
    currentPage: 1,
    fields: {},
    errors: {},
    extra_data: [],
    locationModal: false,
    locationLoader: true,
    userAddressesData: [],
    selectedLocation: null,
    guestLocation: {},
    handleAddLocation: false,
    radioData: "",
  };

  componentDidMount() {
    Promise.all([
      this.getSingleAds(),
      this.getPackages(1),
      this.getUserLocation(),
    ]).then(() => {
      this.setState({
        pageLoader: false,
        loader: false,
      });
    });
  }

  // get Single Ads start
  getSingleAds = async () => {
    // function to get Single Ads
    const { data, success } = await generalServices.getSingleAds(
      "home_under_categories_block_left_space",
      0,
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      adsData: data,
      adsLoader: false,
    });
  };
  // get Single Ads end

  // on Select Package start
  onSelectPackage = async (id) => {
    await this.setState({
      PackageSelected: id,
    });
    await this.getSubscriptionDetails(id);

    this.nextStep();
  };
  // on Select Package end

  // next Step start
  nextStep = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  };
  // next Step end

  // prev Step start
  prevStep = () => {
    const { currentStep } = this.state;
    if (currentStep === 2) {
      this.setState({
        fields: {},
        extra_data: [],
        currentStep: currentStep - 1,
      });
    } else {
      this.setState({
        currentStep: currentStep - 1,
      });
    }
  };
  // prev Step end

  // get Packages start
  getPackages = async (page) => {
    const { data, meta, success } = await subscriptionsService.getPackages(
      this.props.match.params.page ?? 1
    );
    if (!success) return;

    this.setState({
      packages: data,
      pageLoader: false,
      packagesMeta: meta,
      meta,
    });
  };
  // get Packages end

  // get Checkout Amounts start
  getCheckoutAmounts = async () => {
    // function to get Checkout Amounts and set it to current state
    const { data, success } = await cartService.getCheckoutAmounts({
      payment_id: 0,
      delivery_company_id: 0,
      // place_id: this.props.country.id,
      place_id: "",
      coupons: [localStorage.getItem("beam_coupon")],
    });
    if (!success) return;
    this.setState({
      cartAmount: data,
    });
  };
  // get Checkout Amounts end

  // get Subscription Details start
  getSubscriptionDetails = async (id) => {
    const { data, success } = await subscriptionsService.getSubscriptionDetails(
      id
    );
    if (!success) return;
    this.setState({
      subscriptionDetails: data,
      pageLoader: false,
    });
  };
  // get Subscription Details end

  // get User Location start
  getUserLocation = async () => {
    // function to get user locations and set it to current state
    const { data, success } = await locationService.getUserLocation();
    this.setState({
      locationLoader: false,
    });
    if (!success) return;
    this.setState({
      userAddressesData: data,
      locationLoader: false,
    });
  };
  // get User Location end

  render() {
    const {
      i18n: {
        BEAMSUBSCRIPTIONS,
        PersonalStylingForEverybodyBEAMMonthlySubscriptionPackage,
        BEAMMonthlySubscriptionPackage,
        Home,
        SUBSCRIPTIONS,
        MaxFileSize,
        AddLocationButt,
        selectMonths,
      },
      language,
      currentUser,
    } = this.props;
    const {
      adsLoader,
      packages,
      currentStep,
      meta,
      cart,
      selectPayment,
      pageLoader,
      subscriptionDetails,
      fields,
      errors,
      radioData,
      locationModal,
      locationLoader,
      userAddressesData,
      selectedLocation,
      handleAddLocation,
      extra_data,
      withPrice,
      PackageSelected,
    } = this.state;

    const subscriptionsControlObj = {
      currentStep,
      nextStep: this.nextStep,
      prevStep: this.prevStep,
    };
    return (
      <div
        className={`subscriptions ${
          language === "rtl" ? "subscription-arabic" : ""
        }`}
      >
        {/* page title start  */}
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={BEAMSUBSCRIPTIONS}
          links={[
            { name: Home, url: "/" },
            { name: SUBSCRIPTIONS, url: "/Subscriptions" },
          ]}
        />
        {/* page title end  */}

        <Container>
          {/* subscriptions notes start  */}
          <div className="subscriptions__note">
            <h4>{BEAMMonthlySubscriptionPackage}</h4>
            <p>{PersonalStylingForEverybodyBEAMMonthlySubscriptionPackage}</p>
          </div>
          {/* subscriptions notes end  */}

          {/* steps progress start  */}
          <Steps
            stepNum={this.state.currentStep}
            subscriptionsControlObj={subscriptionsControlObj}
          />
          {/* steps progress end  */}

          {/* packages start  */}
          {currentStep === 1 && (
            <div className="subscriptions__cards my-5">
              {pageLoader ? (
                <>
                  <Row>
                    <Col md={4} className="mb-4">
                      <Skeleton height={400} />
                    </Col>

                    <Col md={4} className="mb-4">
                      <Skeleton height={400} />
                    </Col>

                    <Col md={4} className="mb-4">
                      <Skeleton height={400} />
                    </Col>
                  </Row>
                </>
              ) : (
                <Row>
                  {packages?.map((item, index) => {
                    return (
                      <Col md={4} key={index} className="mb-4">
                        <SubscriptionCard
                          data={item}
                          onSelectPackage={this.onSelectPackage}
                          index={index}
                        />
                      </Col>
                    );
                  })}
                </Row>
              )}
            </div>
          )}
          {currentStep === 2 && (
            <div className="subscriptions__questions my-5">
              <Row>
                <Col md={12} lg={8}>
                  <Row>
                    {subscriptionDetails?.fields?.map((item, index) => {
                      return (
                        <Col sm={6} xs={12} className="mb-3">
                          <div>
                            <p>{item.label}</p>
                          </div>
                          {+item.type === 1 || +item.type === 3 ? (
                            <div>
                              <TextInput
                                name={item.label}
                                label={null}
                                type={+item.type === 3 ? "number" : "text"}
                                placeholder={
                                  item.label + (item.is_required ? " *" : "")
                                }
                                value={fields[item.label]}
                                onFieldChange={(name, value) =>
                                  this.onExtraDataChange(
                                    +item.type,
                                    value,
                                    item
                                  )
                                }
                                validate={errors["field" + (index + 1)]}
                                className="my-input"
                              />
                            </div>
                          ) : +item.type === 2 ? (
                            <div>
                              <SelectInput
                                name={
                                  item.label + (item.is_required ? " *" : "")
                                }
                                label={null}
                                placeholder={
                                  item.label + (item.is_required ? " *" : "")
                                }
                                value={fields[item.label]}
                                onFieldChange={(name, value) =>
                                  this.onExtraDataChange(
                                    +item.type,
                                    value,
                                    value
                                  )
                                }
                                data={item.values?.map(
                                  ({ value: label, id: value }) => ({
                                    value,
                                    label,
                                    id: item.id,
                                  })
                                )}
                                className="my-input"
                                validate={errors["field" + (index + 1)]}
                              />
                            </div>
                          ) : +item.type === 4 ? (
                            <div className="c-f-u-c my-input">
                              <UploadFile
                                name={item.label}
                                uploadImage={(fileImage) =>
                                  this.uploadImage(item.type, item, fileImage)
                                }
                                value={fields[item.label]}
                                className="w-100"
                              />
                              <span
                                style={{
                                  color: "#D49556",
                                  fontSize: "12px",
                                }}
                              >
                                JPEG/PNG {MaxFileSize}
                              </span>
                              {errors["field" + (index + 1)] && (
                                <ErrorMessage
                                  message={errors["field" + (index + 1)]}
                                />
                              )}
                            </div>
                          ) : +item.type === 5 ? (
                            <div>
                              <SelectInput
                                name={item.label}
                                isMulti
                                label={null}
                                placeholder={
                                  item.label + (item.is_required ? " *" : "")
                                }
                                value={fields[item.label]}
                                onFieldChange={(name, value) =>
                                  this.onExtraDataChange(
                                    +item.type,
                                    value,
                                    item
                                  )
                                }
                                className="my-input"
                                data={item.values?.map(
                                  ({ value: label, id: value }) => ({
                                    value,
                                    label,
                                    id: value,
                                  })
                                )}
                                validate={errors["field" + (index + 1)]}
                              />
                            </div>
                          ) : +item.type === 6 ? (
                            <div>
                              <div className="flex radio-type-field my-input">
                                {item.values?.map(({ value }) => {
                                  return (
                                    <div className="agree-check-container agree-signup">
                                      <label
                                        className="checkbox-container"
                                        onClick={() => {
                                          this.setState(
                                            { radioData: value },
                                            () => {
                                              this.onExtraDataChange(
                                                +item.type,
                                                value,
                                                item
                                              );
                                            }
                                          );
                                        }}
                                      >
                                        <p className="d-flex justify-content-center align-items-center">
                                          {value}
                                        </p>
                                        <div
                                          className={`div-radio ${
                                            radioData === value &&
                                            "div-radio-active"
                                          }`}
                                        >
                                          <div></div>
                                        </div>
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                              {errors["field" + (index + 1)] && (
                                <ErrorMessage
                                  message={errors["field" + (index + 1)]}
                                />
                              )}
                            </div>
                          ) : null}
                        </Col>
                      );
                    })}
                    {subscriptionDetails?.months?.length > 0 && (
                      <Col lg={6} md={6} sm={6} className="mb-3">
                        <div>
                          <p>{selectMonths}</p>
                        </div>
                        <SelectInput
                          name={"months"}
                          isMulti
                          label={null}
                          placeholder={"months *"}
                          value={fields["months"]}
                          onFieldChange={this.onFieldChange}
                          data={subscriptionDetails?.months.map(
                            ({ name, id }) => ({ value: id, label: name })
                          )}
                          validate={errors["months"]}
                          className="my-input"
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col md={12} lg={4} className="d-flex justify-content-end">
                  <CheckoutSummaryBlock
                    cartAmount={subscriptionDetails?.calculations}
                    selectedMonths={fields?.months}
                    packageInfo={subscriptionDetails?.information}
                    FirstBlock="package"
                    inSubscriptions={true}
                    subscriptionsControlObj={subscriptionsControlObj}
                    currentStep={this.state.currentStep}
                    extraData={extra_data}
                    putErrors={(errors) => this.setState({ errors })}
                  />
                </Col>
              </Row>
            </div>
          )}
          {/* customize  step end  */}

          {/* select or add location  step start  */}
          {currentStep === 3 && (
            <div className="subscriptions__questions my-5">
              <Row>
                <Col md={12} lg={8}>
                  <Col
                    xl={cart.length > 0 ? 8 : 12}
                    lg={8}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    {pageLoader ? (
                      <Row>
                        <Col>
                          <Skeleton height={tabletScreen ? 100 : 170} />
                        </Col>
                      </Row>
                    ) : (
                      <>
                        {locationLoader ? (
                          <Row>
                            <Col lg={6} md={12}>
                              <Skeleton height={160} />
                            </Col>
                            <Col lg={6} md={12}>
                              <Skeleton height={160} />
                            </Col>
                          </Row>
                        ) : currentUser?.email &&
                          userAddressesData.length > 0 ? (
                          <>
                            <Row>
                              {userAddressesData.map((item) => {
                                return (
                                  <Col lg={6} md={12} key={item.id}>
                                    <LocationBlock
                                      data={item}
                                      loader={this.state.loader}
                                      reFetchData={() => this.getUserLocation()}
                                      // showEditLocation={showEditLocation}
                                      withoutEditDelete
                                      selectedLocation={(location) => {
                                        this.props.setUser({
                                          selectedLocation: location,
                                        });
                                        this.setState({
                                          selectedLocation: location,
                                        });
                                      }}
                                    />
                                  </Col>
                                );
                              })}
                            </Row>
                            {/* user Locations end  */}

                            <button
                              className="submit-button2"
                              onClick={() =>
                                this.setState({ locationModal: true })
                              }
                            >
                              {AddLocationButt}
                            </button>
                          </>
                        ) : (
                          <>
                            <EditAddLocation
                              closeModal={() =>
                                this.setState({ locationModal: false })
                              }
                              reFetchData={() => this.getUserLocation()}
                              isAdd={true}
                              data={{}}
                              subscriptionsControlObj={subscriptionsControlObj}
                              fromCheckOut
                              storeLocation={(data) =>
                                this.setState({ selectedLocation: data })
                              }
                              fromGuest={true}
                              handleAddLocation={handleAddLocation}
                              restHandleAddLocation={() =>
                                this.setState({ handleAddLocation: false })
                              }
                            />
                          </>
                        )}
                      </>
                    )}
                  </Col>
                </Col>
                <Col md={12} lg={4} className="d-flex justify-content-end">
                  <CheckoutSummaryBlock
                    cartAmount={subscriptionDetails?.calculations}
                    selectedMonths={fields?.months}
                    packageInfo={subscriptionDetails?.information}
                    FirstBlock="package"
                    inSubscriptions={true}
                    subscriptionsControlObj={subscriptionsControlObj}
                    currentStep={this.state.currentStep}
                    selectedLocation={selectedLocation}
                    handleAddLocation={() =>
                      this.setState({ handleAddLocation: true })
                    }
                  />
                </Col>
              </Row>
            </div>
          )}
          {/* select or add location  step end  */}
          {/* payment methods step start  */}
          {currentStep === 4 && (
            <div className="subscriptions__questions my-5">
              <Row>
                <Col md={12} lg={8}>
                  <PaymentMethods
                    place_id={
                      this.props.currentUser?.selectedLocation?.place?.id
                    }
                    selectPayment={(payment) =>
                      this.setState({ selectPayment: payment }, () =>
                        this.getCheckoutAmounts()
                      )
                    }
                    withPriceChange={(data) =>
                      this.setState({ withPrice: data })
                    }
                    subscriptions
                  />
                </Col>
                <Col md={12} lg={4} className="d-flex justify-content-end">
                  <CheckoutSummaryBlock
                    cartAmount={subscriptionDetails?.calculations}
                    selectedMonths={fields?.months}
                    packageInfo={subscriptionDetails?.information}
                    FirstBlock="package"
                    subscriptionsControlObj={subscriptionsControlObj}
                    currentStep={this.state.currentStep}
                    selectPayment={selectPayment}
                    inSubscriptions={true}
                    withPrice={withPrice}
                    extraData={extra_data}
                    months={fields?.months?.map(({ value }) => value)}
                    PackageSelected={PackageSelected}
                    subscriptions
                  />
                </Col>
              </Row>
            </div>
          )}
          {/* payment methods step end  */}

          {/* Pagination Start */}
          {pageLoader ? null : meta?.last_page > 1 ? (
            <Row>
              <Col md={12}>
                <div className="Pagination">
                  <PaginatedItems // pagination component that handle the pages
                    items={[...Array(meta?.total).keys()]}
                    itemsPerPage={meta?.per_page}
                    changePage={(page) => this.changePage(page)}
                  />
                </div>
              </Col>
            </Row>
          ) : null}
          {/* Pagination End */}

          {/* ads section start  */}
          <div md={12} className="mb-5">
            <div className="CartAds">
              {adsLoader ? (
                <Row>
                  <Col>
                    <Skeleton height={tabletScreen ? 110 : 200} />
                  </Col>
                </Row>
              ) : (
                <Row className="no-margin">
                  {/* {adsData?.ad_image && ( */}
                  {/**<Col xs={12} className=" no-padd mt-5">
                    <a href="#">
                      <img src="/images/Advertising.png" />
                    </a>
                  </Col> */}
                  {/* )} */}
                </Row>
              )}
            </div>
          </div>
          {/* ads section end  */}
        </Container>
        {/* add location modal  */}
        <Modal
          size="lg"
          show={locationModal}
          onHide={() => this.setState({ locationModal: false })}
          aria-labelledby="example-modal-sizes-title-lg"
          className="login-modal"
        >
          <Modal.Header
            closeButton
            className="login-modal__header"
          ></Modal.Header>
          <Modal.Body className="login-modal__body mb-3">
            <EditAddLocation
              show={locationModal}
              closeModal={() => this.setState({ locationModal: false })}
              reFetchData={() => this.getUserLocation()}
              currentState={"add"}
              isAdd={true}
              data={{}}
            />
          </Modal.Body>
        </Modal>
        {/* add location Modal  */}
      </div>
    );
  }

  // change Page function start
  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(`/Subscriptions/${+page + 1}`);

    this.setState(
      {
        pageLoader: true,
        currentPage: +page + 1,
      },
      () => {
        this.getPackages(+page + 1);
      }
    );
  }
  // change Page function end

  // on extra data change start
  onExtraDataChange = (type, value, item) => {
    const { extra_data, fields } = this.state;
    const addNew = (data) => {
      if (type !== 2 && type !== 5) {
        this.setState({
          fields: {
            ...fields,
            [item.label]: value,
          },

          extra_data: [
            ...data,
            {
              field_id: item.id,
              field_value_ids: "",
              value_text: value,
            },
          ],
        });
      } else if (+type === 5) {
        this.setState({
          fields: {
            ...fields,
            [item.label]: value,
          },
          extra_data: [
            ...data,
            {
              field_id: item?.id,
              field_value_ids: value.map(({ id }) => id),
              value_text: value.map(({ label }) => label),
            },
          ],
        });
      } else {
        this.setState({
          fields: {
            ...fields,
            [item.label]: value,
          },
          extra_data: [
            ...data,
            {
              field_id: value.id,
              field_value_ids: value.value,
              value_text: value.label,
            },
          ],
        });
      }
    };

    if (extra_data?.findIndex(({ field_id }) => field_id === item?.id) === -1) {
      addNew(extra_data);
    } else {
      let newExtraData = extra_data.filter(
        ({ field_id }) => field_id !== item.id
      );

      addNew(newExtraData);
    }
  };
  // on extra data change start

  // on fields change start
  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {},
    });
  };
  // on fields change end

  // uploadImage start
  uploadImage = async (type, item, fileImage) => {
    // upload Image function
    const formData = new FormData();
    formData.append("file", fileImage);
    const { data } = await generalServices.uploadImage(formData);
    this.setState({
      image: data,
    });

    this.onExtraDataChange(type, data, item);
  };
  // uploadImage end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setUser })(Subscriptions);
