import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import PageTitle from "../../blocks/PageTitle";
import { categoryService } from "../../../services/CategoryService";
import PaginatedItems from "../../Pages/Search/PaginationBlack";
import Product from "../../blocks/Product";

class Sale extends Component {
  state = {
    saleProducts: [],
    loaderPage: true,
    meta: {},
  };

  async componentDidMount() {
    this.getSaleProducts();
  }
  // get Sale Products start
  getSaleProducts = async () => {
    // function to get Single Category Products and set it to current state
    const { data, success, meta } =
      await categoryService.getSingleCategoryProducts({
        machine_name: 0,
        page: +this.props.match.params.page,
        options_values: [],
        brands: [],
        rate: "",
        price_from: "",
        price_to: "",
        free_shipping: false,
        new_arrivals: false,
        sort: "",
        has_discount: true,
        display: 20,
      });

    if (!success) return;

    this.setState({
      saleProducts: data,
      meta: meta,
      loaderPage: false,
    });
  };
  // get Sale Products end

  render() {
    const { saleProducts, loaderPage, meta } = this.state;
    const {
      i18n: { SALE, saleEmpty, Home },
    } = this.props;

    return (
      <div className="Cart" style={{ paddingBottom: 50 }}>
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={SALE}
          links={[
            { name: Home, url: "/" },
            { name: SALE, url: "/Sale/1" },
          ]}
        />

        {/* Start */}
        <Container>
          <Row className="g-4 mb-4">
            {loaderPage ? (
              Array(8)
                .fill(4)
                .map((item, index) => (
                  <Col xl={3} lg={4} md={6} xs={6} key={index}>
                    <Skeleton className="product-image-Skeleton" />
                    <div className="px-2 py-3">
                      <div className="mb-2 px-3 px-sm-4">
                        <Skeleton height={25} />
                      </div>
                      <div className="px-4 px-sm-5">
                        <Skeleton height={25} />
                      </div>
                    </div>
                  </Col>
                ))
            ) : saleProducts.length === 0 ? (
              <Col md={12}>
                <div className="empty-products mb-5">
                  <img src="/img/saleEmpty.svg" alt="k" />
                  <p> {saleEmpty} </p>{" "}
                </div>
              </Col>
            ) : (
              saleProducts.map((item, index) => {
                return (
                  <Col xl={3} lg={4} md={6} xs={6}>
                    <Product // Product card that takes product as data and show product as a card with image
                      data={item}
                      key={index}
                      reFetchData={() => this.getUserFavorites()}
                      fromSale
                    />
                  </Col>
                );
              })
            )}
          </Row>

          {loaderPage ? null : meta?.last_page > 1 ? (
            <Row>
              <Col md={12}>
                <div className="Pagination">
                  <PaginatedItems // pagination component that handle the pages
                    items={[...Array(meta?.total).keys()]}
                    itemsPerPage={meta?.per_page}
                    changePage={(page) => this.changePage(page)}
                  />
                </div>
              </Col>
            </Row>
          ) : null}
          
        </Container>
      </div>
    );
  }

  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(`/sale/${page + 1}`);
    this.setState(
      {
        loaderPage: true,
      },
      () => {
        this.getSaleProducts();
      }
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});

export default connect(mapStateToProps, { setLanguage })(Sale);
