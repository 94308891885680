import React, { Component } from "react";
import { Carousel, Container } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../../utils/misc";

class HomeCarousel extends Component {
  render() {
    const {
      sliders = [],
      sliderLoader,
    } = this.props;
    return (
      <div className="home-carousel">
        {sliderLoader ? (
          <Skeleton className="home-carousel__img_skeleton" />
        ) : (
          <Carousel // slider components
            interval={null}
            controls={sliders?.length < 2 ? false : true}
            indicators={sliders?.length < 2 ? false : true}
          >
            {sliders?.map(
              ({ image, link, button_text, mobile_image }, index) => {
                return (
                  <Carousel.Item key={index}>
                    {/* carousel image start  */}
                    <img
                      className="d-block w-100 home-carousel__img"
                      src={
                        tabletScreen
                          ? apiService.imageLink + mobile_image
                          : apiService.imageLink + image
                      }
                      // src="/img/tp182-sasi-instagramad-07.jpg"
                      alt="First slide"
                    />
                    {/* carousel image end  */}

                    <Container>
                      {/* carousel button start  */}
                      <Carousel.Caption>
                        {button_text && (
                          <button
                            className="carousel-btn"
                            onClick={() => {
                              if (link) {
                                window.open(link, "_blank");
                              }
                            }}
                          >
                            <p className="m-0">{button_text}</p>
                          </button>
                        )}
                      </Carousel.Caption>
                      {/* carousel button end  */}
                    </Container>
                  </Carousel.Item>
                );
              }
            )}
          </Carousel>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(HomeCarousel);
