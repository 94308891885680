import React from "react";
import moment from "moment";
import { Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

export default function InvoiceToPrint({ items, ...props }) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const {
    Total,
    Country,
    Area,
    Building,
    ApartmentNumber,
    FloorNumber,
    Phone,
    Email,
    Invoice,
    Discount,
    DeliveryFeez,
    Product,
    UnitPrice,
    Quantity,
    itemOptions,
    Note,
    ThankYouForOrdering,
    OrderAmount,
    InvoiceDate,
    Status,
  } = i18n;

  const statusTranslatot = (statusText) => {
    return language.langCode === "ltr"
      ? statusText
      : statusText == "Pending"
      ? "قيد الانتظار"
      : statusText == "Cacnelled"
      ? "ملغي"
      : statusText == "Accepted"
      ? "تم القبول"
      : statusText == "On the Way"
      ? "في الطريق"
      : statusText == "Arrived"
      ? "تم التوصيل"
      : statusText == "Completed"
      ? "مكتمل"
      : "";
  };
  return (
    <div
      className={`pagebreak w-100 bg-white invoice-to-print ${language.langCode}`}
    >
      <div className="p-4">
        {props.data && props.data.receiver_details && (
          <div className="p-4">
            <div className="print:p-0">
              <div style={{ height: 100 }}></div>
              <div className="mb-4">
                <div>
                  <Row className="align-items-center g-4">
                    <Col item xs={12}>
                      <div className="mb-4">
                        <h1 className="fw-light" variant="h6" color="inherit">
                          BEAM SHOP
                        </h1>
                      </div>
                      <div style={{ marginBottom: 12 }}>
                        <h6
                          color="h6"
                          className="fw-bold"
                        >{`${props.data.receiver_details?.person_name}`}</h6>
                      </div>
                      <p>{`${Country}: ${props.data?.receiver_details?.country_name}`}</p>
                      {props.data?.receiver_details?.city_name && (
                        <p>{`${Area}: ${props.data?.receiver_details?.city_name}`}</p>
                      )}

                      {props.data.receiver_details?.extra?.map(
                        ({ place_field, value_text }) => {
                          if (
                            typeof value_text === "string" &&
                            value_text?.trim()
                          ) {
                            if (Number(place_field?.type) === 4)
                              return (
                                <p className="flex">
                                  {`${
                                    place_field?.label[
                                      language.langCode === "rtl" ? "ar" : "en"
                                    ]
                                  }: `}
                                  <img
                                    src={value_text}
                                    width={100}
                                    className="px-12"
                                  />
                                </p>
                              );
                            else
                              return (
                                <p>{`${
                                  place_field?.label[
                                    language.langCode === "rtl" ? "ar" : "en"
                                  ]
                                }: ${value_text}`}</p>
                              );
                          }
                        }
                      )}
                      <p>{`${Building}: ${props.data.receiver_details?.building}`}</p>
                      <p>{`${ApartmentNumber}: ${props.data.receiver_details?.appartment}`}</p>
                      <p>{`${FloorNumber}: ${props.data.receiver_details?.floor}`}</p>
                      <p>{`${Status}: ${
                        props.data?.statusText &&
                        statusTranslatot(props.data?.statusText)
                      }`}</p>
                    </Col>
                    <Col item xs={6}>
                      {props.data.receiver_details?.phone && (
                        <p>
                          {Phone}: {props.data.receiver_details?.phone}
                        </p>
                      )}
                      {props.data.receiver_details?.email && (
                        <p>
                          {Email}: {props.data.receiver_details?.email}
                        </p>
                      )}
                    </Col>
                    <Col item xs={6}>
                      <div>
                        <Row className="gy-2">
                          <Col item xs={6}>
                            <p className="fw-bold">{Invoice}</p>
                          </Col>
                          <Col item xs={6}>
                            <p className="fw-bold">{props.data.id}</p>
                          </Col>
                          <Col item xs={6}>
                            <p>{InvoiceDate}</p>
                          </Col>
                          <Col item xs={6}>
                            <p>
                              {moment(props.data.created_at).format(
                                "yy-MM-DD h:mm:ss a"
                              )}
                            </p>
                          </Col>
                          <Col item xs={6}>
                            <p>{OrderAmount}</p>
                          </Col>
                          <Col item xs={6}>
                            <p>{props.data.total_amount} KWD</p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="mb-2">
                <Table size="small" className="mw-100 w-100">
                  <thead>
                    <tr className="border-bottom border-black border-2">
                      <th className="text-black">{Product}</th>
                      <th className="text-black">{UnitPrice}</th>
                      <th className="text-black">{Quantity}</th>
                      <th className="text-black">{itemOptions}</th>
                      <th className="text-black">{Total}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((product) => (
                      <tr
                        className="border-bottom border-black border-2"
                        key={product.id}
                      >
                        <td className="text-black">
                          <p className="subtitle1">{product.product.title}</p>
                        </td>
                        <td className="text-black">
                          {formatter.format(product.unit_price)} KWD
                        </td>
                        <td className="text-black">{product.qty}</td>
                        <td className="text-black">
                          {product.option_values.join(", ")}
                        </td>
                        <td className="text-black">
                          {product.total_price} KWD
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className="">
                <Row className="align-items-center g-4">
                  <Col item xs={6}></Col>
                  <Col item xs={6}>
                    <div className="flex justify-content-between mb-2">
                      <div>
                        <p className="fw-normal subtitle1">{Discount}</p>
                      </div>
                      <div align="right">
                        <p className="fw-normal subtitle1">
                          {formatter.format(props.data.discount_amount)} KWD
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-content-between mb-2">
                      <div>
                        <p className="fw-normal subtitle1">{DeliveryFeez}</p>
                      </div>
                      <div align="right">
                        <p className="fw-normal subtitle1">
                          {formatter.format(props.data.delivery_amount)} KWD
                        </p>
                      </div>
                    </div>

                    {props.data.coupons && props.data.coupons?.length > 0 && (
                      <>
                        <div className="flex justify-content-between mb-2">
                          <div>
                            <p className="fw-normal subtitle1">Coupon Code</p>
                          </div>
                          <div align="right">
                            <p className="fw-normal subtitle1">
                              {props.data.coupons[0].coupon.code}
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="flex justify-content-between border border-black border-2 p-1">
                      <div>
                        <p className="fw-normal subtitle1">{Total}</p>
                      </div>
                      <div>
                        <p className="fw-normal subtitle1">
                          {formatter.format(props.data.total_amount)} KWD
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <p>{Note}:</p>
                <div>------------------------------</div>
                <p>{ThankYouForOrdering}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
