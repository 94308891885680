import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Pdf from "react-to-pdf";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { userService } from "../../services/UserService";
import PageTitle from "./PageTitle";
import SummaryProduct from "./SummaryProduct";
import InvoiceToPrint from "./InvoiceToPrint";

function FinalInvoice({ is_subsecribtion }) {
  const currency = useSelector(({ currency }) => currency);
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const [OrderDetails, setOrderDetails] = useState({});
  const params = useParams();
  const {
    TotalPrice,
    SubTotal,
    Print,
    orderRef,
    deliveryAmount,
    feesAmount,
    taxesAmount,
    T,
    DeliveryAddress,
    ThankYou,
    Download,
    ThankYouOrderTitle,
    OrderID,
    PaymentMethod,
    PaymentStatus,
    Home,
    Categories,
    Product,
    ProcerdtoCheckout,
  } = i18n;

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    Promise.all([getOrderInvoice()]);
  }, []);

  const getOrderInvoice = async () => {
    // function to get order invoices from the server and set them on setOrderDetails
    const { data, success } = await userService.getOrderInvoice(params.id);
    if (!success) return;
    setOrderDetails(data);
    setLoader(false);
  };

  let componentRef = useRef();

  let componentPrintRef = React.createRef();

  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
  });

  const statusTranslatot = (statusText) => {
    return language.langCode === "ltr"
      ? statusText
      : statusText == "Pending"
      ? "قيد الانتظار"
      : statusText == "Cacnelled"
      ? "ملغي"
      : statusText == "Accepted"
      ? "تم القبول"
      : statusText == "On the Way"
      ? "في الطريق"
      : statusText == "Arrived"
      ? "تم التوصيل"
      : statusText == "Completed"
      ? "مكتمل"
      : "";
  };

  return (
    <div ref={componentRef}>
      <PageTitle // component that shows links to the page ( Bread Crumb )
        title={ThankYou}
        links={[
          { name: Home, url: "/" },
          { name: Categories, url: "/" },
          { name: Product, url: "/" },
          { name: ProcerdtoCheckout, url: "/Checkout" },
        ]}
      />
      <Container className="invoice-block-container mb-5">
        <div className="invoice-block-content final-invoice w-100">
          <header className="invoice-block-header text-start mb-2">
            {ThankYouOrderTitle}
          </header>
          {/* order reference start  */}
          <div className="invoice-block__order-ref">
            <span>{orderRef}:</span>
            <span>{params.id}</span>
          </div>
          {/* order reference end  */}

          <div className="final-invoice__date-track-btn d-flex justify-content-between align-items-center w-100 border-bot">
            <div className="final-invoice__date">
              {/* order create date  start  */}
              <span>
                {moment(OrderDetails?.order_information?.created_at).format(
                  "yy-MM-DD"
                )}{" "}
                | {OrderID}:{" "}
              </span>
              {/* order create date  end  */}

              {/* order id  start  */}
              <span>#{OrderDetails?.order_information?.id}</span>
              {/* order id  end  */}
            </div>
          </div>

          {/* order products start  */}
          {loader ? (
            <Skeleton height={200} />
          ) : (
            OrderDetails?.items?.map((item, index) => {
              return (
                <div
                  className="cart-summary__section__body__summary-field flex-col"
                  key={index}
                >
                  <div className="product-container w-100">
                    <SummaryProduct
                      product={item}
                      product_option={item.option_values}
                      is_subsecribtion={is_subsecribtion}
                      invoice={true}
                    />
                  </div>
                </div>
              );
            })
          )}
          {/* order products end  */}

          <div className="invoice-info__container d-flex justify-content-between mt-3">
            <div className="invoice-info__block">
              <header className="invoice-block-header text-start text-capitalize mb-2">
                {DeliveryAddress}
              </header>
              {/* delivery address and Recipient info start   */}
              <div className="invoice-block__body mt-3">
                {/* person_Address start  */}
                <p className="m-0 text-start">
                  {OrderDetails?.order_information?.receiver_details.address}
                </p>
                {/* person_Address start  */}

                {/* person Phone start  */}
                <div className="addresses__block__phone flex">
                  <span className="d-block m-3 ms-0">
                    <b>{T} : </b>
                  </span>
                  <span className="d-block m-3 ms-1">{` ${
                    OrderDetails?.order_information?.receiver_details.phone ??
                    ""
                  } `}</span>
                </div>
                {/* person Phone end  */}
              </div>
              {/* delivery address and Recipient info end   */}
            </div>

            {/* payment method and status start  */}
            <div className="invoice-info__block">
              {/* payment method name start  */}
              <header className="invoice-block-header text-start text-capitalize">
                {PaymentMethod}
              </header>
              <div className="invoice-block__body mt-3">
                <p className="m-0 text-start">
                  {OrderDetails?.order_information?.payment_method.name}
                </p>
              </div>
              {/* payment method name end  */}

              {/* payment method status start  */}
              <header className="invoice-block-header text-start text-capitalize mt-2">
                {PaymentStatus}
              </header>
              <div className="invoice-block__body mt-3">
                <p className="m-0 text-start">
                  {OrderDetails?.order_information?.statusText &&
                    statusTranslatot(
                      OrderDetails?.order_information?.statusText
                    )}
                </p>
              </div>
              {/* payment method status end  */}
            </div>
            {/* payment method and status end  */}

            {/* amounts summary start  */}
            <div className="invoice-info__block">
              <div className="invoice-block__body invoice-method mt-3">
                <div>
                  {/* subtotal amount start  */}
                  <div className="cart-summary__section__body__summary-field d-flex justify-content-between mt-3 pb-3 border-bot">
                    <div className="summary-field__title">{SubTotal}</div>
                    <div className="summary-field__value">
                      {OrderDetails?.order_information?.items_amount &&
                        (
                          OrderDetails?.order_information?.items_amount *
                          currency.currencyFactor
                        ).toFixed(2)}{" "}
                      {currency.currencyName}
                    </div>
                  </div>
                  {/* subtotal amount end  */}

                  {/* fees amount start  */}
                  {OrderDetails?.order_information?.fees_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field d-flex justify-content-between mt-3 border-bot pb-3">
                      <div className="summary-field__title">{feesAmount}</div>
                      <div className="summary-field__value">
                        {(
                          OrderDetails?.order_information?.fees_amount *
                          currency.currencyFactor
                        ).toFixed(2)}{" "}
                        {currency.currencyName}
                      </div>
                    </div>
                  )}
                  {/* fees amount end  */}

                  {/* taxes amount start  */}
                  {OrderDetails?.order_information?.taxes_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field d-flex justify-content-between mt-3 border-bot pb-3">
                      <div className="summary-field__title">{taxesAmount}</div>
                      <div className="summary-field__value">
                        {(
                          OrderDetails?.order_information?.taxes_amount *
                          currency.currencyFactor
                        ).toFixed(2)}{" "}
                        {currency.currencyName}
                      </div>
                    </div>
                  )}
                  {/* taxes amount end  */}

                  {/* delivery amount start  */}
                  {OrderDetails?.order_information?.delivery_amount > 0 && (
                    <div className="cart-summary__section__body__summary-field d-flex justify-content-between mt-3 border-bot pb-3">
                      <div className="summary-field__title">
                        {deliveryAmount}
                      </div>
                      <div className="summary-field__value">
                        {(
                          OrderDetails?.order_information?.delivery_amount *
                          currency.currencyFactor
                        ).toFixed(2)}{" "}
                        {currency.currencyName}
                      </div>
                    </div>
                  )}
                  {/* delivery amount end  */}

                  {/* total amount start  */}
                  <div className="cart-summary__section__body__summary-field total-field d-flex justify-content-between mt-3 pb-3">
                    <div className="summary-field__title  bold-18">
                      {TotalPrice}
                    </div>
                    <div className="summary-field__value  bold-18">
                      <span>
                        {OrderDetails?.order_information?.total_amount &&
                          (
                            OrderDetails?.order_information?.total_amount *
                            currency.currencyFactor
                          ).toFixed(2)}{" "}
                        {currency.currencyName}
                      </span>
                    </div>
                  </div>
                  {/* total amount end  */}
                </div>
              </div>
            </div>
            {/* amounts summary end  */}
          </div>

          {/* download and print buttons start  */}
          {OrderDetails?.order_information && (
            <div className="invoice-btns-container text-start ">
              {language.langCode === "rtl" ? (
                <button
                  className="invoice-btn black-one ms-0"
                  onClick={handlePrint}
                >
                  {Download}
                </button>
              ) : (
                <Pdf
                  targetRef={componentPrintRef}
                  filename={`order-${params.id}-invoice.pdf`}
                  options={{
                    R2L: true,
                    align: "right",
                    rtl: true,
                  }}
                >
                  {({ toPdf }) => (
                    <button
                      className="invoice-btn black-one ms-0"
                      onClick={toPdf}
                    >
                      {Download}
                    </button>
                  )}
                </Pdf>
              )}

              <div className="show-on-print" ref={componentPrintRef}>
                {/* print component */}
                <InvoiceToPrint
                  items={OrderDetails.items}
                  data={OrderDetails?.order_information}
                />
              </div>
              <button
                onClick={handlePrint}
                className="invoice-btn gray-one ms-0"
              >
                {Print}
              </button>
            </div>
          )}
          {/* download and print buttons start  */}
        </div>
      </Container>
    </div>
  );
}

export default FinalInvoice;
