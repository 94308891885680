import React, { useEffect, useState } from "react";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { Modal } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";
import { generalServices } from "../../../services/GeneralService";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import { ErrorMessage } from "../../blocks/ErrorMessage";
import LoginModal from "../../blocks/LoginModal";
import Swal from "sweetalert2";

function GiftCardBlock({ data }) {
  const [fields, setFields] = useState({
    amount: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    amount: "",
    email: "",
    message: "",
    payment_method_id: "",
  });

  const language = useSelector(({ language }) => language);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const country = useSelector(({ country }) => country);
  const [open, setOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [PaymentChecked, setPaymentChecked] = useState(-1);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const {
    SELECTCARD,
    Amount,
    EmailOrPhone,
    message,
    PaymentMethod,
    GIFTCARD,
    PleaseFillFormToEnjoy,
    submit,
    error,
    thisFeaturesJustForUsers,
    Sorry,
    Login,
    Register,
  } = i18n;
  const history = useHistory();
  const [openLogin, setOpenLogin] = useState(false);

  const onFieldChange = async (name, value) => {
    // Field Change handler
    setFields({ ...fields, [name]: value });
    setErrors({ amount: "", email: "" });
  };

  useEffect(() => {
    getPaymentMethodsByPlaceID();
  }, []);

  // get Payment Methods By Place ID function start
  const getPaymentMethodsByPlaceID = async () => {
    // function to get Payment Methods By Place ID and set it to paymentMethods
    const { success, data } = await generalServices.getPaymentMethodsByPlaceID(
      country.id
    );
    if (!success) return;
    setPaymentMethods(data);
  };

  const createGiftCardOrder = async () => {
    const {
      success,
      data: apiData,
      errors,
      message,
    } = await generalServices.createGiftCardOrder({
      gift_card_id: data.id,
      payment_method_id: PaymentChecked.id,
      amount: fields.amount,
      email: fields.email,
      message: fields.message,
    });

    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        setErrors({ ...handelErrors });

        return;
      }
      return displayAlert(error, message, "error");
    }

    if (apiData.has_payment_link) {
      window.location.href = apiService.webURL + apiData.payment_link;
    } else {
      history.push(`/GiftCardsDetails/${apiData.txn_ref}`);
    }
  };
  // create Gift Card Order function end

  return (
    <div className="gift-card-block">

      <LoginModal
        loginShow={openLogin}
        closeModal={() => setOpenLogin(false)}
      />

      {/* gift card image start  */}
      <div className="gift-card-block__content mb-1">
        <img src={apiService.imageLink + data.image} alt="l" />
      </div>
      {/* gift card image end  */}

      {/* select button start  */}
      <div className="gift-card-block__footer flex">
        <span>{data.name}</span>
        <div
          onClick={() => {
            if (currentUser?.email) {
              setOpen(true);
              setErrors({
                amount: "",
                email: "",
                payment_method_id: "",
              });
            } else {
              Swal.fire({
                title: Sorry,
                showCloseButton: true,
                text: thisFeaturesJustForUsers,

                showConfirmButton: true,
                showDenyButton: true,

                confirmButtonText: Login,
                denyButtonText: Register,

                confirmButtonColor: "#000",
                denyButtonColor: "#000",
              }).then((value) => {
                if (value.isDenied) history.push("/signup");
                else if (value.isConfirmed) setOpenLogin(true);
              });
            }
          }}
        >
          {SELECTCARD}
        </div>
      </div>
      {/* select button end  */}

      {/* gift form start  */}
      <Modal
        size="md"
        show={open}
        onHide={() => {
          setOpen(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        className={`login-modal giftCard-modal ${
          language.langCode === "rtl" ? "giftCard-modal-arabic" : ""
        }`}
      >
        <Modal.Header
          closeButton
          className="login-modal__header"
        ></Modal.Header>
        <Modal.Body className="login-modal__body mb-3">
          <div>
            <h3>{GIFTCARD}</h3>
            <p>{PleaseFillFormToEnjoy}</p>
          </div>
          {/* amount input start  */}
          <TextInput
            name="amount"
            label={null}
            placeholder={Amount}
            value={fields.amount}
            onFieldChange={onFieldChange}
            validate={errors.amount}
          />
          {/* amount input start  */}

          {/* email input start  */}
          <TextInput
            name="email"
            label={null}
            placeholder={EmailOrPhone}
            value={fields.email}
            onFieldChange={onFieldChange}
            validate={errors.email}
          />
          {/* email input start  */}

          {/* message input start  */}
          <TextInput
            name="message"
            label={null}
            placeholder={message}
            value={fields.message}
            onFieldChange={onFieldChange}
            validate={errors.message}
            isTextArea
          />
          {/* message input start  */}

          {/* payment methods start  */}
          <h6>{PaymentMethod}</h6>
          <ul className="flex">
            {paymentMethods.map((item) => {
              if (item.id == 1) {
                return (
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="radio"
                        name="PaymentChecked"
                        value={PaymentChecked}
                        onChange={() => setPaymentChecked(item)}
                      />
                      <span className="checkmark"></span>
                      <img src={apiService.imageLink + item.image} alt="k" />
                    </label>
                  </li>
                );
              }
            })}
          </ul>
          {errors.payment_method_id && (
            <ErrorMessage message={errors.payment_method_id} />
          )}

          {/* payment methods end  */}
          {/* submit button start  */}
          <button className="submit-button2" onClick={createGiftCardOrder}>
            {submit}
          </button>
          {/* submit button end  */}
        </Modal.Body>
      </Modal>
      {/* gift form end  */}
      
    </div>
  );
}

export default GiftCardBlock;
