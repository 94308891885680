import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService"; // api service class that contains general services ( functions and variables )

class UserService {
  // guest Id getter function
  get guestId() {
    return localStorage.getItem("guestId");
  }

  // checkout Submit function
  async checkoutSubmit(checkOutData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.user.checkoutSubmit, {
          ...checkOutData,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get User Orders function
  async getUserOrders(page, ordersData) {
    try {
      const data = await apiService
        .authenticated()
        .post(`${apiEndPoints.user.getUserOrders}?page=${page}`, {
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
          ...ordersData,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get My Wallet function
  async getMyWallet(page) {
    try {
      const data = await apiService
        .authenticated()
        .get(`${apiEndPoints.user.getMyWallet}?page=${page}`, {
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Order Invoice function
  async getOrderInvoice(id) {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.user.getOrderInvoice}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }&order_reference=${id}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get User Information function
  async getUserInformation() {
    try {
      const data = await apiService
        .authenticated()
        .get(apiEndPoints.user.getUserInformation)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Gest Information function
  async getGestInformation(guestId) {
    try {
      const data = await apiService
        .authenticated()
        .get(`/checkGuestID?guest_id=${guestId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // update General Info function
  async updateGeneralInfo(userGeneralData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.user.updateGeneralInfo, userGeneralData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // update Security Info function
  async updateSecurityInfo(updateSecurityInfo) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.user.updateSecurityInfo, updateSecurityInfo)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Gift Cards Orders function
  async getGiftCardsOrders() {
    try {
      const data = await apiService
        .authenticated()
        .get(apiEndPoints.user.getGiftCardsOrders)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Refund Reasons function
  async getRefundReasons() {
    try {
      const data = await apiService
        .authenticated()
        .get(apiEndPoints.user.getRefundReasons)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Return Requests function
  async getReturnRequests() {
    try {
      const data = await apiService
        .authenticated()
        .get(apiEndPoints.user.getReturnRequests)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Exchange Requests function
  async getExchangeRequests() {
    try {
      const data = await apiService
        .authenticated()
        .get(apiEndPoints.user.getExchangeRequests)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // submit Return Request function
  async submitReturnRequest(returnData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.user.submitReturnRequest, returnData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // submit Exchange function
  async submitExchange(returnData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.user.submitExchange, returnData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get My Points function
  async getMyPoints(page) {
    try {
      const data = await apiService
        .authenticated()
        .get(apiEndPoints.user.getMyPoints + "?page=" + page)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get My Loyality Rewards function
  async getMyLoyalityRewards() {
    try {
      const data = await apiService
        .authenticated()
        .get(apiEndPoints.user.getMyLoyalityRewards)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const userService = new UserService();
