import React from "react";
import { useSelector } from "react-redux";
import { generalServices } from "../../../services/GeneralService";
import SelectInput from "../../blocks/SelectInput";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { locationService } from "../../../services/LocationService";
import { Modal } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../blocks/TextInput";
import { displayAlert, getResponseErrors } from "../../../utils/misc";
import UploadFile from "../../blocks/UploadFile";

const haveToEnter = ["firstName", "middleName", "lastName"]; // the data that you chick on

export default function ChoseCity({
  selectedLocation,
  check,
  modal,
  setModal,
  setCheck,
  setLoading,
}) {
  const { langCode } = useSelector(({ language }) => language);
  const {
    middleName,
    completeYourInformation,
    thisFieldIsRequired,
    Save,
    Area,
    FirstName,
    LastName,
    FileSelected,
    success: successText,
    LocationAddedSuccessfully,
  } = Global[langCode === "ltr" ? "en" : "ar"];

  const [fields, setFields] = React.useState({
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    country: "",
    city: "",
    building: "",
    buildingType: 1,
  });
  const [extraFields, setExtraFields] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [cities, setCities] = React.useState([]);
  const [checkGo, setCheckGo] = React.useState(false);
  const [placeFields, setPlaceFields] = React.useState([]);
  const [extra_data, setExtra_data] = React.useState([]);
  const [extra_data_images, setExtra_data_images] = React.useState({});
  const [imageLoader, setImageLoader] = React.useState(false);

  const onExtraDataChange = (type, value, item) => {
    // on Extra Data Change place Extra Fields
    setErrors({
      firstName: "",
      lastName: "",
      address: "",
      building: "",
      phone: "",
      floor: "",
      appartment: "",
    });
    const addNew = (data) => {
      if (type !== 2) {
        setExtraFields((prev) => ({
          ...prev,
          [item.label]: value,
        }));

        setExtra_data((prev) => [
          ...data,
          {
            place_field_id: item.id,
            place_field_value_id: "",
            value_text: value,
          },
        ]);
      } else {
        setExtraFields((prev) => ({
          ...prev,
          [item.label]: value,
        }));

        setExtra_data((prev) => [
          ...data,
          {
            place_field_id: value.id,
            place_field_value_id: value.value,
            value_text: value.label,
          },
        ]);
      }
    };

    if (
      extra_data.findIndex(
        ({ place_field_id }) => place_field_id === item.id
      ) === -1
    ) {
      addNew(extra_data);
    } else {
      let newExtraData = extra_data.filter(
        ({ place_field_id }) => place_field_id !== item.id
      );

      addNew(newExtraData);
    }
  };

  const getPlaceFields = React.useCallback(
    async (placeId, userLocationId = 0) => {
      // get Place Fields and if the items have user_value then add the item label and item value to the fields and extra data
      const { data, success } = await generalServices.getPlaceFields(
        placeId,
        userLocationId
      );
      if (!success) return;

      setPlaceFields(data);

      for (let i = 0; i < data.length; i++) {
        if (data[i].user_value) {
          setExtraFields((prev) => ({
            ...prev,
            [data[i].label]:
              data[i].type !== 2
                ? data[i].user_value?.value_text
                : {
                    label: data[i].user_value?.place_field_value.value,
                    value: data[i].user_value?.place_field_value?.id,
                    id: data[i].user_value?.place_field.id,
                  },
          }));
          setExtra_data((prev) => [
            ...prev,
            {
              place_field_id: data[i].user_value?.place_field?.id,
              place_field_value_id:
                data[i].user_value?.place_field_value?.id ?? "",
              value_text: data[i].user_value?.value_text,
            },
          ]);
        }
      }
    },
    []
  );

  const onFieldChange = (name, value) => {
    // Field Change handler
    setFields((prev) => ({ ...prev, [name]: value }));
  };

  const updateUserLocation = async () => {
    // update User Location
    let fieldsErrors = {};

    // chick the required field
    haveToEnter.forEach((item) => {
      if (
        !fields[item] ||
        fields[item] === "" ||
        (typeof fields[item] === "string" && !fields[item]?.trim())
      )
        fieldsErrors[item] = thisFieldIsRequired;
    });

    // place chick the required field
    if (placeFields?.length > 0) {
      placeFields.map((item_one) => {
        if (item_one.is_required == 1) {
          let isFound = false;
          if (extra_data) {
            extra_data.map((item_two) => {
              if (item_one.id == item_two.place_field_id) {
                isFound = true;
                if (
                  !item_two.value_text ||
                  item_two.value_text == "" ||
                  (typeof item_two.value_text === "string" &&
                    !item_two.value_text?.trim())
                ) {
                  fieldsErrors["placeFields_" + item_one.id] =
                    thisFieldIsRequired;
                }
              }
            });
          }

          if (!isFound) {
            fieldsErrors["placeFields_" + item_one.id] = thisFieldIsRequired;
          }
        }
      });
    }

    // if there are cities and not city value
    if (cities && cities.length > 0 && !fields?.city) {
      fieldsErrors["city"] = thisFieldIsRequired;
    }

    // set the errors
    setErrors(fieldsErrors);

    // id there are errors exit
    if (Object.keys(fieldsErrors).length > 0) {
      return true;
    }

    setLoading(true);

    // edit user api request
    const { success, message, errors } = await locationService.editUserLocation(
      {
        ...selectedLocation,
        user_location_id: selectedLocation.id,
        nickname:
          fields.firstName + " " + fields.middleName + " " + fields.lastName,
        first_name: fields.firstName,
        middle_name: fields.middleName,
        last_name: fields.lastName,
        place_id: fields.city ? fields.city : fields.country,
        extra_data: extra_data,
      }
    );
    setLoading(false);
    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        setErrors(handelErrors);
        return;
      } else {
        return displayAlert("Error", message, "error");
      }
    }
    displayAlert(successText, LocationAddedSuccessfully, "success").then(
      (ok) => {
        if (ok) {
          window.location.href = "/checkoutpayment";
        }
      }
    );

    setFields((prev) => ({
      ...prev,
      buildingType: 1,
    }));
  };

  const getPlace = React.useCallback(async (id) => {
    if (id) {
      const { data } = await generalServices.getAllCountries(id);
      return data;
    }
  }, []);

  const uploadImage = async (type, item, fileImage) => {
    // upload Image function
    setErrors({
      firstName: "",
      lastName: "",
      address: "",
      building: "",
      phone: "",
      floor: "",
      appartment: "",
    });
    setImageLoader(true);

    const formData = new FormData();
    formData.append("file", fileImage);
    const { data } = await generalServices.uploadImage(formData);

    let arrayImages = extra_data_images ? extra_data_images : [];
    arrayImages[item.label] = data;
    setExtra_data_images(arrayImages);
    setImageLoader(false);

    onExtraDataChange(type, data, item);
  };

  const checkUserLocation = async () => {
    // check User Location
    let fieldsErrors = {};
    haveToEnter.forEach((item) => {
      if (
        !fields[item] ||
        fields[item] === "" ||
        (typeof fields[item] === "string" && !fields[item]?.trim())
      )
        fieldsErrors[item] = thisFieldIsRequired;
    });

    if (placeFields?.length > 0) {
      placeFields.map((item_one) => {
        if (item_one.is_required == 1) {
          let isFound = false;
          if (extra_data) {
            extra_data.map((item_two) => {
              if (item_one.id == item_two.place_field_id) {
                isFound = true;
                if (
                  !item_two.value_text ||
                  item_two.value_text == "" ||
                  (typeof item_two.value_text === "string" &&
                    !item_two.value_text?.trim())
                ) {
                  fieldsErrors["placeFields_" + item_one.id] =
                    thisFieldIsRequired;
                }
              }
            });
          }

          if (!isFound) {
            fieldsErrors["placeFields_" + item_one.id] = thisFieldIsRequired;
          }
        }
      });
    }

    if (cities && cities.length > 0 && !fields?.city) {
      fieldsErrors["city"] = thisFieldIsRequired;
    }

    setCheck(false);
    setCheckGo(false);

    if (Object.keys(fieldsErrors).length > 0) {
      setModal(true);
      setLoading(false);
      return true;
    }

    const { success, message, errors } = await locationService.editUserLocation(
      {
        ...selectedLocation,
        user_location_id: selectedLocation.id,
        nickname:
          fields.firstName + " " + fields.middleName + " " + fields.lastName,
        first_name: fields.firstName,
        middle_name: fields.middleName,
        last_name: fields.lastName,
        place_id: fields.city ? fields.city : fields.country,
        extra_data: extra_data,
      }
    );
    setLoading(false);

    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        setErrors(handelErrors);
        setModal(true);

        return;
      } else {
        return displayAlert("Error", message, "error");
      }
    }

    displayAlert(successText, LocationAddedSuccessfully, "success").then(
      (ok) => {
        if (ok) {
          window.location.href = "/checkoutpayment";
        }
      }
    );

    setFields((prev) => ({
      ...prev,
      buildingType: 1,
    }));
  };

  React.useEffect(() => {
    if (check && selectedLocation?.main_place && selectedLocation?.id) {
      setExtra_data([]);
      setExtraFields({});
      let fields = {};
      if (
        !selectedLocation.first_name ||
        !selectedLocation.middle_name ||
        !selectedLocation.last_name
      )
        fields = {
          ...fields,
          ...selectedLocation,
          firstName: "",
          middleName: "",
          lastName: "",
          country: selectedLocation.main_place,
        };
      else
        fields = {
          ...fields,
          ...selectedLocation,
          firstName: selectedLocation.first_name,
          middleName: selectedLocation.middle_name,
          lastName: selectedLocation.last_name,
          country: selectedLocation.main_place,
        };
      getPlace(selectedLocation?.place?.id).then((data) => {
        setCities(data.map((item) => ({ value: item.id, label: item.name })));
        fields = {
          ...fields,
          city: data?.length === 0 ? selectedLocation.place?.id : "",
        };
        setFields(fields);
        getPlaceFields(selectedLocation?.main_place, selectedLocation?.id).then(
          () => setCheckGo(true)
        );
      });
    }
  }, [check, selectedLocation?.id]);

  React.useEffect(() => {
    if (checkGo) checkUserLocation();
  }, [checkGo]);

  return (
    <div className="signup-login newCheckOut">
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{completeYourInformation}</Modal.Title>
        </Modal.Header>
        <div className="signup-login__form-container confirm-checkout-container p-4 pb-5 pb-lg-4">
          <Row className=" pb-5 pb-lg-0">
            {(!selectedLocation.first_name ||
              !selectedLocation.middle_name ||
              !selectedLocation.last_name ||
              errors.first_name ||
              errors.middle_name ||
              errors.last_name) && ( // if there is no one from names or there is error on one of them
              <>
                <Col xs={12} className="mb-4">
                  <TextInput
                    name="firstName"
                    maxLength={20}
                    label={FirstName}
                    placeholder={FirstName}
                    value={fields.firstName}
                    onFieldChange={onFieldChange}
                    validate={errors.firstName || errors.first_name}
                  />
                </Col>
                <Col xs={12} className="mb-4">
                  <TextInput
                    name="middleName"
                    maxLength={20}
                    label={middleName}
                    placeholder={middleName}
                    value={fields.middleName}
                    onFieldChange={onFieldChange}
                    validate={errors.middleName || errors.middle_name}
                  />
                </Col>
                <Col xs={12} className="mb-4">
                  <TextInput
                    name="lastName"
                    maxLength={20}
                    label={LastName}
                    placeholder={LastName}
                    value={fields.lastName}
                    onFieldChange={onFieldChange}
                    validate={errors.lastName || errors.last_name}
                  />
                </Col>
              </>
            )}
            {cities?.length > 0 && ( // city select
              <Col xs={12} className="mb-4">
                <SelectInput
                  name={
                    cities.filter(({ value }) => value === fields?.city)
                      .length > 0
                      ? cities.filter(({ value }) => value === fields?.city)[0]
                          .label
                      : null
                  }
                  label={Area}
                  placeholder={Area}
                  value={
                    cities.filter(({ value }) => value === fields?.city)
                      .length > 0
                      ? cities.filter(({ value }) => value === fields?.city)[0]
                      : null
                  }
                  onFieldChange={(name, value) => {
                    onFieldChange("city", value.value);
                  }}
                  data={cities}
                  validate={errors.city}
                />
              </Col>
            )}
            {placeFields?.map((item, index) => {
              // place Fields insert
              return +item.type === 1 || +item.type === 3 ? (
                <Col xs={12} className="mb-4" key={index}>
                  <TextInput
                    name={item.label}
                    label={item.label}
                    type={+item.type === 3 ? "number" : "text"}
                    placeholder={item.label}
                    value={extraFields[item.label]}
                    onFieldChange={(name, value) =>
                      onExtraDataChange(+item.type, value, item)
                    }
                    validate={errors["placeFields_" + item?.id]}
                  />
                </Col>
              ) : +item.type === 2 ? (
                <Col xs={12} className="mb-4">
                  <SelectInput
                    name={item.label}
                    label={item.label}
                    placeholder={item.label}
                    value={
                      extra_data.filter(
                        ({ place_field_id }) => place_field_id == item.id
                      ) &&
                      extra_data.filter(
                        ({ place_field_id }) => place_field_id == item.id
                      ).length > 0
                        ? {
                            label: extra_data.filter(
                              ({ place_field_id }) => place_field_id == item.id
                            )[0]?.value_text,
                            value: extra_data.filter(
                              ({ place_field_id }) => place_field_id == item.id
                            )[0]?.place_field_value_id,
                          }
                        : ""
                    }
                    onFieldChange={(name, value) =>
                      onExtraDataChange(+item.type, value, value)
                    }
                    data={item.dropdown_values.map(
                      ({ value: label, id: value }) => ({
                        value,
                        label,
                        id: item.id,
                      })
                    )}
                    validate={errors["placeFields_" + item?.id]}
                  />
                </Col>
              ) : +item.type === 4 ? (
                <Col xs={12} className=" align-items-center c-f-u-c">
                  <div className="mb-3">
                    <UploadFile
                      name={item.label}
                      uploadImage={(fileImage) =>
                        uploadImage(item.type, item, fileImage)
                      }
                      value={extraFields[item.label]}
                      placeholder={item.label}
                      label={item.label}
                      validate={errors["placeFields_" + item?.id]}
                      loading={imageLoader}
                    />

                    {(extra_data_images && extra_data_images[item.label]) ||
                    (extra_data.filter(
                      ({ place_field_id }) => place_field_id == item.id
                    ) &&
                      extra_data.filter(
                        ({ place_field_id }) => place_field_id == item.id
                      ).length > 0) ? (
                      <span
                        style={{
                          color: "#009952",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {FileSelected}
                      </span>
                    ) : null}
                  </div>
                </Col>
              ) : null;
            })}{" "}
            <Col
              lg={12}
              md={12}
              sm={12}
              className="locationButton d-flex justify-content-center "
            >
              <button className="submit-button2" onClick={updateUserLocation}>
                {Save}
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}
