import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Product from "./Product";
import { connect } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import {
  desktopScreen,
  laptopScreen,
  phoneScreen,
  screenInnerWidth,
  tabletScreen,
} from "../../utils/misc";
import Carousel from "react-grid-carousel";
import { Link } from "react-router-dom";

class ProductCarousel extends Component {
  state = {
    responsive: [
      {
        breakpoint: 2000,
        cols: 4,
        gap: 20,
        loop: true,
      },
      {
        breakpoint: 992,
        cols: 3,
        gap: 20,
        loop: true,
      },
      {
        breakpoint: 768,
        cols: 2,
        gap: 20,
        loop: true,
      },
      {
        breakpoint: 567,
        cols: 2,
        gap: 12,
        loop: true,
      },
    ],
  };

  render() {
    const {
      language,
      data,
      reFetchData,
      title,
      labelTitle,
      rowCount,
      viewAllLink,
      i18n: { VIEWALL },
    } = this.props;
    const { responsive } = this.state;
    return (
      <div
        className={`categories-block categories-block-2  ${
          language === "rtl" && "categories-block-arabic"
        }`}
        style={{ backgroundColor: "#F2F2F2", paddingBottom: "50px" }}
      >
        <Container>
          <div className="categories-block__header">
            <span>{labelTitle}</span>
            <h3>{title}</h3>
            <div className="hr" />
          </div>

          {data?.length < 1 ? (
            <>
              <Row className="no-margin p-1">
                <Col>
                  <Skeleton height={phoneScreen ? 250 : 350} />
                </Col>

                {(laptopScreen || desktopScreen || tabletScreen) && (
                  <Col>
                    <Skeleton height={phoneScreen ? 150 : 350} />
                  </Col>
                )}
                {(laptopScreen || desktopScreen) && (
                  <Col>
                    <Skeleton height={phoneScreen ? 150 : 350} />
                  </Col>
                )}
                {(laptopScreen || desktopScreen) && (
                  <Col>
                    <Skeleton height={phoneScreen ? 150 : 350} />
                  </Col>
                )}
              </Row>
            </>
          ) : data?.length < 4 ? (
            // if data less than 4
            <Row className="no-margin p-1">
              {data?.map((item, index) => {
                return (
                  <Col key={index} lg={3} md={4} xs={6}>
                    <Product // Product card that takes product as data and show product as a card with image
                      data={item}
                      reFetchData={() => reFetchData()}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Carousel // slider components
              cols={4}
              rows={rowCount}
              gap={20}
              loop
              responsiveLayout={responsive}
              mobileBreakpoint={0}
              hideArrow={screenInnerWidth <= 768}
            >
              {data?.map((item, index) => {
                return (
                  <Carousel.Item key={index}>
                    <Product // Product card that takes product as data and show product as a card with image
                      key={index}
                      data={item}
                      reFetchData={() => reFetchData()}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          )}
          {/* products carousel start  */}

          {/* view all button start  */}
          {viewAllLink && (
            <div className="categories-block__viewAll">
              <Link to={viewAllLink}>{VIEWALL}</Link>
            </div>
          )}
          {/* view all button end  */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(ProductCarousel);
