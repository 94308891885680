import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import { locationService } from "../../../../services/LocationService";
import LocationBlock from "../../../blocks/LocationBlock";

class Addresses extends Component {
  state = {
    addAddress: false,
    currentState: "",
    userAddressesData: [],
    loader: true,
  };

  componentDidMount() {
    Promise.all([this.getUserLocation()]);
  }

  getUserLocation = async () => {
    // function to get user locations and set it to current state
    const { data, success } = await locationService.getUserLocation();
    if (!success) return;
    this.setState({
      userAddressesData: data,
      loader: false,
    });
  };

  render() {
    const {
      language,
      addLocation,
      showEditLocation,
      i18n: {
        addNewAddress,
        AddressBook,
        PleaseSelectYourDefaultAddress,
        addressEmpty,
      },
    } = this.props;
    const { userAddressesData } = this.state;

    return (
      <Container>
        <div
          className={`addresses ${language === "rtl" && "addresses-arabic"}`}
        >
          {/* address section header start  */}
          <div className="account-page__title">
            <h3>{AddressBook}</h3>
            <h4>{PleaseSelectYourDefaultAddress}</h4>
          </div>
          {/* address section header end  */}

          <Row>
            {/* address block start  */}
            {this.state.loader ? (
              <>
                <Col lg={6} md={12}>
                  <Skeleton height={160} />
                </Col>
                <Col lg={6} md={12}>
                  <Skeleton height={160} />
                </Col>
              </>
            ) : userAddressesData.length > 0 ? (
              userAddressesData.map((item) => {
                return (
                  <Col lg={6} md={12} key={item.id}>
                    <LocationBlock
                      data={item}
                      addAddress={(data) =>
                        this.setState({
                          addAddress: true,
                          currentState: "Edit",
                          fields: {
                            nickname: data.nickname ?? "",
                            address: data.address ?? "",
                            building: data.building ?? "",
                            phone: data.phone ?? "",
                            floor: data.floor ?? "",
                            apartment: data.apartment ?? "",
                          },
                          selectedLocation: data,
                        })
                      }
                      loader={this.state.loader}
                      reFetchData={() => this.getUserLocation()}
                      showEditLocation={showEditLocation}
                      selectedLocation={() => {}}
                    />
                  </Col>
                );
              })
            ) : (
              <div className="empty-products">
                <img src="/img/locationEmpty.svg" alt="k" />
                <p> {addressEmpty} </p>
              </div>
            )}
            {/* address block end  */}

            {/* add address button start  */}
            <div className="button-container">
              <button onClick={addLocation} className="submit-button2">
                {addNewAddress}
              </button>
            </div>
            {/* add address button end  */}
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  countryId: country.id,
});

export default connect(mapStateToProps, { setLanguage })(Addresses);
