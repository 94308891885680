import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../src/language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import TextInput from "../blocks/TextInput";
import { displayAlert, getResponseErrors } from "../../utils/misc";
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { authService } from "../../services/AuthService";
import { setUser } from "../../redux/actions-reducers/user";
import { setLanguage } from "../../redux/actions-reducers/language";
import { setCurrency } from "../../redux/actions-reducers/currency";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
class LoginModal extends React.Component {
  state = {
    errors: { email: "", password: "" },
    fields: { email: "", password: "" },
    showVerificationCode: false,
    flag: true,
    showPassword: "password",
    socialLogin: {
      email: "",
      social_provider: "",
      social_provider_id: "",
      phone: "",
      name: "",
      place_id: "",
      social_image: "",
    },
    submitLoader: false,
    googleLoginBtnLoad: false,
  };

  // onFieldChange start
  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        email: "",
        password: "",
      },
    });
  };
  // onFieldChange end

  // login submit start
  submit = async () => {
    // on login submit send api request ( basicLogin ) and then set user data in redux if the request success
    this.setState({ submitLoader: true });
    const { fields } = this.state;
    const { errors: errorTag } = this.props.i18n;
    const { success, data, message, errors } = await authService.login({
      email: fields.email,
      password: fields.password,
    });

    if (!success) {
      this.setState({ submitLoader: false }, () => {
        if (errors) {
          const handelErrors = getResponseErrors(errors);
          this.setState({
            errors: handelErrors,
          });
          return;
        } else {
          return displayAlert(errorTag, message, "error");
        }
      });

      return;
    }
    if (data.user) {
      localStorage.setItem("beamAccessToken", data.access_token);
      this.props.setUser({ ...data.user });
      this.props.setCurrency({
        currencyName: data.user?.currency?.code,
        currencyFactor: data.user?.currency?.convert_factor,
      });
      localStorage.setItem("langCode", data.user?.language?.direction || "ltr");
      await this.props.setLanguage({
        lang: data.user?.language?.direction == "rtl" ? "English" : "العربية",
        langCode: data.user?.language?.direction || "ltr",
      });

      this.setState({ submitLoader: false });
      this.props?.closeModal();
    }
  };
  // login submit end

  componentDidMount() {
    this.setState({
      flag: !this.state.flag,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginShow !== this.props.loginShow) {
      this.setState({
        flag: !this.state.flag,
      });
    }
  }

  render() {
    const {
      loginShow,
      closeModal,
      i18n: {
        Password,
        LoginBtn,
        Register,
        Facebook,
        Google,
        RememberMe,
        ForgetPassword,
        NewCustomer,
        Login,
        LoginUserName,
        OrSocailMediaLogin,
      },
    } = this.props;

    const { fields, errors, submitLoader } = this.state;

    return (
      <>
        <Modal
          size="lg"
          show={loginShow}
          onHide={closeModal}
          aria-labelledby="example-modal-sizes-title-lg"
          className="login-modal"
        >
          {/* modal header start  */}
          <Modal.Header
            closeButton
            className="login-modal__header"
          ></Modal.Header>
          {/* modal header start  */}

          <Modal.Body className="login-modal__body mb-3">
            {/* modal Title start  */}
            <header className="login-modal__body__header w-100 mb-3">
              {Login}
            </header>
            {/* modal Title end  */}

            <Row>
              <Col className="login-modal__body__left p-3 pt-0" sm={12} md={6}>
                <Row className="login-form">
                  {/* email input start  */}
                  <Col sm={12} md={12} className="">
                    <Form.Label>
                      {LoginUserName} <span className="red-astrick">*</span>
                    </Form.Label>
                    <Form.Group className="signup-login__form-container__field">
                      <TextInput
                        name="email"
                        value={fields.email}
                        onFieldChange={this.onFieldChange}
                        validate={errors.email}
                      />
                    </Form.Group>
                  </Col>
                  {/* email input end  */}
                  {/* password input start  */}

                  <Col
                    sm={12}
                    md={12}
                    className=""
                    style={{ position: "relative" }}
                  >
                    <Form.Label>
                      {Password} <span className="red-astrick">*</span>
                    </Form.Label>
                    <Form.Group className="signup-login__form-container__field">
                      <TextInput
                        name="password"
                        value={fields.password}
                        type="password"
                        onFieldChange={this.onFieldChange}
                        validate={errors.password}
                        withImg
                      />
                    </Form.Group>
                  </Col>
                  {/* password input end  */}

                  {/* remember me and forget password  start  */}

                  <Col md={12}>
                    <div className="d-flex justify-content-between">
                      <div className="remember-me">
                        <label className="checkbox-container text-transform-capitalize m-0">
                          <p>{RememberMe}</p>
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <a href="/forgetpassword" className="forgot-pass pt-2">
                        {ForgetPassword}
                      </a>
                    </div>
                  </Col>
                  {/* remember me and forget password  end  */}

                  {/* login button start  */}
                  <Col md={12} className="">
                    <button
                      className="submit-btn mt-3 p-2 w-100"
                      onClick={this.submit}
                    >
                      {submitLoader ? (
                        <div className="button">
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      ) : (
                        <>{LoginBtn}</>
                      )}
                    </button>
                  </Col>
                  {/* login button end  */}

                  <Col md={12} className="mt-3">
                    <div className="text-center w-100 socialLoginTexr">
                      {OrSocailMediaLogin}
                    </div>
                    <div className="social-btns d-flex mt-3">
                      {/* facebook login start  */}
                      <div style={{ position: "relative" }}>
                        <div className="mb-3">
                          <label
                            for="fb"
                            style={{
                              display: "block",
                              position: "absolute",
                              top: "0",
                            }}
                          >
                            <button
                              className="p-3 w-100 d-flex align-items-center social-btn"
                              id="facebook"
                              htmlFor="fb"
                            >
                              <img src="/images/Path 1.png" />
                              <p className="m-0 w-100">{Facebook}</p>
                            </button>
                          </label>
                          <FacebookLogin
                            appId="1004135036854983"
                            autoLoad={false}
                            fields="id,name,email,picture"
                            onFailure={(error) => {}}
                            callback={this.responseFacebook}
                            cssclassName=""
                            isMobile={false}
                            icon="fa fa-facebook-f fa-2x"
                            textButton=""
                            className="socialBtn fb-btn p-0"
                            // style={{display: "none"}}
                            // icon=""
                            id="fb"
                          />
                        </div>
                      </div>
                      {/* facebook login end  */}
                      {/* google login start  */}

                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <div className="mb-3">
                          <GoogleLogin
                            clientId="751367658206-iv22mmduam9na7qs1u6q7viqctlabdf3.apps.googleusercontent.com"
                            buttonText={``}
                            onSuccess={this.responseGoogle}
                            onFailure={(error) => {}}
                            disabled={false}
                            render={(propsGoogle) => {
                              return (
                                <label
                                  for="google"
                                  style={{
                                    display: "block",

                                    marginTop: "0",
                                  }}
                                  className="gl-label login-google-label"
                                >
                                  <button
                                    className="p-3 w-100 d-flex align-items-center social-btn"
                                    id="google"
                                    onClick={propsGoogle.onClick}
                                    disabled={propsGoogle.disabled}
                                  >
                                    <img src="/images/google_plus.png" />
                                    <p className="m-0 w-100">{Google}</p>
                                  </button>
                                </label>
                              );
                            }}
                          />
                        </div>
                      </div>
                      {/* google login end  */}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col className="login-modal__body__right p-5 pt-0" sm={12} md={6}>
                <div className="reg-header">{NewCustomer}</div>
                <div className="reg-text">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat,
                </div>
                {/* register link button start  */}
                <button
                  className="reg-btn mt-5 p-2 w-100"
                  onClick={() => (window.location.href = "/signup")}
                >
                  {Register}
                </button>
                {/* register link button end  */}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  // Handle Social Login START..

  responseFacebook = async (response) => {
    // response Facebook for login with Facebook by sending api request and then set user data in redux if the request success
    const { error: errorTag } = this.props.i18n;

    this.setState({
      fields: {
        email: response.email ?? "",
      },
    });

    const { success, data, message, errors } = await authService.socialLogin({
      phone: "",
      place_id: this.props.country.id,
      email: response.email ?? "",
      name: response.name ?? "",
      need_to_email_verification: false,
      password: "",
      social_image: response.image ?? "",
      social_provider_id: response.id ?? "",
      social_provider: "facebook",
    });

    if (!success) {
      if (errors) {
        this.props.setUser({
          socialMedia: {
            email: response?.email ?? "",
            name: response?.name ?? "",
            phone: response?.phone ?? "",
            password_confirmation: "",
            password: "",
            terms_conditions: false,
            social_image: response?.image ?? "",
            social_provider_id: response?.id ?? "",
            social_provider: "facebook",
          },
        });
        setTimeout(() => {
          window.location.href = "/SocialLogin";
        }, 100);
      } else {
        this.props?.closeModal();
        return displayAlert(errorTag, message, "error");
      }
    }

    if (data.access_token) {
      apiService.storeToken(data.access_token);
      this.props.setUser({ ...data.user });
    }
    this.props?.closeModal();
    // setTimeout(() => (window.location.href = "/"), 500);
  };

  responseGoogle = async (response) => {
    // response Google for login with Google by sending api request and then set user data in redux if the request success
    const { error: errorTag } = this.props.i18n;
    this.setState({
      fields: {
        ...this.state.fields,
        email: response?.profileObj?.email ?? "",
      },
    });
    const { success, data, message, errors } = await authService.socialLogin({
      phone: "",
      password: "",
      place_id: this.props.country.id,
      email: response.profileObj.email ?? "",
      name: response.profileObj.name ?? "",
      need_to_email_verification: false,
      social_image: response.profileObj.image ?? "",
      social_provider_id: response.profileObj.googleId ?? "",
      social_provider: "google",
    });

    if (!success) {
      if (errors) {
        this.props.setUser({
          socialMedia: {
            email: response?.profileObj?.email ?? "",
            name: response?.profileObj?.name ?? "",
            phone: response?.profileObj?.phone ?? "",
            password_confirmation: "",
            password: "",
            terms_conditions: false,
            social_image: response?.profileObj?.image ?? "",
            social_provider_id: response.profileObj.googleId ?? "",
            social_provider: "google",
          },
        });
        setTimeout(() => {
          window.location.href = "/SocialLogin";
        }, 100);
      } else {
        this.props?.closeModal();
        return displayAlert(errorTag, message, "error");
      }
    }
    if (data.access_token) {
      apiService.storeToken(data.access_token);
      this.props.setUser({ ...data.user });
    }
    this.props?.closeModal();
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setUser, setLanguage, setCurrency })(
  LoginModal
);
