import React, { Component } from "react";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import { setUser } from "../../../../redux/actions-reducers/user";
import { userService } from "../../../../services/UserService";
import { displayAlert, getResponseErrors } from "../../../../utils/misc";
import SelectInput from "../../../blocks/SelectInput";
import TextInput from "../../../blocks/TextInput";
class ProfileForm extends Component {
  state = {
    remindMe: false,
    userInfo: {},
    genders: [
      { label: "Male", value: 1 },
      { label: "Female", value: 2 },
    ],
    generalFields: {
      first_name: "",
      last_name: "",
      gender: "",
      Phone: "",
      email: "",
      date: "",
    },
    securityFields: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    errors: {
      name: "",
      language_id: "",
      old_password: "",
      password: "",
      password_confirmation: "",
      date: "",
    },
  };

  componentDidMount() {
    this.getUserInformation();
  }

  getUserInformation = async () => {
    // function to get user Information from the server and set it to the current state
    const { data, success } = await userService.getUserInformation();
    if (!success) return;
    this.setState({
      userInfo: data,

      generalFields: {
        ...this.state.generalFields,
        name: data.name,
        first_name: data.name.split(" ").filter((item) => item !== "")[0] ?? "",
        last_name: data.name.split(" ").filter((item) => item !== "")[1] ?? "",
        email: data.email,
        gender: this.state.genders.find(({ value }) => +value === +data.gender),
        date: data.date_of_birth,
        Phone: data.phone,
      },
      securityFields: {},
    });
  };

  render() {
    const {
      language,
      showProfileInfo,
      currentUser,
      i18n: {
        oldPassword,
        Password,
        genderText,
        Email,
        EditProfile,
        UpdateDetails,
        cancel,
        FullName,
        DateOfBirth,
        ConfirmPassword,
        Save,
        Security,
      },
    } = this.props;
    const { genders, generalFields, securityFields, errors } = this.state;

    return (
      <Container>
        {/* title start */}
        <div className={`profile  ${language === "rtl" && "profile-arabic"}`}>
          {/* title start */}

          {/* general information Start section */}
          <div className="general-info">
            <h2 className="general-info__title">{EditProfile}</h2>
            <Row>
              {/* name input start  */}
              <Col lg={6} md={12}>
                <TextInput
                  name="name"
                  placeholder={FullName}
                  value={generalFields.name}
                  onFieldChange={this.onFieldChange}
                  validate={errors.name}
                />
              </Col>
              {/* name input end  */}
              {/* email input start  */}

              <Col lg={6} md={12}>
                <TextInput
                  name="email"
                  placeholder={Email}
                  value={generalFields.email}
                  onFieldChange={this.onFieldChange}
                  validate={errors.email}
                />
              </Col>
              {/* email input end  */}
              {/* phone input start  */}

              <Col lg={6} md={12}>
                <Form.Group className="phone-number d-flex">
                  <InputGroup.Text className="phone-zipcode text-center">
                    <div className="">{currentUser?.place?.zipcode}</div>
                  </InputGroup.Text>
                  <TextInput
                    type="number"
                    placeholder={"000 000 000"}
                    name="Phone"
                    onInput={({ target }) => {
                      target.value = Math.max(0, parseInt(target.value))
                        .toString()
                        .slice(0, this.props.country.digits);
                    }}
                    onChange={({ target: { value } }) => {
                      if (generalFields["Phone"].length != 9) {
                        this.setState({
                          generalFields: {
                            ...generalFields,
                            Phone: value,
                          },
                        });
                      }
                    }}
                    onFieldChange={this.onFieldChange}
                    value={generalFields.Phone}
                    className="signup-login__form-container__field phone_field"
                  />
                </Form.Group>
                <small
                  style={{
                    fontSize: "0.875em",
                    color: "red",
                    letterSpacing: "0px",
                  }}
                >
                  {errors.phone}
                </small>
              </Col>
              {/* phone input end  */}
              {/* date input start  */}

              <Col lg={6} md={12}>
                <TextInput
                  name="date"
                  type="date"
                  placeholder={DateOfBirth}
                  value={generalFields.date}
                  onFieldChange={this.onFieldChange}
                  validate={errors.date}
                />
              </Col>
              {/* date input end  */}
              {/* gender input start  */}

              <Col lg={6} md={12}>
                <SelectInput
                  name="gender"
                  placeholder={genderText}
                  value={generalFields.gender}
                  onFieldChange={this.onFieldChange}
                  // isTextArea
                  data={genders}
                  validate={errors.gender}
                />
              </Col>
              {/* gender input end  */}

              {/* update and cancel buttons  start  */}

              <div className="button-container mt-5 flex profile-form__buttons">
                <button
                  className="submit-button2"
                  onClick={this.submitGeneralInfo}
                >
                  {UpdateDetails}
                </button>

                <button className="submit-button2" onClick={showProfileInfo}>
                  {cancel}
                </button>
              </div>
              {/* update and cancel buttons  end  */}
            </Row>
          </div>
          {/* general information End section */}

          {/* Security form    Start  */}

          <div className="Security">
            <h2 className="Security__title">{Security}</h2>
            <Row>
              {/* old password start  */}
              <Col lg={4} md={12}>
                <TextInput
                  type="password"
                  name="old_password"
                  label={oldPassword}
                  placeholder={oldPassword}
                  value={securityFields.old_password}
                  onFieldChange={this.onSecurityFieldChange}
                  validate={errors.old_password}
                  withImg
                />
              </Col>
              {/* old password end  */}
              {/* new password start  */}

              <Col lg={4} md={6}>
                <TextInput
                  name="password"
                  label={Password}
                  placeholder={Password}
                  value={securityFields.password}
                  onFieldChange={this.onSecurityFieldChange}
                  type="password"
                  validate={errors.password}
                  withImg
                />
              </Col>
              {/* new password end  */}
              {/* confirmation password start  */}

              <Col lg={4} md={6}>
                <TextInput
                  name="password_confirmation"
                  placeholder={ConfirmPassword}
                  withImg
                  label={ConfirmPassword}
                  type="password"
                  value={securityFields.password_confirmation}
                  onFieldChange={this.onSecurityFieldChange}
                  validate={errors.password_confirmation}
                />
              </Col>
              {/* confirmation password end  */}

              {/* save button start  */}
              <div className="button-container">
                <button
                  onClick={this.submitSecurityInfo}
                  className="submit-button2"
                >
                  {Save}
                </button>
              </div>
              {/* save button end  */}
            </Row>
          </div>
          {/* Security form End (Change password) */}
        </div>
      </Container>
    );
  }

  onFieldChange = (name, value) => {
    // Field Change handler
    const { generalFields } = this.state;
    this.setState({
      generalFields: { ...generalFields, [name]: value },
      errors: {
        first_name: "",
        last_name: "",
        language_id: "",
        Phone: "",
      },
    });
  };

  onSecurityFieldChange = (name, value) => {
    // set security field on change
    const { securityFields } = this.state;
    this.setState({
      securityFields: { ...securityFields, [name]: value },
      errors: {
        password: "",
        old_password: "",
        password_confirmation: "",
      },
    });
  };

  submitGeneralInfo = async () => {
    //submit General Info
    const { generalFields } = this.state;
    const { success: successText, ProfileUpdatedSuccessfully } =
      this.props.i18n;
    const { success, message, errors } = await userService.updateGeneralInfo({
      email: generalFields.email,
      phone: generalFields.Phone,
      name: generalFields.name,
      place_id: this.props.country.id,
      image: "",
      date_of_birth: generalFields.date,
      gender: generalFields.gender.value,
    });

    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert("Error", message, "error");
      }
    }
    this.props.setUser({
      name: generalFields.name,
    });
    displayAlert(successText, ProfileUpdatedSuccessfully, "success").then(
      () => {
        this.props.showProfileInfo();
      }
    );
  };

  submitSecurityInfo = async () => {
    // submit Security Info
    const { securityFields } = this.state;
    const { success, message, errors } = await userService.updateSecurityInfo({
      old_password: securityFields.old_password,
      password: securityFields.password,
      password_confirmation: securityFields.password_confirmation,
    });
    if (!success) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert("Error", message, "error");
      }
    }
    // apiService.storeToken(data.access_token);
    displayAlert(
      this.props.i18n.Done,
      this.props.i18n.PasswordUpdatedSuccessfully,
      "success"
    ).then((ok) => {
      if (ok) {
        this.props.showProfileInfo();
      }
    });
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country: country,
});
export default connect(mapStateToProps, { setLanguage, setUser })(ProfileForm);
