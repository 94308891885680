import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import PageTitle from "../../blocks/PageTitle";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import TextInput from "../../blocks/TextInput";
import { authService } from "../../../services/AuthService";
import { displayAlert, getResponseErrors } from "../../../utils/misc";

class ForgetPassword extends React.Component {
  state = {
    step: 1,
    fields: {
      email: "",
    },
    errors: {
      email: "",
    },
  };

  render() {
    const {
      language,
      i18n: {
        EnterEmail,
        submit,
        forgetPassText,
        otp,
        ConfirmNewPass,
        ResetNewPass,
      },
    } = this.props;

    const { fields, errors } = this.state;
    return (
      <>
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title="forgot password"
        />
        <Container>
          <div
            className={`signup-login p-3 ${
              language === "rtl" && "signup-login-arabic"
            }`}
          >
            <div className="signup-login__form-container forget-pass-container shadow mt-5 mb-5">
              <Row>
                {/* note start  */}
                <Col md={12}>
                  <p className="header-paragraph p-3">{forgetPassText}</p>
                </Col>

                {this.state.step === 1 && (
                  <>
                    <Col md={12} className="mt-3 ps-4 pe-4">
                      <Form.Label>
                        {EnterEmail} <span className="red-astrick">*</span>
                      </Form.Label>
                      <Form.Group className="mt-3 signup-login__form-container__field">
                        <TextInput
                          placeholder={EnterEmail}
                          name="email"
                          value={fields.email}
                          onFieldChange={this.onFieldChange}
                          validate={errors.email}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12} className="ps-4 pe-4 mt-3">
                      <button
                        className="submit-btn mt-3 p-3"
                        onClick={this.forgetPassword}
                      >
                        {submit}
                      </button>
                    </Col>
                  </>
                )}

                {this.state.step === 2 && (
                  <>
                    <Col md={12} className="mt-3 ps-4 pe-4">
                      <Form.Label>
                        {otp} <span className="red-astrick">*</span>
                      </Form.Label>
                      <Form.Group className="mt-3 signup-login__form-container__field">
                        <TextInput
                          placeholder={otp}
                          name="otp"
                          type="number"
                          value={fields.otp}
                          onFieldChange={this.onFieldChange}
                          validate={errors.otp}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12} className="ps-4 pe-4 mt-3">
                      <button
                        className="submit-btn mt-3 p-3"
                        onClick={this.verifyForgetPassword}
                      >
                        {submit}
                      </button>
                    </Col>
                  </>
                )}

                {this.state.step === 3 && (
                  <>
                    <Col md={12} className="mt-3 ps-4 pe-4">
                      <Form.Label>
                        {ResetNewPass} <span className="red-astrick">*</span>
                      </Form.Label>
                      <Form.Group className="mt-3 signup-login__form-container__field">
                        <TextInput
                          placeholder={ResetNewPass}
                          name="password"
                          value={fields.password}
                          type="password"
                          onFieldChange={this.onFieldChange}
                          validate={errors.password}
                          withImg
                        />
                      </Form.Group>
                    </Col>
                    {/* new password input end  */}

                    {/* Confirm new password input start  */}
                    <Col md={12} className="mt-2 ps-4 pe-4">
                      <Form.Label>
                        {ConfirmNewPass}
                        <span className="red-astrick">*</span>
                      </Form.Label>
                      <Form.Group className="mt-3 signup-login__form-container__field">
                        <TextInput
                          placeholder={ConfirmNewPass}
                          name="password_confirmation"
                          value={fields.password_confirmation}
                          type="password"
                          onFieldChange={this.onFieldChange}
                          validate={errors.password_confirmation}
                          withImg
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12} className="ps-4 pe-4 mt-3">
                      <button
                        className="submit-btn mt-3 p-3"
                        onClick={this.resetForgetPassword}
                      >
                        {submit}
                      </button>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }

  // on Field Change function start
  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        email: "",
      },
    });
  };
  // on Field Change function end

  forgetPassword = async () => {
    // forget Password step 1 to send the obt with forgetPassword api request if ok set step to 2
    const { fields } = this.state;
    const { status, success, message, errors } =
      await authService.forgetPassword({
        ...fields,
      });
    const { error: errorTag } = this.props.i18n;
    const { success: successText } = this.props;
    if (!success || status === 0) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert(errorTag, message, "error");
      }
    } else {
      return displayAlert(successText, message, "success").then(() => {
        this.setState({
          step: 2,
        });
      });
    }
  };

  verifyForgetPassword = async () => {
    //verify Forget Password step 2 to send the user entred obt to the api request if ok set step to 3
    const { fields } = this.state;
    const { status, success, message, errors } =
      await authService.verifyForgetPassword({
        ...fields,
      });
    const { error: errorTag } = this.props.i18n;
    const { success: successText } = this.props;
    if (!success || status === 0) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert(errorTag, message, "error");
      }
    } else {
      return displayAlert(successText, message, "success").then(() => {
        this.setState({
          step: 3,
        });
      });
    }
  };

  resetForgetPassword = async () => {
    // Reset password forget step 3 change the Password if ok go to home page
    const { fields } = this.state;
    const { status, success, message, errors } =
      await authService.resetPassword({
        ...fields,
      });
    const { error: errorTag } = this.props.i18n;
    const { success: successText } = this.props;
    if (!success || status === 0) {
      if (errors) {
        const handelErrors = getResponseErrors(errors);
        this.setState({
          errors: handelErrors,
        });
        return;
      } else {
        return displayAlert(errorTag, message, "error");
      }
    } else {
      return displayAlert(successText, message, "success").then(() => {
        this.props.history.push(`/`);
      });
    }
  };
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage })(ForgetPassword);
