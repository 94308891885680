import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../redux/actions-reducers/language";
import { generalServices } from "../../services/GeneralService";
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
class Footer extends Component {
  state = {
    payment_methods: [],
    help_section: [],
    settings: [],
    social_media: [],
    PublicPagesData: [],
    footerData: {},
    loader: true,
  };
  async componentDidMount() {
    await Promise.all([
      this.getFooter(),
      this.getPublicPagesData(),
      this.getSocialMediaLinks(),
      this.getPaymentMethodsByPlaceID(),
      this.getHelpCenter(),
      this.getPaymentMethodsByPlaceID(),
    ]);
    this.setState({ loader: false });
  }

  // get footer start
  async getFooter() {
    const { success, data } = await generalServices.getFooter();
    if (!success) return;
    this.setState({
      footerData: data,
    });
  }
  // get footer end

  // get Social Media Links start
  async getSocialMediaLinks() {
    const { success, data } = await generalServices.getSocialMediaLinks();
    if (!success) return;
    this.setState({
      social_media: data,
    });
  }
  // get Social Media Links end

  // get payment_methods start
  async getPaymentMethodsByPlaceID() {
    const { success, data } = await generalServices.getPaymentMethodsByPlaceID(
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      payment_methods: data,
    });
  }
  // get payment_methods end

  // get Helps Center start
  async getHelpCenter() {
    const { success, data } = await generalServices.getHelpCenter(
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      help_section: data,
    });
  }
  // get Helps Center end

  // get Public Pages Data start
  async getPublicPagesData() {
    const { success, data } = await generalServices.getPublicPagesData();
    if (!success) return;
    this.setState({
      PublicPagesData: data,
    });
  }
  // get Public Pages Data end

  render() {
    const {
      language,
      i18n: { ContactUs, copyWriteText },
    } = this.props;
    const { social_media, PublicPagesData, footerData, loader } = this.state;
    return (
      <>
        {loader ? (
          <Skeleton height={400} />
        ) : (
          <div className={`footer  ${language === "rtl" && "footer-arabic"}`}>
            {/* Footer upper side (Main Footer) Start */}
            <div className="footer__upper-side">
              <Container>
                {/* footer logo start  */}
                <Link to="/" className="footer-logo">
                  <img
                    src={apiService.imageLink + footerData.footer_logo}
                    alt="l"
                  />
                </Link>
                {/* footer logo end  */}

                <p>{footerData?.footer_text}</p>

                {/* footer public page links list  */}
                <ul className="public-page-list flex">
                  {PublicPagesData.map(({ name, id, machine_name }) => (
                    <React.Fragment key={id}>
                      <li>
                        <Link
                          to={`/PublicPagesData/${machine_name}`}
                          onClick={() => {
                            this.getPublicPagesData();
                          }}
                        >
                          {name}
                        </Link>
                      </li>
                      <div className="public-page-list__hr" />
                    </React.Fragment>
                  ))}

                  <li>
                    <Link to={`/ContactUs`} onClick={() => {}}>
                      {ContactUs}
                    </Link>
                  </li>
                </ul>
                
                {/* social_media list start  */}
                <ul className="social-media-list flex">
                  {social_media.map(({ name, id, icon, link }) => {
                    return (
                      <li key={id}>
                        <a href={link}>
                          <img src={apiService.imageLink + icon} alt="l" />
                        </a>
                      </li>
                    );
                  })}
                </ul>
                {/* social_media list end  */}
              </Container>
            </div>
            {/* Footer upper side (Main Footer) End */}

            {/* Footer lower side (copy write) Start */}
            <div className="footer__lower-side">
              <Container>
                <div className="coppyrights">
                  <p className="">{copyWriteText}</p>
                </div>
              </Container>
            </div>
            {/* Footer lower side End */}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage })(Footer);
