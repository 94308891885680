import _ from "lodash";
import Global from "../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

export function findTranslatedLabel(itemStr, langCode = "en") {
  let translationObj = Global[langCode === "ltr" ? "en" : "ar"]; // get the language object from Global object
  let targetTranslation =
    typeof _.get(translationObj, itemStr) !== "undefined"
      ? _.get(translationObj, itemStr)
      : typeof _.get(translationObj, itemStr.replace("_", "")) !== "undefined"
      ? _.get(translationObj, itemStr.replace("_", ""))
      : typeof _.get(
          translationObj,
          itemStr.charAt(0).toUpperCase() +
            // get everything between first letter and underscore
            itemStr.substring(1, itemStr.indexOf("_")) +
            // capitalize the letter that comes after underscore
            itemStr[itemStr.indexOf("_") + 1].toUpperCase() +
            // slice the rest of the string
            itemStr.slice(itemStr.indexOf("_") + 2)
        ) !== "undefined"
      ? _.get(
          translationObj,
          itemStr.charAt(0).toUpperCase() +
            // get everything between first letter and underscore
            itemStr.substring(1, itemStr.indexOf("_")) +
            // capitalize the letter that comes after underscore
            itemStr[itemStr.indexOf("_") + 1].toUpperCase() +
            // slice the rest of the string
            itemStr.slice(itemStr.indexOf("_") + 2)
        )
      : typeof _.get(
          translationObj,
          itemStr.charAt(0).toLowerCase() +
            // get everything between first letter and underscore
            itemStr.substring(1, itemStr.indexOf("_")) +
            // capitalize the letter that comes after underscore
            itemStr[itemStr.indexOf("_") + 1].toUpperCase() +
            // slice the rest of the string
            itemStr.slice(itemStr.indexOf("_") + 2)
        ) !== "undefined"
      ? _.get(
          translationObj,
          itemStr.charAt(0).toLowerCase() +
            // get everything between first letter and underscore
            itemStr.substring(1, itemStr.indexOf("_")) +
            // capitalize the letter that comes after underscore
            itemStr[itemStr.indexOf("_") + 1].toUpperCase() +
            // slice the rest of the string
            itemStr.slice(itemStr.indexOf("_") + 2)
        )
      : _.get(
          translationObj,
          itemStr.charAt(0).toUpperCase() + itemStr.slice(1)
        );

  if (typeof targetTranslation === "undefined") {
    targetTranslation = itemStr;
  }

  return targetTranslation;
}
