import React, { Component } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import CartProductBlock from "../Cart/CartProductBlock";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";
import { cartService } from "../../../services/CartService";
import { userService } from "../../../services/UserService";
import Skeleton from "react-loading-skeleton";
import { generalServices } from "../../../services/GeneralService";
import PageTitle from "../../blocks/PageTitle";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { ErrorMessage } from "../../blocks/ErrorMessage";
import Swal from "sweetalert2";
import { productService } from "../../../services/ProductService";

class Cart extends Component {
  state = {
    fullView: false,
    cart: [],
    payment_methods: [],
    itemsPriceBeforeDiscount: 0,
    itemsPriceAfterDiscount: 0,
    itemsCount: [],
    customerData: [],
    loaderPage: true,
    cartAmount: {},
    adsData: {},
    adsLoader: true,
    qtyLoader: false,
    fields: {
      promocode: "",
    },
    Coupon: [],
    CouponFields: {},
    couponError: false,
    couponErrorText: "",
    couponInputIsEmpty: true,
  };

  componentDidMount() {
    localStorage.setItem("beam_coupon", []);

    Promise.all([
      this.getUserCart(),
      this.getCheckoutAmounts(),
      this.getSingleAds(),
    ]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser?.email !== this.props.currentUser?.email) {
      localStorage.setItem("beam_coupon", []);

      Promise.all([
        this.getUserCart(),
        this.getCheckoutAmounts(),
        this.getSingleAds(),
      ]);
    }
  }

  // Handle Checkout Click
  handleCheckoutClick = () => {
    if (this.state.cart.length > 0) {
      // if there is any bundle in cart,
      // check that there are at least BUNDLE_QTY items in cart that are part of this bundle
      // if not, return

      //  first find all products that are bundles
      const bundles = this.state.cart.filter((item) => item.product?.bundle);
      if (bundles.length > 0) {
        // if there are bundles in cart, check that there are at least BUNDLE_QTY items in cart that are part of this bundle
        bundles.forEach((bundle) => {
          const bundleItems = this.state.cart.filter(
            (item) => item?.bundle_id === bundle.product?.id
          );
          if (bundleItems.length < bundle.product?.bundle_count) {
            // Here i must remove the bundle from cart
            // and show error message

            // remove bundle from cart
            productService
              .addToCart(bundle.product_option?.id, 0)
              .then((res) => {
                // reload cart
                if (res.success) {
                  cartService.getUserCart().then(async (res) => {
                    await this.getCartIDs();

                    // set cart to current state
                    this.setState({
                      ...this.state,
                      cart: res.data,
                    });
                    this.props.setUser({
                      ...this.props.currentUser,
                      cart: res.data.length,
                    });

                    // show error message
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: this.props.i18n.bundleProductSoldOutError,
                    }).then(() => {
                      this.props.history.push(
                        `/ProductDetailsBundle/${bundle.product?.machine_name}`
                      );
                    });
                  });
                }
              });

            return;
          } else {
            this.props.history.push("/Checkout");
          }
        });
      } else {
        this.props.history.push("/Checkout");
      }
    }
  };

  // get Single Ads start
  getSingleAds = async () => {
    // function to get Single Ads
    const { data, success } = await generalServices.getSingleAds(
      "home_under_categories_block_left_space",
      0,
      this.props.country.id
    );
    if (!success) return;
    this.setState({
      adsData: data,
      adsLoader: false,
    });
  };
  // getSingleAds end

  // get User Cart start
  getUserCart = async () => {
    // function to get user cart data
    this.setState({ qtyLoader: true });
    const { data, success } = await cartService.getUserCart();
    const itemsCount = data?.map(({ qty }) => qty);
    if (!success) return;
    this.setState({
      cart: data,
      itemsCount,
      payment_methods: data.payment_methods,
      loaderPage: false,
      qtyLoader: false,
    });
  };
  // get User Cart end

  // getCheckoutAmounts start
  getCheckoutAmounts = async () => {
    // function to get Checkout Amounts and set it to current state
    const { data, success } = await cartService.getCheckoutAmounts({
      payment_id: 0,
      delivery_company_id: 0,
      place_id: "1",
      coupons: [localStorage.getItem("beam_coupon")],
    });
    if (!success) return;
    this.setState({
      cartAmount: data,
    });
  };
  // getCheckoutAmounts end

  render() {
    const { cart, cartAmount, loaderPage, qtyLoader } = this.state;
    const {
      couponErrorText,
      couponError,
      couponInputIsEmpty,
      Coupon,
      CouponFields,
    } = this.state;
    const {
      language,
      i18n: {
        CouponText,
        CouponCode,
        ProcerdtoCheckout,
        cartEmpty,
        ClearCart,
        DiscountAmount,
        CouponsSlashGiftCards,
        taxesAmount,
        feesAmount,
        AddPromo,
        Apply,
        cartSummary,
        SubTotal,
        deliveryAmount,
        TotalPrice,
        Home,
        Cart,
        Delete,
      },
    } = this.props;

    return (
      <div className={`Cart ${language === "rtl" && "Cart-Arabic"}`}>
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={Cart}
          links={[
            { name: Home, url: "/" },
            { name: Cart, url: "/cart" },
          ]}
        />

        <Container className="mt-5">
          <Row>
            <Col
              lg={cart.length || loaderPage > 0 ? 8 : 12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginBottom: "100px" }}
            >
              {loaderPage ? (
                <Row className="mb-5 gy-4">
                  <Col xs={12}>
                    <Skeleton height={200} />
                  </Col>
                  <Col xs={12}>
                    <Skeleton height={200} />
                  </Col>
                </Row>
              ) : (
                <>
                  {cart.length < 1 && (
                    <div className="empty-products">
                      <img src="/img/fileEmpty.svg" alt="k" />
                      <p> {cartEmpty} </p>
                    </div>
                  )}
                  {cart?.map((item, index) => {
                    return (
                      <>
                        <CartProductBlock
                          data={item}
                          key={index}
                          reFetchData={() =>
                            Promise.all([
                              this.getUserCart(),
                              this.getCheckoutAmounts(),
                            ])
                          }
                          loader={qtyLoader}
                        />
                      </>
                    );
                  })}
                  {cart?.length > 0 && (
                    <div className="clear-cart mt-3" onClick={this.clearCart}>
                      {ClearCart}
                    </div>
                  )}
                </>
              )}
            </Col>
            {/* Cart Products end */}

            {/* Cart summary block start */}
            {loaderPage ? (
              <Col lg={4} md={12}>
                <Skeleton height={600} />
              </Col>
            ) : (
              cart.length > 0 && (
                <Col lg={4} md={12} className="cart-summary-container">
                  <div className="cart-summary">
                    <section className="cart-summary__section">
                      {/* Add Promo title start */}
                      <header className="cart-summary__section__header">
                        <h5>{AddPromo}</h5>
                      </header>
                      {/* Add Promo title end */}

                      <div className="cart-summary__section__body">
                        <div className="mb-3">
                          {/* Code input Start */}
                          <InputGroup className="add-promo-input-group">
                            <FormControl
                              name={`couponCode-${Coupon.length}`}
                              value={
                                CouponFields[`couponCode-${Coupon.length - 1}`]
                              }
                              placeholder={CouponCode}
                              aria-label={CouponCode}
                              aria-describedby="basic-addon2"
                              onChange={({ target: { name, value } }) =>
                                this.onCouponFieldChange(name, value)
                              }
                            />
                            <Button
                              disabled={couponInputIsEmpty}
                              variant="outline-secondary"
                              id="button-addon2"
                              className={` submit-promocode ${
                                language === "ar" && "buttAr"
                              }`}
                              onClick={this.checkCouponIfValid}
                            >
                              {Apply}
                            </Button>
                          </InputGroup>
                          {couponError && (
                            <ErrorMessage message={couponErrorText} isSelect />
                          )}
                          {/* Code input End */}

                          {/* valid coupons start  */}
                          <ul className="valid-coupon-list mt-4">
                            {Coupon.map((item, index) => {
                              return (
                                <li
                                  data-index={index}
                                  key={index}
                                  className="mb-1"
                                >
                                  <span>
                                    {CouponText} : {item}
                                  </span>
                                  <span
                                    onClick={() => this.deleteValidCoupon(item)}
                                  >
                                    {Delete}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                          {/* valid coupons end  */}
                        </div>
                      </div>
                    </section>
                    <section className="cart-summary__section">
                      <header className="cart-summary__section__header">
                        <h5>{cartSummary}</h5>
                      </header>
                      <div className="cart-summary__section__body p-0">
                        <div className="cart-summary__section__body__summary-field">
                          <div className="summary-field__title">{SubTotal}</div>
                          <div className="summary-field__value">
                            <span>
                              {this.props.currency.currencyName} {"  "}
                            </span>
                            {(
                              cartAmount?.items_amount *
                              this.props.currency.currencyFactor
                            ).toFixed(2)}
                          </div>
                        </div>
                        {cartAmount?.delivery_amount > 0 && (
                          <div className="cart-summary__section__body__summary-field">
                            <div className="summary-field__title">
                              {deliveryAmount}
                            </div>
                            <div className="summary-field__value">
                              <span>{this.props.currency.currencyName}</span>
                              {"  "}{" "}
                              {(
                                cartAmount?.delivery_amount *
                                this.props.currency.currencyFactor
                              ).toFixed(2)}
                            </div>
                          </div>
                        )}

                        {cartAmount?.fees_amount > 0 && (
                          <div className="cart-summary__section__body__summary-field">
                            <div className="summary-field__title">
                              {feesAmount}
                            </div>
                            <div className="summary-field__value">
                              <span>{this.props.currency.currencyName}</span>
                              {"  "}{" "}
                              {(
                                cartAmount?.fees_amount *
                                this.props.currency.currencyFactor
                              ).toFixed(2)}
                            </div>
                          </div>
                        )}

                        {cartAmount?.taxes_amount > 0 && (
                          <div className="cart-summary__section__body__summary-field">
                            <div className="summary-field__title">
                              {taxesAmount}
                            </div>
                            <div className="summary-field__value">
                              <span>{this.props.currency.currencyName}</span>
                              {"  "}{" "}
                              {(
                                cartAmount?.taxes_amount *
                                this.props.currency.currencyFactor
                              ).toFixed(2)}
                            </div>
                          </div>
                        )}

                        {cartAmount?.discount_amount > 0 && (
                          <div className="cart-summary__section__body__summary-field">
                            <div className="summary-field__title">
                              {DiscountAmount}
                              <p
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                ({CouponsSlashGiftCards})
                              </p>
                            </div>
                            <div className="summary-field__value">
                              <span>{this.props.currency.currencyName}</span>
                              {"  "}{" "}
                              {(
                                cartAmount?.discount_amount *
                                this.props.currency.currencyFactor
                              ).toFixed(2)}
                            </div>
                          </div>
                        )}
                        <div className="cart-summary__section__body__summary-field total-field">
                          <div className="summary-field__title">
                            {TotalPrice}
                          </div>
                          <div className="summary-field__value">
                            <span>{this.props.currency.currencyName}</span>{" "}
                            {"  "}{" "}
                            {(
                              cartAmount?.total_amount *
                              this.props.currency.currencyFactor
                            ).toFixed(2)}
                          </div>
                        </div>
                      </div>
                      <div className="cart-summary__section__footer">
                        <div className="CheckoutButt">
                          {/* Checkout Button */}
                          <a onClick={this.handleCheckoutClick}>
                            {ProcerdtoCheckout}
                          </a>
                          {/* Checkoout end */}
                        </div>
                      </div>
                    </section>
                  </div>
                </Col>
              )
            )}
          </Row>
        </Container>
        {/* Cart Products And Amount End */}
      </div>
    );
  }

  onCouponFieldChange = (name, value) => {
    // Field Change handler
    if (value === "") return this.setState({ couponInputIsEmpty: true });
    this.setState({
      couponError: false,
      couponInputIsEmpty: false,
      CouponFields: {
        [name]: value,
      },
    });
  };

  deleteValidCoupon = (coupon) => {
    let newCoupon = this.state.Coupon.filter((item) => item !== coupon);
    console.log("newCoupon", newCoupon);
    this.setState(
      {
        Coupon: newCoupon,
      },
      () => {
        localStorage.removeItem("beam_coupon");
        this.getCheckoutAmounts();
      }
    );
  };

  checkCouponIfValid = async () => {
    // Check if coupon is valid
    const { Coupon, CouponFields, selectedLocation } = this.state;
    let couponReadyToSend = [];
    for (const item in CouponFields) {
      couponReadyToSend.push(CouponFields[item]);
    }

    const { data } = await cartService.checkCouponIfValid({
      guest_id: apiService.accessToken === 0 ? this.guestId : 0,
      payment_id: 1,
      delivery_company_id: 0,
      place_id: selectedLocation?.place?.id,
      coupon: CouponFields[`couponCode-${Coupon.length}`],
    });
    console.log("data", data);
    if (!data)
      return this.setState({
        couponError: true,
        couponErrorText: "Invalid Coupon Code",
      });

    if (Coupon.includes(CouponFields[`couponCode-${Coupon.length}`]))
      return this.setState({
        couponError: true,
        couponErrorText: "Coupon Used",
        CouponFields: { [`couponCode-${Coupon.length}`]: "" },
      });

    localStorage.setItem("beam_coupon", [...Coupon, ...couponReadyToSend]);

    this.setState(
      {
        CouponFields: { [`couponCode-${Coupon.length}`]: "" },
        couponInputIsEmpty: true,
        Coupon: [...Coupon, ...couponReadyToSend],
      },
      () => {
        this.getCheckoutAmounts();
      }
    );
  };

  // get Cart IDs start
  getCartIDs = async () => {
    // function to get user cart from the server and set it to user redux store data
    const { data, success } = await generalServices.getCartIDs();
    if (!success) return;
    this.props.setUser({
      cartIds: data,
    });
  };
  // get Cart IDs end

  // get Icon Data start
  getIconData = async () => {
    // function to get Fav Cart compare Counters from the server and set it to user redux store data
    const { data, success } = await generalServices.getIconData();
    if (!success) return;
    this.props.setUser({
      cart: data.cart_counter,
      fav: data.fav_counter,
      compare: data.compare_counter,
    });
  };
  // get Icon Data end

  // clear cart function start
  clearCart = async () => {
    // clear Cart server request
    const { data, success } = await cartService.clearCart();
    if (!success) return;
    this.getUserCart();
    this.getCartIDs();
    this.getIconData();
  };
  // clear cart function end
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
  currency,
});

export default connect(mapStateToProps, { setUser })(Cart);
