import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function PageTitle({ title, links }) {
  const language = useSelector(({ language }) => language);

  return (
    <Container
      fluid
      className={`page-title ${
        language.langCode === "rtl" && "page-title-arabic"
      }`}
    >
      <div className="text-center">
        {/* title start  */}
        <p className="m-0 title">{title}</p>
        {/* title end  */}

        {/* breadcrumb start  */}
        <Breadcrumb
          className="bread text-center justify-content-center"
          style={{ height: "25px" }}
        >
          {links?.map(({ name, url }, index) => {
            return (
              <Breadcrumb.Item active key={index}>
                <Link to={url}>{name}</Link>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        {/* breadcrumb end  */}
      </div>
    </Container>
  );
}

export default PageTitle;
