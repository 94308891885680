import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)

const PushMenu = ({ setShowLoginModal }) => {
  const [MenuOpen, setMenuOpen] = useState(false);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const {
    GIFTCARDS,
    SALE,
    MyOrders,
    SUBSCRIPTIONS,
    Home,
    theExchange,
    theReturn,
  } = i18n;

  const pathName = window.location.pathname;

  const closeMenu = () => {
    // close Menu handler
    setMenuOpen(false);
  };

  const handleStateChange = (state) => {
    // handle burger menu State Change
    setMenuOpen(state.isOpen);
  };

  return (
    <div
      className={`mobile-push-menu ${
        language.langCode === "rtl" && "mobile-push-menu-arabic"
      }`}
    >
      <Menu
        right={language.langCode === "rtl" ? true : false}
        onStateChange={(state) => handleStateChange(state)}
        isOpen={MenuOpen}
        customBurgerIcon={<i className="fa fa-bars" />}
        customCrossIcon={<i className="fa fa-times croosIconMenu" />}
      >
        {/* logo start  */}
        <div className="sideMenu_logo">
          <a href="/" onClick={closeMenu}>
            <img
              className="logo-image"
              src={
                "https://api.beamshop.com/public/storage/298833908343299.png"
              }
              alt="logo"
            />
          </a>
        </div>
        {/* logo end  */}

        <div className="sideMenu_List">
          <ul className="mb-0 mt-3">
            {/* home start  */}
            <li className={pathName.toLowerCase() === "/" ? "active" : ""}>
              <Link to="/" onClick={() => closeMenu()}>
                {Home}
              </Link>
            </li>
            {/* home end  */}

            {/* SUBSCRIPTIONS start  */}
            <li
              className={
                `preParams${pathName.toLowerCase()}`.startsWith(
                  "preParams/subscriptions"
                )
                  ? "active"
                  : ""
              }
              onClick={() => closeMenu()}
            >
              <Link to="/Subscriptions">{SUBSCRIPTIONS}</Link>
            </li>
            {/* SUBSCRIPTIONS end  */}

            {/* SALE start  */}
            <li
              className={pathName.toLowerCase() === "/sale" ? "active" : ""}
              onClick={() => closeMenu()}
            >
              <Link to="/sale/1">{SALE}</Link>
            </li>
            {/* SALE end  */}

            {/* GIFTCARDS start  */}
            <li
              className={
                pathName.toLowerCase().startsWith("/giftcard") ? "active" : ""
              }
              onClick={() => closeMenu()}
            >
              <Link to="/GiftCardPage">{GIFTCARDS}</Link>
            </li>

            <li>
              <Link
                onClick={() => {
                  setShowLoginModal();
                  closeMenu();
                }}
                to={currentUser?.email ? "/ReturnRequestsProfilePage" : "#"}
              >
                {theReturn}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setShowLoginModal();
                  closeMenu();
                }}
                to={currentUser?.email ? "/ExchangeRequestsProfilePage" : "#"}
              >
                {theExchange}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setShowLoginModal();
                  closeMenu();
                }}
                to={currentUser?.email ? "/orders/1" : "#"}
              >
                {MyOrders}
              </Link>
            </li>
          </ul>
        </div>
      </Menu>
    </div>
  );
};

export default PushMenu;
