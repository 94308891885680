import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../../redux/actions-reducers/user";
import { cartService } from "../../../services/CartService";
import Skeleton from "react-loading-skeleton";
import { tabletScreen } from "../../../utils/misc";
import PageTitle from "../../blocks/PageTitle";
import { locationService } from "../../../services/LocationService";
import LocationBlock from "../../blocks/LocationBlock";
import { useDispatch, useSelector } from "react-redux";
import ChoseCity from "./ChoseCity";
import Summary from "./Summary";
import AddLocationGest from "./AddLocationGest";
import AddLocationUser from "./AddLocationUser";
import EditLocationUser from "./EditLocationUser";

export default function Checkout() {
  const dispatch = useDispatch();
  const [cartAmount, setCartAmount] = React.useState(0);
  const [loaderPage, setLoaderPage] = React.useState(0);
  const [cart, setCart] = React.useState(0);
  const [locationLoader, setLocationLoader] = React.useState(0);
  const [selectedLocation, setSelectedLocation] = React.useState(0);
  const [userAddressesData, setUserAddressesData] = React.useState(0);
  const [cityModalOpen, setCityModalOpen] = React.useState(0);
  const [locationModalOpen, setLocationModalOpen] = React.useState(0);
  const [cityModalLoading, setCityModalLoading] = React.useState(false);
  const [handleAddLocation, setHandleAddLocation] = React.useState(0);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const { langCode } = useSelector(({ language }) => language);
  const [completeInformation, setCompleteInformation] = React.useState(false);

  const { Home, ProcerdtoCheckout, AddLocationButt, CartMobile } =
    Global[langCode === "ltr" ? "en" : "ar"];

  const getUserLocation = async () => {
    // function to get user locations and set it to current state
    setLocationLoader(true);
    const test = await locationService.getUserLocation();
    setLocationLoader(false);

    const data = test?.data;
    const success = test?.success;
    if (!success) return;
    setUserAddressesData(data);
  };

  const getUserCart = async () => {
    // function to get user cart data
    const { data, success } = await cartService.getUserCart();
    if (!success) return;
    setCart(data);
  };

  const getCheckoutAmounts = async () => {
  

    // function to get Checkout Amounts and set it to current state
    const { data, success } = await cartService.getCheckoutAmounts({
      payment_id: 0,
      delivery_company_id: 0,
      place_id: "1",
      coupons: [localStorage.getItem("beam_coupon")],
    });
    if (!success) return;

    setCartAmount(data);
  };

  React.useEffect(() => {
    setLoaderPage(true);
    Promise.all([
      getUserCart(),
      getCheckoutAmounts(),
      getUserLocation(),
    ]).finally(() => setLoaderPage(false));
  }, []);

  return (
    <div className={`Cart ${langCode === "rtl" && "Cart-Arabic"}`}>
      <PageTitle // component that shows links to the page ( Bread Crumb )
        title="Checkout"
        links={[
          { name: Home, url: "/" },
          { name: CartMobile, url: "/cart" },

          { name: ProcerdtoCheckout, url: "/Checkout" },
        ]}
      />
      <Container className="mt-5">
        <Row>
          <Col
            xl={cart.length || loaderPage > 0 ? 8 : 12}
            lg={8}
            md={12}
            sm={12}
            xs={12}
            style={{ marginBottom: "100px" }}
          >
            {locationLoader ? (
              <Row className="mb-5 gy-4">
                <Col lg={6} md={12}>
                  <Skeleton height={160} />
                </Col>
                <Col lg={6} md={12}>
                  <Skeleton height={160} />
                </Col>
              </Row>
            ) : loaderPage ? (
              <Row className="mb-5">
                <Col xs={12}>
                  <Skeleton height={tabletScreen ? 100 : 170} />
                </Col>
              </Row>
            ) : currentUser?.email && userAddressesData.length > 0 ? ( // if there is current user and there is user addresses user have to select one
              <div>
                <Row>
                  {userAddressesData.map((item) => {
                    return (
                      <Col lg={6} md={12} key={item.id}>
                        <LocationBlock
                          data={item}
                          loader={false}
                          reFetchData={() => getUserLocation()}
                          withoutEditDelete
                          selectedLocation={(location) => {
                            dispatch(
                              setUser({
                                selectedLocation: location,
                              })
                            );
                            setSelectedLocation(location);
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <ChoseCity // if the user select an address without all required data then this component help him to fill all required data
                  selectedLocation={selectedLocation}
                  setLoading={setCityModalLoading}
                  modal={cityModalOpen}
                  setModal={setCityModalOpen}
                  check={completeInformation}
                  setCheck={setCompleteInformation}
                  key={selectedLocation?.id}
                />

                <button
                  className="submit-button2"
                  onClick={() => setLocationModalOpen(true)}
                >
                  {AddLocationButt}
                </button>
              </div>
            ) : currentUser?.email ? (
              <AddLocationUser // if there is current user and there is no user addresses user have to add one
                show={locationModalOpen}
                closeModal={setLocationModalOpen}
                reFetchData={() => getUserLocation()}
                currentState={"add"}
                isAdd={true}
                data={{}}
                fromCheckOut
                storeLocation={(data) => setSelectedLocation(data)}
                fromGuest={true}
                handleAddLocation={handleAddLocation}
                restHandleAddLocation={() => setHandleAddLocation(false)}
                setLoading={setCityModalLoading}
                loading={cityModalLoading}
              />
            ) : (
              <>
                <AddLocationGest // if there is no current user
                  show={locationModalOpen}
                  closeModal={setLocationModalOpen}
                  reFetchData={() => getUserLocation()}
                  currentState={"add"}
                  isAdd={true}
                  data={{}}
                  fromCheckOut
                  storeLocation={(data) => setSelectedLocation(data)}
                  fromGuest={true}
                  handleAddLocation={handleAddLocation}
                  restHandleAddLocation={() => setHandleAddLocation(false)}
                  setLoading={setCityModalLoading}
                  loading={cityModalLoading}
                />
              </>
            )}
          </Col>
          {/* user address end */}

          {/* CART SUMMARY BLOCK START */}
          {loaderPage ? (
            <Col lg={4} md={12}>
              <Skeleton height={600} />
            </Col>
          ) : (
            cart.length > 0 && (
              <Col
                lg={4}
                md={12}
                className="cart-summary-container align-items-end"
              >
                <Summary
                  cartAmount={cartAmount}
                  cart={cart}
                  FirstBlock="products"
                  inCheckout={true}
                  selectedLocation={selectedLocation}
                  handleAddLocation={() => setHandleAddLocation(true)}
                  loading={cityModalLoading}
                  setLoading={setCityModalLoading}
                  email={currentUser?.email && userAddressesData.length > 0}
                  setModal={setCityModalOpen}
                  setCompleteInformation={setCompleteInformation}
                />
              </Col>
            )
          )}
        </Row>
      </Container>

      {/* add location modal start */}
      <Modal
        size="lg"
        show={locationModalOpen}
        onHide={() => setLocationModalOpen(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="login-modal"
      >
        <Modal.Header
          closeButton
          className="login-modal__header"
        ></Modal.Header>
        <Modal.Body className="login-modal__body mb-3">
          <EditLocationUser
            show={locationModalOpen}
            closeModal={() => setLocationModalOpen(false)}
            reFetchData={() => getUserLocation()}
            currentState={"add"}
            data={{}}
            fromCheckOut
            storeLocation={(data) => setSelectedLocation(data)}
            fromGuest={true}
            handleAddLocation={handleAddLocation}
            restHandleAddLocation={() => setHandleAddLocation(false)}
            isAdd
            setLoading={setCityModalLoading}
          />
        </Modal.Body>
      </Modal>

      {/* add location modal end */}
    </div>
  );
}
