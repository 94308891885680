import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import { userService } from "../../../../services/UserService";
import WalletTable from "./WalletTable";
import PaginatedItems from "../../../Pages/Search/PaginationBlack";
import { withRouter } from "react-router-dom";
class Wallet extends Component {
  state = {
    StatusCase: this.props.i18n.All,
    color: "",
    wallet: [],
    fields: {
      to: "",
      from: "",
      key: "",
      status: "",
    },
    currentPage: 1,
    loader: true,
    meta: {},
    userInfo: {},
  };

  componentDidMount() {
    Promise.all([this.getMyWallet(), this.getUserInformation()]);
  }
  
  getMyWallet = async (page) => {
    // function to get user Wallet and set it to the state
    const { data, success, meta } = await userService.getMyWallet(
      this.props.match.params.page
    );
    if (!success) return;

    this.setState({
      wallet: data,
      pageLoader: false,
      meta: meta,
      loader: false,
    });
  };

  getUserInformation = async () => {
    // function to get user Information from the server and set it to the current state
    const { data, success } = await userService.getUserInformation();
    if (!success) return;
    this.setState({
      userInfo: data,
    });
  };

  render() {
    const {
      language,
      i18n: { CurrentWalletBalance, DeductedAmountWhich },
    } = this.props;
    const { loader, wallet, userInfo, meta } = this.state;

    return (
      <Container>
        {/* title start  */}
        <div
          className={`layalty wallet ${language === "rtl" && "layalty-arabic"}`}
        >
          <div className="account-page__title">
            <h3>
              {CurrentWalletBalance}
              {loader ? (
                <Skeleton height={25} width={100} />
              ) : (
                <span>
                  {userInfo?.wallet_balance &&
                    (
                      userInfo?.wallet_balance *
                      this.props.currency.currencyFactor
                    ).toFixed(2)}{" "}
                  {this.props.currency.currencyName}
                </span>
              )}
            </h3>
            <h4>{DeductedAmountWhich}</h4>
          </div>
          {/* title start */}

          {this.state.loader ? (
            <>
              <Row>
                <Col md={12}>
                  <Skeleton height={400} className="mt-4" />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <WalletTable // Wallet Table component
                data={wallet}
                meta={meta}
                getUserOrders={() => this.getMyWallet()}
              />
            </>
          )}

        </div>

        {/* Pagination Start */}
        {this.state.loader ? null : meta.last_page > 1 ? (
          <Row>
            <Col md={12}>
              <div className="Pagination">
                <PaginatedItems // pagination component that handle the pages
                  items={[...Array(meta?.total).keys()]}
                  itemsPerPage={meta?.per_page}
                  changePage={(page) => this.changePage(page)}
                />
              </div>
            </Col>
          </Row>
        ) : null}
        {/* Pagination End */}
        
      </Container>
    );
  }

  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(`/WalletPage/${page + 1}`);

    this.setState(
      {
        pageLoader: true,
      },
      () => {
        this.getMyWallet();
      }
    );
  }
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  countryId: country.id,
  currency,
});

export default connect(mapStateToProps, { setLanguage })(withRouter(Wallet));
