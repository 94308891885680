import React, { Component } from "react";
import PageTitle from "../../blocks/PageTitle";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import TextInput from "../../blocks/TextInput";
import { generalServices } from "../../../services/GeneralService";
import { displayAlert } from "../../../utils/misc";
import { getResponseErrors } from "../../../utils/misc";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )
import UploadFile from "../../blocks/UploadFile";
import Skeleton from "react-loading-skeleton";
export class ContactUs extends Component {
  state = {
    loaderPage: true,
    loading: false,
    subjects: [
      { label: "Male", value: 1 },
      { label: "Female", value: 2 },
    ],
    companyLocation: {},
    helpCenter: [],
    fields: {
      name: this.props.currentUser.name ?? "",
      email: this.props.currentUser.email ?? "",
      message: "",
      subject: "",
      phone: "",
      file: "",
    },
    errors: {
      name: "",
      email: "",
      message: "",
      subject: "",
      phone: "",
      file: "",
    },
  };

  componentDidMount() {
    Promise.all([this.getCompanyLocationByPlace(), this.getHelpCenter()]);
  }

  // get Help Center start
  getHelpCenter = async () => {
    // function to get Help Center data from the server and set it to the state
    const { data, success } = await generalServices.getHelpCenter(
      this.props.country.id
    );

    if (!success) return;

    this.setState({
      helpCenter: data,
    });
  };
  // get Help Center end

  // get Company Location By Place start
  getCompanyLocationByPlace = async () => {
    // function to get Company Location data from the server and set it to the state
    const { data, success } = await generalServices.getCompanyLocationByPlace(
      this.props.country.id
    );
    if (!success) return;

    this.setState({
      companyLocation: data,
    });
  };
  // get Company Location By Place end

  render() {
    const { fields, errors, companyLocation, loading, helpCenter } = this.state;
    const {
      i18n: {
        Home,
        ContactUs,
        Name,
        Email,
        send,
        subjectText,
        ContactForm,
        ConnectWithUs,
        Phone,
        contactUsMessage,
      },
    } = this.props;

    return (
      <div className="contact-us">
        {/* page title start  */}
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={ContactUs}
          links={[
            { name: Home, url: "/" },
            { name: ContactUs, url: "/ContactUs" },
          ]}
        />
        {/* page title end  */}

        <Container>
          <Row>
            <Col md={8} className="p-0">
              <div className="contact-us__form">
                <h4>{ContactForm}</h4>

                {/* contact form start  */}
                <Row>
                  {/* name input start  */}
                  <Col md={6}>
                    <TextInput
                      placeholder={Name}
                      name="name"
                      value={fields.name}
                      onFieldChange={this.onFieldChange}
                      validate={errors.name}
                    />
                  </Col>
                  {/* name input end  */}

                  {/* email input start  */}
                  <Col md={6}>
                    <TextInput
                      placeholder={Email}
                      name="email"
                      value={fields.email}
                      onFieldChange={this.onFieldChange}
                      validate={errors.email}
                    />
                  </Col>
                  {/* email input end  */}

                  {/* subject input start  */}
                  <Col md={6}>
                    <TextInput
                      placeholder={subjectText}
                      name="subject"
                      value={fields.subject}
                      onFieldChange={this.onFieldChange}
                      validate={errors.subject}
                    />
                  </Col>
                  {/* subject input end  */}

                  {/* name input start  */}
                  <Col md={6}>
                    <TextInput
                      placeholder={Phone}
                      name="phone"
                      value={fields.phone}
                      onFieldChange={this.onFieldChange}
                      validate={errors.phone}
                    />
                  </Col>
                  {/* name input end  */}

                  {/* message input start  */}
                  <Col md={12}>
                    <TextInput
                      placeholder={contactUsMessage}
                      name="message"
                      value={fields.message}
                      onFieldChange={this.onFieldChange}
                      validate={errors.message}
                      isTextArea
                      maxLength={2000}
                    />
                  </Col>
                  {/* message input end  */}

                  {/* image input start  */}
                  <Col md={6} className="mb-4">
                    <UploadFile
                      name="file"
                      uploadImage={this.uploadImage}
                      value={fields.file}
                      validate={errors.file}
                      noBrowse
                      setValue={(x) =>
                        this.setState({ fields: { ...fields, file: x } })
                      }
                    />
                  </Col>
                  {/* image input end  */}
                </Row>
                {/* contact form end  */}

                {/* contact submit button start  */}
                {loading ? (
                  <Row>
                    <Col>
                      <Skeleton className="submit-btn" />
                    </Col>
                  </Row>
                ) : (
                  <button className="submit-btn" onClick={this.submitContactUs}>
                    {send}
                  </button>
                )}
                {/* contact submit button end  */}
              </div>
            </Col>
            {/* help center start  */}
            <Col md={4} className="p-0">
              <div className="contact-us__info">
                <h3>{ConnectWithUs}</h3>
                <ul>
                  {helpCenter?.map(({ id, name, icon, value }, index) => {
                    return (
                      <li key={id}>
                        <a
                          href={
                            index === 0
                              ? `mailto:${value}`
                              : index === 1
                              ? `tel:${value}`
                              : "#"
                          }
                          className="flex"
                        >
                          <div>
                            <img src={apiService.imageLink + icon} alt="l" />
                          </div>
                          <div>
                            <span>{name}</span>
                            <p>{value}</p>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
            {/* help center end  */}
          </Row>
        </Container>
        {/* map start  */}
        <iframe
          src={`https://maps.google.com/maps?q=${companyLocation?.latitude},${companyLocation?.longitude}&t=&z=10&ie=UTF8&iwloc=&output=embed`}
          width="100%"
          height="450"
          className="my-5"
        ></iframe>
        {/* map end  */}
      </div>
    );
  }

  // on field change start
  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        name: "",
        mobile: "",
        password_confirmation: "",
        password: "",
        email: "",
        file: "",
      },
    });
  };
  // on field change end

  // upload Image start
  uploadImage = async (fileImage) => {
    // upload Image function
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("file", fileImage);
    const { data } = await generalServices.uploadImage(formData);
    if (data) {
      this.setState({
        loading: false,
        fields: { ...this.state.fields, file: data },
      });
    }
  };
  // upload Image end

  // submit Contact Us start
  submitContactUs = async () => {
    // submit Contact Us to the server
    const { fields } = this.state;
    const { done, thanksForContact, Error, contactUsMessage } = this.props.i18n;

    if (fields.message.length > 50) {
      const { success, data, error } = await generalServices.submitContactForm({
        email: fields.email,
        name: fields.name,
        phone: fields.phone,
        // subject: fields.subject.value,
        file: fields.file,
        subject: fields.subject,
        message: fields.message,
      });

      if (!data) {
        if (error) return this.setState({ errors: getResponseErrors(error) });
        return;
      }

      displayAlert(done, thanksForContact, "success");
      this.setState({
        fields: {
          email: "",
          name: "",
          phone: "",
          subject: "",
          message: "",
          file: "rerender",
        },
      });
    } else {
      displayAlert(Error, contactUsMessage, "error");
    }
  };
  // submit Contact Us end
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});

export default connect(mapStateToProps, { setLanguage })(ContactUs);
