import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import PageTitle from "../../blocks/PageTitle";
import { homeService } from "../../../services/homeService";
import ProductCarousel from "../../blocks/ProductCarousel";

class PrivateCollections extends Component {
  state = {
    fullView: false,
    cart: [],
    collections: [],
    payment_methods: [],
    itemsPriceBeforeDiscount: 0,
    itemsPriceAfterDiscount: 0,
    adsData: [
      {
        image: "/images/Group55.png",
        link: "/",
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ],
    loaderPage: true,
  };

  componentDidMount() {
    Promise.all([this.getCollections()]);
  }

  // getCollections start
  getCollections = async () => {
    // function to get Collections  and set it to current state
    const { data, success } = await homeService.getCollections();
    if (!success) return;

    this.setState({
      collections: data?.under_recommendedforyou,
      loaderPage: false,
    });
  };
  // getCollections end

  render() {
    const { loaderPage, collections } = this.state;
    const {
      i18n: { Home, PRIVATECOLLECTIONS, brandProductsEmpty },
    } = this.props;

    return (
      <div className="Cart">
        {/* page title start  */}
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={PRIVATECOLLECTIONS}
          links={[
            { name: Home, url: "/" },
            { name: PRIVATECOLLECTIONS, url: "/PrivateCollections" },
          ]}
        />
        {/* page title end  */}

        {/* Start */}
        <Container>
          <Row>
            {/* Private loaderPage products start  */}
            {loaderPage ? (
              <Col xs={12}>
                <div
                  className="pb-5 px-4 categories-block categories-block-2"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  <div className="flex justify-content-center mb-5">
                    <Skeleton height={50} width={300} />
                  </div>
                  <Row className="px-2 flex-nowrap overflow-hidden">
                    {Array(4)
                      .fill(4)
                      .map((item, index) => (
                        <Col xl={3} lg={4} md={6} xs={6} key={index}>
                          <Skeleton className="product-image-Skeleton" />
                          <div className="px-2 py-3">
                            <div className="mb-2 px-3 px-sm-4">
                              <Skeleton height={25} />
                            </div>
                            <div className="px-4 px-sm-5">
                              <Skeleton height={25} />
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              </Col>
            ) : collections.length === 0 ? (
              <Col md={12}>
                <div className="empty-products mb-5">
                  <img src="/img/fileEmpty.svg" alt="k" />
                  <p> {brandProductsEmpty} </p>{" "}
                </div>
              </Col>
            ) : (
              collections.map((item, index) => {
                return (
                  <Col xs={12}>
                    <ProductCarousel //Product Carousel ( slider ) component
                      labelTitle={null}
                      title={item?.name}
                      data={item.products}
                      reFetchData={() => this.getCollections()}
                      rowCount={1}
                    />
                  </Col>
                );
              })
            )}
          </Row>
        </Container>
        {/* End */}
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});

export default connect(mapStateToProps, { setLanguage })(PrivateCollections);
