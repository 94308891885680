import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import LayaltyProgramTable from "./LayaltyProgramTable";
import { userService } from "../../../../services/UserService";
import RewardsTable from "./RewardsTable";
import PaginatedItems from "../../../Pages/Search/PaginationBlack";
import { withRouter } from "react-router-dom";
class LayaltyProgram extends Component {
  state = {
    StatusCase: this.props.i18n.All,
    color: "",
    points: [],
    fields: {
      to: "",
      from: "",
      key: "",
      status: "",
    },
    currentPage: 1,
    loader: true,
    meta: {},
    userInfo: {},
    Rewards: [],
  };

  componentDidMount() {
    Promise.all([
      this.getMyPoints(),
      this.getUserInformation(),
      this.getMyLoyalityRewards(),
    ]);
  }

  getMyPoints = async (page) => {
    // function to get user points from the server and set it to the current state 
    const { data, success, meta } = await userService.getMyPoints(
      this.props.match.params.page
    );

    if (!success) return;
    this.setState({
      points: data,
      pageLoader: false,
      meta: meta,
      loader: false,
    });
  };

  getMyLoyalityRewards = async (page) => {
    // function to get user Loyality Rewards from the server and set it to the current state 
    const { data, success } = await userService.getMyLoyalityRewards(1);

    if (!success) return;
    this.setState({
      Rewards: data,
      pageLoader: false,
      loader: false,
    });
  };

  getUserInformation = async () => {
    // function to get user Information from the server and set it to the current state 
    const { data, success } = await userService.getUserInformation();

    if (!success) return;

    this.setState({
      userInfo: data,
    });
  };

  render() {
    const {
      language,
      i18n: { Points, Earn, MorePointsYoReachGoldTier },
    } = this.props;
    const { Rewards, points, userInfo, meta } = this.state;

    return (
      <Container>
        {/* title start  */}
        <div className={`layalty ${language === "rtl" && "layalty-arabic"}`}>
          <div className="account-page__title">
            <h3>
              {userInfo?.loyalty_program?.current_tier?.name}{" "}
              <span>
                {userInfo?.points} {Points}
              </span>
            </h3>
            {userInfo?.loyalty_program?.next_tier && (
              <h4>
                {Earn} {userInfo?.loyalty_program?.next_tier_remain_points}{" "}
                {MorePointsYoReachGoldTier}{" "}
                {userInfo?.loyalty_program?.next_tier?.name}{" "}
                <i class="fa fa-exclamation-circle"></i>
              </h4>
            )}
          </div>
          {/* title start */}
          {this.state.loader ? (
            <>
              <Row>
                <Col md={12}>
                  <Skeleton height={350} className="mt-5" />
                </Col>
                <Col md={12}>
                  <Skeleton height={350} className="mt-5" />
                </Col>
              </Row>
            </>
          ) : (
            <>
              {/* Points table start  */}
              <LayaltyProgramTable
                data={points}
                meta={meta}
                getUserPoints={() => this.getMyPoints()}
              />
              {/* Points table end  */}

              {/* Points Pagination Start */}
              {meta.last_page > 1 && (
                <Row>
                  <Col md={12}>
                    <div className="Pagination">
                      <PaginatedItems // pagination component that handle the pages
                        items={[...Array(meta?.total).keys()]}
                        itemsPerPage={meta?.per_page}
                        changePage={(page) => this.changePage(page)}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {/* Points Pagination End */}

              {/* Rewards table start  */}
              {Rewards.length > 0 && (
                <RewardsTable
                  data={Rewards}
                  getUserPoints={() => this.getMyLoyalityRewards()}
                />
              )}
              {/* Rewards table start  */}
            </>
          )}
        </div>
      </Container>
    );
  }
  closeModal = () => {
    this.setState({ addAddress: false });
  };

  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(`/LayaltyProgram/${page + 1}`);

    this.setState(
      {
        pageLoader: true,
      },
      () => {
        this.getMyPoints();
      }
    );
  }
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  countryId: country.id,
  currency,
});

export default connect(mapStateToProps, { setLanguage })(
  withRouter(LayaltyProgram)
);
