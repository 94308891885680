import axios from "axios";

class ApiService {
  /* webURL = "http://192.168.1.192:9998/";
  baseURL = "http://192.168.1.192:9998/api/";
   token = "f95aedfed793a46023f9baa7d769c4c01be459cf";
  imageLink = "http://192.168.1.192:9998/storage/";*/

  webURL = "https://api.beamshop.com/";
  baseURL = "https://api.beamshop.com/api/";
  token = "f95aedfed793a46023f9baa7d769c4c01be459cf";
  imageLink = "https://api.beamshop.com/public/storage/";

  // accessToken getter
  get accessToken() {
    return localStorage.getItem("beamAccessToken") ?? 0;
  }

  // current language getter
  get language() {
    const language = localStorage.getItem("langCode");

    if (language) return language === "rtl" ? "ar" : "en";
    return "en";
  }

  // authenticated api call
  authenticated() {
    return axios.create({
      baseURL: this.baseURL,
      headers: {
        Token: this.token,
        Authorization: `Bearer ${this.accessToken}`,
        Language: this.language || "en",
      },
    });
  }

  // unauthenticated api call
  unauthenticated() {
    return axios.create({
      baseURL: this.baseURL,
      headers: { Token: this.token, Language: this.language || "en" },
    });
  }

  // set token to the local storage
  storeToken(token) {
    localStorage.setItem("beamAccessToken", token);
  }
}

export const apiService = new ApiService();
