import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setUser } from "../../redux/actions-reducers/user";

export class SummaryProduct extends Component {
  render() {
    const {
      product,
      product_option,
      invoice,
      i18n: { Total, Qunatity },
    } = this.props;
    return (
      <div className="summary-product mt-3">
        <Row className={`${invoice ? "align-items-center" : ""}`}>
          <Col xs={12} md={invoice ? 2 : 4}>
            <div className="summary-product__img">
              <img
                src={
                  product.product?.main_image
                    ? apiService.imageLink + product.product?.main_image
                    : "/images/placeholder.png"
                }
                alt="main_image"
                className="mw-100"
              />
            </div>
          </Col>

          <Col xs={12} md={invoice ? 10 : 8}>
            <div className="summary-product__info">
              <div className="summary-product__info__title">
                <p>{product.product?.title}</p>
              </div>
              <p className="summary-product__info__pre-order">
                {Boolean(product.is_pre_order) && <span> Pre-order</span>}
              </p>

              {(!product?.product?.bundle ||
                product?.product?.bundle?.length === 0) && (
                <div className="summary-product__info__options">
                  <p>{product_option?.join(",")}</p>
                </div>
              )}
              {!product.bundle_id && (
                <div className="summary-product__info__qty-price">
                  <p className="qty m-0">
                    {Qunatity}: x{product.qty}
                  </p>
                  <p className="price m-0">
                    {invoice && <span className="total-word">{Total}</span>}
                    {this.props.currency.currencyName}{" "}
                    {(
                      (invoice
                        ? product.total_price
                        : product?.product_option?.price_after_discount) *
                      this.props.currency.currencyFactor
                    ).toFixed(2)}
                  </p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
  currency,
});

export default connect(mapStateToProps, { setUser })(SummaryProduct);
