import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import PageTitle from "../../blocks/PageTitle";
import BoxCard from "../../blocks/BoxCard";
import { categoryService } from "../../../services/CategoryService";
import PaginatedItems from "../../Pages/Search/PaginationBlack";

class MySteryBoxPage extends Component {
  state = {
    fullView: false,
    cart: [],
    mysteryProducts: [],
    payment_methods: [],
    itemsPriceBeforeDiscount: 0,
    itemsPriceAfterDiscount: 0,
    adsData: [
      {
        image: "/images/Group55.png",
        link: "/",
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ],
    loaderPage: true,
    meta: {},
  };

  componentDidMount() {
    Promise.all([this.getMysteryBox()]);
  }

  getMysteryBox = async () => {
    // Mystery Box getter
    const { data, success, meta } =
      await categoryService.getSingleCategoryProducts({
        machine_name: this.props.match.params.machine_name,
        page: +this.props.match.params.page,
        options_values: [],
        brands: [],
        rate: "",
        price_from: "",
        price_to: "",
        free_shipping: false,
        new_arrivals: false,
        sort: "",
        has_discount: false,
        display: 20,
      });
    if (!success) return;

    this.setState({
      mysteryProducts: data,
      meta: meta,
      loaderPage: false,
    });
  };

  render() {
    const { mysteryProducts, loaderPage, meta } = this.state;
    const {
      i18n: { boxEmpty, Home, MYSTERYBOX },
    } = this.props;

    return (
      <div className="Cart">
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={MYSTERYBOX}
          links={[
            { name: Home, url: "/" },
            { name: MYSTERYBOX, url: "/MySteryBoxPage" },
          ]}
        />

        {/* Start */}
        <Container>
          <Row>
            {loaderPage ? (
              <>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
              </>
            ) : mysteryProducts.length === 0 ? (
              <Col md={12}>
                <div className="empty-products mb-5">
                  <img src="/img/orderEmpty.svg" alt="k" />
                  <p> {boxEmpty} </p>{" "}
                </div>
              </Col>
            ) : (
              mysteryProducts.map((item, index) => {
                return (
                  <Col lg={4} sm={6} className="mb-5">
                    <BoxCard key={index} data={item} />
                    {/* BoxCard comment */}
                  </Col>
                );
              })
            )}
          </Row>

          {/* Pagination Start */}
          {loaderPage ? null : meta?.last_page > 1 ? (
            <Row>
              <Col md={12}>
                <div className="Pagination">
                  <PaginatedItems // pagination component that handle the pages
                    items={[...Array(meta?.total).keys()]}
                    itemsPerPage={meta?.per_page}
                    changePage={(page) => this.changePage(page)}
                  />
                </div>
              </Col>
            </Row>
          ) : null}
          {/* Pagination End */}
        </Container>
        {/* End */}
      </div>
    );
  }

  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(
      `/MySteryBoxPage/${this.props.match.params.machine_name}/${page + 1}`
    );
    this.setState(
      {
        loaderPage: true,
      },
      () => {
        this.getMysteryBox();
      }
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});

export default connect(mapStateToProps, { setLanguage })(MySteryBoxPage);
