import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../redux/actions-reducers/language";
import { apiService } from "../../services/ApiService"; // api service class that contains general services ( functions and variables )
import { generalServices } from "../../services/GeneralService";
import Cart from "../Pages/Cart/Cart";
import Favourite from "../Pages/Favourite/Favourite";

class HeaderMobile extends Component {
  state = {
    showCategory: false,
    showMegaCategory: false,
    menuOpen: false,
    allCategory: [],
    topCategory: [],
    countries: [],
    topBrand: [],
    tab: "home",
    categoriesLoader: true,
    currentLang: this.props.language.lang,
  };

  componentDidMount() {
    Promise.all([
      this.getCountries(),
      this.getCategories(0, 0, 0, 1, 1),
      this.getCategories(0, 0, 1, 1, 1),
    ]);
    this.setState({ tab: "home" });
  }

  getCountries = async () => {
    // function to get countries from the server and set the first one to the current state
    const { data, success } = await generalServices.getAllCountries(0);
    if (!success) return;
    this.setState({
      countries: data[0],
    });
  };

  getCategories = async (parentId, isFeatured, topBar, showBrands, showSub) => {
    // function to get categories from the server and set it to current state base isTopBar select data name
    const { data, success } = await generalServices.getCategories(
      parentId,
      isFeatured,
      topBar,
      showBrands,
      showSub,
      this.props.country.id
    );
    if (!success) return;
    let name = topBar === 0 ? "allCategory" : "topCategory";
    this.setState({
      [name]: data,
      categoriesLoader: false,
    });
  };
  componentDidUpdate(prevProps) {
    // if the pathname changed set tab to the home
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ tab: "home" });
    }
  }

  render() {
    const {
      currentUser,
      i18n: {
        Profile,
        MyOrders,
        Logout,
        SUBSCRIPTIONS,
        GIFTCARDS,
        Home,
        Categories,
        Favourite: favoriteText,
        Account,
        TopCategories,
        CartMobile,
        viewAll,
        AddressBook,
        ReturnRequests,
        ExchangeRequests,
        LoyaltyProgram,
        Wallet,
        Settings,
      },
    } = this.props;
    const { tab, allCategory, categoriesLoader } = this.state;

    const accountLinks = [
      { name: Profile, url: "/Profile", img: "/img/profile.svg" },
      { name: MyOrders, url: "/orders/1", img: "/img/orders.svg" },
      {
        name: SUBSCRIPTIONS,
        url: "/SubscriptionsProfilePage/1",
        img: "/img/subscriptions.svg",
      },
      {
        name: GIFTCARDS,
        url: "/GiftCardProfilePage",
        img: "/img/gidtCard.svg",
      },
      { name: AddressBook, url: "/Address", img: "/img/orders.svg" },
      {
        name: ReturnRequests,
        url: "/ReturnRequestsProfilePage",
        img: "/img/orders.svg",
      },
      {
        name: ExchangeRequests,
        url: "/ExchangeRequestsProfilePage",
        img: "/img/orders.svg",
      },
      { name: LoyaltyProgram, url: "/LayaltyProgram/", img: "/img/orders.svg" },
      { name: Wallet, url: "/WalletPage/1", img: "/img/orders.svg" },
      { name: Settings, url: "/Settings", img: "/img/orders.svg" },
    ];

    return (
      <div
        className={`mobile-header ${tab !== "home" ? "active" : null}`}
        style={tab === "home" ? null : {}}
      >
        <div
          className="mobile-header__contents"
          style={tab === "home" ? { display: "none" } : null}
        >
          {tab === "home" ? null : (
            <>
              <Link to="/" className="mobile-header__logo">
                <img src="/img/Logo.svg" alt="/" />
              </Link>
            </>
          )}
          {tab === "cart" && (
            <div>
              <Cart />
            </div>
          )}
          {tab === "categories" && (
            <div className="header-mobile__categories">
              <div className="header-mobile__categories__all-category">
                <Accordion defaultActiveKey="0">
                  {categoriesLoader ? (
                    <>
                      {Array(20)
                        .fill("")
                        .map((item, index) => (
                          <Accordion.Item key={index}>
                            <Skeleton height={45} />
                          </Accordion.Item>
                        ))}
                    </>
                  ) : (
                    <>
                      {/* categories list start  */}
                      {allCategory.map(
                        (
                          { name, subcategories, id, machine_name, brands },
                          index
                        ) => {
                          if (subcategories.length > 0) {
                            return (
                              <Accordion.Item key={index} eventKey={id}>
                                <Accordion.Header>{name}</Accordion.Header>
                                <Accordion.Body>
                                  <h5 className="fw-bold">{TopCategories}</h5>
                                  <ul>
                                    {subcategories.map(({ name }, index) => {
                                      return (
                                        <li key={index}>
                                          <Link
                                            to={
                                              "/ProductsCategory/" +
                                              machine_name +
                                              "/1"
                                            }
                                          >
                                            {name}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <Link
                                    to={
                                      "/ProductsCategory/" + machine_name + "/1"
                                    }
                                  >
                                    <h5 className="fw-normal">{viewAll} ...</h5>
                                  </Link>
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          } else {
                            return (
                              <div
                                key={index}
                                eventKey={id}
                                className="without-arrow-accordion border-bottom border-gray"
                                disabled
                              >
                                <Link
                                  to={
                                    "/ProductsCategory/" + machine_name + "/1"
                                  }
                                >
                                  <Accordion.Header>{name}</Accordion.Header>
                                </Link>
                              </div>
                            );
                          }
                        }
                      )}
                    </>
                  )}
                </Accordion>
              </div>
            </div>
          )}

          {tab === "favourite" && <Favourite />}

          {tab === "account" && (
            <div className="header-mobile__account">
              <ul style={{ marginTop: "30px" }}>
                {/* header mobile account  */}
                {accountLinks.map((link, index) => (
                  <li key={index}>
                    <Link to={link.url} className="p-1">
                      <img src={link.img} alt={link.name} className="m-2" />
                      <p className="m-0 d-inline-block">{link.name}</p>
                    </Link>
                  </li>
                ))}
                <li>
                  <a
                    role="button"
                    onClick={async () => {
                      // logout and remove user data from localStorage and refresh
                      localStorage.removeItem("beam_admin_access_token");
                      localStorage.removeItem("beamAccessToken");
                      localStorage.removeItem("persist:Beam");
                      window.location.href = "/";
                    }}
                    className="p-1"
                  >
                    <img src="/img/log-in.svg" alt="k" className="m-2" />
                    <p className="m-0 d-inline-block">{Logout}</p>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>

        <nav className="mobile-header__tabs">
          <ul className="">
            <li
              onClick={() => {
                this.setState({
                  tab: "home",
                });
              }}
            >
              <Link to="/">
                <img src="/img/home1.svg" alt="search" />
                {Home}
              </Link>
            </li>

            <li
              onClick={() => {
                if (tab === "categories") {
                  this.setState({
                    tab: "home",
                  });
                } else {
                  this.setState({
                    tab: "categories",
                  });
                }
              }}
            >
              <a>
                <img src="/img/category1.svg" alt="search" />
                {Categories}
              </a>
            </li>

            <li>
              <Link to="/cart">
                <div className="img-container">
                  <img src="/img/cart1.svg" alt="search" />
                  {currentUser?.cart && currentUser?.cart > 0 ? (
                    <span>{currentUser?.cart}</span>
                  ) : null}
                </div>
                {CartMobile}
              </Link>
            </li>

            <li>
              <Link to="/Favourite">
                <div className="img-container">
                  <img src="/img/heart1.svg" alt="favorite" />
                  {currentUser?.fav && currentUser?.fav > 0 ? (
                    <span>{currentUser?.fav}</span>
                  ) : null}
                </div>
                {favoriteText}
              </Link>
            </li>

            {apiService.accessToken === 0 ? (
              <li>
                <a href="/signup">
                  <img src="/img/user1.svg" alt="search" />
                  {Account}
                </a>
              </li>
            ) : (
              <li
                onClick={() => {
                  if (tab === "account") {
                    this.setState({
                      tab: "home",
                    });
                  } else {
                    this.setState({
                      tab: "account",
                    });
                  }
                }}
              >
                <a>
                  <img src="/img/user1.svg" alt="search" />
                  {Account}
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }
}

const mapStateToProps = ({ language, currentUser, country }) => ({
  language: language,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country,
});
export default connect(mapStateToProps, { setLanguage })(
  withRouter(HeaderMobile)
);
