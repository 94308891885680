import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import { setUser } from "../../../../redux/actions-reducers/user";
import { setCurrency } from "../../../../redux/actions-reducers/currency";
import { generalServices } from "../../../../services/GeneralService";
import { displayAlert, mapSelectData } from "../../../../utils/misc";
import SelectInput from "../../../blocks/SelectInput";
import { withRouter } from "react-router-dom";
class SettingsForm extends Component {
  state = {
    remindMe: false,
    userInfo: {},
    languages: [],
    currencies: [],
    fields: {
      language: "",
      currancy: "",
      email_notifications: this.props.currentUser.email_notifications ?? false,
      sms_notifications: this.props.currentUser.sms_notifications ?? false,
    },
    errors: {
      language: "",
      currancy: "",
      email_notifications: "",
      sms_notifications: "",
    },
  };

  async componentDidMount() {
    await Promise.all([this.getCurrencies(), this.getLanguages()]);
    setTimeout(() => {
      this.setState({
        fields: {
          ...this.state.fields,
          language: this.state.languages.find(
            ({ direction }) => direction === localStorage.getItem("langCode")
          ),
          currancy: this.state.currencies.find(
            ({ code }) => code === this.props?.currency?.currencyName
          ),
        },
      });
    }, 0);
  }

  getCurrencies = async () => {
    // function to get Currencies from the server and set the first one to the redux (setCurrency from currency store) if not user currencyName

    const { data, success } = await generalServices.getCurrencies();

    if (!success) return;

    this.setState({
      currencies: mapSelectData(data),
    });
  };

  getLanguages = async () => {
    // function to get from the server and set if to the state
    const { data, success } = await generalServices.getLanguages();

    if (!success) return;

    this.setState({
      languages: mapSelectData(data),
    });
  };

  onFieldChange = (name, value) => {
    // Field Change handler
    const { fields } = this.state;
    this.setState({
      fields: { ...fields, [name]: value },
      errors: {
        first_name: "",
        last_name: "",
        language_id: "",
        Phone: "",
      },
    });
  };
  
  render() {
    const {
      language,
      i18n: { Language, Currency, saveChanges, EmailNot, SMSNot, Settings },
    } = this.props;
    const { languages, fields, errors, currencies } = this.state;

    return (
      <Container>
        <div
          className={`profile accountInformation  ${
            language.langCode === "rtl" && "profile-arabic"
          }`}
        >
          <div className="general-info">
            {/* title start */}
            <h2 className="general-info__title">{Settings}</h2>
            {/* title end */}

            <div className="profile-info__box">
              <Row>
                {/* language dropdown start  */}
                <Col md={12} className="profile-info__border-bottom mt-1">
                  <div className="settings-info-field d-flex align-items-center p-4 ps-0 pe-0">
                    <span>{Language}</span>
                    <span>:</span>
                    <SelectInput
                      name="language"
                      placeholder={fields.language}
                      value={fields.language}
                      data={languages}
                      onFieldChange={this.onLanguageChange}
                      validate={errors.language}
                    />
                  </div>
                </Col>
                {/* language dropdown end  */}
                {/* currency dropdown start  */}

                <Col md={12} className="profile-info__border-bottom mt-1">
                  <div className="settings-info-field d-flex align-items-center p-4 ps-0 pe-0">
                    <span>{Currency}</span>
                    <span>:</span>

                    <SelectInput
                      name="currancy"
                      placeholder={fields.currancy}
                      value={fields.currancy}
                      data={currencies}
                      onFieldChange={this.onCurrencyChange}
                      validate={errors.currancy}
                    />
                  </div>
                </Col>
                {/* currency dropdown start  */}

                {/* email  switch start  */}

                <Col md={12} className="profile-info__border-bottom mt-1">
                  <div className="settings-info-field d-flex align-items-center p-4 ps-0 pe-0">
                    <span>{EmailNot}</span>
                    <span>:</span>

                    <div className="SwitchButt">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={fields.email_notifications}
                          onClick={() =>
                            this.onFieldChange(
                              "email_notifications",
                              !fields.email_notifications
                            )
                          }
                        />

                        <span
                          className={`slider round ${
                            fields.email_notifications && "switchActive"
                          }`}
                        >
                          {fields.email_notifications ? "On" : "Off"}
                        </span>
                      </label>
                    </div>
                  </div>
                </Col>
                {/* email  switch end  */}

                {/* sms Notification  switch start  */}
                <Col md={12} className="mt-1">
                  <div className="settings-info-field d-flex align-items-center p-4 ps-0 pe-0">
                    <span>{SMSNot}</span>
                    <span>:</span>

                    <div className="SwitchButt">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={fields.sms_notifications}
                          onClick={() =>
                            this.onFieldChange(
                              "sms_notifications",
                              !fields.sms_notifications
                            )
                          }
                        />

                        <span
                          className={`slider round ${
                            fields.sms_notifications && "switchActive"
                          }`}
                        >
                          {fields.sms_notifications ? "On" : "Off"}
                        </span>
                      </label>
                    </div>
                  </div>
                </Col>
                {/* sms Notification  switch end  */}

                {/* save changes button start  */}
                <div className="button-container mt-3">
                  <button
                    className="submit-button2 m-end"
                    onClick={this.editUserSettings}
                  >
                    {saveChanges}
                  </button>
                </div>
                {/* save changes button end  */}
              </Row>
            </div>
          </div>
        </div>
      </Container>
    );
  }

  // on currency dropdown change start
  onCurrencyChange = (_, value) => {
    this.props.setCurrency({
      currencyName: value?.code,
      currencyFactor: value?.convert_factor,
    });

    this.setState({
      fields: {
        ...this.state.fields,
        currancy: value,
      },
    });
  };
  // on currency dropdown change end

  // update User/Guest Currency start
  updateUserGuestCurrency = async (id) => {
    const { success } = await generalServices.updateUserGuestCurrency(id);
    if (!success) return;
    window.location.reload();
  };
  // update User/Guest Currency end

  // on language dropdown change start
  onLanguageChange = async (_, value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        language: value,
      },
    });
  };
  // on language dropdown change end

  // Edit setting function end
  editUserSettings = async () => {
    const { fields } = this.state;
    const { Done, settingUpdateSuccessfully } = this.props.i18n;
    const { data, success } = await generalServices.editUserSettings({
      language_id: fields.language.value,
      currency_id: fields.currancy.value,
      email_notifications: fields.email_notifications ? 1 : 0,
      sms_notifications: fields.sms_notifications ? 1 : 0,
    });

    if (!success) return;

    if (
      localStorage.getItem("langCode") != fields.language?.direction ||
      this.props.currentUser?.currency?.id != fields.currancy?.value
    ) {
      localStorage.setItem("langCode", fields.language?.direction || "ltr");
      await this.props.setLanguage({
        lang: fields.language?.direction == "rtl" ? "English" : "العربية",
        langCode: fields.language?.direction || "ltr",
      });

      this.updateUserGuestCurrency(fields.currancy?.value);
    } else {
      this.props.setUser({
        email_notifications: fields.email_notifications ? 1 : 0,
        sms_notifications: fields.sms_notifications ? 1 : 0,
      });
      displayAlert(Done, settingUpdateSuccessfully, "success");
    }
  };
  // Edit setting function end
}

const mapStateToProps = ({ language, currentUser, country, currency }) => ({
  language: language,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
  country: country,
  currency,
});
export default connect(mapStateToProps, { setLanguage, setUser, setCurrency })(
  withRouter(SettingsForm)
);
