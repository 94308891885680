import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Global from "../../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../../redux/actions-reducers/language";
import { userService } from "../../../../services/UserService";
import PaginatedItems from "../../../Pages/Search/PaginationBlack";
import { withRouter } from "react-router-dom";
import OrderTable from "./OrderTable";
import ExchangeModal from "../../../blocks/ExchangeModal";

class Orders extends Component {
  state = {
    StatusCase: this.props.i18n.All,
    color: "",
    activeOrders: [],
    historyOrders: [],
    fields: {
      to: "",
      from: "",
      key: "",
      status: "",
    },
    currentPage: 1,
    loader: true,
    meta: {},
    openExchangeModal: false,
    returnOrExchangeType: "",
    orderId: "",
  };

  componentDidMount() {
    Promise.all([
      this.getUserOrders(true, false),
      this.getUserOrders(false, true),
    ]).then(() => {
      this.setState({
        pageLoader: false,
        loader: false,
      });
    });
  }
  // refetch Api's function start
  refetchData() {
    Promise.all([
      this.getUserOrders(true, false),
      this.getUserOrders(false, true),
    ]).then(() => {
      this.setState({
        pageLoader: false,
        loader: false,
      });
    });
  }
  // refetch Api's function end

  // get user Orders start
  getUserOrders = async (isActive, isHistory) => {
    // function to get user Orders from the server and set it to the current state isActive if the Active tab is Active and isHistory if the Active tab is History
    const { data, success, meta } = await userService.getUserOrders(
      this.props.match.params.page,
      {
        from_date: "",
        to_date: "",
        search_key: "",
        status: "",
        isActive,
        isHistory,
      }
    );
    if (!success) return;

    if (isActive) {
      this.setState({
        activeOrders: data,
        ["activeMeta"]: meta,
      });
    } else {
      this.setState({
        historyOrders: data,
        ["historyMeta"]: meta,
      });
    }
  };
  // get user Orders end

  // control exchange modal function start
  controlExchangeModal = (type, id, order) => {
    if (order && order.receiver_details.place?.id != 1) {
      this.props.history.push(`/PublicPagesData/international_refund_exchange`);
      return;
    }

    if (id && order && type) {
      this.setState({
        openExchangeModal: !this.state.openExchangeModal,
        returnOrExchangeType: type,
        orderId: id,
        thisOrder: order,
      });
    } else {
      this.setState({
        openExchangeModal: false,
        returnOrExchangeType: "",
        orderId: "",
      });
    }
  };

  // control exchange modal function end

  render() {
    const {
      language,
      i18n: { ordersEmpty },
    } = this.props;
    const {
      historyOrders,
      activeMeta,
      historyMeta,
      activeOrders,
      orderId,
      meta,
    } = this.state;

    return (
      <Container>
        {/* Exchange Modal start  */}
        {orderId && (
          <ExchangeModal
            controlExchangeModal={this.controlExchangeModal}
            openExchangeModal={this.state.openExchangeModal}
            type={this.state.returnOrExchangeType}
            orderId={orderId}
            thisOrder={this.state.thisOrder}
          />
        )}

        {/* Exchange Modal end  */}

        <div className={`orders ${language === "rtl" && "orders-arabic"}`}>
          {/* Order table start */}
          {this.state.loader ? (
            <>
              <Skeleton height={400} className="mt-3" />
            </>
          ) : activeOrders.length > 0 || historyOrders.length > 0 ? (
            <OrderTable
              refetchData={() => this.refetchData()}
              activeOrders={activeOrders}
              historyOrders={historyOrders}
              activeMeta={activeMeta}
              historyMeta={historyMeta}
              changePage={(page) => this.changePage(page)}
              isActive={true}
              controlExchangeModal={this.controlExchangeModal}
            />
          ) : (
            <Col md={12}>
              <div className="empty-products">
                <img src="/img/ordersEmpty2.svg" alt="k" />
                <p> {ordersEmpty} </p>
              </div>
            </Col>
          )}
          {/* Order table End */}
        </div>

        {/* Pagination Start */}
        {this.state.loader ? null : meta.last_page > 1 ? (
          <Row>
            <Col md={12}>
              <div className="Pagination">
                <PaginatedItems // pagination component that handle the pages
                  items={[...Array(meta?.total).keys()]}
                  itemsPerPage={meta?.per_page}
                  changePage={(page) => this.changePage(page)}
                />
              </div>
            </Col>
          </Row>
        ) : null}

        {/* Pagination End */}
      </Container>
    );
  }

  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(`/orders/${page + 1}`);
    this.setState(
      {
        pageLoader: true,
      },
      () => {
        this.getUserOrders(true, false);
        this.getUserOrders(false, true);
      }
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(withRouter(Orders));
