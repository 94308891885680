import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "react-awesome-lightbox/build/style.css";
import FsLightbox from "fslightbox-react";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )

export default function Gallery({
  items,
  _renderCustomControls,
  _renderCustomThumbs,
  imageLink,
  images,
  product,
}) {
  const [openLightBox, setOpenLightBox] = React.useState(false);
  const [lightboxOpen, setLightboxOpen] = useState({
    toggler: false,
    slide: 1,
  });

  const lightBoxToggleHandler = (number) => {
    setLightboxOpen({
      toggler: !lightboxOpen.toggler,
      slide: number,
    });
  };

  return (
    <>
      <div className="d-none d-md-block">
        <ImageGallery
          showBullets
          items={items}
          thumbnailPosition="left"
          showNav={true}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={true}
          renderItem={(images) => _renderCustomControls(images)}
          renderThumbInner={(images) => _renderCustomThumbs(images)}
          onClick={(event) => {
            let itemIndex = Array.from(
              document.querySelectorAll(".image-gallery-slides")[0]?.children
            )?.indexOf(event.target.parentElement.parentElement);
            if (itemIndex > -1) {
              lightBoxToggleHandler(itemIndex + 1);
            }
          }}
        />
      </div>
      <div className="d-md-none">
        <ImageGallery
          showBullets
          items={items}
          thumbnailPosition="bottom"
          showNav={true}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={true}
          renderItem={(images) => _renderCustomControls(images)}
          renderThumbInner={(images) => _renderCustomThumbs(images)}
          onClick={(event) => {
            let itemIndex = Array.from(
              document.querySelectorAll(".image-gallery-slides")[1]?.children
            )?.indexOf(event.target.parentElement.parentElement);
            if (itemIndex > -1) {
              lightBoxToggleHandler(itemIndex + 1);
            }
          }}
        />
      </div>

      <FsLightbox
        toggler={lightboxOpen.toggler}
        slide={lightboxOpen.slide}
        sources={images.map((item) => item.original)}
        types={[...new Array(images.length).fill("image")]}
        initialAnimation="scale-in-long"
        svg={{
          toolbarButtons: {
            zoomIn: {
              width: "20px",
            },
            zoomOut: {
              height: "20px",
            },
          },
        }}
        // {...(product?.images?.length === 1
        //   ? {
        //       image: imageLink + product?.images[0].image,
        //     }
        //   : {
        //       image: images[0]?.original,
        //     })}
        // {...(product?.images?.length > 1 && {
        //   images: product.images.map((item) => {
        //     return imageLink + item.image;
        //   }),
        // })}
        // {...(product?.images?.length === 0 &&
        //   images.length > 1 && {
        //     images: images.map((img) => img.original),
        //   })}
      />
    </>
  );
}
