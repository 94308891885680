import { apiService } from "./ApiService"; // api service class that contains general services ( functions and variables )
import { handleResponse } from "../utils/misc";
import { apiEndPoints } from "../api";
class GeneralServices {
  // guest Id getter function
  get guestId() {
    return localStorage.getItem("guestId");
  }

  // upload Image function
  async uploadImage(formData) {
    try {
      return await apiService
        .unauthenticated()
        .post(apiEndPoints.general.uploadFile, formData)
        .then(({ data }) => data);
    } catch ({ response }) {
      return handleResponse({ success: false, message: response, data: [] });
    }
  }

  // get All Countries function
  async getAllCountries(level) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getPlaces}?parent_id=${level}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Place Details function
  async getPlaceDetails(level) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getPlaceDetails}?place_id=${level}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Icon Data function
  async getIconData() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.general.getIconData}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Categories function
  async getCategories(
    parentId,
    isFeatured,
    topBar,
    showBrands,
    showSub,
    countryId
  ) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getCategories}?parent_id=${parentId}&is_featured=${isFeatured}&in_top_bar=${topBar}&show_brands=${showBrands}&show_subcategories=${showSub}&place_id=${countryId}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // function
  async getSlider(catId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getSlider}?category_id=${catId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Cities function
  async getCities(countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getCities}?country_id=${countryId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Areas function
  async getAreas(cityId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getAreas}?city_id=${cityId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Brands function
  async getBrands(limit) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getBrands}?with_limit=${limit}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Favorites IDs function
  async getFavoritesIDs() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.general.getFavoritesIDs}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Compares IDs function
  async getComparesIDs() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.general.getComparesIDs}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Cart IDs function
  async getCartIDs() {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.general.getCartIDs}?guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Home Ads function
  async getHomeAds(countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getHomeAds}?place_id=${countryId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Footer data ( settings ) function
  async getFooter() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.general.getFooter)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Social Media Links function
  async getSocialMediaLinks() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.general.getSocialMediaLinks)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Public Pages Data function
  async getPublicPagesData() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.general.getPublicPagesData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Currencies function
  async getCurrencies() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.general.getCurrencies)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Languages function
  async getLanguages() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(apiEndPoints.general.getLanguages + "?place_id=" + 1)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // update User GuestCurrency function
  async updateUserGuestCurrency(id) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.general.updateUserGuestCurrency, {
          currency_id: id,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // submit Contact Form function
  async submitContactForm(contactData) {
    try {
      const data = await apiService
        .authenticated()
        .post(apiEndPoints.general.submitContactForm, {
          ...contactData,
          guest_id: apiService.accessToken === 0 ? this.guestId : 0,
        })
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Public Pages Data Details function
  async getPublicPagesDataDetails(machine_name) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getPublicPagesDataDetails}?machine_name=${machine_name}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Payment Methods By Place ID function
  async getPaymentMethodsByPlaceID(countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getPaymentMethodsByPlaceID}?place_id=${countryId}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Help Center function
  async getHelpCenter(countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getHelpCenter}?place_id=${countryId}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Mystery Boxes function
  async getMysteryBoxes() {
    try {
      const data = await apiService
        .unauthenticated()
        .get(`${apiEndPoints.general.getMysteryBoxes}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Company Location By Place function
  async getCompanyLocationByPlace(countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getCompanyLocationByPlace}?&place_id=${countryId}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Place Fields function
  async getPlaceFields(countryId, user_location_id) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getPlaceFields}?place_id=${countryId}&user_location_id=${user_location_id}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Single Ads function
  async getSingleAds(machine_name, category_id, countryId) {
    try {
      const data = await apiService
        .unauthenticated()
        .get(
          `${apiEndPoints.general.getSingleAds}?machine_name=${machine_name}&category_id=${category_id}&place_id=${countryId}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // search function
  async search(key, catId, page) {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${
            apiEndPoints.general.search
          }?key=${key}&category_id=${catId}&page=${page}&guest_id=${
            apiService.accessToken === 0 ? this.guestId : 0
          }`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Gift Cards function
  async getGiftCards(page) {
    try {
      const data = await apiService
        .authenticated()
        .get(`${apiEndPoints.general.getGiftCards}?page=${page}`)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // create Gift Card Order function
  async createGiftCardOrder(giftCardData) {
    try {
      const data = await apiService
        .authenticated()
        .post(`${apiEndPoints.general.createGiftCardOrder}`, giftCardData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // get Gift Cards Order Details function
  async getGiftCardsOrderDetails(txn_ref) {
    try {
      const data = await apiService
        .authenticated()
        .get(
          `${apiEndPoints.general.getGiftCardsOrderDetails}?txn_ref=${txn_ref}`
        )
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // edit User Settings function
  async editUserSettings(settingData) {
    try {
      const data = await apiService
        .authenticated()
        .post(`${apiEndPoints.general.editUserSettings}`, settingData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const generalServices = new GeneralServices();
