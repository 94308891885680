import React from "react";
import { useSelector } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import moment from "moment";
import { apiService } from "../../../services/ApiService"; // api service class that contains general services ( functions and variables )

function GiftCardTable({ data }) {
  const language = useSelector(({ language }) => language);
  const i18n = language.langCode === "rtl" ? Global.ar : Global.en;
  const { Item, GiftCode, ActivationDate, ExpiryDate, AvailableBalance } = i18n;

  return (
    <>
      <div className="gift-card-table mb-5">
        <table>
          {/* table header start */}
          <tr className="table-header">
            <th>{Item}</th>
            <th>{GiftCode}</th>
            <th>{ActivationDate}</th>
            <th>{ExpiryDate}</th>
            <th>{AvailableBalance}</th>
          </tr>

          <tr className="gift-card-table__item">
            <td>
              <img
                src={apiService.imageLink + data?.gift_card?.image}
                alt="l"
              />
              <p>{data?.gift_card?.name}</p>
            </td>
            <td>{data?.gift_card?.code ?? "---"}</td>
            <td>
              {data?.activation_date
                ? moment(data?.activation_date).format("yy-MM-DD")
                : "---"}
            </td>
            <td>{moment(data?.validation_date).format("yy-MM-DD")}</td>
            <td>KWD {data?.amount?.toFixed(2)}</td>
          </tr>
        </table>
      </div>
    </>
  );
}

export default GiftCardTable;
