import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService"; // api service class that contains general services ( functions and variables )

class AuthService {
  // login function
  async login(userData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.login, userData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // social Login function
  async socialLogin(userData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.socialLogin, userData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // guest Login function
  async guestLogin(guestData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.guestLogin, guestData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // SignUp function
  async SignUp(userData) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.signUp, userData)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // logout function
  logout() {
    window.localStorage.clear();
    window.location.href = "/login";
  }

  // forget Password function
  async forgetPassword(email) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.forgetPassword, email)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // reset Password function
  async resetPassword(email) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.resetPassword, email)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }

  // verify Forget Password function
  async verifyForgetPassword(email) {
    try {
      const data = await apiService
        .unauthenticated()
        .post(apiEndPoints.auth.verifyForgetPassword, email)
        .then(({ data }) => data);
      return handleResponse({ success: true, ...data });
    } catch ({ response }) {
      return handleResponse({ success: false, ...response?.data });
    }
  }
}

export const authService = new AuthService();
