import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Product from "./Product";
import { connect } from "react-redux";
import Global from "../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import {
  desktopScreen,
  laptopScreen,
  tabletScreen,
} from "../../utils/misc";
import { phoneScreen } from "../../utils/misc";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class CategoriesBlock extends Component {
  state = {
    responsive: {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      laptop: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    },
  };
  render() {
    const {
      language,
      data,
      reFetchData,
      title,
      labelTitle,
      i18n: { RelatedProducts },
    } = this.props;
    const { responsive } = this.state;
    return (
      <div
        className={`categories-block  ${
          language === "rtl" && "categories-block-arabic"
        }`}
        style={{ backgroundColor: "#000", paddingBottom: "50px" }}
      >
        <Container>
          {/* category title and label start  */}
          <div className="categories-block__header">
            <span>{labelTitle}</span>
            <h3>{title}</h3>
            <div className="hr" />
          </div>
          {/* category title and label end  */}

          {/* category products carousel start  */}
          {data?.length < 1 ? (
            <>
              {/* if is loader return skeleton  */}
              <Row className="no-margin p-1">
                <Col>
                  <Skeleton height={phoneScreen ? 250 : 350} />
                </Col>

                {(laptopScreen || desktopScreen || tabletScreen) && (
                  <Col>
                    <Skeleton height={phoneScreen ? 150 : 350} />
                  </Col>
                )}
                {(laptopScreen || desktopScreen) && (
                  <Col>
                    <Skeleton height={phoneScreen ? 150 : 350} />
                  </Col>
                )}
                {(laptopScreen || desktopScreen) && (
                  <Col>
                    <Skeleton height={phoneScreen ? 150 : 350} />
                  </Col>
                )}
              </Row>
            </>
          ) : data?.length < 4 && !tabletScreen ? (
            // {/* if products less than 4  */}

            <Row className="no-margin p-1">
              {data?.map((item, index) => {
                return (
                  <Col
                    key={index}
                    lg={title === RelatedProducts ? 4 : 3}
                    md={4}
                    xs={6}
                  >
                    <Product // Product card that takes product as data and show product as a card with image
                      data={item}
                      reFetchData={() => reFetchData()}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Carousel // slider components
              responsive={responsive}
              isRTL={language === "rtl" && true}
              itemClass="carousel-item-margin-20-px"
              showDots={true}
              removeArrowOnDeviceType={["mobile", "tablet"]}
              infinite
            >
              {data?.map((item, index) => {
                return (
                  <Product // Product card that takes product as data and show product as a card with image
                    key={index}
                    data={item}
                    reFetchData={() => reFetchData()}
                  />
                );
              })}
            </Carousel>
          )}
          {/* category products carousel start  */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});
export default connect(mapStateToProps, { setLanguage })(CategoriesBlock);
