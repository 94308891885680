import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Global from "../../../language/Global.json"; // object that have tow keys (ar: arabic language object, en: english language object)
import { setLanguage } from "../../../redux/actions-reducers/language";
import Skeleton from "react-loading-skeleton";
import PageTitle from "../../blocks/PageTitle";
import FavoriteProductBlock from "../Favourite/FavoriteProductBlock";
import PaginatedItems from "../Search/PaginationBlack";
import { homeService } from "../../../services/homeService";

class FeaturedCollections extends Component {
  state = {
    fullView: false,
    cart: [],
    saleProducts: [],
    payment_methods: [],
    itemsPriceBeforeDiscount: 0,
    itemsPriceAfterDiscount: 0,
    adsData: [
      {
        image: "/images/Group55.png",
        link: "/",
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ],
    loaderPage: true,
    meta: {},
  };

  async componentDidMount() {
    this.getHomeTDRDeals(5);
  }

  getHomeTDRDeals = async (type) => {
    const { data, success } = await homeService.getHomeTDRDeals(type);
    if (!success) return;
    this.setState({
      saleProducts: data,
      loaderPage: false,
    });
  };

  render() {
    const { saleProducts, loaderPage, meta } = this.state;
    const {
      i18n: { featuredCollections, saleEmpty, Home },
    } = this.props;

    return (
      <div className="Cart">
        <PageTitle // component that shows links to the page ( Bread Crumb )
          title={featuredCollections}
          links={[
            { name: Home, url: "/" },
            { name: featuredCollections, url: "/FeaturedCollections" },
          ]}
        />

        {/* Start */}
        <Container>
          <Row>
            {/* featured Collections products start  */}
            {loaderPage ? (
              <>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
                <Col md={3} sm={6}>
                  <Skeleton height={250} className="mb-3" />
                </Col>
              </>
            ) : saleProducts.length === 0 ? (
              <Col md={12}>
                <div className="empty-products mb-5">
                  <img src="/img/saleEmpty.svg" alt="k" />
                  <p> {saleEmpty} </p>{" "}
                </div>
              </Col>
            ) : (
              saleProducts.map((item, index) => {
                return (
                  <Col lg={3} md={4} sm={6} className="mb-5">
                    <FavoriteProductBlock
                      data={item}
                      key={index}
                      reFetchData={() => this.getUserFavorites()}
                      fromSale
                    />
                  </Col>
                );
              })
            )}
            {/* featured Collections products end  */}
          </Row>

          {/* Pagination Start */}
          {loaderPage ? null : meta?.last_page > 1 ? (
            <Row>
              <Col md={12}>
                <div className="Pagination">
                  <PaginatedItems // pagination component that handle the pages
                    items={[...Array(meta?.total).keys()]}
                    itemsPerPage={meta?.per_page}
                    changePage={(page) => this.changePage(page)}
                  />
                </div>
              </Col>
            </Row>
          ) : null}

          {/* Pagination End */}
        </Container>
        {/* End */}
      </div>
    );
  }

  changePage(page) {
    // handel on Pagination page change
    this.props.history.push(`/FeaturedCollections/${page + 1}`);
    this.setState(
      {
        loaderPage: true,
      },
      () => {
        this.getSaleProducts();
      }
    );
  }
}

const mapStateToProps = ({ language, currentUser }) => ({
  language: language.langCode,
  i18n: language.langCode === "rtl" ? Global.ar : Global.en,
  currentUser,
});

export default connect(mapStateToProps, { setLanguage })(FeaturedCollections);
